import store from "../reducers/store";
import {appLoadingWrapper, appReadyWrapper, setAppModeWrapper} from "./appActions";
import * as actionTypes from "./actionTypes";
import {ADDON} from "../enums/appModes";
import UtilService from "../services/UtilService";
import BackendApi from "../api/BackendApi";
import AuthorizedUserService from "../services/AuthorizedUserService";
import PackageService from "../services/PackageService";

const apartmentNotFoundWrapper = (apartmentId) => {
    return {type: actionTypes.APARTMENT_NOT_FOUND, payload: { apartmentId: apartmentId }};
};

const receiveRoomWrapper = (room) => {
    return {type: actionTypes.ROOM_RECEIVE, payload: room}
};

const receiveLinkedProductsMapWrapper = (room, maps) => {
    return {type: actionTypes.LINKED_PRODUCTS_MAP_RECEIVE, payload: {forRoom: room, maps: maps}}
};

const receiveFakeProductSelectionsForStyleWrapper = (room, style, fakeProductSelections) => {
    return {type: actionTypes.STYLE_FAKE_PRODUCT_SELECTIONS_RECEIVE, payload: {forRoom: room, style: style, fakeProductSelections: fakeProductSelections}}
};

const lockApartmentWrapper = () => {
    return {type: actionTypes.LOCK_APARTMENT, payload: {}}
};

const lockAllRoomsWrapper = () => {
    return {type: actionTypes.LOCK_ALL_ROOMS, payload: {}}
};

export const loadRooms = (dispatch, state, apartmentId, rooms) => {
    let fetchRoomPromises = rooms.map(room =>
        state.app.appMode === ADDON
            ? BackendApi.fetchRoom(room.id)
            : BackendApi.fetchReadOnlyRoom(room.id, apartmentId)
            // workaround for the async issue in the reducer - TODO: find better solution
                .then(room => PackageService.restorePackageSelectionsFromLocalStorageInRoom(apartmentId, room))
    );

    return Promise.all(fetchRoomPromises).then(rooms => {
        rooms.forEach(room => {

            // Call API for more data about Packages (Style) in this room and their products ------------
            room.activeStyleGroups.forEach((asg) => {
                asg.styleGroup.styles.forEach((styleLight) => {
                    PackageService.getFakedProductSelectionsFromUnselectedPackageFromApi(styleLight, room, state.app.readOnlyMode ? apartmentId : undefined).then((fakeProductSelections) => {
                        dispatch(receiveFakeProductSelectionsForStyleWrapper(room, styleLight, fakeProductSelections));
                    });
                });
            });

            // Call API for more data about Linked products in this room --------------------------------
            let fetchLinkedProductMapsForRoomPromises = room.activeProductRelationshipGroups.map(aprg =>
                state.app.appMode === ADDON
                    ? BackendApi.fetchLinkedProductsMap(aprg.id)
                    : BackendApi.fetchReadOnlyLinkedProductsMap(aprg.id, apartmentId)
            );

            if (fetchLinkedProductMapsForRoomPromises.length > 0) {
                Promise.all(fetchLinkedProductMapsForRoomPromises).then((maps) => {
                    const flattenMaps = [].concat(...maps);
                    dispatch(receiveLinkedProductsMapWrapper(room, flattenMaps));
                });
            }
            // End of Linked products -------------------------------------------------------------------

            // Initial converter on new room
            dispatch(receiveRoomWrapper(room));
        });
    });
};

export const getApartment = (appMode, singleApartment, apartmentId) => {
    return (dispatch) => {
        const state = store.getState();

        // set selected customer
        AuthorizedUserService.removeSelectedCustomer();
        if (state.app.me && state.app.me.customers) {
            const customer = state.app.me.customers.find(customer => '' + customer.apartment.id === '' + apartmentId);
            if (customer) {
                AuthorizedUserService.selectCustomer(customer.id);
            }
        }

        if (appMode && (state.app.appMode !== appMode || state.app.singleApartment !== singleApartment))
            dispatch(setAppModeWrapper(appMode, singleApartment));

        if (state.app.appMode === ADDON) {
            const apartmentIdAsNumber = +apartmentId;
            if (!UtilService.equals(apartmentIdAsNumber, apartmentId)) {
                if (state.app.apartment2.apartmentId !== apartmentId || !state.app.apartment2.notFound) {
                    dispatch(apartmentNotFoundWrapper(apartmentId));
                    dispatch(appReadyWrapper());
                }
                return;
            }

            apartmentId = apartmentIdAsNumber
        }

        if (state.app.apartment2.apartmentId === apartmentId) return;

        dispatch(appLoadingWrapper());
        dispatch({type: actionTypes.APARTMENT_LOADING, payload: { apartmentId: apartmentId }});

        let fetchApartmentPromise = state.app.appMode === ADDON
            ? BackendApi.fetchApartmentById(apartmentId)
            : BackendApi.fetchApartmentByPublicKey(apartmentId);

        fetchApartmentPromise.then (apartment => {
            if (apartment) {
                loadRooms(dispatch, state, apartmentId, apartment.apartmentRooms)
                    .then(() => dispatch({type: actionTypes.APARTMENT_LOADED, payload: { apartmentId: apartmentId, apartment: apartment }}))
                    .finally(() => dispatch(appReadyWrapper()));
            } else {
                dispatch(apartmentNotFoundWrapper(apartmentId));
                dispatch(appReadyWrapper());
            }
        }).catch(() => {
            dispatch(apartmentNotFoundWrapper(apartmentId));
            dispatch(appReadyWrapper());
        });
    }
};

export const getConfirmationMessage = () => {
    return (dispatch) => {
        const state = store.getState();

        if (state.app.appMode === ADDON && state.app.apartment2.apartmentId) {
            return BackendApi.getApartmentConfirmationMessage(state.app.apartment2.apartmentId);
        }
    }
};

export const getAddonCheckout = (apartmentId) => {
    return (dispatch) => {
        let loadingMessage = UtilService.getLoadingMessage("addonCheckout");

        dispatch(appLoadingWrapper(loadingMessage));

        return BackendApi.addonCheckout(apartmentId).then(checkoutResult => {
            dispatch(appReadyWrapper());
            return checkoutResult;
        }).catch((error) => {
            dispatch(appReadyWrapper());
            throw error;
        })
    }
};

export const getDefaultMessageBoxGreeting = () => {
    return (dispatch) => {
        const state = store.getState();

        if (state.app.appMode !== ADDON || !state.app.apartment2.apartment) {
            return Promise.reject();
        }

        return BackendApi.fetchDefaultMessageBoxGreeting(state.app.apartment2.apartmentId);
    }
};

export const sendNewMessage = (newMessageText) => {
    return (dispatch) => {
        const state = store.getState();

        if (state.app.appMode !== ADDON || !state.app.apartment2.apartment || !newMessageText || newMessageText === '')
            return Promise.resolve();

        const newMessage = {
            text: newMessageText,
            isReadByCustomer: true,
            apartment: {
                id: state.app.apartment2.apartment.id
            }
        };

        return BackendApi.sendNewMessage(newMessage)
            .then(messages => {
                dispatch({type: actionTypes.MESSAGES_UPDATED, payload: messages});
            });
    }
};

export const markMessagesAsRead = () => {
    return (dispatch) => {
        const state = store.getState();

        if (state.app.appMode !== ADDON || !state.app.apartment2.apartment) return;

        const messageIds = state.app.apartment2.apartment.messages
            ? state.app.apartment2.apartment.messages
                .filter(msg => !msg.isReadByCustomer)
                .map(msg => msg.id)
            : [];

        if (messageIds.length === 0)
            return;

        BackendApi.markMessagesAsReadByCustomer(messageIds).then(messages => {
            dispatch({type: actionTypes.MESSAGES_UPDATED, payload: messages});
        });
    }
};

// This will just update the local redux variables until next page reload
export const softLockApartmentAndAllRooms = () => {
    return (dispatch) => {
        dispatch(lockApartmentWrapper());
        dispatch(lockAllRoomsWrapper());
    }
};
