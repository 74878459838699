import * as types from './actionTypes';
import ProductService from "../services/ProductService";
import BackendApi from "../api/BackendApi";
import store from "../reducers/store";
import ProjectProduct from "../models/ProjectProduct";
import PackageService from "../services/PackageService";

// Reducer actions related to products
export function productChanging(changing) {
    return {type: types.PRODUCT_CHANGING, payload: changing};
}

export function changeProjectProductSuccess(projectProduct, productSelection) {
    return {type: types.PRODUCT_CHANGE, payload: {projectProduct: projectProduct, productSelection: productSelection}}
}

export function dispatchChangeProjectProductSuccess(projectProduct, productSelection) {
    return function (dispatch) {
        dispatch(changeProjectProductSuccess(projectProduct, productSelection));
        setTimeout(() => {
            dispatch(productChanging(false))
        }, 1000);
    }
}

export function openProductCategoryChangeSuccess(productSelection) {
    return {type: types.CLICKED_PRODUCT_CATEGORY_CHANGE, payload: {productSelection: productSelection}}
}

export function changeOpenProductCategory(productSelection) {
    return function (dispatch) {
        dispatch(openProductCategoryChangeSuccess(productSelection));
    }
}

export function openPackageChangeSuccess(style) {
    return {type: types.CLICKED_PACKAGE_CHANGE, payload: {style: style}}
}

export function changeOpenPackage(style) {
    return function (dispatch) {
        dispatch(openPackageChangeSuccess(style));
    }
}

//TODO this doesn't seem to work selecting same product again
export function changeProjectProduct(projectProduct, productSelection) {

    return function (dispatch) {

        dispatch(productChanging(true));

        //TODO reapply this
        if (store.getState().app.readOnlyMode) {
            // Offline mode, Preform the selection, this will update the reference ultimately coming from the currentRoom in redux store
            productSelection.setProjectProduct(projectProduct);
            ProductService.persistProductSelectionToLocalStorage(store.getState().app.apartment2.apartmentId, store.getState().app.currentRoom, productSelection, projectProduct);

            // Dispatch event
            return dispatch(
                dispatchChangeProjectProductSuccess(projectProduct, productSelection)
            );
        } else {
            // Api mode, perform the selection with a call and update the local product selection variable
            BackendApi.selectProjectProduct(productSelection.id, projectProduct.id).then((ps) => {

                //FIXME Parsing the whole productSelection from JSON will break object equality checking especially for alternatives.
                // This is although probably the best thing to do as the frontend would respect the API response over its own pricing calculation etc.

                // productSelection.parse(ps);
                const newProjectProduct = new ProjectProduct().parse(ps.projectProduct);
                productSelection.setProjectProduct(newProjectProduct);

                // Dispatch event
                return dispatch(
                    dispatchChangeProjectProductSuccess(projectProduct, productSelection)
                );
            }).catch(() => {
                dispatch(productChanging(false));
            });
        }
    }
}

//TODO implement me when necessary
export function resetProductSelection(productSelection) {

    return function (dispatch) {

        if (store.getState().app.readOnlyMode) {
            // Offline mode, Preform the selection, this will update the reference ultimately coming from the currentRoom in redux store
            productSelection.reset();
            ProductService.persistProductSelectionToLocalStorage(store.getState().app.apartment2.apartmentId, store.getState().app.currentRoom, productSelection, productSelection.projectProduct);

            // Dispatch event
            return dispatch(
                dispatchChangeProjectProductSuccess(productSelection.projectProduct, productSelection)
            );
        } else {
            // TODO!
            // BackendApi.resetProductSelection(productSelection.id).then((ps) => {
            //     productSelection.setProjectProduct(aLocalInstance);
            //
            //     return dispatch(
            //         dispatchChangeProjectProductSuccess(aLocalInstance, productSelection)
            //     );
            // })
        }
    }
}

//TODO this should be calling the reset method instead, remove this references.
export function removeExtraOption(productSelection) {
    return function (dispatch) {

        if (store.getState().app.readOnlyMode) {
            // Offline mode, Preform the selection, this will update the reference ultimately coming from the currentRoom in redux store
            productSelection.setProjectProduct(null);
            ProductService.persistProductSelectionToLocalStorage(store.getState().app.apartment2.apartmentId, store.getState().app.currentRoom, productSelection, undefined);

            // Dispatch event
            return dispatch(
                dispatchChangeProjectProductSuccess(null, productSelection)
            );
        } else {
            BackendApi.resetProductSelection(productSelection.id).then((ps) => {
                productSelection.setProjectProduct(null);

                return dispatch(
                    dispatchChangeProjectProductSuccess(null, productSelection)
                );
            })
        }
    }
}

export function selectStyle(activeStyle, newStyle) {
    return function (dispatch) {

        if (store.getState().app.readOnlyMode) {

            PackageService.selectStyle(activeStyle, newStyle, newStyle.productSelections);
            PackageService.persistPackageSelectionsToLocalStorage(store.getState().app.apartment2.apartmentId, store.getState().app.currentRoom, activeStyle, newStyle);

            dispatch(dispatchChangeProjectProductSuccess());
        } else {
            BackendApi.selectPackage(activeStyle.id, newStyle.id).then ((updatedActiveStyle) => {
                PackageService.selectStyle(activeStyle, newStyle, updatedActiveStyle.productSelections);
                dispatch(dispatchChangeProjectProductSuccess());
            })
        }
    }
}
