import React, {Component} from 'react';
import * as productActions from "../../../../actions/productActions";
import * as imageActions from "../../../../actions/imageActions";
import ExtraOptionAvailable from "../../../../assets/images/extraOptionAvailable.png"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './style.scss'
import ProductStatus from "../ProductStatus";
import ProjectProduct from "../../../../models/ProjectProduct";
import PropTypes from "prop-types";
import ProductService from "../../../../services/ProductService";
import HMImage from "../../../Utils/HMImage";
import ProductProperty from "../ProductProperty"
import ProductSelection from "../../../../models/ProductSelection";
import HMButton from "../../../Utils/HMButton";
import HMInformationIcon from "../../../Utils/HMInformationButton";

// This class represent a ProjectProduct but aware of all other project products thru it's metaProduct
class ProductCard extends Component {

    isEmptyExtraOption() {
        return !this.props.productSelection.projectProduct && !this.props.projectProduct
    }

    onShowInfo(e = undefined) {
        if (this.props.showInfo) {
            if (e) {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }

            this.props.showInfo();
        }
    }

    render() {
        const {projectProduct, showStatus, productSelection} = this.props;

        //TODO not needed anymore
        // const statusBorderColorClass = ProductService.getStatusStyle(projectProduct).borderColorClass;

        return (
            <div className="row no-gutters hm-product-card">
                {showStatus && !this.isEmptyExtraOption() &&
                <div className="col-12">
                    <ProductStatus projectProduct={projectProduct} hidePrices={this.props.hidePrices}/>
                </div>
                }

                <div className="row no-gutters hm-product-data">

                    <div className="col-auto" onClick={e => this.onShowInfo(e)}>
                        <HMImage imageSrc={this.isEmptyExtraOption() ? ExtraOptionAvailable : ProductService.getImageUrl(projectProduct.product)}
                                 className={`product-image ${projectProduct && projectProduct.disabled ? "disabled" : ""}`}
                                 title={projectProduct && projectProduct.product.productNum}
                                 imageVisibilityType={'contain'}
                        />
                    </div>

                    {!this.isEmptyExtraOption() ?
                        (<div className="col product-description">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="text-uppercase">
                                        <h5 className={`bold ${projectProduct.disabled ? "disabled" : ""}`}>{projectProduct.product.vendor} - {projectProduct.product.name}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <ProductProperty hideKey={true} propertyKey="common:product.property.color"
                                                     property={projectProduct.product.color}/>
                                    <ProductProperty hideKey={true} propertyKey="common:product.property.material"
                                                     property={projectProduct.product.material}/>
                                    <ProductProperty hideKey={true} propertyKey="common:product.property.assembly"
                                                     property={projectProduct.product.assembly}/>
                                    <ProductProperty hideKey={true} propertyKey="common:product.property.dimension"
                                                     property={projectProduct.product.dimension}/>
                                </div>
                            </div>
                            {this.props.isShowInfoButton &&
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="float-right">
                                        <HMButton
                                            thinStyle={true}
                                            text={""}
                                            active={false}
                                            clickFn={() => this.onShowInfo()}
                                        >
                                            <HMInformationIcon/>
                                        </HMButton>
                                    </div>
                                </div>
                            </div>}

                            {this.props.children &&
                            <div className="row no-gutters product-children">
                                <div className="col-12">
                                    {this.props.children}
                                </div>
                            </div>
                            }
                        </div>)
                        :
                        (<div className="col product-description">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="text-uppercase">
                                        <h5 className={`bold`}>{productSelection.subCategory.name}</h5>
                                    </div>
                                </div>
                            </div>

                            {this.props.children &&
                            <div className="row no-gutters product-children">
                                <div className="col-12">
                                    {this.props.children}
                                </div>
                            </div>
                            }
                        </div>)
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        roomRelationships: state.app.roomRelationships,
        currentRoom: state.app.currentRoom,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActions, dispatch),
        imageActions: bindActionCreators(imageActions, dispatch),
    }
}

ProductCard.propTypes = {
    hidePrices: PropTypes.bool,
    projectProduct: PropTypes.instanceOf(ProjectProduct), // This is a project product to be displayed as a row
    productSelection: PropTypes.instanceOf(ProductSelection).isRequired, // Sometimes we need to examine product selection to show unselected extra option image
    showStatus: PropTypes.bool,
    isShowInfoButton: PropTypes.bool,
    showInfo: PropTypes.func,
};

ProductCard.defaultProps = {
    showStatus: true,
    isShowInfoButton: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
