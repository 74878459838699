import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import AddonCheckout from "./AddonCheckout";
import ContactForm from './ContactForm';
import {ADDON} from "../../enums/appModes";
import {bindActionCreators} from "redux";
import * as roomActions from "../../actions/roomActions";
import {AppRoute} from '../../services/RouterService';
import * as apartmentActions from "../../actions/apartmentActions";

class Checkout extends Component {

    componentDidMount() {
        this.props.roomActions.changeRoom(AppRoute.Checkout);
        this.props.apartmentActions.softLockApartmentAndAllRooms();
    }

    render() {
        if (this.props.currentRoomId === AppRoute.Checkout) {
            if (this.props.appMode === ADDON) {
                return (<AddonCheckout/>);
            } else {
                return (<ContactForm/>);
            }
        } else
            return null;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        currentRoomId: state.app.currentRoomId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        roomActions: bindActionCreators(roomActions, dispatch),
        apartmentActions: bindActionCreators(apartmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
