import React, {Component} from 'react';
import './style.scss'
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

class HeaderLabel extends Component {

    render() {
        const {text} = this.props;
        let subText = (this.props.subText || "").replace(/\n/g, "<br/>");

        if ((!subText || subText === "") && this.props.fallbackSubTextKey && this.props.fallbackSubTextKey !== "") {
            subText = this.props.t(this.props.fallbackSubTextKey);
        }

        return (
            <div className="hm-header-label">
                <h2>
                    {text}
                </h2>
                <div className={`${text && subText !== "" ? "top-border": ""}`}>
                    <p className="sub-text" dangerouslySetInnerHTML={{__html: subText}}></p>
                </div>
            </div>
        )
    }
}

HeaderLabel.propTypes = {
    text: PropTypes.string.isRequired,
    subText: PropTypes.string,
    fallbackSubTextKey: PropTypes.string
};

HeaderLabel.defaultProps = {
    text: "",
    subText: ""
};

export default withTranslation('common')(HeaderLabel);
