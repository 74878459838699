import ProjectProduct from "./ProjectProduct";
import ModelValidationError from "./ModelValidationError";
import ProductService from "../services/ProductService";

export default class ProductSelection {
    constructor() {
        this.id = null;
        this.room = null;
        this.note = "";
        this.price = 0;
        this.quantity = 1;
        this.projectProduct = null;
        this.subCategory = null;
        this.priceAddition = 0;
        this.discount = 0;
        this.priceAdjustment = 0;
        this.extraOption = false;

        // Options array is a primary source of Product options for this selection and are used in a lot of places.
        // For example product relationships. The instances here are reused and added as this.projectProduct and this.standards.
        // This is to ensure object reference reuse and one reference update apply to all occurencies of the same product. Kind of like a local redux storage.
        this.options = [];

        this.standards = [];

        // The awakened property was used before to give user feedback about the complex events of relationships and their effect but are not used anymore
        this.awakened = false;
    }

    parse(obj) {
        Object.assign(this, obj);

        // We're trying to reuse object assignments below that will ease object equally checks throughout the application
        // Don't alter this without understanding implications.

        if (obj.options.length > 0) {
            this.options = obj.options.map((val) => { return new ProjectProduct().parse(val)});
            // Sort options according to a specific rule set
            this.options.sort((a,b) => {
                return ProductService.getStatusWeightForSorting(b) - ProductService.getStatusWeightForSorting(a);
            });
        }

        if (obj.standards.length > 0) {
            this.standards = obj.standards.map((val) => {

                // Lets try pass as reference from already parsed options. This ease other logic
                const reusedPPInstance = this.options.find((pp) => { return pp.id === val.id});

                // It should be extremely unusual that a standard product is not in options. Potential bug
                return reusedPPInstance ? reusedPPInstance : new ProjectProduct().parse(val);
            });
        }

        if (obj.projectProduct) {
            // Complex object that needs to be set explicitly
            this.setProjectProduct(new ProjectProduct().parse(obj.projectProduct));
        }

        this._validate();

        return this;
    }

    // This is the preferred way of changing the project product
    setProjectProduct (projectProduct) {

        if (!projectProduct) {
            // This is resetting an extra option
            if (!this.extraOption)
                throw new ModelValidationError("Only extra options can be without a product");

            this.price = 0;
            this.projectProduct = null;

        } else {

            // Lets try pass as reference from already parsed options.
            // This ease other logic that depends on object equality and object referencing
            const reusedPPInstance = this.options.find((pp) => { return pp.id === projectProduct.id});

            // Only in cases a selected project product is no longer in use or is disabled should we not be able to use reused instance from the options array
            this.projectProduct = reusedPPInstance ? reusedPPInstance : projectProduct;

            this.price = this.projectProduct.price * this.quantity + this.priceAdjustment;
            this.subCategory = this.projectProduct.product.subCategory;
        }

        this._validate();

        return this;
    }

    getDelayedPrice () {
        if (this.projectProduct)
            return this.projectProduct.delayedPrice && this.priceAdjustment === 0;
        return false;
    }

    reset(){
        //TODO add possibility to reset an extra option -> this.projectProduct = undefined
        // Only non extra should throw this error
        if(this.standards.length === 0)
            throw new Error("The product selection is missing standard option and can therefore not be reset");

        this.setProjectProduct(this.standards[0]);
    }

    // This will be the method that tells if this product selection (with it's selected product) is disabled by another one in the room (ProductRelationship / Linked Product)
    isDisabledFromProductRelationships() {
        return this.projectProduct && this.projectProduct.disabled;
    }

    // Is this product selection completely disabled and no other choice exists? Time to hide this from UI until it gets awaken again
    // This is true also for extra options in the initial phase where they miss a selected product
    hasNoEnabledChoisesFromProductRelationships() {
        return (this.isDisabledFromProductRelationships() || this.extraOption) && this.options.filter((pp) => {return !pp.disabled}).length === 0
    }

    resetMarkingFromProductRelationships() {

        if(this.projectProduct)
            this.projectProduct.resetMarkingFromProductRelationships();

        this.standards.forEach((projectProduct) => {
            projectProduct.resetMarkingFromProductRelationships();
        });
        this.options.forEach((projectProduct) => {
            projectProduct.resetMarkingFromProductRelationships();
        });

        // This is an indicator telling the product selection went from fully hidden (hasNoEnabledChoisesFromProductRelationships() === true) to not hidden. It's therefore awakened.
        // This should be used for UI feedback like highlighting etc.
        this.awakened = false;
    }

    /**
     * Gets all the products linked to this product selection. You may call these selectables or choosables if you want.
     * @returns {Map<productId, Product>}
     */
    getAllProducts () {
        const products = new Map();

        if (this.projectProduct)
            products.set(this.projectProduct.product.id, this.projectProduct.product);

        this.standards.forEach((pp) => {
            products.set(pp.product.id, pp.product);
        });

        this.options.forEach((pp) => {
            products.set(pp.product.id, pp.product);
        });

        return products;
    }

    _validate() {
        if (!this.id) {
            throw new ModelValidationError("Instance is missing id");
        }

        if (!this.subCategory) {
            throw new ModelValidationError("Instance is missing sub category");
        }

        if (!this.room) {
            throw new ModelValidationError("Instance is missing room");
        }
    }
}
