import React, {Component} from 'react';
import "./style.scss"
import {withTranslation} from "react-i18next";
import CollectorCheckoutService from "../../../services/CollectorCheckoutService";
import RouterService from "../../../services/RouterService";
import PropTypes from "prop-types";
import {withRouter} from "react-router";

class CollectorCheckout extends Component {

    componentDidMount() {
        if (CollectorCheckoutService.isCompleted(this.props.payment)
        ) {
            this.props.history.replace(RouterService.confirmationPath());
        }
    }

    render() {
        if (!this.props.payment
            || !this.props.payment.data
            || CollectorCheckoutService.isCompleted(this.props.payment)) {
            return null;
        }

        const { t, payment } = this.props;

        return(
            <div className="container-fluid hm-collector-checkout">

                <div className="row no-gutters mb-0 hm-collector-checkout-header bg-frosted-heavy">
                    <div className="col">
                        <div className="row no-gutters">
                            <div className="col-6 pl-0">
                                <h2 className="normalcase mb-0">{t('collectorCheckout.paymentGeneration.title')}</h2>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row no-gutters mt-0 hm-collector-checkout-header hm-collector-checkout-frame">
                    <div className="col px-0 hm-collector-checkout-frame" ref={CollectorCheckoutService.setDangerousHtml.bind(null, CollectorCheckoutService.getCollectorCheckoutScript(payment.data))}>
                    </div>
                </div>

            </div>
        )
    }
}

CollectorCheckout.defaultProps = {
    payment: PropTypes.object.isRequired
};

export default withRouter(withTranslation('common')(CollectorCheckout));
