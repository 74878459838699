import React, {Component} from 'react';
import {connect} from "react-redux";
import * as appActions from "../../../actions/appActions";
import * as apartmentActions from "../../../actions/apartmentActions";
import {bindActionCreators} from "redux";
import CollectorCheckout from "../CollectorCheckout"
import DigitalSigningCheckout from "../DigitalSigningCheckout"
import LegacyDigitalSigningCheckout from "../LegacyDigitalSigningCheckout"
import {COLLECTOR_CHECKOUT, DIGITAL_SIGNING_ASSENTLY, DIGITAL_SIGNING_SCRIVE} from "../../../enums/checkoutTypes";
import {withTranslation} from "react-i18next";

class AddonCheckout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkout: undefined,
            showCheckoutError: false
        };
    }

    componentDidMount() {
        this.props.apartmentActions.getAddonCheckout(this.props.apartment.apartmentId).then((result) => {
            this.setState({...this.state, checkout: result});
        }).catch(error => {
            this.setState({...this.state, checkout: undefined, showCheckoutError: true});
        });
    }

    render() {

        const {t} = this.props;

        if (this.state.showCheckoutError)
            return(<div className="general-error-message"><h4>{t('errors.checkoutError')}</h4></div>);

        if (!this.state.checkout)
            return(null);

        if (this.state.checkout.type === COLLECTOR_CHECKOUT)
            return (<CollectorCheckout payment={this.state.checkout.data}/>);

        if (this.state.checkout.type === DIGITAL_SIGNING_SCRIVE)
            return (<DigitalSigningCheckout contract={this.state.checkout.data}/>);

        // Legacy contracts
        if (this.state.checkout.type === DIGITAL_SIGNING_ASSENTLY)
            return (<LegacyDigitalSigningCheckout case={this.state.checkout.data}/>);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        apartment: state.app.apartment2
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch),
        apartmentActions: bindActionCreators(apartmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddonCheckout));
