import {Config} from "../config";
import BackendApi from "./BackendApi";

export default class BackendAuthApi {

    static authorize(username, password) {
        return BackendApi.doFetch(`${Config.API_BASE_URL}/api/login`, {"username": username, "password": password});
    }

    static forgotPassword(username) {
        return BackendApi.doFetch(`${Config.API_BASE_URL}/password/forgot`, {"username": username});
    }

    static resetPassword(resetToken, newPassword) {
        return BackendApi.doFetch(`${Config.API_BASE_URL}/password/reset`, {"resetToken": resetToken, "newPassword": newPassword});
    }

    // static deauthorize() {
    //     return this.call(`${Config.API_BASE_URL}/logout`);
    // }
}
