import React, {Component} from 'react';
import {connect} from "react-redux";
import * as roomActions from "../../actions/roomActions";
import {bindActionCreators} from "redux";
import "./style.scss"
import {withRouter} from 'react-router-dom';
import RoomSummary from "./RoomSummary";
import {withTranslation} from "react-i18next";
import {AppRoute} from '../../services/RouterService';
import SummaryCallToActionBottom from "./SummaryCallToActionBottom";
import {ADDON} from "../../enums/appModes";
import SummaryCallToActionTop from "./SummaryCallToActionTop";
import HMScrollDown from "../Utils/HMScrollDown";

class Summary extends Component {

    componentDidMount() {
        this.props.roomActions.changeRoom(AppRoute.Summary);
    }

    render() {
        // const { t } = this.props;

        if (this.props.currentRoomId === AppRoute.Summary
            && this.props.rooms)
            return(
                <div className="container-fluid hm-showroom-summary">

                    <SummaryCallToActionTop
                        hidePrices={this.props.hidePrices}
                        apartmentLocked={this.props.apartment.isLocked}
                        addonMode={this.props.appMode === ADDON}
                        grandTotal={this.props.grandTotal}
                        roomsOrder={this.props.roomsOrder}
                        rooms={this.props.rooms}
                        doLeadsForSalesStart={this.props.project.settings.doLeadsForSalesStart}
                    />

                    {this.props.roomsOrder.map((roomId, i) => {
                        if (roomId !== 0) //Don't show summary room
                            return <RoomSummary
                                key={`${this.constructor.name}-${i}-${roomId}`}
                                hidePrices={this.props.hidePrices}
                                room={this.props.rooms.get(roomId)}
                                forcedRelationshipUpdate={this.props.forcedRelationshipUpdate}
                                grandTotal={this.props.grandTotal}
                            />;

                        return null;
                    })}


                    <SummaryCallToActionBottom
                        hidePrices={this.props.hidePrices}
                        doLeadsForSalesStart={this.props.project.settings.doLeadsForSalesStart}
                        apartmentLocked={this.props.apartment.isLocked}
                        addonMode={this.props.appMode === ADDON}
                        grandTotal={this.props.grandTotal}
                    />

                    <HMScrollDown/>
                </div>
            );
        else
            return null;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        forcedRelationshipUpdate: state.app.forcedRelationshipUpdate || 0,
        apartment: state.app.apartment2.apartment,
        project: state.app.project.project,
        appMode: state.app.appMode,
        readOnlyMode: state.app.readOnlyMode,
        hidePrices: state.app.hidePrices,
        currentRoomId: state.app.currentRoomId,
        rooms: state.app.rooms,
        roomsOrder: state.app.roomsOrder,
        grandTotal: state.app.grandTotal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        roomActions: bindActionCreators(roomActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Summary)));
