import React, {Component} from 'react';
import {connect} from "react-redux";
import * as appActions from "../../../actions/appActions";
import * as apartmentActions from "../../../actions/apartmentActions";
import {bindActionCreators} from "redux";
import HMImageCarousel, {HMImageCarouselMode} from "../../Utils/HMImageCarousel";
import SigningParty from "./SigningParty";
import PropTypes from "prop-types";
import RouterService from "../../../services/RouterService";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import DigitalSigningService from "../../../services/DigitalSigningService";
import "./style.scss"

class LegacyDigitalSigningCheckout extends Component {

    render() {

        // Check props and if all wanted parties (customers) has signed go to confirmation
        if (!this.props.case || DigitalSigningService.isCaseFinishedForCustomer(this.props.case, true)) {
            // this.props.history.push(RouterService.confirmationPath());
            return (null);
        }

        const {t} = this.props;

        return(
            <div className="container-fluid hm-project-landing hm-digital-signing">
                {this.props.project.project.moodImagesSorted.header.length > 0 &&
                <HMImageCarousel images={this.props.project.project.moodImagesSorted.header.map(image => image.url)}
                                 containerHeight="226px"
                                 mode={HMImageCarouselMode.OneFadingImage}/>
                }
                <div className="row m-0 p-3 bg-frosted no-gutters">
                    <div className="col content bg-frosted text-center">
                        <h1>{t('signingCheckout.title')}</h1>
                        <p className="m-0 p-0">{t('signingCheckout.subTitle')}</p>
                    </div>
                </div>
                <div className="row no-gutters justify-content-center">
                    {this.props.case.parties.map(party => {
                        if (!party.isAgent)
                            return (
                                <SigningParty key={`party-${party.id}`} party={party}/>
                            );

                        return null;
                    })}
                </div>
            </div>
        );
    }
}

LegacyDigitalSigningCheckout.propTypes = {
    case: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        project: state.app.project
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch),
        apartmentActions: bindActionCreators(apartmentActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(LegacyDigitalSigningCheckout)));
