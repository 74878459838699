import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import './style.scss'
import HMImageCarouselWithContentAndCallToAction from "../../../Utils/HMImageCarouselWithContentAndCallToAction";
import ClockIcon from "../../../../assets/icons/clockIcon.png";
import RouterService from "../../../../services/RouterService";
import UtilService from "../../../../services/UtilService";
import moment from "moment";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

class ApartmentBigCarousel extends Component {

    shouldShowLockDate() {
        const today = moment().startOf('day');

        return !this.props.apartment.apartment.isLocked && ((this.props.apartment.apartment.lockDate && moment(this.props.apartment.apartment.lockDate).isSameOrAfter(today))
            || (this.props.rooms && this.props.rooms.some(r => !r.isLocked && r.lockDate && moment(r.lockDate).isSameOrAfter(today))));
    }

    isLockDateToday(lockDate) {
        const today = moment().startOf('day');

        return (!!lockDate && moment(lockDate).isSame(today));
    }

    getRoomsWithLockDate() {
        const today = moment().startOf('day');

        const roomDates = (this.props.rooms || [])
            .filter(r => !r.isLocked && r.lockDate && moment(r.lockDate).isSameOrAfter(today))
            .reduce((dates, room) => {
                let date = dates.find(d => d.lockDate === room.lockDate);
                if (!date) {
                    date = {
                        lockDate: room.lockDate,
                        rooms: [],
                        isToday: moment(room.lockDate).isSame(today)
                    }

                    dates.push(date);
                }

                date.rooms.push(room)

                return dates;
            }, [])
            .sort((a, b) => moment(a.lockDate).diff(moment(b.lockDate)));

        if (roomDates.length === 2) {
            if (roomDates[0].rooms.length === 1) {
                roomDates[1].restOfTheApartment = true;
            } else if (roomDates[1].rooms.length === 1) {
                roomDates[0].restOfTheApartment = true;
            }
        }

        return roomDates;
    }

    render() {
        if (!this.props.project
            || !this.props.project.project
            || !this.props.apartment
            || !this.props.apartment.apartment) {
            return null;
        }

        const { t, section } = this.props;

        return(
            <HMImageCarouselWithContentAndCallToAction
                className="big-carousel hm-landing-page-section-header"
                images={!section ? this.props.project.project.moodImagesSorted.header.map(image => image.url) : section.images.map(image => image.url)}
                containerHeight={!!section && !!section.customProperties.height && section.customProperties.height !== '' ? section.customProperties.height : '623px'}
                contentHtml={!!section ? `<h1>${section.title ? section.title : ""}</h1>` + (section.richContentLeft || "") : this.props.apartment.apartment.formattedLandingPageText || "<h1>Configure apartment landing page.</h1>"}
                callToActionText={this.props.apartment.apartment.apartmentRooms.length > 0 ? t('seeMyOptions') : null}
                callToActionHref={RouterService.roomPath(this.props.roomsOrder[0])}
            >
                {this.shouldShowLockDate() &&
                    <div className="row alarm align-items-center">
                        <div className="col-xs-12 col-md-4 alarm-icon"><img src={ClockIcon} alt=""/></div>
                        <div className="col-xs-12 col-md-8 alarm-text">
                            {this.props.apartment.apartment.lockDate &&
                            <React.Fragment>
                                <p><Link to={RouterService.roomPath(this.props.roomsOrder[0])}>{this.isLockDateToday(this.props.apartment.apartment.lockDate) ? t('apartmentLandingPage.lastDayToChooseOptionsForApartment') : t('apartmentLandingPage.chooseOptionsForApartmentBeforeDay')}</Link></p>
                                <p className="apartmentLockDate">{t('longDate', {date: this.props.apartment.apartment.lockDate})}</p>
                            </React.Fragment>
                            }
                            {!this.props.apartment.apartment.lockDate && this.getRoomsWithLockDate().map(lockDateDefinition => {
                                return (
                                <React.Fragment key={lockDateDefinition.lockDate}>
                                    <p>
                                        {(lockDateDefinition.isToday ? t('apartmentLandingPage.lastDayToChooseOptionsForRoom') : t('apartmentLandingPage.chooseOptionsForRoomBeforeDay')) + ' '}
                                        {lockDateDefinition.restOfTheApartment
                                            ? <Link to={RouterService.roomPath(lockDateDefinition.rooms[0].id)}>
                                                {t('apartmentLandingPage.chooseOptionsForRoomRestOfTheApartment')}
                                                {lockDateDefinition.rooms.every(r => this.props.selectionProgressRoomCompletion.some(rId => rId === r.id)) &&
                                                    <clr-icon shape="check-circle" class="is-solid is-completed" size="20"></clr-icon>
                                                }
                                            </Link>
                                            : lockDateDefinition.rooms.map((room, i) => {
                                                return (
                                                    <React.Fragment key={room.id}>
                                                        <Link to={RouterService.roomPath(room.id)}>
                                                            {room.roomType}
                                                            {this.props.selectionProgressRoomCompletion.some(rId => rId === room.id) &&
                                                                <clr-icon shape="check-circle" class="is-solid is-completed" size="20"></clr-icon>
                                                            }
                                                        </Link>
                                                        <span>{i < lockDateDefinition.rooms.length - 1 ? ', ': ''}</span>
                                                    </React.Fragment>
                                                );
                                        })}
                                        {lockDateDefinition.isToday ? '. ' + t('apartmentLandingPage.lastDayToChooseOptionsForRoomAdditionalMessage') : ' ' + t('apartmentLandingPage.chooseOptionsForRoomBefore')}
                                    </p>
                                    {lockDateDefinition.isToday ? null : <p className="roomLockDate">{t('longDate', {date: lockDateDefinition.lockDate})}</p>}
                                </React.Fragment>
                                )
                            })}

                        </div>
                    </div>
                }
            </HMImageCarouselWithContentAndCallToAction>
        )
    }
}

ApartmentBigCarousel.propTypes = {
    section: PropTypes.object,
};



function mapStateToProps(state, ownProps) {
    return {
        project: state.app.project,
        apartment: state.app.apartment2,
        roomsOrder: state.app.roomsOrder,
        rooms: UtilService.iterableToArray(state.app.rooms),
        selectionProgressRoomCompletion: state.app.selectionProgressRoomCompletion || [],
    }
}

export default connect(mapStateToProps)(withTranslation('common')(ApartmentBigCarousel));
