import React, {Component} from 'react';
import PropTypes from "prop-types";
import "./style.scss"
import {connect} from "react-redux";
import PackageService from "../../../../services/PackageService";
import {withTranslation} from "react-i18next";

class RoomUnselectedPackage extends Component {

    render() {
        if (!this.props.activeStyle) return null;
        const t = this.props.t;
        const styleGroup = this.props.activeStyle.styleGroup;
        const styleGroupImg = PackageService.getStyleGroupImage(this.props.activeStyle);

        return (
            <div className="hm-unselected-package row no-gutters">
                <div className="col-12">
                    <h1 className="uppercase">{t('unselectedStyleHeader')}</h1>
                </div>
                {styleGroupImg &&
                <div className="col-12 col-lg-6">
                    <img src={styleGroupImg} alt={styleGroup.name}/>
                </div>
                }
                <div className={styleGroupImg ? "col-12 col-lg-6 pl-0 pl-lg-4 pt-4 pt-lg-0" : "col-12"} dangerouslySetInnerHTML={{__html: styleGroup.description}}></div>
            </div>
        )
    }
}

RoomUnselectedPackage.propTypes = {
    activeStyle: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(null, mapDispatchToProps)(withTranslation('common')(RoomUnselectedPackage));
