import * as actionTypes from './actionTypes';
import RoomService from "../services/RoomService";
import ProductService from "../services/ProductService";
import {AppRoute} from '../services/RouterService';
import store from "../reducers/store";
import {loadRooms} from "./apartmentActions";
import {appLoadingWrapper, appReadyWrapper} from "./appActions";
import BackendApi from "../api/BackendApi";
import {ADDON} from "../enums/appModes";

// Reducer actions related to the Backend API
export function chooseStandardsForRoom(apartmentId, roomId) {
    return (dispatch) => {
        if (!roomId) return;

        dispatch(appLoadingWrapper());

        const state = store.getState();

        let resetRoomSelections = Promise.resolve();
        if (!state.app.readOnlyMode) {
            resetRoomSelections = BackendApi.resetRoomSelection(apartmentId, roomId);
        }

        resetRoomSelections.then((room) => {
            ProductService.resetRoomSelectionsFromLocalStorage(apartmentId, roomId);

            if (state.app.readOnlyMode || room) {
                loadRooms(dispatch, state, apartmentId, [state.app.currentRoom])
                    .then(() => {
                        dispatch({type: actionTypes.ROOM_CHANGE, payload: {roomId: roomId}});
                        dispatch({type: actionTypes.SELECTION_PROGRESS_SELECT_ALL, payload: {}});
                        dispatch(appReadyWrapper());
                    })
            } else {
                dispatch(appReadyWrapper());
            }
        }).catch(() => {
            dispatch(appReadyWrapper());
        });
    }
}

export function resetReadOnlyRoom(apartmentId, roomId) {
    ProductService.resetRoomSelectionsFromLocalStorage(apartmentId, roomId);
    window.location.href = "";
}

export function changeRoom(roomId) {
    switch(roomId) {
        case AppRoute.Summary:
        {
            const state = store.getState();
            if (state.app.appMode === ADDON && state.app.currentRoomId !== undefined && !!window && !!window.location && !!window.location.reload) {
                window.location.reload();
                roomId = undefined;
            }
        }
        break;
        case AppRoute.Checkout:
        case AppRoute.Confirmation:
            break;
        default:
            const state = store.getState();

            roomId = +roomId;
            RoomService.persistLastRoomToLocalStorage(state.app.apartment2.apartmentId, roomId);
            break;
    }
    return function (dispatch) {
        dispatch({type: actionTypes.ROOM_CHANGE, payload: {roomId: roomId}});
    }
}

export function userHasSeenRoom(roomId) {
    return (dispatch) => {
        dispatch({type: actionTypes.ROOM_SEEN_BY_USER, payload: {roomId: roomId}});
    };
}
