import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

class ProductProperty extends Component {

    render() {
        const { t, propertyKey, property, hideKey } = this.props;

        if (!this.props.property)
            return (null);

        return (
            <div className="row no-gutters">
                <div className="col-12">
                    <span className="font-weight-bold">{hideKey ? "" : `${t(propertyKey)}:`}</span> {property}
                </div>
            </div>
        )
    }
}

ProductProperty.propTypes = {
    propertyKey: PropTypes.string.isRequired,
    property: PropTypes.string,
    hideKey: PropTypes.bool // This will hide the key and just show property value
};

export default withTranslation('common')(ProductProperty);
