// Offline version of the backend code for demo apartments
import ProductSelection from "../models/ProductSelection";
import SceneProduct from "../models/SceneProduct";
import UtilService from "./UtilService";
import LogService from "./LogService";
import i18next from 'i18next';
import PackageService from "./PackageService";
import ProductImageNotFound from "../assets/images/productImageNotFound.png"
import BrandService from "./BrandService";

export default class ProductService {

    /**
     * This method will concatenate all product selections both regular and placed by styles
     * It has the option to also include product selections that are just now disabled by others via relationships.
     * The user should understand that these are not valid selections, therefore some views are including them to just show that they are disabled
     * TODO build tests for this
     */
    static getAllProductSelectionsInRoom(room, includeDisabledByRelationships = false, excludeStyleGroups = false, includeEmptyExtraOptions = false) {

        // We give the caller the option to include empty product selections of type extra product selection. Otherwise it should usually not show up in summary and calculations
        const selectedExtraProductSelections = includeEmptyExtraOptions ? room.extraProductSelections : room.extraProductSelections.filter( ps => ps.projectProduct);

        let productSelections = [...room.productSelections, ...selectedExtraProductSelections];

        // Go through each active style group fetching their product selections
        if (!excludeStyleGroups)
            room.activeStyleGroups.forEach((activeStyle) => {
                productSelections = [...productSelections, ...PackageService.getAllProductSelectionsFromActiveStyle(activeStyle, includeDisabledByRelationships, includeEmptyExtraOptions)];
            });

        // Return with what ever filter was wanted by caller
        return includeDisabledByRelationships ? productSelections : productSelections.filter((ps) => {return !ps.isDisabledFromProductRelationships()});
    }

    // Calculate the total costs of all rooms
    static calculateGrandTotal(rooms) {

        const result = {price: 0, hasDelayedPrice: false, hasDisabledPrice: false};

        rooms.forEach((room) => {

            const processProductSelections = (productSelections) => {
                const roomResult = this.calculateGrandTotalOfProductSelections(productSelections.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships()));
                result.price += roomResult.price;
                if (roomResult.hasDelayedPrice) result.hasDelayedPrice = true;
                if (roomResult.hasDisabledPrice) result.hasDisabledPrice = true;
            };

            // Get even product selections that are disabled due to relatioships, we're gonna ignore them later
            const allProductSelectionsHierarchy = PackageService.getAllProductSelectionsInRoomWithStylesHierarchy(room);

            // add prices for product selections that are not from styles
            processProductSelections(allProductSelectionsHierarchy.room);

            // process styles
            allProductSelectionsHierarchy.activeStyles.forEach(styleProductSelections => {
                if (styleProductSelections.style.hasStylePrice) {
                    // add fixed price for selected style
                    result.price += styleProductSelections.style.stylePrice;
                } else {
                    // process product selections from style instead
                    processProductSelections(styleProductSelections.productSelections);
                }
            })
        });

        return result;
    }

    /**
     * Calculates the grand total of given product selections
     * @param productSelections [ProductSelection]
     * @returns {{price: number, hasDelayedPrice: boolean}}
     */
    static calculateGrandTotalOfProductSelections(productSelections) {
        var result = {price: 0, hasDelayedPrice: false, hasDisabledPrice: false};

        productSelections.forEach((productSelection) => {

            // Don't calculate the price of a disabled product selection (Disabling comes from relationships)
            if (productSelection.isDisabledFromProductRelationships())
                result.hasDisabledPrice = true;
            else
                result.price += productSelection.price;

            if (productSelection.getDelayedPrice())
                result.hasDelayedPrice = true;
        });

        return result;
    }

    //TODO there should exist a bulk version of this by providing multiple Ids
    static isProductSelectedInProductSelections(productId, productSelections) {
        var selected = false;

        productSelections.forEach((productSelection) => {
            if (productSelection.projectProduct && productSelection.projectProduct.product.id === productId) {
                selected = true;
            }
        });

        return selected;
    }

    // Sort options according to a specific rule set
    static getStatusWeightForSorting(projectProduct) {
        const status = this.getStatusStyle(projectProduct).status;

        switch (status) {

            case "STANDARD":
                return 100;

            case "OPTIONAL_FREE":
                return 50;

            case "OPTIONAL":
                return 30;

            case "OPTIONAL_DELAYED_PRICE":
                return 20;

            default:
                return 0;
        }
    }

    // Render util for colors and strings
    static getStatusStyle (projectProduct, hidePrices = false) {

        var colorName = projectProduct.status.toLowerCase();
        var context = '';

        switch (projectProduct.status) {
            case "OPTIONAL":
                if (projectProduct.delayedPrice) {
                    context = "_DELAYED_PRICE";
                    colorName = "free-optional"; //TODO really? According to Emelie
                    break;
                }
                if (!hidePrices && projectProduct.isFree()) {
                    context = "_FREE";
                    colorName = "free-optional";
                    break;
                }

                break;

            default:
                break;
        }

        const status = `${projectProduct.status}${context}`;
        const statusTextKey = `${BrandService.getTerminologyNamespace()}:product.status.${status}`;

        return {
            translationKey: statusTextKey,
            text: i18next.t(statusTextKey),
            textColorClass: `color-${colorName}`,
            borderColorClass: `border-color-${colorName}`,
            status: status
        }
    }

    // This method should just be called in DEMO mode as the reassignments are not persisted to API
    static restoreProductSelectionsFromLocalStorageInRoom(apartmentId, room) {
        const productSelections = this.getProductSelectionsFromLocalStorageByRoom(apartmentId, room);

        let numOfRestoredSelections = 0;

        if (productSelections.size !== 0) {
            this.getAllProductSelectionsInRoom(room, true, false, true).forEach((productSelection) => {

                // Check if this product selection occurred in localStorage
                if(productSelections.has(productSelection.id)) {

                    const projectProductId = productSelections.get(productSelection.id);

                    // Find the selected product by looking through the available options
                    // It's important we select only the ones present in options. The offering might have changed after the users last session
                    const projectProduct = productSelection.options.find((pp) => { return pp.id === projectProductId });

                    // Restore Extra options
                    if (productSelection.extraOption) {

                        // If we didn't encounter a project product id this means the user set the category to not have a product
                        if (!projectProductId) {
                            productSelection.setProjectProduct(null);
                        } else {
                            productSelection.setProjectProduct(projectProduct);
                        }

                        numOfRestoredSelections++;

                    } else {
                        // Restore regular options

                        // Don't bother doing anything if the projectProduct is already selected
                        if (projectProduct && productSelection.projectProduct && productSelection.projectProduct.id !== projectProduct.id) {
                            productSelection.setProjectProduct(projectProduct);
                            numOfRestoredSelections++;
                        }
                    }
                }
            });
        }

        LogService.log(`Session for room ${room.id} restored from local storage. Restored ${numOfRestoredSelections} categories`, "ProductService");
    }

    static getPersistentProductSelectionKey(apartmentId, roomId) {
        return `apartment_${apartmentId}.room_${roomId}.selected_products`;
    }

    static persistProductSelectionToLocalStorage(apartmentId, room, productSelection, projectProduct) {
        const newProjectProductId = projectProduct !== undefined ? projectProduct.id : undefined;
        UtilService.appendToLocalStorageMap(
            this.getPersistentProductSelectionKey(apartmentId, room.id),
            productSelection.id,
            newProjectProductId
        )
    }

    static getProductSelectionsFromLocalStorageByRoom(apartmentId, room) {
        return UtilService.getMapFromLocalStorage(this.getPersistentProductSelectionKey(apartmentId, room.id));
    }

    static resetRoomSelectionsFromLocalStorage(apartmentId, roomId) {
        UtilService.removeFromLocalStorage(this.getPersistentProductSelectionKey(apartmentId, roomId));
        UtilService.removeFromLocalStorage(PackageService.getPersistentPackageSelectionKey(apartmentId, roomId));
    }

    static getImageUrl(product) {
        return product && product.image ? product.image.url : ProductImageNotFound;
    }

    /* JSON Parsing utilities ------------------------------ */

    /* Json parsing util for the special model class SceneProduct */
    static parseAllSceneProductsInRoom (room) {
        if (room.showroomScene)
            room.scene.products = room.scene.products.map ((json) => {
                return new SceneProduct().parse(json);
            });
    }

    static parseAllProductSelectionsInRoom (room) {
        room.productSelections = room.productSelections.map ((jsonPs) => {
            return new ProductSelection().parse(jsonPs);
        });

        room.extraProductSelections = room.extraProductSelections.map ((jsonPs) => {
            return new ProductSelection().parse(jsonPs);
        });

        room.activeStyleGroups.forEach((asg) => {
            asg.productSelections = asg.productSelections.map ((jsonPs) => {
                return new ProductSelection().parse(jsonPs);
            });
        });
    }
}
