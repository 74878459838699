import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import HMButton from "../../../Utils/HMButton";
import RouterService from "../../../../services/RouterService";

class SignCallToAction extends Component {

    render() {
        const { t } = this.props;

        return(
            <div className="row">
                <div className="col">
                    <p>{t('apartmentLandingPage.caseNotFinished')}</p>
                    <div className="call-to-action-button">
                    <HMButton text={t('apartmentLandingPage.callToActions.completeSigning')}
                              active={true}
                              activeText={t('apartmentLandingPage.callToActions.completeSigning')}
                              href={RouterService.checkoutPath()}
                              clickFn={() => {}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('common')(SignCallToAction);
