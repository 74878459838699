import React, {Component} from 'react';
import "./style.scss"
import PropTypes from "prop-types";

export default class LandingPageRichText extends Component {

    render() {

        if (!this.props.text || this.props.text === "")
            return (null);

        return <div className={`row hm-landing-page-rich-text ${this.props.highlight ? "highlight" : ""}`}>
                    <div className="col-12">
                        <div className={`dynamic-content`} dangerouslySetInnerHTML={{__html: this.props.text}}>
                        </div>
                    </div>
                </div>


    }
}

LandingPageRichText.propTypes = {
    text: PropTypes.string.isRequired,
    highlight: PropTypes.bool // Highlighting the element would give it HM Orange background color
};
