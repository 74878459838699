import React, {Component} from 'react';
import "./style.scss"

class HMScrollDown extends Component {
    constructor(props) {
        super(props);

        this.onScroll = this.onScroll.bind(this);

        this.state = {
            scrolledToBottom: '',
        };
    }

    componentDidMount(prevProps, prevState, snapshot) {
        window.addEventListener('scroll', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    _timeout = undefined;
    onScroll() {

        clearTimeout(this._timeout);
        setTimeout(() => {
            const scrolledToBottom = (window.innerHeight + window.pageYOffset) >= document.body.scrollHeight ? 'scrolled-to-bottom' : '';

            if (scrolledToBottom !== this.state.scrolledToBottom) {
                this.setState({...this.state, scrolledToBottom: scrolledToBottom});
            }
        }, 50);
    }

    render() {
        return (
            <div className={`hm-scroll-down ${this.state.scrolledToBottom}`} onClick={() => window.scrollTo({
                top: document.body.scrollHeight || document.documentElement.scrollHeight,
                left: 0,
                behavior: 'smooth'
            })}>
                <div className="container">
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                </div>
            </div>
        )
    }
}

export default HMScrollDown;
