import React, {Component} from 'react';
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ActivatePassword from "./ActivatePassword";

import Login from "./Login"
import {Redirect, Route, Switch} from "react-router-dom";
import "./style.scss"
import RouterService from "../../services/RouterService";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as appActions from "../../actions/appActions";
import {ADDON} from "../../enums/appModes";

class Auth extends Component {

    componentDidMount() {
        this.props.appActions.setAppMode(ADDON);
    }

    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/login`} component={Login} />
                <Route path={`${this.props.match.path}/forgot-password`} component={ForgotPassword} />
                <Route path={`${this.props.match.path}/reset-password/:token`} component={ResetPassword} />
                <Route path={`${this.props.match.path}/activate-password/:token`} component={ActivatePassword} />
                <Redirect to={RouterService.authLoginPath()} />
            </Switch>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
