import React, {Component} from 'react';
import HMButton from "../../Utils/HMButton";
import * as actions from "../../../actions/authActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {Link, Redirect} from "react-router-dom";
import AuthService from "../../../services/AuthService";
import RouterService from "../../../services/RouterService";
import HMLogo from "../HMLogo";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            reauthorize: false
        };
    }

    getTextForLoginState() {
        const { t } = this.props;

        if(this.props.auth.invalidCredentials)
            return t("wrongTryAgain");
        else
            return t("logIn");
    }

    handleInputChange(event) {
        this.setState({...this.state, [event.target.name]: event.target.value})
    }

    handleSubmit(event) {
        this.props.actions.authorize(this.state.username, this.state.password);
        event.preventDefault();
    }

    componentWillMount() {
        this.props.actions.resetAuth();

        if (AuthService.getAuthentication()) {
            this.setState({...this.state, reauthorize: true});
            this.props.actions.reauthorize();
        }
    }

    render() {

        let { from } = this.props.location.state || { from: { pathname: "/" } };
        if (this.props.auth.authorized) return <Redirect to={from} />;

        const { t } = this.props;

        if (this.state.reauthorize) return null;

        return (
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-sm-9 col-md-7 col-lg-6 align-self-center hm-auth bg-frosted">
                        <div className="my-5">
                            <div>
                                <HMLogo/>
                                <form onSubmit={(event) => {this.handleSubmit(event)}} className="form-signin">
                                    <div className="form-label-group">
                                        <label htmlFor="inputEmail">{t('enterUsernameTitle')}</label>
                                        <input
                                            name="username"
                                            type="email"
                                            id="inputEmail"
                                            className="form-control"
                                            placeholder={t('enterUsernamePlaceholder')}
                                            required
                                            value={this.state.username}
                                            onChange={(target) => this.handleInputChange(target)}
                                            autoFocus
                                        />
                                    </div>

                                    <div className="form-label-group">
                                        <label htmlFor="inputPassword">{t('enterPasswordTitle')}</label>
                                        <input
                                            name="password"
                                            type="password"
                                            id="inputPassword"
                                            className="form-control"
                                            placeholder={t('enterPasswordPlaceholder')}
                                            value={this.state.password}
                                            onChange={(target) => this.handleInputChange(target)}
                                            required
                                        />
                                    </div>
                                    <HMButton clickFn={() => {}}
                                              text={this.getTextForLoginState()}
                                              active={this.props.loading > 0} activeText={t('loggingIn')}
                                              disabled={this.props.loading > 0} disabledText={t('loggingIn')}
                                              type="submit"  />
                                    <hr></hr>
                                    <div className={`${this.props.auth.invalidCredentials ? 'shake animated' : ''}`} >
                                    <Link to={RouterService.authResetPasswordPath()}>{t("forgotYourPassword?")}</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.app.auth,
        loading: state.app.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('auth')(Login));

