// Separate service for Product alternatives
// Alternatives is a new name for the MetaProduct (Products that are same base product but with different colors for example), MetaPropertyGroups (Colors, Materials) and MetaProperties (White, Black))

import ProjectProductNotFoundError from "../models/ProjectProductNotFoundError";
import UtilService from "./UtilService";
import ProductImageNotFound from "../assets/images/productImageNotFound.png"

export default class ProductAlternativesService {

    /** The "special properties" of a product that are treated differently from the "others" */
    static specialProperties = ['COLOR', 'MATERIAL', 'ASSEMBLY'];

    // Will return both standards and optionals!
    // Generic method for future logic where MetaProduct has finished played its role
    static filterAlternativesToProjectProduct(projectProductList, projectProduct) {
        return projectProductList.filter((pp) => {
            return pp.product.metaProduct.id === projectProduct.product.metaProduct.id;
        });
    }

    // This will be a standardized map for Color, Material, Assembly. Other entries will be considered extras
    static buildProjectProductAlternativesMap(projectProductList, selectedProjectProduct) {

        let iterated = [];
        const merged = [];

        projectProductList.forEach((nextProjectProduct) => {

            // Have we already collected all alternatives for this project product
            if (!iterated.includes(nextProjectProduct)) {

                var alternatives = this.filterAlternativesToProjectProduct(projectProductList, nextProjectProduct);

                // Did we find more alternatives than our own reference project product
                if (alternatives.length > 1) {
                    const selectedIsWithinAlternatives = alternatives.some(pp => selectedProjectProduct && pp.id === selectedProjectProduct.id);
                    const projectProduct = selectedIsWithinAlternatives ? selectedProjectProduct : nextProjectProduct

                    merged.push({projectProduct: projectProduct, alternatives: alternatives});

                    // never iterate alternative project products again
                    iterated = [...iterated, ...alternatives];

                } else {
                    merged.push({projectProduct: nextProjectProduct, alternatives: []});
                }
            }
        });

        return merged;
    }

    /** the OTHER case contains data on a potentially big number of property types, but in a different format, where
     * the name of the type of a property is stored in the `metaProperty.metaPropertyGroup.name`.
     * The hardcoded property groups (currently COLOR, MATERIAL and ASSEMBLY) store their type in `metaProperty.metaPropertyGroup.type` */
    static resolvePropertyTypeName(property) {
        return property.metaPropertyGroup.type === "OTHER" ? property.metaPropertyGroup.name : property.metaPropertyGroup.type;
    }

    static mapProjectProductsToPropertyGroups(projectProductList) {
        const groups = {};

        projectProductList.forEach((projectProduct) => {
            projectProduct.product.productProperties.forEach((metaProperty) => {

                // MetaProperty can be like "Gray" (from Color group) or "Right hanged" (From Assembly group)

                // The MetaPropertyGroup represent the Category of the properties
                // It can be like Color that holds properties like "Gray", "White" or Assembly
                // This system should predefine the three most common group and call all other for Others

                const propertyTypeName = this.resolvePropertyTypeName(metaProperty);
                this.groupProjectProductsByPropertyType(groups, propertyTypeName, metaProperty, projectProduct);
            });
        });
        return groups;
    }

    /**
     * A ProjectProduct can match several property types (by simply having properties of these types), and therefore
     * be a part of several groups
     * */
    static groupProjectProductsByPropertyType(groups, groupName, metaProperty, newMatch) {
        if(!groups[groupName]) {
            groups[groupName] = new Map();
        }
        const map = groups[groupName];
        if (map.has(metaProperty.id)) {
            map.get(metaProperty.id).matches.add(newMatch);
        } else {
            map.set(metaProperty.id, {...metaProperty, matches: new Set([newMatch])});
        }
    }

    /**
     * Due to the usage of Maps to store property type groups, the order gets lost. This method is responsible for imposing
     * the required order.
     * ONLY WORKS ON PROPERTY TYPE NAMES, NOT THE ACTUAL PROPERTIES
     * @param propertyTypeNamesArray - a [] of names to sort
     */
    static getOrderedPropertyTypesList(propertyTypeNamesArray) {
        const sorted = [];
        /** first always go the `specialProperties`, only if they are available */
        this.specialProperties.forEach(property => propertyTypeNamesArray.includes(property) && sorted.push(property));
        /** after that goes all the rest in the alphabetical order */
        propertyTypeNamesArray.sort().forEach(propertyTypeName => {
            if(!this.specialProperties.includes(propertyTypeName)) {
                sorted.push(propertyTypeName);
            }
        });

        return sorted;
    }

    /**
     * Gets a flat ordered list of properties of the passed product.
     * Used for some displays in React rendering iteration, like in `ProductInfoTable`.
     * @param product
     * @returns a [] of properties in the order as determined by `getOrderedPropertyTypesList()`
     */
    static getOrderedPropertiesList(product) {
        /** get an ordered list of property names */
        const propertyTypesNames = this.getOrderedPropertyTypesList(product.productProperties.map(property => this.resolvePropertyTypeName(property)));
        /** associate each property group with its name in a {} */
        const tempMap = product.productProperties.reduce((acc, property) => {
            acc[this.resolvePropertyTypeName(property)] = property;
            return acc;
        }, {});
        /** pick the property groups from the {} and push them in the required order */
        return propertyTypesNames.reduce((acc, typeName) => {
            acc.push(tempMap[typeName]);
            return acc;
        }, []);
    }

    /**
     * Remember we cannot search for object equality
     */
    static isPropertySelected(projectProduct, property) {
        return projectProduct.product.productProperties.find((currentProperty) => {return currentProperty.id === property.id}) !== undefined;
    }

    // The inputted meta property should have a variable matches:[]
    // Usually thats the case if the data comes from this.mapProjectProductsToPropertyGroups()
    static getAlternativeThumbnail(metaProperty) {
        if (metaProperty.imageUrl)
            return metaProperty.imageUrl;

        // Fall back on product image should there only be one match
        if (metaProperty.matches.size === 1) {
            const projectProduct = UtilService.iterableToArray(metaProperty.matches)[0];
            if (projectProduct.product.image)
                return projectProduct.product.image.thumbnailUrl;
        }

        return ProductImageNotFound;
    }

    /**
     * TODO This method will search for object equalities
     * currentProperties must contain Set property "matches" you get them from mapProjectProductsToPropertyGroups
     * nextProperty must contain Set property "matches" you get them from mapProjectProductsToPropertyGroups
     * Throws ProjectProductNotFoundError if no candidate was found, useful for seeking alternatives
     */
    static findNextProjectProduct(currentProperties, nextProperty, projectProductAlternatives) {
        const nextPropertyType = this.resolvePropertyTypeName(nextProperty);
        const nextProperties = [...currentProperties.filter (
            (property) => this.resolvePropertyTypeName(property) !== nextPropertyType),
            nextProperty];

        const candidates = projectProductAlternatives.filter(projectProduct =>
            nextProperties.every(property => property.matches.has(projectProduct)));

        if(candidates.length > 1) {
            throw new Error(`Error: more than one (n=${candidates.length}, ids=${candidates.map(it => it.id).toString()}) candidates is found to match properties (ids=${nextProperties.map(it => it.id).toString()})`);
        }

        const candidate = candidates[0];
        if(candidate && !candidate.disabled) {
            return candidate;
        }

        throw new ProjectProductNotFoundError(`A new project product candidate could not be found given the properties selected, ${nextProperties.map(it => it.id).toString()}, was it disabled?`);
    }

    static tryLocalizingTheName(t, group) {
        /** if the translation does not return the key back then we have a locale for that. Otherwise it is implied that the `group.name`
         * does not need to be translated */
        return group.type === 'OTHER' ? group.name : t(`product.property.${group.type.toLowerCase()}`);
    }
}
