import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/roomActions";
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import './style.scss'
import {ADDON} from "../../../enums/appModes";
import RouterService from "../../../services/RouterService";
import {Link} from "react-router-dom";
import GoogleMap from "../../Utils/GoogleMap";
import HMFooter from "../../Utils/HMFooter";
import MoodImages from "../../Apartment/ApartmentLanding/MoodImages";
import HMImageCarouselWithContentAndCallToAction from "../../Utils/HMImageCarouselWithContentAndCallToAction";
import LandingPageRichText from "../../Utils/LandingPageRichText";
import UtilService from "../../../services/UtilService";
import HeaderActions from "./HeaderActions";
import SectionCol1HmsForm from "../../Utils/SectionCol1HmsForm";
import SectionCol1Text from "../../Utils/SectionCol1Text";
import SectionCol2TextImage from "../../Utils/SectionCol2TextImage";
import SectionCol2ImageText from "../../Utils/SectionCol2ImageText";
import SectionCol2ImageImage from "../../Utils/SectionCol2ImageImage";
import ImageNotFound from "../../../assets/imageNotFound.png";

class ProjectLanding extends Component {

    componentDidMount() {

        if (this.props.appMode === ADDON) {
            // goto apartment landing page - there's no project landing for addon mode
            this.props.history.replace(RouterService.chooseApartmentPath());
            return;
        }


        if (window.hj) {
            window.hj('trigger', `projectLanding`);
        }
    }

    render() {
        if (this.props.appMode === ADDON || !this.props.project) {
            return null;
        }

        // const { t } = this.props;

        const apartments = (this.props.project.project.apartments || [])
            .filter(a => this.props.appMode === ADDON || a.publicKeyEnabled);

        if (!this.props.project.project.landingSections || this.props.project.project.landingSections.length === 0)
            return(
                <div className="container-fluid hm-project-landing">

                    <HMImageCarouselWithContentAndCallToAction
                        className="big-carousel hm-landing-page-section-header"
                        images={this.props.project.project.moodImagesSorted.header.map(image => image.url)}
                        containerHeight="663px"
                        contentHtml={this.props.project.project.formattedProjectLandingPageText || "<h1>Configure project landing page.</h1>"}
                    >
                    </HMImageCarouselWithContentAndCallToAction>

                    <LandingPageRichText text={this.props.project.project.formattedProjectLandingPageInfoText}/>

                    <div className="row justify-content-center mb-4 ml-0 mr-0">
                        {apartments && UtilService.arrayToChunks(apartments, 3).map((chunk, i) => {
                            return <div key={`apartment-chunk-${i}`} className="col-xs-12 col-xs-offset-0 col-xl-8 col-xl-offset-2">
                                <div className="row justify-content-center apartment-links">
                                    {chunk.map(apartment => {
                                    return (
                                        <div key={`apartment-${apartment.id}`} className="apartment-links-col-auto col-auto mt-4">
                                            <Link to={RouterService.chooseApartmentApartmentPath(this.props.project.projectId, this.props.appMode === ADDON ? apartment.id : apartment.publicKey)}>
                                                <div className="card">
                                                    <h5>{apartment.name}</h5>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                        })}
                    </div>

                    <MoodImages
                        className={"small-carousel " + (this.props.project.project.moodImagesSorted.statics.length > 1 ? 'many-images' : 'one-image')}
                        containerHeight="276px"
                        images={this.props.project.project.moodImagesSorted.statics.map(image => image.url).slice(0, 2)}
                    />

                    <LandingPageRichText highlight={true} text={this.props.project.project.formattedProjectLandingPageFooterText}/>

                    {this.props.project.project.location && this.props.project.project.location.hasLocation &&

                    <GoogleMap
                        location={this.props.project.project.location}
                        markerText={this.props.project.project.name}
                        containerHeight="70vh"/>
                    }

                    <HMFooter/>
                </div>
            );
        else
            return (
                <div className="container-fluid hm-project-landing">
                    {this.props.project.project.landingSections
                        .sort((a, b) => {return a.sectionOrder - b.sectionOrder})
                        .map(section => {
                            switch (section.sectionType) {
                                case "Header":
                                    return <HMImageCarouselWithContentAndCallToAction
                                        key={section.sectionOrder}
                                        className="big-carousel"
                                        images={section.images.length > 0 ? section.images.map(image => image.url) : [ImageNotFound]}
                                        containerHeight={!!section.customProperties.height && section.customProperties.height !== '' ? section.customProperties.height : '623px'}
                                        contentHtml={`<h1>${section.title || ''}</h1>` + (section.richContentLeft || '')}
                                    >
                                    </HMImageCarouselWithContentAndCallToAction>;
                                case "HeaderActions":
                                    return <HeaderActions
                                        key={section.sectionOrder}
                                        appMode={this.props.appMode}
                                        project={this.props.project}
                                    />;
                                case "Col1HmsForm":
                                    return <SectionCol1HmsForm
                                        key={section.sectionOrder}
                                        section={section}
                                    />;
                                case "Col1Text":
                                    return <SectionCol1Text
                                        key={section.sectionOrder}
                                        section={section}
                                    />;
                                case "Col2ImageText":
                                    return <SectionCol2ImageText
                                        key={section.sectionOrder}
                                        section={section}
                                    />;
                                case "Col2TextImage":
                                    return <SectionCol2TextImage
                                        key={section.sectionOrder}
                                        section={section}
                                    />;
                                case "Col2ImageImage":
                                    return <SectionCol2ImageImage
                                        key={section.sectionOrder}
                                        section={section}
                                    />;
                                case "FullWidthImages":
                                    if (section.images && section.images.length > 0)
                                        return <MoodImages
                                            key={section.sectionOrder}
                                            className={`small-carousel hm-landing-page-section-fullWidthImages images-${section.images.length}`}
                                            containerHeight={!!section.customProperties.height && section.customProperties.height !== '' ? section.customProperties.height : '278px'}
                                            images={section.images.map(image => image.url)}
                                        />;
                                    else
                                        return null;
                                case "FullWidthMap":
                                    if (!!this.props.project.project.location && this.props.project.project.location.hasLocation)
                                        return <GoogleMap
                                            key={section.sectionOrder}
                                            className="hm-landing-page-section-fullWidthMap"
                                            location={this.props.project.project.location}
                                            markerText={this.props.project.project.name}
                                            containerHeight={!!section.customProperties.height && section.customProperties.height !== '' ? section.customProperties.height : '70vh'}/>;
                                    else
                                        return null;
                                case "Footer":
                                    return <HMFooter
                                        key={section.sectionOrder}
                                        className="hm-landing-page-section-footer"
                                    />;
                                default:
                                    return null;
                            }
                        })
                    }
                </div>
            );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        project: state.app.project
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ProjectLanding));
