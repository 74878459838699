import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as projectActions from "../../actions/projectActions";
import connect from "react-redux/es/connect/connect";
import ProjectLanding from './ProjectLanding';
import HMError from '../Utils/HMError';
import {Trans, withTranslation} from "react-i18next";
import Apartment from '../Apartment';
import {RouterService} from "../../services/RouterService";
import {ADDON} from "../../enums/appModes";
import ChooseApartment from "../App/Header/TopBar/SettingsDropdown/ChooseApartment";

class Project extends Component {

    componentDidMount() {
        this.initProject();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initProject();
    }

    initProject() {
        var apartmentId = this.tryParseApartmentId();

        if (this.props.match.params.projectId)
            this.props.projectActions.getProject(this.props.appMode, false, this.props.match.params.projectId, apartmentId);
    }

    tryParseApartmentId() {
        if (!this.props.match.isExact && this.props.appMode === ADDON) {
            // extract apartment id for addon mode as react router is not providing it in parent route
            var path = this.props.location.pathname.replace(this.props.match.url, '');

            if (path.startsWith('/')) {
                // trim starting slash
                path = path.substring(1);
            }

            const trailingIndex = path.indexOf('/');
            if (trailingIndex > 0) {
                // trim ending slash with rest of the url
                path = path.substring(0, trailingIndex);
            }

            return +path;
        }

        // apartment id is not necessary for other modes
        return undefined;
    }

    render() {

        if (this.props.match.params.projectId !== '' + this.props.project.projectId)
            return null;

        if (this.props.project.notFound) {
            const projectId = this.props.match.params.projectId;
            return (
                <HMError>
                    <Trans i18nKey="common:errors.projectNotFound">
                        Project with id: '<strong>{{projectId}}</strong>' not found.
                    </Trans>
                </HMError>
            );
        }

        const isFinishedProject = this.props.project.project && this.props.project.project.finished;
        if(isFinishedProject){
            return <Redirect to="/"/>
        }

        if (!this.props.project.project)
            return null;

        return (
            <Switch>
                <Route exact path={`${this.props.match.path}/`} component={ProjectLanding}/>
                <Route path={`${this.props.match.path}/:apartmentId`} component={Apartment}/>
            </Switch>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentAppMode: state.app.appMode,
        project: state.app.project
    }
}

function mapDispatchToProps(dispatch) {
    return {
        projectActions: bindActionCreators(projectActions, dispatch)
    }
}


Project.propTypes = {
    appMode: PropTypes.string.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Project)));
