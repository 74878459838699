import store from "../reducers/store";
import {appLoadingWrapper, appReadyWrapper, setAppModeWrapper} from "./appActions";
import * as actionTypes from "./actionTypes";
import {ADDON} from "../enums/appModes";
import UtilService from "../services/UtilService";
import BackendApi from "../api/BackendApi";
import AuthorizedUserService from "../services/AuthorizedUserService";

const projectNotFoundWrapper = (projectId) => {
    return {type: actionTypes.PROJECT_NOT_FOUND, payload: { projectId: projectId }};
};

export const setCustomCss = (projectId, styleId, customCss) => {
    try {
        // remove previous style if present
        const existingStyle = document.getElementById(styleId);
        if (existingStyle) {
            existingStyle.parentNode.removeChild(existingStyle);
        }

        // add new one
        if (!!customCss && customCss !== '') {
            const head = document.head || document.getElementsByTagName('head')[0];
            const style = document.createElement('style');

            style.setAttribute('id', styleId);
            head.appendChild(style);

            style.type = 'text/css';
            if (style.styleSheet){
                // This is required for IE8 and below.
                style.styleSheet.cssText = customCss;
            } else {
                style.appendChild(document.createTextNode(customCss));
            }
        }
    } catch (err) {
        console.log(`Can't set custom css for project [${projectId}] style id [${styleId}], style:`);
        console.log(customCss);
        console.log(err);
    }
};

export const getProject = (appMode, singleApartment, projectId, apartmentId = undefined) => {
    return (dispatch) => {
        const state = store.getState();

        if (state.app.me && state.app.me.customers) {
            const customer = state.app.me.customers.find(customer => '' + customer.apartment.project.id === '' + projectId && (!apartmentId || '' + customer.apartment.id === '' + apartmentId));
            if (customer) {
                AuthorizedUserService.selectCustomer(customer.id);
            }
        }

        if (state.app.appMode !== appMode)
            dispatch(setAppModeWrapper(appMode));

        if (appMode === ADDON) {
            const projectIdAsNumber = +projectId;
            if (!UtilService.equals(projectIdAsNumber, projectId)) {
                if (state.app.project.projectId !== projectId || !state.app.project.notFound) {
                    dispatch(projectNotFoundWrapper(projectId));
                    dispatch(appReadyWrapper());
                }
                return;
            }

            projectId = projectIdAsNumber
        }

        if (state.app.project.projectId === projectId) return;

        dispatch(appLoadingWrapper());
        dispatch({type: actionTypes.PROJECT_LOADING, payload: { projectId: projectId }});


        let fetchProjectPromise = state.app.appMode === ADDON
            ? BackendApi.fetchProjectById(projectId)
            : singleApartment
                ? BackendApi.fetchProjectByPublicKey(projectId, "apartmentKey")
                : BackendApi.fetchProjectByPublicKey(projectId, "key");

        fetchProjectPromise.then (project => {
            if (project) {
                if (project.finished && state.app.appMode !== ADDON) {
                    window.location.href = 'https://envise.io';
                }

                dispatch({type: actionTypes.PROJECT_LOADED, payload: { projectId: projectId, project: project }});
                setCustomCss(project.id, 'projectLandingPageCss', project.projectLandingPageCss);
                setCustomCss(project.id, 'apartmentLandingPageCss', project.apartmentLandingPageCss);
                dispatch(appReadyWrapper());
            } else {
                dispatch(projectNotFoundWrapper(projectId));
                dispatch(appReadyWrapper());
            }
        }).catch(() => {
            dispatch(projectNotFoundWrapper(projectId));
            dispatch(appReadyWrapper());
        });
    }
};
