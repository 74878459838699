import React, {Component} from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import Currency from "react-currency-formatter";
import {withTranslation} from "react-i18next";
import {RENTALS} from "../../../enums/appModes";

//TODO translate the swedish strings below
class GrandTotal extends Component {

    render() {

        const {t, hasChoices, grandTotal, showDelayedPrice} = this.props;

        //TODO is this necessary nowadays?
        if (!hasChoices)
            return "Inget val";

        if (grandTotal.hasDelayedPrice && !showDelayedPrice)
            return t('cost_delayed_price');
        else
            // TODO: use the code from the Currency plugin and add there additional options like hideDecimals etc.
            return <React.Fragment>
                <Currency quantity={this.props.grandTotal.price} currency={this.props.currency.currency} pattern={this.props.grandTotal.hasDelayedPrice ? this.props.currency.patternDelayedPrice : this.props.currency.pattern} />
                {
                    this.props.doPricePerMonth &&
                    t("cost_per_month_suffix")
                }
            </React.Fragment>
    }
}

GrandTotal.propTypes = {
    grandTotal: PropTypes.object.isRequired,
    showDelayedPrice: PropTypes.bool, // Setting this to true will display "Delayed price" and false "0 kr*
    hasChoices: PropTypes.bool // This is to show that no options exists or no option has been selected
};

GrandTotal.defaultProps = {
    grandTotal: {price: 0, hasDelayedPrice: false},
    showDelayedPrice: true,
    hasChoices: true
};

function mapStateToProps(state, ownProps) {
    return {
        currency: state.app.currency,
        isRental: state.app.appMode === RENTALS,
        doPricePerMonth: state.app.appMode === RENTALS || state.app.pricePerMonth
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(GrandTotal));
