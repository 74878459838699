import "@babel/polyfill"; // Support legacy browser (>IE11)
import './i18n';
import './hotjar';
import './vhFix';
import React from 'react'
import {render} from 'react-dom'
import App from './components/App'
import './index.css'
import smoothscroll from 'smoothscroll-polyfill';

// smooth scroll polyfill
smoothscroll.polyfill();

// closest polyfill
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

render(
    <App/>,
    document.getElementById('root')
);
