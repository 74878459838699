import React, {Component} from 'react';
import HMButton from "../../Utils/HMButton";
import * as actions from "../../../actions/authActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {Link, Redirect} from "react-router-dom";
import HMLogo from "../HMLogo";

//TODO, we need to redirect the user back to login page should they use a consumed or invalid resetToken. Otherwise they will be trapped, clicking the link from email
class ActivatePassword extends Component {

    constructor() {
        super();
        this.state = {newPassword: ""};
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    handleSubmit(event) {
        this.props.actions.resetPassword(this.props.match.params.token, this.state.newPassword);
        event.preventDefault();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.auth.username && this.props.auth.username) {
            this.props.actions.authorize(this.props.auth.username, this.state.newPassword);
        }
    }

    render() {

        if (this.props.auth.authorized) return <Redirect to={{ pathname: "/" }} />;

        const { t } = this.props;

        return (
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-sm-9 col-md-7 col-lg-6 align-self-center hm-auth bg-frosted">
                        <div className="my-5">
                            <div>
                                <HMLogo/>
                                <h4 className="text-center">{t('activatePasswordView.title')}</h4>
                                <h6>{t('activatePasswordView.description')}</h6>
                                <form onSubmit={(event) => {this.handleSubmit(event)}} className="form-signin">
                                    <div className="form-label-group">
                                        <label htmlFor="inputPassword">{t('enterPasswordTitle')}</label>
                                        <input
                                            name="newPassword"
                                            type="password"
                                            id="inputPassword"
                                            className="form-control"
                                            placeholder={t('enterNewPasswordPlaceholder')}
                                            value={this.state.password}
                                            onChange={(target) => this.handleInputChange(target)}
                                            required
                                        />
                                    </div>

                                    <HMButton clickFn={() => {}}
                                              text={t("resetPassword")}
                                              disabled={this.props.loading > 0} disabledText={t("sending")}
                                              active={this.props.auth.resetLinkSent} activeText={t('sent')}
                                              type="submit"  />
                                    <hr></hr>
                                    <Link to={"/auth/login"}>{t("backToLogin")}</Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.app.auth,
        loading: state.app.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('auth')(ActivatePassword));

