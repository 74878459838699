import LogService from "./LogService";
import ProductService from "./ProductService";

export default class ProductRelationshipService {

    /**
     * //FIXME can probably be removed, do to ProductRelationShipInfo component is not used anymore
     * This is to get the understanding what relation a product has to another. Usefull for displaying information to the user about what is happening and why
     * @param projectProduct The projectProduct to look for
     * @param relationships From redux state
     * @param productStorage This should be a global storage of all products encountered locally stored in redux state
     */
    static getProductRelationships(product, relationships, productStorage) {

        const resultingRelationships = {match: false, asSource: {adds: [], disables: []}, asTarget: {addedBy: [], disabledBy: []}};

        relationships.forEach((relationShip) => {

            const sourceProductIds = relationShip.source.map((obj) => {
                return obj.product.id
            });

            const targetProductIds = relationShip.target.map((obj) => {
                return obj.product.id
            });

            const asSource = sourceProductIds.includes(product.id);
            const asTarget = targetProductIds.includes(product.id);

            if (asSource) {
                relationShip.target.forEach((ppLightObj) => {

                    const prodId = ppLightObj.product.id;

                    if (productStorage.has(prodId)) {
                        const targetProduct = productStorage.get(prodId);

                        switch (relationShip.type) {
                            case "ENABLE":
                                resultingRelationships.asSource.adds.push(targetProduct);
                                resultingRelationships.match = true;
                                break;

                            case "DISABLE":
                                resultingRelationships.asSource.disables.push(targetProduct);
                                resultingRelationships.match = true;
                                break;

                            default:
                                break;
                        }
                    }
                })
            }

            if (asTarget) {
                relationShip.source.forEach((ppLightObj) => {

                    const prodId = ppLightObj.product.id;

                    if (productStorage.has(prodId)) {
                        const sourceProduct = productStorage.get(prodId);

                        switch (relationShip.type) {
                            case "ENABLE":
                                resultingRelationships.asTarget.addedBy.push(sourceProduct);
                                resultingRelationships.match = true;
                                break;

                            case "DISABLE":
                                resultingRelationships.asTarget.disabledBy.push(sourceProduct);
                                resultingRelationships.match = true;
                                break;

                            default:
                                break;
                        }
                    }
                })
            }
        });

        return resultingRelationships;
    }

    /**
     * A wrapper around updateAllProductSelectionsForRelationships where one can provide just a room and a map
     * @param room The room which products will be operated on
     * @param linkedProductsMaps a map of relationships received from API
     */
    static updateAllProductSelectionsForRelationshipsInRoom(room, linkedProductsMaps) {

        // It is important we get ALL product occurencies so we can disable potential options. Even extra options
        const productSelections = ProductService.getAllProductSelectionsInRoom(
            room,
            true,
            false,
            true
        );

        LogService.log(`Found ${productSelections.length} productSelections in Room ${room.id}`, "ProductRelationshipService");

        this.updateAllProductSelectionsForRelationships(
            productSelections,
            linkedProductsMaps
        );
    }

    /**
     * This method will iterate through a collection of product selections and their option trying to find and disable products mentioned in the relationship mapping.
     * This should just be on the room level, therefore don't call this method directly but the wrapping updateAllProductSelectionsForRelationshipsInRoom so productSelections ARE FOR ONE ROOM
     * "Source": This is the product that will will create a relationship targeting another product (the target)
     * "Target": The product that will either be disabled or awaken (undisabled from an disabled state).
     */
    static updateAllProductSelectionsForRelationships(productSelections, relationships) {

        // reset all markings before beginning, we don't now what happened before
        productSelections.forEach((productSelection) => {
            productSelection.resetMarkingFromProductRelationships();
        });

        // Loop through each relationship
        relationships.forEach((relationShip) => {

            // We can have multiple sources and targets of the same relationship
            const sourceProductIds = relationShip.source.map((obj) => { return obj.product.id });
            const targetProductIds = relationShip.target.map((obj) => { return obj.product.id });

            // Lets see if any source has been selected, this mean the relationship is active and we need to find the target
            var anySourceSelected = false;
            sourceProductIds.forEach((productId) => {
                if (ProductService.isProductSelectedInProductSelections(productId, productSelections)){
                    anySourceSelected = true;
                }
            });

            let anyTargetSelected = false;
            targetProductIds.forEach((productId) => {
                if (ProductService.isProductSelectedInProductSelections(productId, productSelections)){
                    anyTargetSelected = true;
                }
            });

            if (relationShip.type === "ENABLE") {
                // Disable all the targets before we know if we should enable them
                productSelections.forEach((productSelection) => {
                    this._markProjectProductsAsDisabled(productSelection, targetProductIds);
                });
            }

            // If we have any of the sources selected, lets take action
            if (anySourceSelected) {

                switch (relationShip.type) {
                    case "ENABLE":
                        productSelections.forEach((productSelection) => {
                            this._markProjectProductsAsAwakened(productSelection, targetProductIds);
                        });
                        break;

                    case "DISABLE":
                        // Disable all product selections that are carrying a selected product that was targeted
                        // Also remember that we need to mark any potential selectable project product so the user cannot select something inactive. via standards and options of a product selection?
                        productSelections.forEach((productSelection) => {
                            this._markProjectProductsAsDisabled(productSelection, targetProductIds);
                        });
                        break;

                    default:
                        LogService.error("Unknown relationship state " + relationShip.type, "ProductRelationshipService");
                        break;
                }
            }
        })
    }

    // This method will check and mark if a disabled product is occurring within the products offered in this product selections
    // By iterating the product selection options we'll make sure all products will be encountered and processed
    static _markProjectProductsAsDisabled(productSelection, productIds) {
        productSelection.options.forEach((projectProduct) => {
            if(productIds.includes(projectProduct.product.id)) {
                if (!projectProduct.disabled) {
                    projectProduct.disabled = true;
                    LogService.log(`Disabled project product ${projectProduct.id} because of linked product`, "ProductRelationshipService");
                }
            }
        });
    }

    // This method will unmark a product as disabled if it should not be it. Also it kan mark the entry as awakened if it was disabled prior to this.
    // The awakened property was used before to give user feedback about the complex events of relationships and their effect but are not used anymore
    // By iterating the product selection options we'll make sure all products will be encountered and processed
    static _markProjectProductsAsAwakened(productSelection, productIds) {

        const wasAsleep = productSelection.hasNoEnabledChoisesFromProductRelationships();

        productSelection.options.forEach((projectProduct) => {
            if(productIds.includes(projectProduct.product.id)) {
                if (projectProduct.disabled) {
                    projectProduct.disabled = false;
                    LogService.log(`Enabled project product ${projectProduct.id} because of linked product`, "ProductRelationshipService");
                }
            }
        });

        // Not asleep anymore?
        if (wasAsleep && !productSelection.hasNoEnabledChoisesFromProductRelationships()) {
            productSelection.awakened = true;
        }
    }
}
