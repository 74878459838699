import React, {Component} from 'react';
import PropTypes from "prop-types";
import './style.scss';
import {withTranslation} from "react-i18next";

class SectionCol1HmsForm extends Component {

    componentDidMount() {
        const script = document.getElementById('hmsForm');
        if (!!script) {
            const loadScript = document.createElement('script');
            loadScript.src = script.src;
            loadScript.async = true;
            document.body.appendChild(loadScript);
        } else {
            console.error('Cannot find HMS reporter form script');
        }
    }

    render() {

        if (!this.props.section)
            return null;

        const { t, section } = this.props;

        return (
            <div className={`row justify-content-center hm-landing-page-section hm-landing-page-section-col1hmsform ${!!section.customProperties.cssClass ? section.customProperties.cssClass : ''}`}>
                <div className="col-auto hm-landing-page-section-wrapper">
                    {section.title && section.title !== '' ? <h1>{section.title}</h1> : null}
                    <div dangerouslySetInnerHTML={{__html: section.richContentLeft}}></div>
                </div>
            </div>
        )
    }
}

SectionCol1HmsForm.propTypes = {
    section: PropTypes.object,
};

export default withTranslation('common')(SectionCol1HmsForm);
