import React from 'react';
import PropTypes from "prop-types";
import GrandTotal from "../../../Utils/GrandTotal";
import {withTranslation} from "react-i18next";
import './style.scss'
import ProductAlternativesService from "../../../../services/ProductAlternativesService";

class ProductInfoTable extends React.Component {

    renderDescriptionAndNote() {
        const t = this.props.t;
        return (
            <div className="mt-5">
                <h4 className="normalcase underlined-header">{t('product.property.description')}</h4>
                {!this.props.projectProduct.product.description || this.props.projectProduct.product.description === "" ? '-' : this.props.projectProduct.product.description}
                {
                    this.props.productSelection.note &&
                    <div>
                        <h4 className="normalcase underlined-header">{t('product.property.note')}</h4>
                        {this.props.productSelection.note}
                    </div>
                }
            </div>
        )
    }

    /** There are special properties that must always be present in a product info print-out. Therefore adding
     * mocks of them here if they are not really present */
    addMissingMainProperties(orderedProperties) {
        ProductAlternativesService.specialProperties.forEach((property, i) => {
            if(!orderedProperties[i] || orderedProperties[i].metaPropertyGroup.type !== property) {
                orderedProperties.splice(i, 0, {metaPropertyGroup: {type: property}});
            }
        });
    }

    render() {
        const {projectProduct, productSelection} = this.props;

        const t = this.props.t;

        const productInfos = [
            'name',
            'vendor',
            'productNum',
            'dimension'
        ];

        const orderedProperties = ProductAlternativesService.getOrderedPropertiesList(projectProduct.product, true);
        this.addMissingMainProperties(orderedProperties);

        return (
            <div className="col-md-4 col-sm-12 pl-md-3 hm-product-info-table">
                <div className="row">
                    <div className="col">
                        <div className="ml-3">
                            <div className="row">
                                <div className="col-12 pl-0 pr-0">
                                    <h4 className="normalcase underlined-header">{t('product.specifics')}</h4>
                                </div>
                            </div>

                            {productInfos.map((info, i) =>
                                <div key={`product-info-${info}-${i}`} className={`row ${i % 2 ? '' : 'bg-frosted-light'}`}>
                                    <div className="col-4">{t('product.property.' + info)}</div>
                                    <div className="col">{projectProduct.product[info] || '-'}</div>
                                </div>
                            )}

                            {orderedProperties.map((property, i) => {
                                /** the group name text localization requires to be passed the property type object with `type` and `name` fields, that is why we simply pass the meta property of the property */
                                const groupNameText = ProductAlternativesService.tryLocalizingTheName(t, property.metaPropertyGroup);
                                return <div key={`product-info-${i}`} className={`row ${i % 2 ? '' : 'bg-frosted-light'}`}>
                                    <div className="col-4">{groupNameText}</div>
                                    <div className="col">{property.name || '-'}</div>
                                </div>
                            })}

                            {!this.props.hidePrices &&
                            <div className="row property-price">
                                <div className="col-4">
                                    <h5 className="black"><i>{t('product.property.price')}</i></h5>
                                </div>
                                <div className="col">
                                    <h5 className="black">
                                        <GrandTotal grandTotal={{
                                            price: productSelection ? projectProduct.price * productSelection.quantity : projectProduct.price,
                                            hasDelayedPrice: projectProduct.delayedPrice
                                        }} showDelayedPrice={false}/>
                                    </h5>
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col pr-0">
                        {this.renderDescriptionAndNote()}
                    </div>
                </div>
            </div>
        )
    }
}

ProductInfoTable.propTypes = {
    hidePrices: PropTypes.bool,
    projectProduct: PropTypes.object,
    productSelection: PropTypes.object
};

ProductInfoTable.defaultProps = {
    projectProduct: null,
    productSelection: null
};

export default withTranslation('common')(ProductInfoTable);
