export default class DigitalSigningService {

    // A case is considered finished and no longer requested once all customers has signed (an agent signature can still be missing)
    static isCaseFinishedForCustomer(contract, isLegacy) {

        if (isLegacy) {
            // Also needs to be active, otherwise its a deleted contract
            return (contract.inactive === true || contract.hasExternalPartiesSigned) ? true : false;
        } else {
            // Scrive contract needs to be published otherwise its finished or canceled
            return (contract.status === "CANCELED" || contract.customersHasSigned) ? true : false;
        }
    }
}
