import UtilService from "./UtilService";

export default class AuthService {

    static getLocalStorageKey() {
        return "auth"
    }

    static storeAuthentication(jwtAccessToken) {
        UtilService.persistToLocalStorage(this.getLocalStorageKey(), jwtAccessToken);
    }

    static getAuthentication() {
        return UtilService.getFromLocalStorage(this.getLocalStorageKey());
    }

    static clearAuthentication() {
        return UtilService.removeFromLocalStorage(this.getLocalStorageKey());
    }
}
