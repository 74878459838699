import store from "../reducers/store";
import BackendApi from "../api/BackendApi";
import AuthorizedUserService from "../services/AuthorizedUserService";
import * as actionTypes from "./actionTypes";

export const appLoadingWrapper = (message = undefined) => {
    return {type: actionTypes.APP_LOADING, payload: {message: message}};
};

export const appReadyWrapper = (message = undefined) => {
    return {type: actionTypes.APP_READY, payload: {message: message}};
};

export const setAppModeWrapper = (appMode, singleApartment = false) => {
    return {type: actionTypes.MODE_SET, payload: {mode: appMode, singleApartment: singleApartment}}
};


const receiveMeAgentWrapper = (userId, identityId, me) => {
    return {type: actionTypes.ME_RECEIVE, payload: {type: "AGENT", userId: userId, identityId: identityId, me: me}}
};

const receiveMeCustomerWrapper = (userId, identityId, me) => {
    return {type: actionTypes.ME_RECEIVE, payload: {type: "CUSTOMER", userId: userId, identityId: identityId, me: me}}
};

export const cookiesAccepted = () => {
    return (dispatch) => {
        dispatch({type: actionTypes.COOKIES_ACCEPTED, payload: {}});
    }
};

export const appSizesChanged = (appSizes) => {
    return (dispatch) => {
        const state = store.getState();

        if (state.app.appSizes.app.width === appSizes.app.width
            && state.app.appSizes.app.height === appSizes.app.height
            && state.app.appSizes.topBar.width === appSizes.topBar.width
            && state.app.appSizes.topBar.height === appSizes.topBar.height
        ) {
            return;
        }

        dispatch({type: actionTypes.APP_SIZES_CHANGED, payload: appSizes})
    }
};


export const appLoading = () => {
    return (dispatch) => {
        dispatch(appLoadingWrapper());
    }
};

export const appReady = () => {
    return (dispatch) => {
        dispatch(appReadyWrapper());
    }
};

export const setAppMode = (appMode) => {
    return (dispatch) => {
        dispatch(setAppModeWrapper(appMode))
    }
};


export const getMe = (force = false) => {
    return (dispatch) => {
        const state = store.getState();

        if (!state.app.auth.authorized) return Promise.resolve();

        if (!force && state.app.me) return Promise.resolve();

        dispatch(appLoadingWrapper());

        AuthorizedUserService.removeSelectedCustomer();

        BackendApi.fetchMe().then((me) => {
            if (me) {
                if (me.isAgent)
                    dispatch(receiveMeAgentWrapper(me.id, me.agent.id, me));
                else {
                    me = AuthorizedUserService.fixLastInviteEmailSent(me);
                    dispatch(receiveMeCustomerWrapper(me.id, me.customer ? me.customer.id : 0, me));
                }
            } else {
                dispatch({type: actionTypes.ME_RECEIVE_ERROR, payload: {}});
            }

            dispatch(appReadyWrapper());
        }).catch(() => {
            dispatch({type: actionTypes.ME_RECEIVE_ERROR, payload: {}});
            dispatch(appReadyWrapper());
        });
    }
};
