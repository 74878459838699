import i18n from "../i18n";

export default class CollectorCheckoutService {
    static getCollectorCheckoutScript(collectorCheckoutData) {
        const script = document.createElement("script");

        let language;
        switch(i18n.language.substring(0, 2)) {
            case 'sv':
                language = 'sv-SE';
                break;
            case 'en':
                language = 'en-SE';
                break;
            case 'nb':
                language = 'nb-NO';
                break;
            case 'fi':
                language = 'fi-FI';
                break;
            default:
                language = 'sv-SE';
                break;
        }

        script.src = collectorCheckoutData.frontEndUrl + '/collector-checkout-loader.js';
        script.setAttribute('data-token', collectorCheckoutData.publicToken);
        script.setAttribute('data-variant', "b2c");
        script.setAttribute('data-padding', "none");
        script.setAttribute('data-lang', language); // supported: sv-SE, en-SE, nb-NO, fi-FI, en-DK and en-DE
        script.setAttribute('data-action-color', "#f2a069");

        return script;
    }

    static setDangerousHtml(html, el) {
        if(el === null) return;
        const range = document.createRange();
        range.selectNodeContents(el);
        range.deleteContents();
        el.appendChild(html);
    }

    static isCompleted(collectorCheckout) {
        return !!collectorCheckout && !!collectorCheckout.data && collectorCheckout.data.isCompleted
    }
}
