import React, {Component} from 'react';
import {connect} from "react-redux";
import UtilService from "../../../../../services/UtilService";
import ProductService from "../../../../../services/ProductService";
import GrandTotal from "../../../../Utils/GrandTotal";
import HMExpandArrow from "../../../../Utils/HMExpandArrow";
import {withTranslation} from "react-i18next";
import {AppRoute} from "../../../../../services/RouterService";
import PropTypes from "prop-types";
import "./style.scss";
import PackageService from "../../../../../services/PackageService";

class CostDropdown extends Component {

    _dropdownMenu = undefined;

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    toggle() {
        const expanded = !this.state.expanded;

        this.setState({...this.state, expanded: expanded});

        if (expanded) {
            this.addListeners();
        } else {
            this.removeListeners();
        }
    }

    addListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    }

    removeListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }

    handleDocumentClick(e) {
        if (!this.state.expanded || (this._dropdownMenu && (this._dropdownMenu.contains(e.target) || this._dropdownMenu === e.target))) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.toggle();
    }

    shouldShowCostInfo() {
        return UtilService.includesAnyOf(this.props.currentRoutes, [AppRoute.Apartment])
            && this.props.ready;
    }

    render() {
        if (!this.shouldShowCostInfo()) return null;

        const { t } = this.props;

        const roomsWithProductSelections = UtilService.iterableToArray(this.props.rooms).map(room => {
            return {
                room: room,
                productSelections: PackageService.getAllProductSelectionsInRoomWithStylesHierarchy(room)
            };
        });

        return (
            <React.Fragment>
                <li className="d-none d-sm-block nav-item dropdown cost-dropdown" ref={(el) => { this._dropdownMenu = el; }}>
                    <span className="nav-link pointer cost-dropdown-header" onClick={() => this.toggle()}>
                        <span className="total-cost-title italic">
                            {this.props.hidePrices ? t('apartmentOverview') : (t('cost_options') + ':')}
                        </span>
                        <span className="total-and-arrow">
                            {!this.props.hidePrices &&
                            <h3><GrandTotal grandTotal={this.props.grandTotal}/></h3>
                            }
                            <HMExpandArrow expanded={this.state.expanded}/>
                        </span>
                    </span>
                    <div className={`hm-price-dropdown-menu dropdown-menu dropdown-menu-right ${this.state.expanded ? "show" : ""}`}>
                        <div className="hm-price-dropdown">
                            {roomsWithProductSelections.map((roomWithProductSelection, i) => {
                                return (
                                    <div key={`${this.constructor.name}-${i}-${roomWithProductSelection.room.id}`} className="room">
                                        <div className="room-title row">
                                            <div className="col">
                                                {roomWithProductSelection.room.roomType}
                                            </div>
                                            {!this.props.hidePrices &&
                                            <div className="col text-right">
                                                <span><GrandTotal grandTotal={ProductService.calculateGrandTotal([roomWithProductSelection.room])} showDelayedPrice={true}/></span>
                                            </div>
                                            }
                                        </div>

                                        {roomWithProductSelection.productSelections.activeStyles.map((styleProductSelections, i) => {
                                            return (
                                                <div key={`${roomWithProductSelection.room.id}-${styleProductSelections.style.id}-${i}`} className="style-group">
                                                    <div className="row style-group-name">
                                                        <div className="col">
                                                            {styleProductSelections.style.name}
                                                        </div>
                                                        {!this.props.hidePrices && styleProductSelections.style.hasStylePrice &&
                                                        <div className="col text-right">
                                                            <GrandTotal grandTotal={{
                                                                price: styleProductSelections.style.stylePrice,
                                                                hasDelayedPrice: false
                                                            }} showDelayedPrice={true}/>
                                                        </div>
                                                        }
                                                    </div>

                                                    {styleProductSelections.productSelections.map((productSelection, i) => {
                                                        return (
                                                            <div key={`${roomWithProductSelection.room.id}-${productSelection.id}-${i}`} className="row product">
                                                                <div className="col product-name">
                                                                    {productSelection.subCategory.name.toLowerCase()}
                                                                </div>
                                                                {!this.props.hidePrices && !styleProductSelections.style.hasStylePrice &&
                                                                <div className="col text-right">
                                                                    {productSelection.isDisabledFromProductRelationships() ?
                                                                        (
                                                                            <clr-icon shape="exclamation-triangle" class="is-solid with-text"/>
                                                                        )
                                                                        :
                                                                        (
                                                                            <GrandTotal grandTotal={{price: productSelection.price, hasDelayedPrice: productSelection.getDelayedPrice()}} showDelayedPrice={true}/>
                                                                        )
                                                                    }

                                                                </div>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}

                                        {roomWithProductSelection.productSelections.room.map((productSelection, i) => {
                                            return (
                                                <div key={`${roomWithProductSelection.room.id}-${productSelection.id}-${i}`} className="row product">
                                                    <div className="col product-name">
                                                            {productSelection.subCategory.name.toLowerCase()}
                                                    </div>
                                                    {!this.props.hidePrices &&
                                                    <div className="col text-right">
                                                        {productSelection.isDisabledFromProductRelationships() ?
                                                            (
                                                                <clr-icon shape="exclamation-triangle" class="is-solid with-text"/>
                                                            )
                                                                :
                                                            (
                                                                <GrandTotal grandTotal={{price: productSelection.price, hasDelayedPrice: productSelection.getDelayedPrice()}} showDelayedPrice={true}/>
                                                            )
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </li>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        hidePrices: state.app.hidePrices,
        rooms: state.app.rooms,
        ready: state.app.apartment2.apartment && state.app.roomsOrder.length > 0,
        grandTotal: state.app.grandTotal
    }
}

CostDropdown.propTypes = {
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};

export default connect(mapStateToProps)(withTranslation('common')(CostDropdown));
