import store from "../reducers/store";

export default class BrandService {

    static getTerminologyNamespace() {

        const project = store.getState().app.project;
        const domain = (project && project.project.account) ? project.project.account.domain : undefined;

        switch (domain) {

            // TODO: TW: remove this service if possible
            // Sorry fort this Tomasz :'(
            case "modexa":
                return "modexa";

            case "jm":
                return "jm";

            default:
                return "common";
        }

    }

}
