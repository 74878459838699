import ModelValidationError from "./ModelValidationError";

export default class Product {
    constructor() {
        this.id = null;
        this.name = null;

        this.subCategory = null;
        this.mainCategory = null;

        this.metaProduct = null;
        this.productProperties = [];

        // Shortcuts to product properties
        this.color = "";
        this.material = "";
        this.assembly = "";
    }

    parse(obj) {
        Object.assign(this, obj);

        this._validate();

        return this;
    }

    isConnectedToAlternatives() {
        return this.productProperties.length > 0;
    }

    _validate() {
        if (!this.id) {
            throw new ModelValidationError("Instance is missing id");
        }

        if (!this.name) {
            throw new ModelValidationError("Instance is missing name");
        }

        if (!this.metaProduct) {
            throw new ModelValidationError("Instance is missing metaProduct");
        }

        if (!this.subCategory) {
            throw new ModelValidationError("Instance is missing subCategory");
        }

        if (!this.mainCategory) {
            throw new ModelValidationError("Instance is missing mainCategory");
        }

        this.productProperties.forEach((metaProperty) => {
            if (!metaProperty.metaPropertyGroup)
                throw new ModelValidationError("Instance is missing a metaPropertyGroup in productProperties");
        });
    }
}
