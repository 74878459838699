import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import './style.scss'
import ReactSVG from "react-svg";
import SignCallToAction from "../SignCallToAction";
import ProcessFinishedCallToAction from "../ProcessFinishedCallToAction";
import ProcessOngoingCallToAction from "../ProcessOngoingCallToAction";
import SizeIcon from "../../../../assets/icons/sizeIcon.png";
import ProjectIcon from "../../../../assets/icons/projectIcon.png";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as paymentActions from "../../../../actions/paymentActions";
import CollectorCheckoutService from "../../../../services/CollectorCheckoutService";
import FinishPaymentCallToAction from "../FinishPaymentCallToAction";
import DigitalSigningService from "../../../../services/DigitalSigningService";

class ApartmentData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collectorCheckout: undefined,
            // cases: undefined, We could fetch apartment cases here but I cannot think of a scenario that the local apartment.cases can be out of sync.
            callToActionDataRefreshed: false
        }
    }

    getBluePrint() {
        return this.props.apartment.attachments.find(a => a.isBlueprint === true)
    }

    componentWillMount() {
        this.props.paymentActions.getPayment().then(payment => {
            this.setState({
                ...this.state,
                callToActionDataRefreshed: true,
                collectorCheckout: payment
            });
        });
    }

    getCallToActionComponent() {

        // wait for call to action data to be downloaded
        if (!this.state.callToActionDataRefreshed)
            return null;

        // if there's a payment pending render finish payment call to action
        if (this.state.collectorCheckout && !CollectorCheckoutService.isCompleted(this.state.collectorCheckout))
            return <FinishPaymentCallToAction/>;

        // Depending on the apartment state, render a call to action
        if (this.props.apartment.contracts.find(c => !DigitalSigningService.isCaseFinishedForCustomer(c)))
            return <SignCallToAction/>;

        // Legacy, Can be removed when we leave assently, depending on the apartment state, render a call to action
        if (this.props.apartment.cases.find(c => !DigitalSigningService.isCaseFinishedForCustomer(c, true)))
            return <SignCallToAction/>;

        // TODO add check on each room aswell, could be that one room is added later
        if (this.props.apartment.isLocked)
            return <ProcessFinishedCallToAction/>;

        return <ProcessOngoingCallToAction roomsOrder={this.props.roomsOrder}/>
    }

    render() {
        const { t, project, apartment } = this.props;

        const blueprint = this.getBluePrint();

        return(
            <div className="hm-apartment-data hm-landing-page-section-header-actions row m-0 align-items-center justify-content-center">
                {blueprint &&
                    <div className="col-xs-12 col-md-6 p-0">
                        <a href={blueprint.url} target="_blank">
                            <ReactSVG className="blueprint" src={blueprint.url}/>
                        </a>
                    </div>
                }

                <div className={`left-column ${blueprint ? "col-xs-12 col-md-6" : "col-auto no-blueprint"}`}>

                    {this.props.apartment.apartmentRooms.length > 0 &&
                        <div className="row call-to-action-section">
                            <div className="col-12 m-0 p-0">
                                <h3 className="normalcase">{t('apartmentLandingPage.theLatest')}</h3>
                                {this.getCallToActionComponent()}
                            </div>
                        </div>
                    }

                    <div className="row project-info-section align-items-center">
                        <div className="col-6 project">
                            <div className="row m-0 justify-content-center align-items-center">
                                <div className="col-auto details-icon">
                                    <img className="image-fluid" src={ProjectIcon} alt=""/>
                                </div>
                                <div className="col-auto details-text">
                                    <p className="text-truncate">{t('chooseApartment.project')}</p>
                                    <h6 className="text-truncate mb-0">{project.name}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 m-0 p-0 size">
                            <div className="row m-0 justify-content-center align-items-center">
                                <div className="col-auto details-icon">
                                    <img className="image-fluid" src={SizeIcon} alt=""/>
                                </div>
                                <div className="col-auto details-text">
                                    <p className="text-truncate">{t('chooseApartment.size')}</p>
                                    <h6 className="text-truncate mb-0">{apartment.size} m<sup>2</sup>{apartment.floorNumber && <span className="lowercase">, {t('chooseApartment.floor')} {apartment.floorNumber}</span>}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch)
    }
}


export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ApartmentData));
