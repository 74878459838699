import React, {Component} from 'react';
import PropTypes from "prop-types";
import "./style.scss"

class HMError extends Component {
    render() {
        return (
            <div className="container-fluid hm-error bg-frosted">
                <div className="row">
                    <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col error-content">
                                <div className="row alert alert-danger m-0 align-items-center" role="alert">
                                    <div className="col-auto px-0">
                                        <i className="fas fa-2x fa-exclamation-triangle"/>
                                    </div>
                                    <div className="col-8">
                                        {this.props.children}
                                        {!this.props.children && this.props.error}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

HMError.propTypes = {
    error: PropTypes.string
};

export default HMError;
