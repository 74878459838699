import React, {Component} from 'react';
import PropTypes from "prop-types";
import ProductSelectionBox from "../ProductSelectionBox";
import "./style.scss"
import {withTranslation} from "react-i18next";
import {NavigationItemType} from "../../../../services/SelectionProgressService";
import PackageService from "../../../../services/PackageService";
import HMImage from "../../../Utils/HMImage";

class RoomSelectedPackageProductList extends Component {

    _element = undefined;

    constructor(props) {
        super(props);

        this.state = {
            initialLoad: true
        }
    }

    _timeout = undefined;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.selectedNavigationItem && !!this.props.selectedNavigationItem && prevProps.selectedNavigationItem.key !== this.props.selectedNavigationItem.key) {
            this.scrollToSelectedItem();
        } else if (!!this.props.selectedNavigationItem && this.state.initialLoad) {
            this.setState({...this.state, initialLoad: false});
            this.scrollToSelectedItem();
        }
    }

    componentWillUnmount() {
        clearTimeout(this._timeout);
    }

    scrollToSelectedItem() {
        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            const box = document.getElementById(this.props.selectedNavigationItem.key);

            let scrollable = this._element.closest('.scrollable');
            let isInScrollable = !!scrollable;

            if (!scrollable) {
                scrollable = window;
            }

            if (!box) {
                scrollable.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                if (isInScrollable) {
                    box.scrollIntoView({
                        behavior: 'smooth'
                    });
                } else {
                    const scrollTop = (window.pageYOffset || document.documentElement.scrollTop) + box.getBoundingClientRect().top - this.props.topBarHeight - 20;
                    scrollable.scrollTo({
                        top: scrollTop,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            }
        }, 50);
    }

    getMatchingNavigationItem(navigationItems, productSelection) {
        return navigationItems.find(item => item.argument.item.id === productSelection.id);
    }

    getStartOrContinueItem(navigationItems) {
        for (let i = 0; i < navigationItems.length; i++) {
            if (!navigationItems[i].finished) {
                if (navigationItems[i] !== this.props.selectedNavigationItem) {
                    navigationItems[i].continuationType = i === 0 ? 'start' : 'continue';
                }

                break;
            }
        }
    }

    getTitle(t, navigationItem) {
        return navigationItem.getTitle(t, {room: this.props.room});
    }

    render() {
        const { t } = this.props;

        if (!this.props.selectedNavigationItem)
            return (null);

        let packageInfo = null;
        let productSelections = [];
        let navigationItems = [];
        switch (this.props.selectedNavigationItem.argument.type) {
            case NavigationItemType.Package: {
                const activeStyle = this.props.selectedNavigationItem.argument.item;
                packageInfo = PackageService.getFullStyle(activeStyle);
                productSelections = activeStyle.productSelections.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
                navigationItems = this.props.selectedNavigationItem.children;
                break;
            }

            case NavigationItemType.PackageCategory: {
                const activeStyle = this.props.selectedNavigationItem.parent.argument.item;
                packageInfo = PackageService.getFullStyle(activeStyle);
                productSelections = activeStyle.productSelections.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
                navigationItems = this.props.selectedNavigationItem.parent.children;
                break;
            }

            case NavigationItemType.Other:
                packageInfo = {
                    name: this.getTitle(t, this.props.selectedNavigationItem)
                };
                productSelections = this.props.selectedNavigationItem.argument.item.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
                navigationItems = this.props.selectedNavigationItem.children;
                break;

            case NavigationItemType.OtherCategory:
                packageInfo = {
                    name: this.getTitle(t, this.props.selectedNavigationItem.parent)
                };
                productSelections = this.props.selectedNavigationItem.parent.argument.item.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
                navigationItems = this.props.selectedNavigationItem.parent.children;
                break;

            case NavigationItemType.Extra:
                packageInfo = {
                    name: this.getTitle(t, this.props.selectedNavigationItem)
                };
                productSelections = this.props.selectedNavigationItem.argument.item.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
                navigationItems = this.props.selectedNavigationItem.children;
                break;

            case NavigationItemType.ExtraCategory:
                packageInfo = {
                    name: this.getTitle(t, this.props.selectedNavigationItem.parent)
                };
                productSelections = this.props.selectedNavigationItem.parent.argument.item.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
                navigationItems = this.props.selectedNavigationItem.parent.children;
                break;
        }

        this.getStartOrContinueItem(navigationItems);

        return (
            // This guy should not float by itself, just show the current room
            <div className="hm-room-selected-package-product-list" ref={ (el) => this._element = el}>
                <div className="row no-gutters">
                    <div className="col-12">
                        <h1 className="color-dark uppercase">{packageInfo.name}</h1>
                    </div>
                </div>

                {(packageInfo.description || packageInfo.image) &&
                <div className="row no-gutters package-info">
                    {packageInfo.image &&
                    <div className={packageInfo.description ? 'col-12 col-lg-8' : 'col-12'}>
                        <HMImage imageSrc={PackageService.getStyleImageUrlFromStyle(packageInfo)}/>
                    </div>
                    }
                    {packageInfo.description &&
                    <div className={`${packageInfo.image ? 'col-12 col-lg-4 pl-0 pl-lg-4 pt-4 pt-lg-0' : 'col-12'}`}>
                        <p className="m-0" dangerouslySetInnerHTML={{__html: packageInfo.description}}></p>
                    </div>
                    }
                </div>
                }
                <div className="row no-gutters product-list">
                    {productSelections.length === 0 &&
                    <div className="col">
                        {t('There are no selected products...')}
                    </div>
                    }

                    {productSelections.length > 0 && productSelections.map((productSelection) => {
                        return <div key={`${this.constructor.name}-${productSelection.id}`}
                                    className="selection-box col-auto mb-3">
                            <ProductSelectionBox
                                hidePrices={this.props.hidePrices}
                                locked={this.props.room.isLocked}
                                navigationItem={this.getMatchingNavigationItem(navigationItems, productSelection)}
                                productSelection={productSelection}
                                onSelectAction={this.props.onSelectAction}
                            />
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

RoomSelectedPackageProductList.propTypes = {
    topBarHeight: PropTypes.number.isRequired,
    hidePrices: PropTypes.bool,
    room: PropTypes.object.isRequired,
    selectedNavigationItem: PropTypes.object,
    onSelectAction: PropTypes.func,
};

export default withTranslation('common')(RoomSelectedPackageProductList);
