import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

class SigningParty extends Component {

    render() {
        const {party, t} = this.props;

        return (
            <div className="col-auto signing-party">
                <a href={party.isSigned ? undefined : party.partyUrl}>
                    <div className={`card ${party.isSigned ? "signed" : ""}`}>
                        <div className="row">
                            <div className="col-12">
                                <h4>{party.name}</h4>
                            </div>
                            {party.isSigned ?
                                (
                                    <div className="col-12 signing-status">
                                        <clr-icon size="30" shape="success-standard"></clr-icon>
                                        <h5>{t('signingCheckout.party.done')}</h5>
                                    </div>
                                )
                                :
                                (
                                    <div className="col-12 signing-status">
                                        <clr-icon size="30" shape="plus-circle"></clr-icon>
                                        <h5>{t('signingCheckout.party.clickToSign')}</h5>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

SigningParty.propTypes = {
    party: PropTypes.object.isRequired
};

export default withTranslation('common')(SigningParty);
