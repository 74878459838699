import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import {ADDON} from "../../../../../../enums/appModes";
import {bindActionCreators} from "redux";
import * as authActions from "../../../../../../actions/authActions";
import RouterService, {AppRoute} from "../../../../../../services/RouterService";
import PropTypes from "prop-types";
import UtilService from "../../../../../../services/UtilService";
import {Link} from "react-router-dom";

class ChooseApartment extends Component {

    handleClick() {
        if (this.props.clickCallback)
            this.props.clickCallback();

        return true;
    }

    render() {
        if (this.props.appMode !== ADDON
            || !UtilService.includesAnyOf(this.props.currentRoutes, [AppRoute.Apartment])
            || !this.props.me || !this.props.me.hasMultipleCustomers) return null;

        const { t } = this.props;

        return (
            <Link to={RouterService.chooseApartmentPath()} className="dropdown-item" onClick={() => { this.handleClick(); }}>
                <clr-icon shape="checkbox-list" class={`with-text`}/> {t('topBar.chooseApartment')}
            </Link>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        me: state.app.me
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch)
    }
}

ChooseApartment.propTypes = {
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired,
    clickCallback: PropTypes.func
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ChooseApartment));
