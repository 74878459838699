import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import HmMapMarker from "./HomeMakerMarker";
import PropTypes from "prop-types";
import "./style.scss";

class GoogleMap extends Component {
    static defaultProps = {
        zoom: 15,
        mapOptions: {
            styles: [
                {
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "gamma": 0.8
                        },
                        {
                            "lightness": 5
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        }
    };

    render() {

        if (!this.props.location)
            return null;

        const center = {lat: this.props.location.latitude, lng: this.props.location.longitude};

        return (
            // Important! Always set the container height explicitly
            <div className={`row hm-google-map ${this.props.className || ''}`} style={{ height: this.props.containerHeight, width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCJvun_XXbRubOBVFwKl6kAX5w6C3P-oME"}}
                    defaultCenter={center}
                    defaultZoom={this.props.zoom}
                    options={this.props.mapOptions}
                >
                    <HmMapMarker
                        lat={center.lat}
                        lng={center.lng}
                        text={this.props.markerText}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

GoogleMap.propTypes = {
    className: PropTypes.string,
    location: PropTypes.object,
    markerText: PropTypes.string,
    containerHeight: PropTypes.string
};

export default GoogleMap;
