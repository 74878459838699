import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import {ADDON} from "../../../../../../enums/appModes";
import {bindActionCreators} from "redux";
import * as authActions from "../../../../../../actions/authActions";
import RouterService from "../../../../../../services/RouterService";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

class Logout extends Component {

    handleClick() {
        this.props.authActions.userBecameUnauthorized();

        if (this.props.clickCallback)
            this.props.clickCallback();

        return true;
    }

    render() {

        if (this.props.appMode !== ADDON || !this.props.auth.authorized) return null;

        const { t } = this.props;

        return (
            <Link to={RouterService.chooseApartmentPath()} className="dropdown-item" onClick={() => this.handleClick() }>
                <clr-icon shape="logout" class={`with-text`}/> {t('topBar.logout')}
            </Link>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        auth: state.app.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch)
    }
}

Logout.propTypes = {
    clickCallback: PropTypes.func
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Logout));
