import * as actionTypes from "./actionTypes";
import {SelectionProgressService} from "../services/SelectionProgressService";

export function selectionProgressVisited(navigationItem) {
    const visitedPayload = SelectionProgressService.getSelectionProgressPayload(navigationItem);

    return function (dispatch) {
        dispatch({type: actionTypes.SELECTION_PROGRESS_VISITED, payload: visitedPayload});
    }
}

export function selectionProgressSelected(navigationItem) {
    const selectedPayload = SelectionProgressService.getSelectionProgressPayload(navigationItem);

    return function (dispatch) {
        dispatch({type: actionTypes.SELECTION_PROGRESS_SELECTED, payload: selectedPayload});
    }
}

export function selectionProgressRoomCompleted(roomId, completed) {
    return function (dispatch) {
        if (roomId) {
            dispatch({type: actionTypes.SELECTION_PROGRESS_ROOM_COMPLETED, payload: {roomId, completed}});
        }
    }
}
