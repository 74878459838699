import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import './style.scss'
import RouterService from "../../../services/RouterService";
import {ADDON} from "../../../enums/appModes";
import {withRouter} from "react-router";
import {parse} from "query-string";
import UtilService from "../../../services/UtilService";
import {bindActionCreators} from "redux";
import * as appActions from "../../../actions/appActions";
import * as apartmentActions from "../../../actions/apartmentActions";
import * as paymentActions from "../../../actions/paymentActions";
import CollectorCheckoutService from "../../../services/CollectorCheckoutService";
import HMButton from "../../Utils/HMButton";

class ThankYouConfirmation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmationMessage: "",
            showCollectorCheckout: false,
            collectorCheckout: undefined
        }
    }

    componentDidMount() {
        if (UtilService.hasProperty(parse(this.props.location.search), "collectorCheckout")) {
            this.props.paymentActions.getPayment().then(payment => {
                this.setState({
                    ...this.state,
                    showCollectorCheckout: !!payment,
                    collectorCheckout: payment
                });
            });
        }

        if (this.props.appMode === ADDON) {

            this.props.appActions.appLoading();
            this.props.apartmentActions.getConfirmationMessage().then(data => {
                this.setState({...this.state, confirmationMessage: data.confirmationMessage});
                this.props.appActions.appReady();
            }).catch(() => {
                this.setState({...this.state, confirmationMessage: ""});
                this.props.appActions.appReady();
            });
        } else {
            this.setState({...this.state, confirmationMessage: this.props.project.project.formattedDemoConfirmationPageText})
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const showCollectorCheckout = UtilService.hasProperty(parse(this.props.location.search), "collectorCheckout");

        if (nextState.showCollectorCheckout !== showCollectorCheckout) {
            this.setState({...nextState, showCollectorCheckout: showCollectorCheckout});
        }
    }

    render() {
        if (!this.props.project || !this.props.project.project) {
            return null;
        }

        const { t } = this.props;

        return(
            <div className="container-fluid hm-thank-you">

                <div className={`row no-gutters align-items-center justify-content-center content-wrapper ${this.state.showCollectorCheckout && CollectorCheckoutService.isCompleted(this.state.collectorCheckout) ? "with-receipt" : ""}`}>
                    <div className="col-auto p-0">
                        <div className="row no-gutters">
                            <div className="col-12 bg-frosted-heavy content"
                                 dangerouslySetInnerHTML={{__html: this.state.confirmationMessage}}/>
                            <div className="call-to-action col-12 text-right">
                                <div className="float-right">
                                    <HMButton text={t('common:goToDashboard')}
                                              active={true}
                                              activeText={t('common:goToDashboard')}
                                              href={RouterService.startPath()}
                                              clickFn={() => {}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showCollectorCheckout && CollectorCheckoutService.isCompleted(this.state.collectorCheckout) &&

                <div className="row payment-information">
                    <div className="col bg-frosted-heavy title">
                        <h2 className="normalcase">{t('confirmation.paymentStatus')}</h2>
                    </div>

                    <div className="col-12 px-0 hm-collector-checkout-frame" ref={CollectorCheckoutService.setDangerousHtml.bind(null, CollectorCheckoutService.getCollectorCheckoutScript(this.state.collectorCheckout.data))}>
                    </div>
                </div>
                }


            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        project: state.app.project
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch),
        apartmentActions: bindActionCreators(apartmentActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ThankYouConfirmation)));
