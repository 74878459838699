import React, {Component} from 'react';
import PropTypes from "prop-types";

class HMExpandArrow extends Component {

    render() {

        return (
            <clr-icon shape={`angle ${this.props.expanded ? 'down' : 'up'}`}></clr-icon>
        )
    }
}

HMExpandArrow.propTypes = {
    expanded: PropTypes.bool.isRequired
};

export default HMExpandArrow;
