import React, {Component} from 'react';
import PropTypes from "prop-types";
import './style.scss';
import {withTranslation} from "react-i18next";

class SectionCol2ImageText extends Component {

    render() {

        if (!this.props.section)
            return null;

        const { t, section } = this.props;

        return (
            <div className={`row justify-content-center hm-landing-page-section hm-landing-page-section-col2imageText ${!!section.customProperties.cssClass ? section.customProperties.cssClass : ''}`}>
                <div className="col-auto hm-landing-page-section-wrapper">
                    <div className="row no-gutters">
                        <div className="col-xs-12 col-md-6 left-content">
                            {section.images && section.images.length > 0 &&
                                <img src={section.images[0].url}/>
                            }
                        </div>
                        <div className="col-xs-12 col-md-6 right-content">
                            {section.title && section.title !== '' ? <h1>{section.title}</h1> : null}
                            <div dangerouslySetInnerHTML={{__html: section.richContentRight}}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SectionCol2ImageText.propTypes = {
    section: PropTypes.object,
};

export default withTranslation('common')(SectionCol2ImageText);
