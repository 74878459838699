import React, {Component} from 'react';
import * as productActions from "../../../../actions/productActions";
import * as imageActions from "../../../../actions/imageActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import HMButton from "../../../Utils/HMButton"
import './style.scss'
import ProductCard from "../ProductCard";
import ProductAlternatives from "../ProductAlternatives";
import ProductSelection from "../../../../models/ProductSelection";
import ProjectProduct from "../../../../models/ProjectProduct";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import ProductProperty from "../ProductProperty"
import GrandTotal from "../../../Utils/GrandTotal";
import * as selectionProgressActions from "../../../../actions/selectionProgressActions";
import HMInformationIcon from "../../../Utils/HMInformationButton";
import ProductPickerInfoModal from "../../../Room/Product/ProductPickerInfoModal";

// This class represent a ProjectProduct but aware of all other project products thru it's metaProduct
class Product extends Component {

    getRelationshipsForThisProduct() {
        const forThisRoom = this.props.roomRelationships.get(this.props.currentRoom.id);
        const forThisProduct = forThisRoom ? forThisRoom.get(this.props.projectProduct.product.id) : {};

        /* TODO
        Since the relationships tied to room and downloaded on room receive there will be cases where Package changes (simulated or api queried) cannot find a
        relationship configuration for the new products added. The quick fix would be to query room for relationships again since the new products where added.
        But we need to find a better approach here and solve this crippling Package / Style group mess once and for all.
        */

        return forThisProduct;
    }

    select () {
        if (this.props.readOnly) {
            return;
        }

        this.props.selectionProgressActions.selectionProgressSelected(this.props.selectedNavigationItem);

        if (!this.props.selected) {
            this.props.productActions.changeProjectProduct(this.props.projectProduct, this.props.productSelection)
        }
    }

    renderProductPrice() {
        const { t, projectProduct, showPrices, productSelection } = this.props;
        const selected = this.isSelected(this.props.selected);

        if (projectProduct.disabled) {
            return t('invalidSelection');
        } else {
            if (showPrices) {
                return <GrandTotal grandTotal={{price: productSelection ? projectProduct.price * this.props.productSelection.quantity : projectProduct.price, hasDelayedPrice: projectProduct.delayedPrice}} showDelayedPrice={false}/>
            } else {
                return selected ? t('yourSelection'): t('select');
            }
        }
    }

    isSelected(selected) {
        return selected && this.props.selectedNavigationItem.selectionProgress.s;
    }

    render() {
        const { t, projectProduct, showPrices, productSelection } = this.props;
        const selected = this.isSelected(this.props.selected);

        //TODO not needed anymore
        // const statusBorderColorClass = ProductService.getStatusStyle(projectProduct).borderColorClass;

        return (
            <div className={`hm-product row no-gutters ${selected ? "selected" : ""} ${this.props.readOnly ? "read-only" : ""}`}
                 onClick={() => this.select()}
            >
                <div className="col no-gutters">
                    <div className={`top-border`}>
                        <div className="hm-product-body">

                            <ProductCard
                                hidePrices={!this.props.showPrices}
                                productSelection={productSelection}
                                projectProduct={projectProduct}
                                showInfo={this.props.showInfo}
                            >
                                {this.props.alternatives.length > 0 &&
                                <ProductAlternatives
                                    readOnly={this.props.readOnly}
                                    alternatives={this.props.alternatives}
                                    projectProduct={projectProduct}
                                    productSelection={productSelection}
                                    selected={this.props.selected}
                                    beforeSelect={() => this.props.selectionProgressActions.selectionProgressSelected(this.props.selectedNavigationItem)}
                                />
                                }
                            </ProductCard>

                            {this.props.productSelection.note &&
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <ProductProperty hideKey={true} propertyKey="common:note"
                                                     property={`${this.props.productSelection.note ? this.props.productSelection.note : ""}`}/>
                                </div>
                            </div>
                            }

                            {/*<ProductRelationshipInfo selected={selected}*/}
                            {/*                         disabled={projectProduct.disabled}*/}
                            {/*                         relationships={this.getRelationshipsForThisProduct()}/>*/}

                            <div className="row no-gutters">
                                <div className="col-9">
                                    <HMButton
                                        thinStyle={true}
                                        text={""}
                                        disabled={this.props.readOnly || projectProduct.disabled}
                                        disabledText={""}
                                        active={selected}
                                        activeText={""}
                                        activeType={"green"}
                                        clickFn={() => {this.select()}}
                                    >
                                        {this.renderProductPrice()}
                                    </HMButton>

                                    {/*This button will be present on extra options to allow removal of product*/}
                                    {selected && productSelection.extraOption && !this.props.readOnly &&
                                    <HMButton
                                        thinStyle={true}
                                        text={t('removeSelection')}
                                        active={false}
                                        activeText={t('removeSelection')}
                                        clickFn={() => this.props.productActions.removeExtraOption(productSelection)}
                                    />
                                    }
                                </div>

                                <div className="col-3 pl-2">
                                    <HMButton
                                        thinStyle={true}
                                        text={""}
                                        active={false}
                                        activeText={t('removeSelection')}
                                        clickFn={() => {
                                            if (this.props.showInfo) this.props.showInfo();
                                        }}
                                    >
                                        <HMInformationIcon/>
                                    </HMButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        roomRelationships: state.app.roomRelationships,
        currentRoom: state.app.currentRoom,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActions, dispatch),
        imageActions: bindActionCreators(imageActions, dispatch),
        selectionProgressActions: bindActionCreators(selectionProgressActions, dispatch),
    }
}

Product.propTypes = {
    readOnly: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    projectProduct: PropTypes.instanceOf(ProjectProduct).isRequired, // This is a project product to be displayed as a row
    alternatives: PropTypes.array.isRequired, // These are the alternatives (Like color options to the project product)
    productSelection: PropTypes.instanceOf(ProductSelection).isRequired, // The product selection for reference in replacement actions
    showPrices: PropTypes.bool,
    selectedNavigationItem: PropTypes.object,
    showInfo: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Product));
