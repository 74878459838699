import * as types from './actionTypes';

// Reducer actions related to the scene
export function sceneLoading() {
    return function (dispatch) {
        return dispatch(sceneLoadingSuccess());
    }
}

export function sceneLoaded() {
    return function (dispatch) {
        return dispatch(sceneLoadedSuccess());
    }
}

function sceneLoadingSuccess() {
    return {type: types.SCENE_LOADING, payload: {}}
}

function sceneLoadedSuccess() {
    return {type: types.SCENE_LOADED, payload: {}}
}