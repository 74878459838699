import React, {Component} from 'react';
import "./style.scss";
import TopBar from "../TopBar";
import Navigation from "../Navigation";
import SubNavigation from "../SubNavigation";
import MobileNavigation from "../MobileNavigation";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as appActions from "../../../../actions/appActions";
import {connect} from "react-redux";

class StickyHeader extends Component {

    _header = undefined;
    _timeout = undefined;
    _unmounted = false;

    constructor(props) {
        super(props);

        this.setAppSizes = this.setAppSizes.bind(this);
    }

    componentDidMount(prevProps, prevState, snapshot) {
        window.addEventListener('resize', this.setAppSizes);
        this.setAppSizes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.appSizes.app.isMobile !== this.props.appSizes.app.isMobile
            || prevProps.appSizes.app.isLandscape !== this.props.appSizes.app.isLandscape
        ) {
            this.setAppSizes(1);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setAppSizes);
        this.clearTimeout();
    }

    clearTimeout() {
        if (this._timeout) {
            clearTimeout(this._timeout);
            this._timeout = undefined;
        }
    }

    setAppSizes(timeout = 300) {
        if (this._header) {
            this.clearTimeout();

            this._timeout = setTimeout(() => {
                this._timeout = undefined;
                if (this._header && !this._unmounted) {
                    const newAppSizes = {
                        app: {
                            width: document.body.clientWidth,
                            height: document.body.clientHeight
                        },
                        topBar: {
                            width: this._header.clientWidth,
                            height: this._header.clientHeight
                        }
                    }

                    this.props.appActions.appSizesChanged(newAppSizes);

                    if (this.props.resizeCallback)
                        this.props.resizeCallback(newAppSizes.topBar.height);
                }
            }, timeout);
        }
    }

    render() {
        return (
            <div id='tutorial-id-top-area' className="fixed-top bottom-shadow" ref={ (el) => this._header = el}>
                <TopBar/>
                {!this.props.appSizes.app.isMobile && <Navigation resizeCallback={() => this.setAppSizes()}/>}
                {!this.props.appSizes.app.isMobile && <SubNavigation resizeCallback={() => this.setAppSizes()}/>}
                {this.props.appSizes.app.isMobile && <MobileNavigation resizeCallback={() => this.setAppSizes(50)}/>}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appSizes: state.app.appSizes
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch)
    }
}

StickyHeader.propTypes = {
    resizeCallback: PropTypes.func
};


export default connect(mapStateToProps, mapDispatchToProps)(StickyHeader);
