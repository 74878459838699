import React, {Component} from 'react';
import HMButton from "../../Utils/HMButton";
import * as actions from "../../../actions/authActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import HMLogo from "../HMLogo";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {username: ""};
    }

    componentWillMount() {
        this.props.actions.resetAuth();
    }

    getTextForLoginState() {
        const { t } = this.props;

        if(this.props.auth.invalidCredentials)
            return t("wrongTryAgain");
        else
            return t("sendForgotPasswordResetLink");
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    handleSubmit(event) {
        this.props.actions.sendForgotPasswordResetLink(this.state.username);
        event.preventDefault();
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-sm-9 col-md-7 col-lg-6 align-self-center hm-auth bg-frosted">
                        <div className="my-5">
                            <div>
                                <HMLogo/>
                                <form onSubmit={(event) => {this.handleSubmit(event)}} className="form-signin">
                                    <div className="form-label-group">
                                        <label htmlFor="inputEmail">{t('enterUsernameTitle')}</label>
                                        <input
                                            name="username"
                                            type="email"
                                            id="inputEmail"
                                            className="form-control"
                                            placeholder={t('enterUsernamePlaceholder')}
                                            required
                                            value={this.state.username}
                                            onChange={(target) => this.handleInputChange(target)}
                                            autoFocus
                                        />
                                    </div>

                                    <HMButton clickFn={($event) => {this.handleSubmit($event)}}
                                              text={this.getTextForLoginState()}
                                              disabled={this.props.loading > 0} disabledText={t('sending')}
                                              active={this.props.auth.resetLinkSent} activeText={t('sent')}
                                    />
                                    <hr></hr>
                                    <Link to={"login"}>{t("backToLogin")}</Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.app.auth,
        loading: state.app.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('auth')(ForgotPassword));

