import React, {Component} from 'react';
import "./style.scss"
import {bindActionCreators} from "redux";
import * as actions from "../../../../actions/productActions";
import * as selectionProgressActions from "../../../../actions/selectionProgressActions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import HeaderLabel from "../../../Utils/HeaderLabel";
import HMNextAction from "../../../Utils/HMNextAction";
import PackageCard from "../../Product/PackageCard"
import HMButton from "../../../Utils/HMButton";
import {Fade, Modal, ModalBody} from "reactstrap";
import SummaryPicker from "../SummaryPicker";
import GrandTotal from "../../../Utils/GrandTotal";
import HMCostInfo from "../../../Utils/HMCostInfo";
import HMChooseStandardsForRoomAction from "../../../Utils/HMChooseStandardsForRoomAction";
import {AppRoute} from "../../../../services/RouterService";
import {withRouter} from "react-router-dom";
import {withBus} from 'react-bus';
import {HM_TOPBAR_OPEN_MOBILE_NAVIGATION} from "../../../../actions/eventTypes";

class PackagePicker extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showMore: false,
            morePackage: undefined
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.synchronizeHashAndModals();
    }

    select (newStyle) {
        if (this.props.readOnly) {
            return;
        }

        this.props.selectionProgressActions.selectionProgressSelected(this.props.selectedNavigationItem);
        this.props.actions.selectStyle(this.props.activeStyle, newStyle)
    }

    isSelected(aPackage) {
        return this.props.selectedNavigationItem.selectionProgress.s && aPackage.id === this.props.activeStyle.style.id;
    }

    synchronizeHashAndModals() {
        if (this.props.history.location.hash && this.props.history.location.hash.startsWith("#moreInfo")) {
            if (!this.state.showMore) {
                if (this.props.activeStyle && this.props.activeStyle.styleGroup && this.props.activeStyle.styleGroup.styles) {
                    const packageId = this.props.history.location.hash.replace('#moreInfo', '');
                    const aPackage = this.props.activeStyle.styleGroup.styles.find(p => ('' + p.id) === packageId);
                    if (aPackage) {
                        setTimeout(() => this.toggleShowMore(true, aPackage, false));
                    } else {
                        this.props.history.replace(this.props.history.location.pathname + this.props.history.location.search);
                    }
                } else {
                    this.props.history.replace(this.props.history.location.pathname + this.props.history.location.search);
                }
            }
        } else if (!this.props.history.location.hash || !this.props.history.location.hash.startsWith("#moreInfo")) {
            if (this.state.showMore) {
                setTimeout(() => this.toggleShowMore(false, undefined, false));
            }
        }
    }

    toggleShowMore(showMore, aPackage, navigate = true) {
        if (this.state.showMore !== showMore) {
            this.setState({...this.state, showMore: showMore, morePackage: showMore ? aPackage : undefined});

            if (navigate) {
                if (showMore) {
                    this.props.history.push(this.props.history.location.pathname + this.props.history.location.search + '#moreInfo' + aPackage.id);
                } else {
                    if (!!this.props.history.location.hash && this.props.history.location.hash.startsWith('#moreInfo')) {
                        this.props.history.goBack();
                    }
                }
            }
        }
    }

    render() {
        const { t, activeStyle } = this.props;
        const isMobile = this.props.appSizes.app.isMobile;

        if (!activeStyle) return null;

        const {styleGroup} = activeStyle;

        return (
            <React.Fragment>
                <div className={`hm-package-picker`}>
                    <div className="scrollable">
                        <div className={"hm-package-picker-header"}>
                            <HeaderLabel
                                text={styleGroup.name}
                                subText={this.props.description && this.props.description !== "" ? this.props.description : styleGroup.description}
                                fallbackSubTextKey="pickerFallbackText"
                            />
                            <Fade in={this.state.showMore} tag="div" className="overlay" mountOnEnter={true} unmountOnExit={true}></Fade>
                        </div>
                        <div className="package-list">
                            {styleGroup.styles.map((aPackage, i) => {
                                return <div key={`Package-${aPackage.id}`}
                                            className={`hm-package row no-gutters ${this.props.readOnly ? 'read-only' : ''} ${this.isSelected(aPackage) ? "selected" : ""} ${this.state.morePackage && this.state.morePackage.id === aPackage.id ? 'has-more' : ''}`}
                                            onClick={() => this.select(aPackage)}>
                                            <div className="col no-gutters">
                                                <div className={`top-border`}>
                                                    <div className="hm-package-body">
                                                        <PackageCard
                                                            style={aPackage}
                                                            activeStyle={activeStyle}
                                                            showMore={() => this.toggleShowMore(true, aPackage)}
                                                        />
                                                        <div className="row no-gutters">
                                                            <div className="col-8 no-gutters">
                                                                {/*Fixme when the specification is sorted out*/}
                                                                {/*<GrandTotal grandTotal={{price: productSelection ? projectProduct.price * productSelection.quantity : projectProduct.price, hasDelayedPrice: projectProduct.delayedPrice}} showDelayedPrice={false}/>*/}
                                                                <HMButton
                                                                    disabled={this.props.readOnly}
                                                                    thinStyle={true}
                                                                    text={this.props.hidePrices ? t('select') : ""}
                                                                    active={this.isSelected(aPackage)}
                                                                    activeText={this.props.hidePrices ? t('yourSelection') : ""}
                                                                    activeType={"green"}
                                                                    clickFn={() => {this.select(aPackage)}}
                                                                >
                                                                    {!this.props.hidePrices && <GrandTotal grandTotal={{price: aPackage.price, hasDelayedPrice: false}} showDelayedPrice={true} hasChoices={true}/>}
                                                                </HMButton>
                                                            </div>

                                                            <div className="col-4 pl-2 no-gutters">
                                                                <HMButton
                                                                    thinStyle={true}
                                                                    text={
                                                                        this.state.showMore && this.state.morePackage && this.state.morePackage.id === aPackage.id ?
                                                                        `${t('less')}<clr-icon shape="angle left" class="with-text-left"/>` :
                                                                        `${t('more')}<clr-icon shape="angle right" class="with-text-left"/>`}
                                                                    active={false}
                                                                    activeText={this.state.showMore ? t('less') : t('more')}
                                                                    clickFn={() => this.toggleShowMore(true, aPackage)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Fade in={this.state.showMore && this.state.morePackage && this.state.morePackage.id !== aPackage.id} tag="div" className="overlay" mountOnEnter={true} unmountOnExit={true}></Fade>
                                </div>
                            })}
                        </div>
                        {this.props.selectedNavigationItem.isStart && !this.props.isMobile && !this.props.readOnly &&
                        <HMChooseStandardsForRoomAction>
                            <Fade in={this.state.showMore} tag="div" className="overlay" mountOnEnter={true} unmountOnExit={true}></Fade>
                        </HMChooseStandardsForRoomAction>
                        }
                        <div className={"remaining"}>
                            <Fade in={this.state.showMore} tag="div" className="overlay" mountOnEnter={true} unmountOnExit={true}></Fade>
                        </div>
                    </div>
                    <div className={"hm-package-picker-more"}>
                        {!this.props.hidePrices && <HMCostInfo currentRoutes={this.props.currentRoutes} onClick={this.props.isMobile ? () => {this.props.bus.emit(HM_TOPBAR_OPEN_MOBILE_NAVIGATION)} : null}/>}
                        <HMNextAction
                            selectedNavigationItem={this.props.selectedNavigationItem}
                            nextUrlLink={this.props.nextUrlLink}
                            appSizes={this.props.appSizes}
                        />
                        <Fade in={this.state.showMore} tag="div" className="overlay" mountOnEnter={true} unmountOnExit={true}></Fade>
                    </div>
                </div>

                <Modal isOpen={this.state.showMore}
                       toggle={() => this.toggleShowMore(false)}
                       centered={false}
                       className={isMobile ? "hm-full-screen-modal-modal" : "hm-package-picker-modal-modal"}
                       modalClassName={isMobile ? "hm-full-screen-modal" : "hm-package-picker-modal"}
                       modalTransition={{ timeout: 100 }}
                       contentClassName={"hm-package-picker-modal-content"}
                       backdropClassName={"hm-package-picker-modal-backdrop"}
                >
                    <ModalBody>
                        {this.state.morePackage &&
                        <SummaryPicker
                            currentRoutes={this.props.currentRoutes}
                            hidePrices={this.props.hidePrices}
                            showStatus={false}
                            hasNextStep={false}
                            title={this.state.morePackage.name}
                            description={this.state.morePackage.description}
                            productSelections={this.state.morePackage.productSelections || []}
                            appSizes={this.props.appSizes}
                            onCloseButtonClick={() => this.toggleShowMore(false)}
                        />
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        hidePrices: state.app.hidePrices,
        currentRoom: state.app.currentRoom, // Required to re render on product change
        appSizes: state.app.appSizes,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        selectionProgressActions: bindActionCreators(selectionProgressActions, dispatch),
    }
}

PackagePicker.propTypes = {
    readOnly: PropTypes.bool,
    description: PropTypes.string,
    activeStyle: PropTypes.object,
    selectedNavigationItem: PropTypes.object,
    nextUrlLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};

PackagePicker.defaultProps = {
    // style, styleGroup
};

export default withBus()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(PackagePicker))));
