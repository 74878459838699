import React from "react";

export const Tutorial = {
    tutorials: {
        threeAreasTour: {
            description: "A simplified tour of 5 steps",
            steps: ["greetings", "topArea", "rightArea", "leftArea", "messageArea", "tutorialArea"]
        }
    },
    items: {
        greetings: {
            selector: '#root',
            translationKey: "tutorial.greetings",
            videoKey: "tutorial.greetingsVideo",
            position: 'top'
        },
        topArea: {
            selector: '#tutorial-id-top-area',
            translationKey: "tutorial.topArea",
            position: 'top'
        },
        rightArea: {
            selector: '#tutorial-id-right-area',
            translationKey: "tutorial.rightArea",
            position: 'top'
        },
        leftArea: {
            selector: '#tutorial-id-left-area',
            translationKey: "tutorial.leftArea",
            position: 'top'
        },
        messageArea: {
            selector: '#tutorial-id-message-area',
            translationKey: "tutorial.messageArea",
            position: 'bottom'
        },
        tutorialArea: {
            selector: '#tutorial-id-tutorial-area',
            translationKey: "tutorial.tutorialArea",
            position: 'bottom'
        }
    }
}
