import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import {ADDON} from "../../../../../enums/appModes";
import PropTypes from "prop-types";
import {AppRoute} from "../../../../../services/RouterService";
import UtilService from "../../../../../services/UtilService";
import './style.scss';

class FileBankDropdown extends Component {

    _dropdownMenu = undefined;

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            hover: false
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    toggle() {
        const expanded = !this.state.expanded;

        this.setState({...this.state, expanded: expanded});

        if (expanded) {
            this.addListeners();
        } else {
            this.removeListeners();
        }
    }

    addListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    }

    removeListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }

    handleDocumentClick(e) {
        if (!this.state.expanded || (this._dropdownMenu && (this._dropdownMenu.contains(e.target) || this._dropdownMenu === e.target))) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.toggle();
    }

    hoverOn() {
        this.setState({...this.state, hover: true});
    }

    hoverOff() {
        this.setState({...this.state, hover: false});
    }

    render() {

        if (this.props.appMode !== ADDON
            || !UtilService.includesAnyOf(this.props.currentRoutes, [AppRoute.Apartment])
            || !this.props.auth.authorized
            || !this.props.apartment
            || !this.props.apartment.attachments
            || this.props.apartment.attachments.length === 0)
            return null;

        const { t } = this.props;

        return (
            <li className="hm-hide-on-small-screens nav-item dropdown" ref={(el) => { this._dropdownMenu = el; }}>
                <div className="action-link pointer"
                     title={t('topBar.downloadFiles')}
                     onClick={() => this.toggle()}
                     onMouseEnter={() => this.hoverOn()}
                     onMouseLeave={() => this.hoverOff()}>
                    <div className="action-link">
                        <clr-icon shape={this.state.expanded ? "folder-open" : "folder"} class={this.state.hover || this.state.expanded ? "is-solid" : ""}/>
                    </div>
                </div>

                <div className={`hm-download dropdown-menu dropdown-menu-right ${this.state.expanded ? "show" : ""}`}>
                    {this.props.apartment.attachments.map(attachment => {
                        return (
                            <div key={`attachment-${attachment.id}`} className="dropdown-item p-0 m-0">
                                <a href={attachment.url} title={attachment.friendlyName}>
                                    <div className="hm-download-item">
                                        <div className="hm-download-item-name">
                                            <p className="m-0 text-truncate">
                                                {attachment.friendlyName}
                                            </p>
                                        </div>
                                        <div className="hm-download-item-icon">
                                            <clr-icon shape="download"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </li>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        auth: state.app.auth,
        apartment: state.app.apartment2.apartment
    }
}

FileBankDropdown.propTypes = {
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};


export default connect(mapStateToProps)(withTranslation('common')(FileBankDropdown));
