import React, {Component} from 'react';

export default class HMLogo extends Component {

    render() {
        return (
            <h5 className="text-center mb-4">
                <a href="https://envise.io" title="Envise">
                    <img src="https://hm-envise-assets.s3.eu-west-1.amazonaws.com/branding/Hydda_Envise_STD_POS_hydda_web.png" width="250" alt="Showroom by Envise"/>
                </a>
            </h5>
        );
    }
}
