import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import ThankYouConfirmation from './ThankYouConfirmation';
import {bindActionCreators} from "redux";
import * as roomActions from "../../actions/roomActions";
import {AppRoute} from '../../services/RouterService';

class Confirmation extends Component {

    componentDidMount() {
        this.props.roomActions.changeRoom(AppRoute.Confirmation);
    }

    render() {
        if (this.props.currentRoomId === AppRoute.Confirmation) {
            return (<ThankYouConfirmation/>);
        } else
            return null;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        currentRoomId: state.app.currentRoomId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        roomActions: bindActionCreators(roomActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
