import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../../../actions/roomActions";
import {bindActionCreators} from "redux";
import "./style.scss"
import UtilService from "../../../../services/UtilService";
import {Link, withRouter} from "react-router-dom";
import {AppRoute, RouterService} from "../../../../services/RouterService";
import CostDropdown from "./CostDropdown";
import ChatDropdown from "./ChatDropdown";
import FileBankDropdown from "./FileBankDropdown";
import SettingsDropdown from "./SettingsDropdown";

class TopBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentRoutes: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const nextRoutes = RouterService.getCurrentRoutes(this.props.location);
        if (!UtilService.arraysEqual(nextRoutes, this.state.currentRoutes)) {
            this.setState({...this.state, currentRoutes: nextRoutes});
        }
    }

    shouldShowProjectLogo() {
        return this.props.project
            && this.props.project.logoSrc
            && UtilService.includesAnyOf(this.state.currentRoutes, [AppRoute.Apartment, AppRoute.Project]);
    }

    render() {
        return (
            <nav className="navbar navbar-expand-0 navbar-light hm-top-bar">
                <span className="navbar-brand">
                    <Link to={RouterService.startPath()}><img className="logo" src={this.shouldShowProjectLogo() ? this.props.project.logoSrc : "https://hm-envise-assets.s3.eu-west-1.amazonaws.com/branding/Hydda_Envise_STD_POS_hydda_web.png"} alt=""/></Link>
                </span>

                <ul className="navbar-nav right-content">
                    <CostDropdown currentRoutes={this.state.currentRoutes}/>

                    <FileBankDropdown currentRoutes={this.state.currentRoutes}/>
                    <ChatDropdown currentRoutes={this.state.currentRoutes}/>
                    <SettingsDropdown currentRoutes={this.state.currentRoutes}/>
                </ul>

            </nav>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        project: state.app.project.project,
        apartment: state.app.apartment2.apartment,
        currentRoomId: state.app.currentRoomId,
        singleApartment: state.app.singleApartment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));
