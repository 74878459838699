import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./index";
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {debounce} from "../services/UtilService";
import {SelectionProgressService} from "../services/SelectionProgressService";
import {TutorialService} from "../services/TutorialService";
import CookiesService from "../services/CookiesService";

export const history = createBrowserHistory();

const createReducer = (history) => combineReducers({
    app: rootReducer,
    router: connectRouter(history)
});

const store = createStore(
    createReducer(history),
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(history),
        ),
        applyMiddleware(thunk)
    )
);

store.subscribe(debounce(() => {
    const appState = store.getState().app;
    SelectionProgressService.saveSelectionProgress(
        appState.selectionProgress
    );
    SelectionProgressService.saveSelectionProgressRoomCompletion(
        appState.selectionProgressRoomCompletion
    );
    TutorialService.saveTutorialProgress(
        appState.tutorialProgress
    );
    CookiesService.saveCookies(
        appState.cookies
    );
}, 1000));

export default store;
