import React, {Component} from 'react';
import GrandTotal from "../../Utils/GrandTotal";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import HMImage from "../../Utils/HMImage";
import PackageService from "../../../services/PackageService";
import {bindActionCreators} from "redux";
import * as imageActions from "../../../actions/imageActions";
import {connect} from "react-redux";

class SummaryCallToActionTop extends Component {

    getLightBoxWithSelectedRoom(selectedRoom) {
        return this.props.roomsOrder
            .map(roomId => this.props.rooms.get(roomId))
            .map(room => {
                let url;
                if (room.showroomScene)
                    url = room.sceneImg;
                else if (!room.showroomScene && !!PackageService.getFirstImageUrlFrom(room.activeStyleGroups))
                    url = PackageService.getFirstImageUrlFrom(room.activeStyleGroups);

                if (!url)
                    return null;

                return {
                    src: url,
                    selected: room.id === selectedRoom.id,
                    title: room.roomType
                }
            })
            .filter(r => !!r)
            .reduce((lightBox, r, i) => {
                lightBox.images.push(r);
                if (r.selected)
                    lightBox.selectedIndex = i;

                return lightBox;
            }, { images: [] });
    }

    render() {
        const { t } = this.props;

        const roomImages = this.props.roomsOrder
            .map(roomId => this.props.rooms.get(roomId))
            .map((room, i) => {
                let url = null;
                if (room.showroomScene)
                    url = room.sceneImg;
                else if (!room.showroomScene && !!PackageService.getFirstImageUrlFrom(room.activeStyleGroups))
                    url = PackageService.getFirstImageUrlFrom(room.activeStyleGroups);

                if (!url)
                    return null;

                return {
                    room: room,
                    url: url
                }
            })
            .filter(r => !!r);

        return(
            <div className="row hm-showroom-summary-header bg-frosted-heavy">
                <div className="col">
                    <div className={`row ${roomImages.length > 0 ? 'mb-3' : 'mb-0'} align-items-center`}>
                        <div className="col-md-3 col-12 pl-0">
                            <h2 className="normalcase">{t('summary.title')}</h2>
                        </div>

                        {!this.props.hidePrices &&
                        <div className="col-md-9 col-12 pr-0 pl-0 text-md-right">
                            <div className="call-to-action-row">
                                <div className="call-to-action-cell call-to-action-cell-top">
                                    <h5><i className="grandTotal">{t('summary.totalCost')}:</i> <GrandTotal grandTotal={this.props.grandTotal}/></h5>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    {(this.props.addonMode || this.props.doLeadsForSalesStart) &&
                    <div className="row align-items-center">
                        <div className="col-12 p-0">
                            <h5 className={`italic normal ${roomImages.length > 0 ? 'summary-info-top with-images' : 'summary-info-top no-images'} m-0`}>{t('summary.summaryInfo')}</h5>
                        </div>
                    </div>
                    }

                    {roomImages.length > 0 &&
                    <div className="row align-items-end">
                        <div className="col-xl-8 pl-0 pr-0">
                            <div className="row p-0 m-0">
                                {roomImages.map((roomWithUrl, i) => {
                                    return (
                                        <div key={`${roomWithUrl.room.id}`} className={`${roomImages.length > 1 ? 'col-12 col-md-6' : 'col-md-12'} pt-3 pb-0 pl-0 ${roomImages.length === 1 || i % 2 !== 0 ? "pr-0" : "pr-0 pr-md-3"}`}>
                                            <HMImage className={`${roomImages.length > 1 ? 'room-image' : 'room-image-single'}`}
                                                     imageSrc={roomWithUrl.url}
                                                     overlayText={roomWithUrl.room.roomType}
                                                     onClick={() => this.props.imageActions.showLightBox(this.getLightBoxWithSelectedRoom(roomWithUrl.room))}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="col-xl-4 pr-0 right-info">
                            {/*<div className="row ml-0 mr-0 mt-5">*/}
                                {/*<div className="col pl-0 pr-0">*/}
                                    {/*<h4 className="italic normal">{t('topBar.showOff')}</h4>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            {/*<div className="row ml-0 mr-0">*/}
                                {/*<div className="col pl-0 pr-0">*/}
                                    {/*Facebook, Tweeter etc.*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row ml-0 mr-0">
                                <div className="col-12 pl-0 pr-0 pt-4">
                                    <div className="info-row">
                                        <div className="info-cell info-icon">
                                            <clr-icon shape="help-info" class="is-solid"></clr-icon>
                                        </div>

                                        <div className="info-cell">
                                            {t('topBar.info')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        imageActions: bindActionCreators(imageActions, dispatch)
    }
}


SummaryCallToActionTop.propTypes = {
    hidePrices: PropTypes.bool,
    roomsOrder: PropTypes.array.isRequired,
    rooms: PropTypes.object.isRequired,
    grandTotal: PropTypes.object.isRequired,
    addonMode: PropTypes.bool.isRequired,
    apartmentLocked: PropTypes.bool.isRequired,
    doLeadsForSalesStart: PropTypes.bool.isRequired,
};


export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SummaryCallToActionTop));
