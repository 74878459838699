import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/sceneActions";
import "./style.css"
import ImageNotFound from "../../../assets/imageNotFound.png";
import TransparentBackground from "../../../assets/transparentBackground.png";
import HMImage from "../../Utils/HMImage";
import PropTypes from "prop-types";
// import interact from "interactjs";

class Scene extends Component {

    _loadingTimer = undefined;
    _loadingShown = false;

    _aspectRatio = 16/10;


    _gestureArea = undefined;
    _scalableArea = undefined;
    _gesturableInitialized = false;
    _scale = 1;

    _lastPromise = null;

    constructor(props) {
        super(props);
        this.state = {
            sceneSrc: TransparentBackground,
            scale: 1,
        };
    }

    componentDidMount() {
        if (this.props.currentRoom && this.props.currentRoom.showroomScene && this.props.currentRoom.sceneImg) {
            this.setScene(this.props.currentRoom.sceneImg);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentRoom && this.props.currentRoom && this.props.currentRoom.showroomScene && prevProps.currentRoom.sceneImg !== this.props.currentRoom.sceneImg) {
            this.setScene(this.props.currentRoom.sceneImg);
        }

        // if (this._gestureArea && !this._gesturableInitialized) {
        //     this._gesturableInitialized = true;
        //
        //     const zoom = (event) => {
        //         const { isMobile, isPortrait } = this.props.appSizes.app;
        //         if (isMobile && isPortrait) {
        //             const viewportHeight = this.props.isModal ? this.props.appSizes.app.height : (this.props.appSizes.app.height - this.props.topBarHeight);
        //             const viewportWidth = viewportHeight * this._aspectRatio;
        //             const minScale = this.props.appSizes.app.width / viewportWidth;
        //             const margin = (viewportWidth - this.props.appSizes.app.width) / 2;
        //
        //             if (this._scale !== 1.0) {
        //                 this._scale = 1;
        //                 this._scalableArea.style.transform = 'scale(1)';
        //                 this._gestureArea.style.overflowX = 'auto';
        //                 this._gestureArea.style.touchAction = 'pan-x';
        //                 this._gestureArea.scrollTo(margin, 0);
        //             } else if (this._scale === 1.0) {
        //                 this._scale = minScale;
        //                 this._scalableArea.style.transform = 'scale(' + this._scale + ')';
        //                 this._gestureArea.style.overflowX = 'hidden';
        //                 this._gestureArea.style.touchAction = 'none';
        //                 this._gestureArea.scrollTo(margin, 0);
        //             }
        //
        //             if (event.preventDefault) {
        //                 event.preventDefault();
        //             }
        //         }
        //     }
        //
        //     interact(this._gestureArea)
        //         .gesturable({
        //             listeners: {
        //                 end: (event) => {
        //                     if (this._scale === 1.0 && event.scale < 1.0 || this._scale !== 1.0 && event.scale >= 1.0) {
        //                         zoom(event);
        //                     }
        //                 }
        //             }
        //         })
        //         .on('doubletap', (event) => {
        //             zoom(event);
        //         });
        // }
        //
        // const { isMobile, isPortrait } = this.props.appSizes.app;
        // if (!isMobile || !isPortrait) {
        //     this._scale = 1;
        //     this._scalableArea.style.transform = 'scale(1)';
        //     this._gestureArea.style.touchAction = 'pan-x';
        //     this._gestureArea.style.overflowX = 'auto';
        // }
    }

    componentWillUnmount() {
        this.clearLoadingTimer();
    }

    setScene(sceneSrc)  {
        this._lastPromise = sceneSrc;
        // if it's promise - new in browser solution
        if (typeof sceneSrc === 'object') {
            this.onLoading();
        }

        Promise.resolve(sceneSrc).then(src => {
            if (sceneSrc !== this._lastPromise) {
                return;
            }

            if (typeof sceneSrc === 'object') {
                this.onLoaded();
            }

            this.setState({...this.state, sceneSrc: src});
            if (this.props.onSceneChanged) {
                this.props.onSceneChanged(src);
            }
        }).catch(err => {
            if (sceneSrc !== this._lastPromise) {
                return;
            }

            if (typeof sceneSrc === 'object') {
                this.onError();
            }
        });
    }

    onLoading() {
        if (this.props.isShadow) { return; }

        const showLoading = () => {
            this._loadingShown = true;
            this.props.actions.sceneLoading();
        };

        if (this.props.isModal) {
            showLoading();
        } else {
            this.clearLoadingTimer();
            this._loadingTimer = setTimeout(() => showLoading(), 1000);
        }
    }

    onLoaded() {
        if (this.props.isShadow) { return; }

        this.clearLoadingTimer();
    }

    onError() {
        if (this.props.isShadow) { return; }

        this.clearLoadingTimer();
        this.setState({...this.state, sceneSrc: ImageNotFound});
    }

    clearLoadingTimer() {
        if (this.props.isShadow) { return; }

        if (this._loadingTimer) {
            clearTimeout(this._loadingTimer);
            this._loadingTimer = undefined;
        }

        if (this._loadingShown) {
            this.props.actions.sceneLoaded();
        }
        this._loadingShown = false;
    }

    _timeout = undefined;
    // Render is always called when the state is changed
    render() {
        const { isModal,  isShadow } = this.props;

        const { isMobile, isPortrait } = this.props.appSizes.app;
        const viewportHeight = isModal ? this.props.appSizes.app.height : (this.props.appSizes.app.height - this.props.topBarHeight - (this.props.bottomOffset || 0));
        const viewportWidth = isMobile && isPortrait ? viewportHeight * this._aspectRatio : this.props.appSizes.app.width;
        const margin = (viewportWidth - this.props.appSizes.app.width) / 2;

        if (!isShadow) {
            clearTimeout(this._timeout);
            if (margin > 0) {
                this._timeout = setTimeout(() => {
                    const scrollable = document.getElementById('hm-scene');
                    if (scrollable) {
                        scrollable.scrollTo(margin, 0)
                    }
                }, 100);
            }
        }

        if (!this.props.currentRoom || !this.props.currentRoom.showroomScene)
            return (null);
        else
            return (
                <div
                    className="hm-scene"
                    id={this.props.isShadow ? 'hm-scene-shadow' : 'hm-scene'}
                    style={{
                        height: isModal ? '100%' : (viewportHeight + 'px'),
                    }}
                    ref={ (el) => this._gestureArea = el}
                >
                    <div
                        style={{
                            height: '100%',
                            width: isMobile && isPortrait ? (viewportWidth + 'px') : '100%',
                            // transformOrigin: '50% 50%',
                            // transition: 'transform 0.15s ease-in-out',
                        }}
                        ref={ (el) => this._scalableArea = el}
                    >
                        <HMImage
                            imageSrc={this.state.sceneSrc}
                            onLoading={() => this.onLoading()}
                            onLoaded={() => this.onLoaded()}
                            onError={() => this.onError()}
                        />
                    </div>
                </div>
            )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentRoom: state.app.currentRoom,
        topBarHeight: state.app.appSizes.topBar.height,
        appSizes: state.app.appSizes,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

Scene.propTypes = {
    isModal: PropTypes.bool,
    isShadow: PropTypes.bool,
    onSceneChanged: PropTypes.func,
    bottomOffset: PropTypes.number,
};


export default connect(mapStateToProps, mapDispatchToProps)(Scene);
