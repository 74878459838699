import React, {Component} from 'react';
import "./style.scss"
import EnviseLogoWhite from "../../../assets/Hydda_Envise_STD_NEG_hydda_web_small.png";
import PropTypes from "prop-types";

class HMFooter extends Component {
    render() {
        return (
            <div className={`row hm-footer align-items-center justify-content-start ${this.props.className || ''}`}>
                <div className="col-12">
                    <a href="https://envise.io/" target="_blank" rel="author noopener noreferrer">
                        <img src={EnviseLogoWhite} alt="Showroom by Envise" width="200"/>
                        <span><strong>&nbsp;© {new Date().getFullYear()}</strong></span>
                    </a>
                </div>
            </div>
        );
    }
}

HMFooter.propTypes = {
    className: PropTypes.string,
};

export default HMFooter;
