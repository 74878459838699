import LogService from "./LogService";
import uuidv1 from "uuid/v1";
import i18n from "../i18n";

class UtilService {

    static equals(val1, val2) {
        return val1 === val2 || ('' + val1) === ('' + val2);
    }

    static includesAnyOf(haystack, array) {
        return haystack && array && array.some(v => haystack.includes(v));
    }

    static arraysEqual(array1, array2) {
        return array1 === array2
            || (array1 && array2 && ((array1.length === 0 && array2.length === 0) || array1.every(e => array2.includes(e))));
    }

    static uniqueArray (entries) {
        const unique = new Set();
        entries.forEach((entry) => {
            unique.add(entry);
        });

        return this.iterableToArray(unique);
    }

    static getUUID() {
        return uuidv1();
    }

    static arrayToChunks(array, chunkSize) {
        const chunks = [];
        var i = 0;
        const n = array.length;

        while (i < n) {
            chunks.push(array.slice(i, i += chunkSize));
        }

        return chunks;
    }

    static iterableToArray(iterable) {

        const array = [];
        iterable.forEach((value) => {
            array.push(value);
        });

        return array;
    }

    static removeElementFromArray(array, element) {
        this.removeIndexFromArray(array, array.indexOf(element));
    }

    static removeIndexFromArray(array, index) {
        if (index === -1)
            throw new Error("Not a valid index " + index);

        array.splice(index, 1);
    }

    static persistToLocalStorage(key, data) {
        const storageKey = `homemaker_showroom.${key}`;
        localStorage.setItem(storageKey, JSON.stringify(data));
    }

    static getMapFromLocalStorage(key) {
        const serialized = this.getFromLocalStorage(key);
        return serialized === null ? new Map() : new Map(serialized);
    }

    static appendToLocalStorageMap(storageKey, key, value) {
        const map = this.getMapFromLocalStorage(storageKey);
        map.set(key, value);
        this.persistToLocalStorage(storageKey, Array.from(map));
    }

    static getFromLocalStorage(key) {
        const storageKey = `homemaker_showroom.${key}`;
        const item = localStorage.getItem(storageKey);
        return JSON.parse(item);
    }

    static removeFromLocalStorage(key) {
        const storageKey = `homemaker_showroom.${key}`;
        localStorage.removeItem(storageKey)
    }

    static clearLocalStorage () {
        localStorage.clear();
        LogService.log("localStorage cleared", "UtilService");
    }

    static sDTrack(userId, event) {
        window.startdeliver('sendEvent', {
            usageType: event,
            userId: userId,
            startdeliverUserFieldToMatchOn: 'customfieldHomemakerId'
        });
    }

    static preloadImages(images) {
        if (images && images.length > 0) {
            images.map(image => {
                let img = new Image();
                img.src = image;

                return img;
            });
        }
    }

    static hasProperty(object, key) {
        return object ? Object.prototype.hasOwnProperty.call(object, key) : false;
    }

    static getLoadingMessage(key) {
        return key ? {
            key: key,
            message: i18n.t("common:loadingMessages." + key)
        } : undefined;
    }

    static capitalize(str) {
        if (!str || str.length === 0) return str;

        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

export function debounce(func, wait, immediate) {
    let timeout;

    return () => {
        let context = this;
        let args = arguments;

        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

export default UtilService;
