import * as types from "./actionTypes";

export function close() {
    return function (dispatch) {
        dispatch({type: types.CLOSE_TUTORIAL});
    }
}

export function updateSteps() {
    return function (dispatch) {
        dispatch({type: types.UPDATE_TUTORIAL_STEPS});
    }
}

export function completeSteps(stepNames) {
    return function(dispatch) {
        dispatch({type: types.COMPLETE_TUTORIAL_STEPS, stepNames: stepNames});
    }
}

export function tutorialIsLoaded() {
    return function (dispatch) {
        dispatch({type: types.TUTORIAL_IS_LOADED});
    }
}

export function resetTutorial() {
    return function (dispatch) {
        dispatch({type: types.RESET_TUTORIAL});
    }
}
