import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import * as authActions from "../../../../../actions/authActions";
import {AppRoute} from "../../../../../services/RouterService";
import PropTypes from "prop-types";
import Logout from "./Logout";
import ChooseApartment from "./ChooseApartment";
import LanguageSelector from "./LanguageSelector";
import "./style.scss";

class SettingsDropdown extends Component {

    _dropdownMenu = undefined;

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            hover: false
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    toggle() {
        const expanded = !this.state.expanded;

        this.setState({...this.state, expanded: expanded});

        if (expanded) {
            this.addListeners();
        } else {
            this.removeListeners();
        }
    }

    addListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    }

    removeListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }

    handleDocumentClick(e) {
        if (!this.state.expanded || (this._dropdownMenu && (this._dropdownMenu.contains(e.target) || this._dropdownMenu === e.target))) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.toggle();
    }

    hoverOn() {
        this.setState({...this.state, hover: true});
    }

    hoverOff() {
        this.setState({...this.state, hover: false});
    }

    render() {

        const { t } = this.props;

        return (
            <li className="nav-item dropdown" ref={(el) => { this._dropdownMenu = el; }}>
                <div className="action-link pointer"
                     title={t('topBar.settings')}
                     onClick={() => this.toggle()}
                     onMouseEnter={() => this.hoverOn()}
                     onMouseLeave={() => this.hoverOff()}>
                    <div className="action-link">
                        <clr-icon shape="cog" class={this.state.hover || this.state.expanded ? "is-solid": ""}/>
                    </div>
                </div>

                <div className={`hm-settings-dropdown dropdown-menu dropdown-menu-right ${this.state.expanded ? "show" : ""}`}>
                    <LanguageSelector/>
                    <ChooseApartment currentRoutes={this.props.currentRoutes} clickCallback={ () => this.toggle() }/>
                    <Logout clickCallback={ () => this.toggle() }/>
                </div>
            </li>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        auth: state.app.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch)
    }
}

SettingsDropdown.propTypes = {
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SettingsDropdown));
