import UtilService from "./UtilService";

export default class CookiesService {
    static _cookiesLocalStorageKey = 'cookies';

    static loadCookies() {
        try {
            const serializedState = UtilService.getFromLocalStorage(this._cookiesLocalStorageKey);

            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    }

    static saveCookies(cookies) {
        try {
            const serializedState = JSON.stringify(cookies);
            UtilService.persistToLocalStorage(this._cookiesLocalStorageKey, serializedState);
        } catch {
            console.log('Cookies write error!')
        }
    }
}
