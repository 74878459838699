import React, {Component} from 'react';
import {Redirect, Route} from "react-router-dom";
import RouterService from "../../../services/RouterService";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as appActions from "../../../actions/appActions";
import HMError from "../../Utils/HMError";
import {Trans, withTranslation} from "react-i18next";

class AuthorizedRoute extends Component {

    componentDidMount() {
        this.props.appActions.getMe();
    }

    render() {
        const { component: Component, render: RenderComponent, authorized, me, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props => {
                    if (authorized) {
                        if (me) {
                            if (Component)
                                return (
                                    <Component {...props} />
                                );
                            else if (RenderComponent)
                                return RenderComponent({...props});
                            else
                                return null;
                        } else if (this.props.meFetchingError) {
                            return (
                                <HMError>
                                    <Trans i18nKey="common:errors.meFetchingError">
                                        Can't get user authentication data.
                                    </Trans>
                                </HMError>
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: RouterService.authLoginPath(),
                                    state: { from: props.location }
                                }}
                            />
                        )
                    }
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        authorized: state.app.auth.authorized,
        me: state.app.me,
        meFetchingError: state.app.meFetchingError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(appActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AuthorizedRoute));
