import React, {Component} from 'react';
import "./style.scss"
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link, NavLink} from "react-router-dom";
import {SelectionProgressService} from "../../../../../services/SelectionProgressService";
import {BreadcrumbNavigationItem} from "../../../../Utils/HMBreadcrumbNavigation";

class NavigationSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    _lastActiveItem = undefined;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.items) {
                const currentActiveItem = SelectionProgressService.getActiveNavigationItem(this.props.items);
                this._lastActiveItem = currentActiveItem
            }
        }
    }

    getItemClasses(item, level) {
        let classes = `bn-level-${level}`;

        if (item.finished)
            classes += " bn-finished";

        if (item.partlyFinished)
            classes += " bn-partly-finished";

        if (!item.finished && !item.partlyFinished)
            classes += " bn-unfinished";

        if (!item.isNavLink && item.active || item.forceActive)
            classes += " bn-active";

        if (!item.linkUrl && !item.onClick)
            classes += " bn-inactive-link";

        return classes;
    }

    getIcon(item) {

        // Show user an error message for this nav item should the selected product be disabled and illegal from Product relationships
        if (item.hasError)
            return <div className="clr-white-background"><div></div><clr-icon shape="error-standard" class="is-solid text-danger"></clr-icon></div>;

        if (item.isLocked)
            return <clr-icon shape="lock" class="is-solid is-locked"></clr-icon>;

        if (item.finished)
            return <div className="clr-white-background"><div></div><clr-icon shape="success-standard" class="is-solid"></clr-icon></div>;

        if (item.partlyFinished)
            return <clr-icon shape="circle" class="is-solid"></clr-icon>;

        return this.props.showUnfinishedIcon ? <clr-icon shape="circle"></clr-icon> : null;
    }

    generateItem(item, level, hidden, index) {
        const { t } = this.props;

        const parent =
            <li key={item.key} className={`${hidden ? 'hidden' : ''}`}>
                {item.linkUrl && item.isNavLink &&
                <NavLink
                    className={this.getItemClasses(item, level)}
                    activeClassName="bn-active"
                    to={item.linkUrl}
                    onClick={() => item.onClick && item.onClick(item)}
                >
                    {this.getIcon(item)}
                    {item.title && <span>{item.title}</span>}
                    {!item.title && item.titleKey && <span>{t(item.titleKey)}</span>}
                </NavLink>}

                {item.linkUrl && !item.isNavLink &&
                <Link
                    className={this.getItemClasses(item, level)}
                    to={item.linkUrl}
                    onClick={() => item.onClick && item.onClick(item)}
                >
                    {this.getIcon(item)}
                    {item.title && <span>{item.title}</span>}
                    {!item.title && item.titleKey && <span>{t(item.titleKey)}</span>}
                </Link>}

                {!item.linkUrl &&
                <a
                    className={this.getItemClasses(item, level)}
                    href="javascript:void(0);"
                    onClick={() => item.onClick && item.onClick(item)}
                >
                    {this.getIcon(item)}
                    {item.title && <span>{item.title}</span>}
                    {!item.title && item.titleKey && <span>{t(item.titleKey)}</span>}
                </a>
                }
            </li>;

        if (!item.children || item.children.length === 0)
            return parent;

        return [parent]
            .concat(item.children.map((child, i) => this.generateItem(child, level + 1, false, i)));
    }

    _menuRef = undefined;
    setMenuElement(el) {
        this._menuRef = el;
    }

    render() {
        if (!this.props.items || this.props.items.length === 0)
            return (null);

        return <ul ref={el => this.setMenuElement(el)} className={`hm-navigation-selector ${this.props.className || ''}`}>
            {this.props.items.map((item, i) => {
                return this.generateItem(item, 0, false, i);
            })}
        </ul>
    }
}

NavigationSelector.propTypes = {
    className: PropTypes.string,
    showUnfinishedIcon: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.instanceOf(BreadcrumbNavigationItem)).isRequired,
};

export default withTranslation('common')(NavigationSelector);
