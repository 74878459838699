import {Component} from 'react';
import UtilService from "../../../services/UtilService";
import {withRouter} from "react-router-dom";
import {AppRoute, RouterService} from "../../../services/RouterService";
import i18next from "../../../i18n";
import connect from "react-redux/es/connect/connect";

class TitleSetter extends Component {

    componentDidMount() {
        i18next.on('languageChanged', (lng) => {
            this.setDocumentTitle();
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setDocumentTitle();
    }

    setDocumentTitle() {
        const currentRoutes = RouterService.getCurrentRoutes(this.props.location);
        let currentRoute = currentRoutes.filter(route => route.exact).shift();

        if (!currentRoute) {
            if (UtilService.includesAnyOf(currentRoutes, [AppRoute.AuthForgotPassword])) {
                currentRoute = AppRoute.AuthForgotPassword;
            }
        }

        let key = currentRoute ? currentRoute.getShortName() : "loading";
        let params = {};

        switch (currentRoute) {
            case AppRoute.AuthLogin:
            case AppRoute.AuthResetPassword:
            case AppRoute.AuthForgotPassword:
            case AppRoute.ChooseApartment:
            case AppRoute.ProjectLanding:
            case AppRoute.ApartmentLanding:
                break;

            case AppRoute.Room:
                params.room = this.props.currentRoom || {roomType: ""};
                break;

            case AppRoute.Summary:
                break;

            case AppRoute.Checkout:
                if (this.props.readOnlyMode) key += 'ReadOnly';
                break;
            case AppRoute.Confirmation:
                break;

            default:
                break;
        }

        document.title = "Showroom by Envise :: " + i18next.t('title.' + key, params);
    }

    render() {
        return null;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        readOnlyMode: state.app.readOnlyMode,
        project: state.app.project.project,
        apartment: state.app.apartment2.apartment,
        currentRoomId: state.app.currentRoomId,
        currentRoom: state.app.currentRoom,
        singleApartment: state.app.singleApartment
    }
}

export default withRouter(connect(mapStateToProps)(TitleSetter));
