import React, {Component} from 'react';
import PropTypes from "prop-types";
import './style.scss';
import {withTranslation} from "react-i18next";

class SectionCol2TextImage extends Component {

    render() {

        if (!this.props.section)
            return null;

        const { t, section } = this.props;

        return (
            <div className={`row justify-content-center hm-landing-page-section hm-landing-page-section-col2textImage ${!!section.customProperties.cssClass ? section.customProperties.cssClass : ''}`}>
                <div className="col-auto hm-landing-page-section-wrapper">
                    <div className="row no-gutters">
                        <div className="col-xs-12 col-md-6 left-content">
                            {section.title && section.title !== '' ? <h1>{section.title}</h1> : null}
                            <div dangerouslySetInnerHTML={{__html: section.richContentLeft}}></div>
                        </div>
                        <div className="col-xs-12 px-sm-0 col-md-6 right-content">
                            {section.images && section.images.length > 0 &&
                            <img src={section.images[0].url}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SectionCol2TextImage.propTypes = {
    section: PropTypes.object,
};

export default withTranslation('common')(SectionCol2TextImage);
