import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import * as roomActions from "../../../actions/roomActions";
import HMButton from "../HMButton";
import "./style.scss"
import {UncontrolledTooltip} from 'reactstrap';
import BrandService from "../../../services/BrandService";

class HMChooseStandardsForRoomAction extends Component {

    render() {
        const { t } = this.props;

        return (
            <div className="hm-reset-room-action">
                <HMButton
                    id="ChooseStandardsForRoomButton"
                    text={t(BrandService.getTerminologyNamespace() + ':chooseStandardsForRoom.title')}
                    active={false}
                    activeText={t(BrandService.getTerminologyNamespace() + ':chooseStandardsForRoom.title')}
                    clickFn={() => {this.props.roomActions.chooseStandardsForRoom(this.props.apartmentId, this.props.currentRoomId)}}/>
                    {this.props.children}
                <UncontrolledTooltip placement="top" target="ChooseStandardsForRoomButton">
                    {t(BrandService.getTerminologyNamespace() + ':chooseStandardsForRoom.tooltipDescription')}
                </UncontrolledTooltip>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        apartmentId: state.app.apartment2.apartmentId,
        currentRoomId: state.app.currentRoomId,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        roomActions: bindActionCreators(roomActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(HMChooseStandardsForRoomAction));
