import React, {Component} from 'react';
import "./style.scss"
import {withTranslation} from "react-i18next";
import {withBus} from 'react-bus';
import {HM_TOPBAR_CHAT_TOGGLE} from "../../../actions/eventTypes";
import HMButton from "../HMButton";

class HMContactUsCallToAction extends Component {

    onClick() {
        this.props.bus.emit(HM_TOPBAR_CHAT_TOGGLE);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="row hm-contact-us hm-landing-page-section-fullWidthContactUs align-items-center justify-content-start">
                <div className="col-xs-12 col-md-6 hm-contact-us-question">
                    <div className="text">
                        <h3 className="normalcase">{t('contactUs.haveAQuestion')}</h3>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6 hm-contact-us-action">
                    <HMButton
                        text=""
                        active={true}
                        activeText={t('contactUs.contactUsCallToAction')}
                        activeType={"orange"}
                        clickFn={() => this.onClick()}
                    />
                </div>
            </div>
        );
    }
}

export default withBus()(withTranslation('common')(HMContactUsCallToAction));

