import UtilService from "./UtilService";
import store from "../reducers/store";
import {BreadcrumbNavigationItem} from "../components/Utils/HMBreadcrumbNavigation";
import {AppRoute, RouterService} from "./RouterService";
import {Enum} from 'enumify';
import RoomService from "./RoomService";

export class NavigationItemType extends Enum {}
NavigationItemType.initEnum([
    'Package', 'PackageCategory',
    'Other', 'OtherCategory',
    'Extra', 'ExtraCategory',
    'Room', 'Summary'
]);

export class SelectionProgressService {
    static _selectionProgressLocalStorageKey = 'selectionProgress';

    static loadSelectionProgress() {
        try {
            const serializedState = UtilService.getFromLocalStorage(this._selectionProgressLocalStorageKey);

            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    }

    static saveSelectionProgress(selectionProgressState) {
        try {
            const serializedState = JSON.stringify(selectionProgressState);
            UtilService.persistToLocalStorage(this._selectionProgressLocalStorageKey, serializedState);
        } catch {
            console.log('Selection progress write error!')
        }
    }

    static _selectionProgressRoomCompletionLocalStorageKey = 'selectionProgressRoomCompletion'
    static loadSelectionProgressRoomCompletion() {
        try {
            const serializedState = UtilService.getFromLocalStorage(this._selectionProgressRoomCompletionLocalStorageKey);

            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    }

    static saveSelectionProgressRoomCompletion(selectionProgressRoomCompletionState) {
        try {
            const serializedState = JSON.stringify(selectionProgressRoomCompletionState);
            UtilService.persistToLocalStorage(this._selectionProgressRoomCompletionLocalStorageKey, serializedState);
        } catch {
            console.log('Selection progress room completion write error!')
        }
    }

    /**
     * This method builds a Selection Progress object for a nav item
     * v = viewed
     * s = selected
     */
    static getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, navigationItem) {
        const defaultItemProgress = {
            v: apartment.isLocked || currentRoom.isLocked,
            s: apartment.isLocked || currentRoom.isLocked,
        };

        if (apartment.isLocked || currentRoom.isLocked) {
            return defaultItemProgress;
        }

        const payload = this.getSelectionProgressPayload(navigationItem, apartment, currentRoom);

        if (!selectionProgress
            || !selectionProgress[payload.apartmentId]
            || !selectionProgress[payload.apartmentId][payload.roomId]
            || !selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId]) {
            return defaultItemProgress;
        }

        if (payload.categoryId) {
            return selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId] || defaultItemProgress;
        } else {
            return selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId] || defaultItemProgress;
        }
    }

    static getRoomNavigationItems(apartment, rooms, currentRoomId, currentRoutes) {
        const roomNavigationItems = rooms.map((room, i) => {
            return Object.assign(new BreadcrumbNavigationItem(), {
                key: `navigation.room-${room.id}`,
                parent: null,
                argument: room,
                title: room.roomType,
                selectable: true,
                active: room.id === currentRoomId,
                finished: apartment.isLocked || room.isLocked || UtilService.includesAnyOf(currentRoutes, [AppRoute.Checkout, AppRoute.Confirmation]),
                isLocked: apartment.isLocked || room.isLocked,
                isNavLink: false,
                linkUrl: RouterService.roomPath(room.id)
            });
        });

        return roomNavigationItems;
    }

    // Main entry method
    // Will iterate through the room's product setup and build nav items
    static getNavigationItems(apartment, currentRoom, selectionProgress, queryString, addNumbers = true) {

        // Style Groups
        let navigationItems = currentRoom.activeStyleGroups
            .filter(activeStyle => activeStyle.productSelections && activeStyle.productSelections.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships()).length > 0)
            .map(activeStyle => {
                const item = Object.assign(new BreadcrumbNavigationItem(), {
                    key: `packageGroup-${activeStyle.styleGroup.id}`,
                    parent: null,
                    argument: {
                        type: NavigationItemType.Package,
                        item: activeStyle,
                    },
                    title: UtilService.capitalize(activeStyle.styleGroup.name),
                    selectable: true,
                    finished: false,
                    linkUrl: {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=${activeStyle.styleGroup.id}`},
                    active: queryString.packageGroup === '' + activeStyle.styleGroup.id,
                    childrenExpanded: true
                });

                item.children = activeStyle.styleGroup.stylesLocked ? [] : activeStyle.productSelections
                    .filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships())
                    .map((ps, i) => {
                        const childItem = Object.assign(new BreadcrumbNavigationItem(), {
                            key: `packageGroup-${activeStyle.styleGroup.id}-category-${ps.id}`,
                            parent: item,
                            argument: {
                                type: NavigationItemType.PackageCategory,
                                item: ps,
                            },
                            title: '' + (addNumbers ? ((i + 1) + '. ') : '') + UtilService.capitalize(ps.subCategory.name),
                            selectable: true,
                            finished: false,
                            active: queryString.packageGroup === '' + activeStyle.styleGroup.id && queryString.category === '' + ps.id,
                            linkUrl: {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=${activeStyle.styleGroup.id}&category=${ps.id}`}
                        });

                        childItem.hasError = ps.isDisabledFromProductRelationships();
                        childItem.selectionProgress = this.getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, childItem);
                        childItem.finished = childItem.selectionProgress.s && !childItem.hasError;
                        childItem.partlyFinished = childItem.selectionProgress.v;
                        return childItem;
                    });

                item.selectionProgress = this.getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, item);
                item.childrenExpanded = (queryString.packageGroup === '' + activeStyle.styleGroup.id) && item.selectionProgress.s;
                item.hasError = item.children.some(c => c.hasError);
                item.finished = item.selectionProgress.s && item.children.every(c => c.finished);
                item.partlyFinished = item.active || item.selectionProgress.v;
                item.active = queryString.packageGroup === '' + activeStyle.styleGroup.id && !item.children.some(c => c.active);

                return item;
            });

        // Other selections (Childrens are product selections)
        if (currentRoom.productSelections && currentRoom.productSelections.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships()).length > 0) {
            const item = Object.assign(new BreadcrumbNavigationItem(), {
                key: `packageGroup-other`,
                parent: null,
                argument: {
                    type: NavigationItemType.Other,
                    item: currentRoom.productSelections,
                },
                titleKey: 'otherOptions',
                selectable: false,
                linkUrl: {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=other&category=${currentRoom.productSelections[0].id}`},
                childrenExpanded: queryString.packageGroup === 'other'
            });

            item.children = currentRoom.productSelections
                .filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships())
                .map((ps, i) => {
                    const childItem = Object.assign(new BreadcrumbNavigationItem(), {
                        key: `packageGroup-other-category-${ps.id}`,
                        parent: item,
                        argument: {
                            type: NavigationItemType.OtherCategory,
                            item: ps,
                        },
                        title: '' + (addNumbers ? ((i + 1) + '. ') : '') + UtilService.capitalize(ps.subCategory.name),
                        selectable: true,
                        active: queryString.packageGroup === 'other' && queryString.category === '' + ps.id,
                        linkUrl: {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=other&category=${ps.id}`}
                    });

                    childItem.hasError = ps.isDisabledFromProductRelationships();
                    childItem.selectionProgress = this.getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, childItem);
                    childItem.finished = childItem.selectionProgress.s && !childItem.hasError;
                    childItem.partlyFinished = childItem.selectionProgress.v;
                    return childItem;
                });

            if (navigationItems.length === 0) {
                item.selectable = true;
                item.titleKey = 'start';
                item.fullTitleKey = 'roomOptions';
                item.linkUrl = {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=other`};
            }

            item.selectionProgress = this.getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, item);
            item.hasError = item.children.some(c => c.hasError);
            item.finished = item.children.every(c => c.finished);
            item.partlyFinished = item.children.some(c => c.partlyFinished || c.finished) || item.selectionProgress.v;
            item.active = queryString.packageGroup === 'other' && !item.children.some(c => c.active);

            navigationItems.push(item);
        }

        // Extra selections (Childrens are product selections)
        if (currentRoom.extraProductSelections && currentRoom.extraProductSelections.filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships()).length > 0) {
            const item = Object.assign(new BreadcrumbNavigationItem(), {
                key: `packageGroup-extra`,
                parent: null,
                argument: {
                    type: NavigationItemType.Extra,
                    item: currentRoom.extraProductSelections,
                },
                titleKey: 'extraOptions',
                selectable: false,
                linkUrl: {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=extra&category=${currentRoom.extraProductSelections[0].id}`},
                childrenExpanded: queryString.packageGroup === 'extra'
            });

            item.children = currentRoom.extraProductSelections
                .filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships())
                .map((ps, i) => {
                    const childItem = Object.assign(new BreadcrumbNavigationItem(), {
                        key: `packageGroup-extra-category-${ps.id}`,
                        parent: item,
                        argument: {
                            type: NavigationItemType.ExtraCategory,
                            item: ps,
                        },
                        title: '' + (addNumbers ? ((i + 1) + '. ') : '') + UtilService.capitalize(ps.subCategory.name),
                        selectable: true,
                        active: queryString.packageGroup === 'extra' && queryString.category === '' + ps.id,
                        linkUrl: {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=extra&category=${ps.id}`}
                    });

                    childItem.hasError = ps.isDisabledFromProductRelationships();
                    childItem.selectionProgress = this.getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, childItem);
                    childItem.finished = childItem.selectionProgress.s && !childItem.hasError;
                    childItem.partlyFinished = childItem.selectionProgress.v;
                    return childItem;
                });

            if (navigationItems.length === 0) {
                item.selectable = true;
                item.titleKey = 'start';
                item.fullTitleKey = 'roomOptions';
                item.linkUrl = {pathname: RouterService.roomPath(currentRoom.id), search: `?packageGroup=extra`};
            }

            item.selectionProgress = this.getSelectionProgressForNavigationItem(apartment, currentRoom, selectionProgress, item);
            item.hasError = item.children.some(c => c.hasError);
            item.finished = item.children.every(c => c.finished);
            item.partlyFinished = item.children.some(c => c.partlyFinished || c.finished) || item.selectionProgress.v;
            item.active = queryString.packageGroup === 'extra' && !item.children.some(c => c.active);

            navigationItems.push(item);
        }

        if (navigationItems && navigationItems.length > 0) {
            navigationItems[0].isStart = true;
        }

        return navigationItems;
    }

    static getActiveNavigationItem(navigationItems) {
        if (!navigationItems || navigationItems.length === 0)
            return null;

        for (let i = 0; i < navigationItems.length; i++) {
            if (navigationItems[i].active)
                return navigationItems[i];

            const activeChild = this.getActiveNavigationItem(navigationItems[i].children);

            if (activeChild)
                return activeChild;
        }

        return null;
    }

    static getNavigationItemNextUrlLinkFromItems(roomsOrder, currentRoom, navigationItems, apartment, rooms, currentRoutes, queryString, selectionProgress) {
        const found = this._findNext(navigationItems);

        switch (found) {
            case "parent":
            case "next":
                const nextRoomId = RoomService.getNeighbouringNotLockedRoomId(currentRoom.id, roomsOrder, 1, rooms);
                if (nextRoomId !== null)
                    return {to: RouterService.roomPath(nextRoomId), type: NavigationItemType.Room};

                const roomNavigationItems = this.getRoomNavigationItems(apartment, rooms, currentRoom.id, currentRoutes);
                this.fillRoomSelectionProgress(roomNavigationItems, queryString);

                if (roomNavigationItems.every(roomItem => this.isNavItemFinished(roomItem))) {
                    return {to: RouterService.summaryPath(), type: NavigationItemType.Summary};
                }

                const firstUnfinishedRoom = this._findNextUnfinished(roomNavigationItems);
                if (firstUnfinishedRoom === "parent") {
                    return {to: RouterService.summaryPath(), type: NavigationItemType.Summary};
                }

                const unfinishedRoomNavigationItems = this.getNavigationItems(apartment, firstUnfinishedRoom.argument, selectionProgress, queryString);
                const firstUnifinishedNavigationItem = this._findNextUnfinished(unfinishedRoomNavigationItems);
                if (firstUnifinishedNavigationItem === "parent") {
                    return {to: RouterService.roomPath(firstUnfinishedRoom.argument.id), type: NavigationItemType.Room};
                }

                firstUnifinishedNavigationItem.linkUrl.pathname = RouterService.roomPath(firstUnfinishedRoom.argument.id);

                return {to: firstUnifinishedNavigationItem.linkUrl, type: firstUnifinishedNavigationItem.argument.type, backToUnfinished: true};
            default:
                return {to: found.linkUrl, type: found.argument.type};
        }
    }

    static _findNext(navigationItems) {
        navigationItems = navigationItems || [];

        for (let i = 0; i < navigationItems.length; i++) {
            if (navigationItems[i].active) {
                if (navigationItems[i].children && navigationItems[i].children.length > 0 && navigationItems[i].childrenExpanded) {
                    return navigationItems[i].children[0];
                } else if (i < navigationItems.length - 1) {
                    return navigationItems[i + 1];
                } else {
                    return "next";
                }
            } else if (navigationItems[i].children && navigationItems[i].children.length > 0) {
                const found = this._findNext(navigationItems[i].children);
                switch (found) {
                    case "parent": {
                        break;
                    }
                    case "next":
                        if (i < navigationItems.length - 1) {
                            return navigationItems[i + 1];
                        }
                        break;

                    default:
                        return found;
                }
            }
        }

        return "parent";
    }

    static _findNextUnfinished(navigationItems) {
        navigationItems = navigationItems || [];

        for (let i = 0; i < navigationItems.length; i++) {
            if (!navigationItems[i].finished && navigationItems[i].selectable) {
                return navigationItems[i];
            }

            if (navigationItems[i].children && navigationItems[i].children.length > 0) {
                const found = this._findNextUnfinished(navigationItems[i].children);
                if (found !== "parent")
                    return found;
            }
        }

        return "parent";
    }

    static isNavItemFinished(navItem) {
        return navItem.finished && (!navItem.children || navItem.children.length === 0 || navItem.children.every(childItem => this.isNavItemFinished(childItem)));
    }

    static isNavItemPartlyFinished(navItem) {
        return navItem.partlyFinished || navItem.finished || (navItem.children && navItem.children.length > 0 && navItem.children.some(childItem => this.isNavItemPartlyFinished(childItem)));
    }

    static fillRoomSelectionProgress(roomNavigationItems, queryString) {
        const state = store.getState();
        const apartment = state.app.apartment2.apartment;
        const selectionProgress = state.app.selectionProgress;

        (roomNavigationItems || []).forEach(roomNavItem => {
            if (!(roomNavItem.argument instanceof AppRoute) && !roomNavItem.finished && !roomNavItem.partlyFinished) {
                const roomSubNavItems = this.getNavigationItems(apartment, roomNavItem.argument, selectionProgress, queryString);
                roomNavItem.finished = roomSubNavItems.every(c => this.isNavItemFinished(c));
                roomNavItem.partlyFinished = roomSubNavItems.some(c => this.isNavItemPartlyFinished(c));
            }
        });

        return roomNavigationItems;
    }

    static addRoomNavigationItemsForRoutes(roomNavigationItems, currentRoutes, openNavigation, readOnlyMode, doLeadsForSalesStart) {
        roomNavigationItems.push(
            Object.assign(new BreadcrumbNavigationItem(), {
                key: `navigation.summary`,
                parent: null,
                argument: AppRoute.Summary,
                titleKey: 'navigation.summary',
                active: UtilService.includesAnyOf(currentRoutes, [AppRoute.Summary]),
                partlyFinished: UtilService.includesAnyOf(currentRoutes, [AppRoute.Summary]),
                finished: UtilService.includesAnyOf(currentRoutes, [AppRoute.Checkout, AppRoute.Confirmation]),
                isNavLink: true,
                linkUrl: UtilService.includesAnyOf(currentRoutes, [AppRoute.Summary]) || openNavigation || roomNavigationItems.every(roomItem => SelectionProgressService.isNavItemFinished(roomItem)) ? RouterService.summaryPath() : undefined
            })
        );

        if ((readOnlyMode && doLeadsForSalesStart) || UtilService.includesAnyOf(currentRoutes, [AppRoute.Checkout, AppRoute.Confirmation]))
            roomNavigationItems.push(
                Object.assign(new BreadcrumbNavigationItem(), {
                    key: `navigation.checkout`,
                    parent: null,
                    argument: AppRoute.Checkout,
                    titleKey: readOnlyMode ? 'navigation.checkoutReadOnly' : 'navigation.checkout',
                    active: UtilService.includesAnyOf(currentRoutes, [AppRoute.Checkout]),
                    forceActive: (readOnlyMode && doLeadsForSalesStart),
                    partlyFinished: UtilService.includesAnyOf(currentRoutes, [AppRoute.Checkout]),
                    finished: UtilService.includesAnyOf(currentRoutes, [AppRoute.Confirmation]),
                    isNavLink: true,
                    linkUrl: RouterService.checkoutPath()
                })
            );

        if (UtilService.includesAnyOf(currentRoutes, [AppRoute.Confirmation]))
            roomNavigationItems.push(
                Object.assign(new BreadcrumbNavigationItem(), {
                    key: `navigation.confirmation`,
                    parent: null,
                    argument: AppRoute.Confirmation,
                    titleKey: 'navigation.confirmation',
                    active: UtilService.includesAnyOf(currentRoutes, [AppRoute.Confirmation]),
                    finished: true,
                    isNavLink: true,
                    linkUrl: RouterService.confirmationPath()
                })
            );

        return roomNavigationItems;
    }

    // Not used
    // static fillRoomFinishStatus(roomNavigationItems, roomsOrder, currentRoomId, queryString) {
    //     if (typeof currentRoomId === 'number') {
    //         const currentRoomIdx = roomsOrder.findIndex(rId => rId === currentRoomId);
    //
    //         (roomNavigationItems || []).forEach(roomNavItem => {
    //             if (roomNavItem.argument && roomNavItem.argument.id) {
    //                 if (roomNavItem.argument.id === currentRoomId && !roomNavItem.finished) {
    //                     roomNavItem.partlyFinished = true;
    //                 }
    //
    //                 if (!roomNavItem.partlyFinished && !roomNavItem.finished) {
    //                     const roomIdx = roomsOrder.findIndex(rId => rId === roomNavItem.argument.id);
    //
    //                     if (roomIdx < currentRoomIdx) {
    //                         roomNavItem.finished = true;
    //                     }
    //                 }
    //             }
    //         });
    //     } else {
    //         for (let i = 0; i < roomNavigationItems.length; i++) {
    //             if (roomNavigationItems[i].argument === currentRoomId) {
    //                 if (roomNavigationItems[i].finished) {
    //                     roomNavigationItems[i].partlyFinished = true;
    //                 }
    //                 break;
    //             }
    //
    //             if (!roomNavigationItems[i].partlyFinished && !roomNavigationItems[i].finished) {
    //                 roomNavigationItems[i].finished = true;
    //             }
    //         }
    //     }
    // }

    // Not used
    // static fillNavigationItemsFinishStatus(navigationItems, queryString) {
    //     navigationItems = navigationItems || [];
    //
    //     for (let i = 0; i < navigationItems.length; i++) {
    //         if (navigationItems[i].active) {
    //             navigationItems[i].partlyFinished = true;
    //             return "partlyFinished";
    //         }
    //
    //         if (navigationItems[i].children && navigationItems[i].children.length > 0) {
    //             const childrenResult = this.fillNavigationItemsFinishStatus(navigationItems[i].children, queryString);
    //             if (childrenResult === "partlyFinished") {
    //                 navigationItems[i].partlyFinished = true;
    //                 return childrenResult;
    //             } else {
    //                 navigationItems[i].finished = true;
    //             }
    //         } else {
    //             navigationItems[i].finished = true;
    //         }
    //     }
    //
    //     return "active";
    // }

    static getSelectionProgressPayloadForRoom(apartment, currentRoom) {
        let navigationItems = currentRoom.activeStyleGroups.map(activeStyle => {
            const item = Object.assign(new BreadcrumbNavigationItem(), {
                parent: null,
                argument: {
                    type: NavigationItemType.Package,
                    item: activeStyle,
                },
            });

            item.children = activeStyle.styleGroup.stylesLocked ? [] : activeStyle.productSelections.map((ps, i) =>
                Object.assign(new BreadcrumbNavigationItem(), {
                    parent: item,
                    argument: {
                        type: NavigationItemType.PackageCategory,
                        item: ps,
                    },
                }));

            return item;
        });

        if (currentRoom.productSelections && currentRoom.productSelections.length > 0) {
            const item = Object.assign(new BreadcrumbNavigationItem(), {
                parent: null,
                argument: {
                    type: NavigationItemType.Other,
                    item: currentRoom.productSelections,
                },
            });

            item.children = currentRoom.productSelections.map((ps, i) =>
                Object.assign(new BreadcrumbNavigationItem(), {
                    parent: item,
                    argument: {
                        type: NavigationItemType.OtherCategory,
                        item: ps,
                    }
                }));


            navigationItems.push(item);
        }

        if (currentRoom.extraProductSelections && currentRoom.extraProductSelections.length > 0) {
            const item = Object.assign(new BreadcrumbNavigationItem(), {
                parent: null,
                argument: {
                    type: NavigationItemType.Extra,
                    item: currentRoom.extraProductSelections,
                },
            });

            item.children = currentRoom.extraProductSelections.map((ps, i) =>
                Object.assign(new BreadcrumbNavigationItem(), {
                    parent: item,
                    argument: {
                        type: NavigationItemType.ExtraCategory,
                        item: ps,
                    }
                }));


            navigationItems.push(item);
        }

        return this._getSelectionProgressPayloadForNavigationItems(navigationItems, apartment, currentRoom);
    }

    static _getSelectionProgressPayloadForNavigationItems(navigationItems, apartment, currentRoom) {
        return (navigationItems || [])
            .map(navigationItem => [this.getSelectionProgressPayload(navigationItem, apartment, currentRoom)].concat(this._getSelectionProgressPayloadForNavigationItems(navigationItem.children, apartment, currentRoom)))
            .reduce((flat, item) => flat.concat(item), []);
    }


    static getSelectionProgressPayload(navigationItem, apartment, currentRoom) {
        let styleGroupId, categoryId;

        switch (navigationItem.argument.type) {
            case NavigationItemType.Package:
                styleGroupId = navigationItem.argument.item.styleGroup.id;
                break;
            case NavigationItemType.PackageCategory:
                styleGroupId = navigationItem.parent.argument.item.styleGroup.id;
                categoryId = navigationItem.argument.item.id;
                break;
            case NavigationItemType.Other:
                styleGroupId = "other";
                break;
            case NavigationItemType.OtherCategory:
                styleGroupId = "other";
                categoryId = navigationItem.argument.item.id;
                break;
            case NavigationItemType.Extra:
                styleGroupId = "extra";
                break;
            case NavigationItemType.ExtraCategory:
                styleGroupId = "extra";
                categoryId = navigationItem.argument.item.id;
                break;
            default:
                return null;
        }

        if (!apartment || !currentRoom) {
            const state = store.getState();

            apartment = apartment || state.app.apartment2.apartment;
            currentRoom = currentRoom || state.app.currentRoom;
        }

        const payload = {
            apartmentId: 'a-' + apartment.id,
            roomId: 'r-' + currentRoom.id,
            styleGroupId: 'g-' + styleGroupId,
            categoryId: categoryId ? 'c-' + categoryId : categoryId
        };

        return payload;
    }
}
