import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from 'i18next-browser-languagedetector';
// import backend from 'i18next-xhr-backend';
import moment from 'moment';

// Danish
import 'moment/locale/sv';
import commonDa from './assets/locales/da/common.json';
import authDa from './assets/locales/da/auth.json';
import modexaDa from './assets/locales/da/modexa.json';
import jmDa from './assets/locales/da/jm.json';

// English
import 'moment/locale/en-gb';
import commonEn from './assets/locales/en/common.json';
import authEn from './assets/locales/en/auth.json';
import modexaEn from './assets/locales/en/modexa.json';
import jmEn from './assets/locales/en/jm.json';

// Norwegian
import 'moment/locale/nb'
import commonNb from './assets/locales/nb/common.json';
import authNb from './assets/locales/nb/auth.json';
import modexaNb from './assets/locales/nb/modexa.json';
import jmNb from './assets/locales/nb/jm.json';

import {Config} from "./config";

// Swedish
import 'moment/locale/sv';
import commonSv from './assets/locales/sv/common.json';
import authSv from './assets/locales/sv/auth.json';
import modexaSv from './assets/locales/sv/modexa.json';
import jmSv from './assets/locales/sv/jm.json';

const resources = {
    da: {
        common: commonDa,
        auth: authDa,
        jm: jmDa, // Custom terminology
        modexa: modexaDa // Custom terminology
    },
    en: {
        common: commonEn,
        auth: authEn,
        jm: jmEn, // Custom terminology
        modexa: modexaEn, // Custom terminology
    },
    nb: {
        common: commonNb,
        auth: authNb,
        jm: jmNb, // Custom terminology
        modexa: modexaNb // Custom terminology
    },
    sv: {
        common: commonSv,
        auth: authSv,
        jm: jmSv, // Custom terminology
        modexa: modexaSv // Custom terminology
    },
};

i18n.on('languageChanged', function(lng) {
    switch (lng) {
        case "da":
            lng = "da";
            break;

        case "en":
            lng = "en-gb";
            break;

        case "nb":
            lng = "nb";
            break;

        case "sv":
            lng = "sv";
            break;

        default:
            break;
    }

    moment.locale(lng);
});

i18n
    .use(detector)
    // .use(backend)
    .use(initReactI18next)
    .init({
        resources: resources,
        ns: ['common'],
        defaultNS: 'common',
        fallbackNS: 'common',
        fallbackLng: 'sv',
        debug: Config.DEV,
        // keySeparator: false,
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                const momentPrefix = "moment:";

                if((value instanceof Date || typeof value === 'string' || value instanceof String)
                    && format
                    && format.substring(0, momentPrefix.length) === momentPrefix) {
                    format = format.substring(momentPrefix.length);
                    return moment(value).format(format);
                }
                return value;
            }
        },
        react: {
            wait: true
        }
    });

export default i18n;
