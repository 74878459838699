// DEMO mode is a read only process where any unauthorized user can enter with a public link, browse a demo apartment and make changes before deciding on a purchase
export const DEMO = 'DEMO';

// The RENTALS mode is exactly like the DEMO mode prices are displayed as monthly costs and where rental residents commits to renovating their apartments
// Since we now have the option to have price per month as an account config, its clear this mode is unnecessary
export const RENTALS = 'RENTALS';

// SALESSTART mode is looser flow (summary available from everywhere) with prices hidden.
export const SALESSTART = 'SALESSTART'; // Based on Demo (will probably replace it)

// ADDON mode is for soon to be homeowners that uses the classic Tillval process. Some rooms are labeled 3D and that is where this app takes over, room by room.
// Changes are persisted to backend and no local summary exists
export const ADDON = 'ADDON';
