import React, {Component} from 'react';
import PropTypes from "prop-types";
import './style.scss';
import {withTranslation} from "react-i18next";

class SectionCol2ImageImage extends Component {

    render() {

        if (!this.props.section)
            return null;

        const { t, section } = this.props;

        const leftImage = (section.images || []).find(attachment => !!attachment && !!attachment.metaData && attachment.metaData.left);
        const rightImage = (section.images || []).find(attachment => !!attachment && !!attachment.metaData && attachment.metaData.right);

        return ((!!leftImage || !!rightImage) &&
            <div className={`row justify-content-center hm-landing-page-section hm-landing-page-section-col2imageImage ${!!section.customProperties.cssClass ? section.customProperties.cssClass : ''}`}>
                <div className="col-auto hm-landing-page-section-wrapper">
                    <div className="row no-gutters">
                        <div className="col-xs-12 col-md-6 left-content">
                            {!!leftImage &&
                            <img src={leftImage.url}/>
                            }
                        </div>
                        <div className="col-xs-12 col-md-6 right-content">
                            {!!rightImage &&
                            <img src={rightImage.url}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SectionCol2ImageImage.propTypes = {
    section: PropTypes.object,
};

export default withTranslation('common')(SectionCol2ImageImage);
