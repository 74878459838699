import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/roomActions";
import connect from "react-redux/es/connect/connect";
import './style.scss'
import {ADDON} from "../../../enums/appModes";
import RouterService from "../../../services/RouterService";
import GoogleMap from "../../Utils/GoogleMap";
import ApartmentData from './ApartmentData'
import HMFooter from "../../Utils/HMFooter";
import FileSlider from "./FileSlider";
import ApartmentBigCarousel from "./ApartmentBigCarousel";
import MoodImages from "./MoodImages";
import HMContactUsCallToAction from "../../Utils/HMContactUsCallToAction";
import SectionCol1Text from "../../Utils/SectionCol1Text";
import SectionCol2ImageText from "../../Utils/SectionCol2ImageText";
import SectionCol2TextImage from "../../Utils/SectionCol2TextImage";
import SectionCol2ImageImage from "../../Utils/SectionCol2ImageImage";
import SectionCol1HmsForm from "../../Utils/SectionCol1HmsForm";

class ApartmentLanding extends Component {

    componentDidMount() {
        if (this.props.appMode !== ADDON) {
            // redirect to first room for modes other than ADDON
            if (this.props.roomsOrder.length > 0)
                this.props.history.replace(RouterService.roomPath(this.props.roomsOrder[0]));

            return;
        }

        if (window.hj){
            window.hj('trigger', `apartmentLanding`);
        }
    }

    render() {
        if (this.props.appMode !== ADDON
            || !this.props.project
            || !this.props.apartment) {
            return null;
        }

        if (!this.props.project.apartmentLandingSections || this.props.project.apartmentLandingSections.length === 0)
            return(
                <div className="container-fluid hm-apartment-landing">

                    <ApartmentBigCarousel/>

                    <ApartmentData
                        apartment={this.props.apartment}
                        project={this.props.project}
                        roomsOrder={this.props.roomsOrder}/>



                    <HMContactUsCallToAction/>

                    <MoodImages
                        className="small-carousel"
                        containerHeight="276px"
                        images={this.props.project.moodImagesSorted.statics.map(image => image.url).slice(0, 2)}
                    />

                    {/*<HMImageCarousel*/}
                        {/*className="small-carousel"*/}
                        {/*images={this.props.project.moodImagesSorted.statics.map(image => image.url)}*/}
                        {/*containerHeight="276px"*/}
                        {/*mode={HMImageCarouselMode.TwoSlidingImages}/>*/}

                    <FileSlider
                        className="file-carousel bottom-shadow"
                        attachments={this.props.apartment.attachments}/>

                    {this.props.apartment.location && this.props.apartment.location.hasLocation &&
                    <GoogleMap
                        location={this.props.apartment.location}
                        markerText={this.props.apartment.name}
                        containerHeight="70vh"/>
                    }

                    <HMFooter/>
                </div>
            );
        else return(
            <div className="container-fluid hm-apartment-landing">
                {this.props.project.apartmentLandingSections
                    .sort((a, b) => {return a.sectionOrder - b.sectionOrder})
                    .map(section => {
                        switch (section.sectionType) {
                            case "Header":
                                return <ApartmentBigCarousel
                                    key={section.sectionOrder}
                                    section={section}
                                />;
                            case "HeaderActions":
                                return <ApartmentData
                                    key={section.sectionOrder}
                                    apartment={this.props.apartment}
                                    project={this.props.project}
                                    roomsOrder={this.props.roomsOrder}/>;
                            case "Col1HmsForm":
                                return <SectionCol1HmsForm
                                    key={section.sectionOrder}
                                    section={section}
                                />;
                            case "Col1Text":
                                return <SectionCol1Text
                                    key={section.sectionOrder}
                                    section={section}
                                />;
                            case "Col2ImageText":
                                return <SectionCol2ImageText
                                    key={section.sectionOrder}
                                    section={section}
                                />;
                            case "Col2TextImage":
                                return <SectionCol2TextImage
                                    key={section.sectionOrder}
                                    section={section}
                                />;
                            case "Col2ImageImage":
                                return <SectionCol2ImageImage
                                    key={section.sectionOrder}
                                    section={section}
                                />;
                            case "FullWidthAttachments":
                                return <FileSlider
                                    key={section.sectionOrder}
                                    className="file-carousel bottom-shadow"
                                    attachments={this.props.apartment.attachments}
                                />;
                            case "FullWidthContactUs":
                                return <HMContactUsCallToAction
                                    key={section.sectionOrder}
                                />;
                            case "FullWidthImages":
                                if (section.images && section.images.length > 0)
                                    return <MoodImages
                                        key={section.sectionOrder}
                                        className={`small-carousel hm-landing-page-section-fullWidthImages images-${section.images.length} ${!!section.customProperties && !!section.customProperties.cssClass ? section.customProperties.cssClass : ''}`}
                                        containerHeight={!!section.customProperties.height && section.customProperties.height !== '' ? section.customProperties.height : '278px'}
                                        images={section.images.map(image => image.url)}
                                    />;
                                else
                                    return null;
                            case "FullWidthMap":
                                if (!!this.props.apartment.location && this.props.apartment.location.hasLocation)
                                    return <GoogleMap
                                        key={section.sectionOrder}
                                        className="hm-landing-page-section-fullWidthMap"
                                        location={this.props.apartment.location}
                                        markerText={this.props.apartment.name}
                                        containerHeight={!!section.customProperties.height && section.customProperties.height !== '' ? section.customProperties.height : '70vh'}/>;
                                else
                                    return null;
                            case "Footer":
                                return <HMFooter
                                    key={section.sectionOrder}
                                    className="hm-landing-page-section-footer"
                                />;
                            default:
                                return null;
                        }
                    })
                }
            </div>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        project: state.app.project.project,
        apartment: state.app.apartment2.apartment,
        roomsOrder: state.app.roomsOrder
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentLanding);
