import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as appActions from "../../actions/appActions";
import * as actions from "../../actions/roomActions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import './style.scss'
import {RouterService} from "../../services/RouterService";
import {ADDON} from "../../enums/appModes";
import HMButton from "../Utils/HMButton";
import HMImageCarousel, {HMImageCarouselMode} from "../Utils/HMImageCarousel";
import SizeIcon from "../../assets/icons/sizeIcon.png";
import ProjectIcon from "../../assets/icons/projectIcon.png";
import DefaultProjectImage from "../../assets/defaultProjectImage.png";
import AuthorizedUserService from "../../services/AuthorizedUserService";

class ChooseApartment extends Component {

    componentDidMount() {
        AuthorizedUserService.removeSelectedCustomer();
        const hasOneCustomerWithFinishedProject = this.props.me.customer && this.props.me.customer.apartment.project.finished;

        if (!this.props.me.hasMultipleCustomers && !hasOneCustomerWithFinishedProject && this.props.me.customer) {
            this.props.history.push(RouterService.chooseApartmentApartmentPath(this.props.me.customer.apartment.project.id, this.props.me.customer.apartment.id));
            return;
        }

        this.props.appActions.setAppMode(ADDON);
        this.props.appActions.appReady();
    }

    render() {
        const { t } = this.props;
        const hasOneCustomerWithFinishedProject = this.props.me.customer && this.props.me.customer.apartment.project.finished;

        return (this.props.me.hasMultipleCustomers || hasOneCustomerWithFinishedProject) ? (
            <div className="container-fluid hm-choose-apartment bg-frosted-light">

                <div className="row hm-choose-apartment-header">
                    <div className="col-12">
                        <div className="row align-items-end bottom-border">
                            <div className="col pl-0">
                                <h1>{t('chooseApartment.title')}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row hm-choose-apartment-content">

                    {this.props.me.customers.sort((c1, c2) => c1.id - c2.id).map((customer) => {
                        return <div key={`customer-${customer.id}`} className="col-auto mb-3 pl-0">
                                <div className="card apartment-card">
                                    <div className="card-body card-background">

                                        <div className="row m-0">
                                            <div className="col-12 p-0">
                                                <HMImageCarousel images={customer.apartment.project.images && customer.apartment.project.images.length > 0 ? customer.apartment.project.images.map(image => image.thumbnailUrl) : [DefaultProjectImage]}
                                                                 containerHeight="124px"
                                                                 mode={HMImageCarouselMode.OneFadingImage}/>
                                            </div>

                                            <div className="col-12 apartment-name">
                                                <h4 className="text-truncate">{customer.apartment.name}</h4>
                                            </div>

                                            <div className="col-12 project-number">
                                                <p className="text-truncate">{t('chooseApartment.projectNumber')}: {customer.apartment.project.projectNumber}</p>
                                            </div>
                                        </div>

                                        <div className="row details">
                                            <div className="col-auto details-icon">
                                                <img className="image-fluid" src={SizeIcon} alt=""/>
                                            </div>
                                            <div className="col-auto details-text">
                                                <div>
                                                <p className="text-truncate">{t('chooseApartment.size')}</p>
                                                    <h6 className="text-truncate">{customer.apartment.size} m<sup>2</sup>{customer.apartment.floorNumber && <span className="lowercase">, {t('chooseApartment.floor')} {customer.apartment.floorNumber}</span>}</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row details">
                                            <div className="col-auto details-icon">
                                                <img className="image-fluid" src={ProjectIcon} alt=""/>
                                            </div>
                                            <div className="col-auto details-text">
                                                <div>
                                                    <p className="text-truncate">{t('chooseApartment.project')}</p>
                                                    <h6 className="text-truncate">{customer.apartment.project.name}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-footer card-background">
                                        <HMButton text={t('view')}
                                                  clickFn={() => {}}
                                                  href={RouterService.chooseApartmentApartmentPath(customer.apartment.project.id, customer.apartment.id)}
                                                  activeText={t('view')}
                                                  active={!customer.apartment.project.finished}
                                                  disabledText={t('closed')}
                                                  disabled={customer.apartment.project.finished}/>
                                    </div>
                                </div>
                        </div>
                    })}
                </div>

            </div>
        ) : null
    }
}


function mapStateToProps(state, ownProps) {
    return {
        me: state.app.me
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        appActions: bindActionCreators(appActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ChooseApartment)));
