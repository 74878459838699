import React, {Component} from 'react';
import './style.scss'
import PropTypes from "prop-types";
import HMImage from "../../../Utils/HMImage";

class MoodImages extends Component {

    render() {
        if (!this.props.images
            || this.props.images.length === 0) {
            return null;
        }

        const images = this.props.images.slice(0, 4);

        const col = 12 / images.length;

        return(
            <div className={`hm-mood-images row ${this.props.className || ''}`} style={{height: this.props.containerHeight}}>
                {this.props.images.map((imgUrl, i) =>
                    <div key={`${i}-${imgUrl}`} className={`no-padding col-md-${col}`}>
                        <HMImage imageSrc={imgUrl}/>
                    </div>
                )}
            </div>
        )
    }
}

MoodImages.propTypes = {
    className: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    containerHeight: PropTypes.string.isRequired,
};

export default MoodImages;
