import React, {Component} from 'react';
import PropTypes from "prop-types";
import ProductService from "../../../services/ProductService"
import "./style.scss"
import ProductCategorySummary from "../ProductCategorySummary";
import {withTranslation} from "react-i18next";
import * as imageActions from "../../../actions/imageActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PackageService from "../../../services/PackageService";
import StyleSummary from "../StyleSummary";

class RoomSummary extends Component {

    getLightbox(allProductSelectionsHierarchy, selectedImageIdx) {
        const lightbox = {
            images: [],
            selectedIndex: selectedImageIdx
        };

        allProductSelectionsHierarchy.activeStyles.forEach(styleProductSelections => {
            if (styleProductSelections.style.hasStylePrice) {
                lightbox.images.push(
                    {
                        src: PackageService.getStyleImageUrlFromStyle(styleProductSelections.style),
                        title: this.props.room.roomType + ' - ' + styleProductSelections.style.name,
                        caption: undefined
                    }
                )
            }

            styleProductSelections.productSelections.forEach(productSelection => {
                lightbox.images.push(
                    {
                        src: ProductService.getImageUrl(productSelection.projectProduct.product),
                        title: this.props.room.roomType + ' - ' + styleProductSelections.style.name + ': ' + productSelection.subCategory.name,
                        caption: productSelection.projectProduct.product.vendor + ': ' + productSelection.projectProduct.product.name
                    }
                );
            })
        });

        allProductSelectionsHierarchy.room.forEach(productSelection => {
            lightbox.images.push(
                {
                    src: ProductService.getImageUrl(productSelection.projectProduct.product),
                    title: this.props.room.roomType + ' - ' + productSelection.subCategory.name,
                    caption: productSelection.projectProduct.product.vendor + ': ' + productSelection.projectProduct.product.name
                }
            );
        });

        return lightbox;
    }

    addImageIndices(allProductSelectionsHierarchy) {
        let imageIdx = 0;
        allProductSelectionsHierarchy.activeStyles.forEach(styleProductSelections => {
            if (styleProductSelections.style.hasStylePrice) {
                styleProductSelections.style.imageIdx = imageIdx;
                imageIdx++;
            }

            styleProductSelections.productSelections.forEach(productSelection => {
                productSelection.imageIdx = imageIdx;
                imageIdx++;
            })
        });

        allProductSelectionsHierarchy.room.forEach(productSelection => {
            productSelection.imageIdx = imageIdx;
            imageIdx++;
        });
    }


    render() {
        const allProductSelectionsHierarchy = PackageService.getAllProductSelectionsInRoomWithStylesHierarchy(this.props.room);
        this.addImageIndices(allProductSelectionsHierarchy);

        return(
            <div className="row hm-showroom-summary-header hm-room-summary bg-frosted-heavy">
                <div className="col">
                    <div className="row align-items-end">
                        <div className="col-12 px-0">
                            <h1 className="normalcase">{this.props.room.roomType}</h1>
                        </div>
                    </div>

                    {allProductSelectionsHierarchy.activeStyles.map(styleProductSelections => {


                        return [
                            <StyleSummary key={`style-${styleProductSelections.style.id}`}
                                          hidePrices={this.props.hidePrices}
                                          style={styleProductSelections.style}
                                          onImageClicked={() => this.props.imageActions.showLightBox(this.getLightbox(allProductSelectionsHierarchy, styleProductSelections.style.imageIdx))}/>
                        ].concat(PackageService.getAllProductSelectionsFromActiveStyle(styleProductSelections).map((productSelection, i) => {
                            return (
                                <ProductCategorySummary key={`${this.constructor.name}-${i}-${productSelection.id}`}
                                                        hidePrices={this.props.hidePrices}
                                                        isInStyleWithFixedPrice={styleProductSelections.style.hasStylePrice}
                                                        styleName={styleProductSelections.style.name}
                                                        productSelection={productSelection}
                                                        onImageClicked={() => this.props.imageActions.showLightBox(this.getLightbox(allProductSelectionsHierarchy, productSelection.imageIdx))}/>
                            )
                        }))
                    })}

                    {allProductSelectionsHierarchy.room.map((productSelection, i) => {
                        return (
                            <ProductCategorySummary key={`${this.constructor.name}-${i}-${productSelection.id}`}
                                                    hidePrices={this.props.hidePrices}
                                                    isInStyleWithFixedPrice={false}
                                                    productSelection={productSelection}
                            onImageClicked={() => this.props.imageActions.showLightBox(this.getLightbox(allProductSelectionsHierarchy, productSelection.imageIdx))}/>
                        )
                    })}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        imageActions: bindActionCreators(imageActions, dispatch)
    }
}


RoomSummary.propTypes = {
    hidePrices: PropTypes.bool,
    forcedRelationshipUpdate: PropTypes.number.isRequired,
    grandTotal: PropTypes.object.isRequired,
    room: PropTypes.object.isRequired
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(RoomSummary));
