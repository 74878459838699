import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import * as actions from "../../../actions/imageActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class HMLightBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: undefined,
            selectedIndex: 0
        };
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.lightbox && !this.props.lightbox) {
            this.setState({...this.state, selectedIndex: nextProps.lightbox.selectedIndex || 0, images: nextProps.lightbox.images});
            return;
        }

        if (!nextProps.lightbox && this.props.lightbox) {
            this.setState({...this.state, selectedIndex: 0, images: undefined});
            return;
        }
    }

    render() {
        if (!this.state.images)
            return (null);

        const images = this.state.images;
        const selectedIndex = this.state.selectedIndex;
        const selectedImage = images[this.state.selectedIndex];

        return (
            <Lightbox
                mainSrc={selectedImage.src}
                imageTitle={selectedImage.title}
                imageCaption={selectedImage.caption}
                prevSrc={images.length > 1 && selectedIndex > 0 ? images[selectedIndex - 1].src : undefined}
                nextSrc={images.length > 1 && selectedIndex < (images.length - 1) ? images[selectedIndex + + 1].src : undefined}
                // nextSrc={images.length > 1 ? images[(selectedIndex + 1) % images.length].src : undefined}
                // prevSrc={images.length > 1 ? images[(selectedIndex + images.length - 1) % images.length].src : undefined}
                onCloseRequest={() => this.props.actions.hideLightBox()}
                onMovePrevRequest={() =>
                    this.setState({
                        selectedIndex: (selectedIndex + images.length - 1) % images.length
                    })}
                onMoveNextRequest={() =>
                    this.setState({
                        selectedIndex: (selectedIndex + 1) % images.length
                    })}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        lightbox: state.app.lightbox
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HMLightBox);
