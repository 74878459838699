import store from "../reducers/store";
import {ADDON, DEMO, RENTALS, SALESSTART} from "../enums/appModes";
import {Enum} from 'enumify';

const rootPathBuilder = (state) => {
    switch(state.app.appMode) {
        case DEMO:
            return state.app.singleApartment ? '/demo-apartment' : '/demo';
        case SALESSTART:
            return state.app.singleApartment ? '/sales-start-apartment' : '/sales-start';
        case RENTALS:
            return state.app.singleApartment ? '/rentals-apartment' : '/rentals';
        case ADDON:
            return '';
        default:
            return '/not-found'
    }
};

const projectPathBuilder = (state) => state.app.project.projectId && !state.app.singleApartment ? `/${state.app.project.projectId || 0}` : undefined;

const apartmentPathBuilder = (state) => `/${state.app.apartment2.apartmentId || 0}`;

const roomPathBuilder = (state, params) => {
    if (params.roomId === undefined) {
        params.roomId = state.app.currentRoom ? state.app.currentRoom.id : 0
    }
    return `/${params.roomId}`
};
const summaryPathBuilder = (state) => '/summary';
const checkoutPathBuilder = (state) => '/checkout';
const confirmationPathBuilder = (state) => '/confirmation';


const combinePaths = (builders, includeRootPath = true) => {
    const state = store.getState();

    builders = builders || [];
    if (includeRootPath) {
        builders = [rootPathBuilder].concat(builders);
    }

    return builders
        .map((builder) => builder instanceof Function ? builder(state) : builder.builder(state, builder.params))
        .filter(path => !!path)
        .reduce((path, currentPath) => path + currentPath, '');
};

export class AppRoute extends Enum {
    get pathFunctionName() {
        return this.getShortName() + "Path";
    }

    getShortName() {
        let shortName = String(this);
        shortName = shortName.slice(shortName.indexOf(".") + 1);
        shortName = shortName.charAt(0).toLowerCase() + shortName.slice(1);
        return shortName;
    }
}
AppRoute.initEnum({

    Auth: {
        get exact() { return false; }
    },

    AuthLogin: {
        get exact() { return true; }
    },

    AuthResetPassword: {
        get exact() { return true; }
    },

    AuthForgotPassword: {
        get exact() { return false; }
    },


    ChooseApartment: {
        get exact() { return true; }
    },


    Project: {
        get exact() { return false; }
    },
    ProjectLanding: {
        get exact() { return true; },
    },

    Apartment: {
        get exact() { return false; }
    },
    ApartmentLanding: {
        get exact() { return true; },
    },

    Room: {
        get exact() { return true; },
    },
    Summary: {
        get exact() { return true; },
    },
    Checkout: {
        get exact() { return true; },
    },
    Confirmation: {
        get exact() { return true; },
    }
});

export class RouterService {

    // static paths:

    static authPath() {
        return '/auth';
    }

    static authLoginPath() {
        return '/auth/login';
    }

    static authResetPasswordPath() {
        return '/auth/forgot-password';
    }

    static authForgotPasswordPath() {
        return '/auth/reset-password/';
    }


    static chooseApartmentPath() {
        return '/';
    }

    static chooseApartmentApartmentPath(projectId, apartmentId) {
        return `${rootPathBuilder(store.getState())}/${projectId}/${apartmentId}`;
    }

    static startPath() {
        const state = store.getState();

        return state.app.appMode === ADDON || state.app.singleApartment ? this.apartmentLandingPath() : this.projectLandingPath()
    }


    // routes paths:

    static projectPath() {
        const state = store.getState();

        return state.app.singleApartment ? undefined : combinePaths([
            projectPathBuilder
        ]);
    }

    static projectLandingPath() {
        return this.projectPath();
    }

    static apartmentPath() {
        return combinePaths([
            projectPathBuilder,
            apartmentPathBuilder
        ]);
    }

    static apartmentLandingPath() {
        return this.apartmentPath();
    }

    static roomPath(roomId = undefined) {
        return combinePaths([
            projectPathBuilder,
            apartmentPathBuilder,
            { builder: roomPathBuilder, params: { roomId: roomId } }
        ]);
    }

    static summaryPath() {
        return combinePaths([
            projectPathBuilder,
            apartmentPathBuilder,
            summaryPathBuilder
        ]);
    }

    static checkoutPath() {
        return combinePaths([
            projectPathBuilder,
            apartmentPathBuilder,
            checkoutPathBuilder
        ]);
    }

    static confirmationPath() {
        return combinePaths([
            projectPathBuilder,
            apartmentPathBuilder,
            confirmationPathBuilder
        ]);
    }

    static pathFor(appRoute, ...params) {
        if (params && params.length === 0) {
            params = undefined;
        }

        if (appRoute instanceof AppRoute) {
            return this[appRoute.pathFunctionName] instanceof Function ? this[appRoute.pathFunctionName](params) : undefined;
        }

        return undefined;
    }

    static getCurrentRoutes(location) {
        //console.log(location);
        //console.log(AppRoute.enumValues.map(appRoute => { return {appRoute: String(appRoute), path: this.pathFor(appRoute)}}));
        const currentRoutes = AppRoute.enumValues.filter(appRoute => {
            let appRoutePath = this.pathFor(appRoute);
            if (!appRoutePath) return false;

            if (appRoute.exact) {
                return appRoutePath === location.pathname || appRoutePath + "/" === location.pathname;
            }
            else {
                return location.pathname.startsWith(appRoutePath);
            }
        });
        //console.log(currentRoutes);

        return currentRoutes;
    }
}

export default RouterService;
