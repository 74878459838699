import {Config} from "../config";
import mergeImages from "merge-images";
import ImageSceneNotSupported from "../assets/imageSceneNotSupported.png";

class SceneService {

    /**
     * A studio3D Scene is usually the bare room but could also be smaller things in that room
     * The rule of thumb is that a Scene is a more intelligent Image that has declared objects which are "areas" or "surfaces"
     * to where a Design can be attached. A Design can either be a pattern or a color. Useful for walls and floors.
     *
     * To render a scene on a scene we need to be a bit hacky, scene1.getUrl()+ "," scene2.getUrl();
     */

    static inBrowserSceneGetUrl(sceneReference, sceneProducts = [], width = 2048) {
        const images = [sceneReference.replace('.pfs', '.jpg')].concat(
        sceneProducts
            .sort((a, b) => (a.zIndex === null || a.zIndex === undefined ? Number.MAX_SAFE_INTEGER : a.zIndex) - (b.zIndex === null || b.zIndex === undefined ? Number.MAX_SAFE_INTEGER : b.zIndex))
            .map(sp => sp.referenceId)
        ).map(img => `${Config.IN_BROWSER_URL}/${sceneReference.replace('.pfs', '')}/2k/${img}`);

        return mergeImages(images, {
            crossOrigin: 'crossorigin',
            format: 'image/jpeg',
            quality: 0.75,
        });
    }

    static constructScene(sceneReference, sceneProducts = [], width = 2048) {
        // disable Picario
        return {
            getUrl: () => {
                return ImageSceneNotSupported;
            }
        }

        // const scene = this._getScene(sceneReference, width);
        //
        // sceneProducts
        //     .sort((a, b) => (a.zIndex === null || a.zIndex === undefined ? Number.MAX_SAFE_INTEGER : a.zIndex) - (b.zIndex === null || b.zIndex === undefined ? Number.MAX_SAFE_INTEGER : b.zIndex))
        //     .forEach((sceneProduct, index) => {
        //         switch (sceneProduct.class) {
        //
        //             case "home.maker.room.visualization.SceneImage":
        //                 this.addImage(scene, sceneProduct, index);
        //                 break;
        //
        //             case "home.maker.room.visualization.SceneDesign":
        //                 this.addDesign(scene, sceneProduct);
        //                 break;
        //
        //             default:
        //                 break;
        //         }
        //     });
        //
        // return scene
    }

    // static addImage(scene, sceneProduct, index) {
    //     scene.addOverlay(function (overlay) {
    //         overlay.setName(sceneProduct.referenceId);
    //         overlay.setIndex(index);
    //     });
    // }
    //
    // // A design is a surface on a scene
    // static addDesign(scene, sceneProduct) {
    //
    //     const isColor = this.designIsColor(sceneProduct.referenceId);
    //
    //     scene.addObject( function(obj) {
    //
    //         if (isColor)
    //             obj.color(sceneProduct.referenceId.substring(1, sceneProduct.referenceId.length)); // Cut away #
    //         else
    //             obj.design(sceneProduct.referenceId);
    //
    //         obj.setIndex(sceneProduct.objectIndex);
    //     });
    // }

    // static getOverlays(scene) {
    //     return scene.request.overlays;
    // }

    // We can determine if the design is of type color by looking at the first character of referenceId and expect Hex character like #000000
    // static designIsColor(referenceId) {
    //     return referenceId.substring(0,1)==="#";
    // }

    // static _getScene(sceneReference, width) {
    //     // Use window because the PicarioApi is a little bit hacky
    //     let fluentXpoUrlFactory = new window.FluentXpoUrlFactory();
    //     let generator = new window.XpoUrlGenerator();
    //
    //     var fluentUrlGenerator = fluentXpoUrlFactory.createFluentUrlGenerator(generator, window.UrlGeneratorModule.FluentXpoUrlType.Image)
    //         .setAbsoluteUrl("https://homemaker.picarioxpo.com")
    //         .setDebug(true);
    //
    //     const scene = fluentUrlGenerator.setPrimaryKey(sceneReference)
    //         .setImageType(window.UrlGeneratorModule.XpoUrlImageTypes.Jpg)
    //         .setEntityType(window.UrlGeneratorModule.XpoUrlFileTypes.Scene)
    //         .setWidth(width)
    //         .setFastRender(true);
    //
    //     return scene;
    // }

}

export default SceneService;
