import React, {Component} from 'react';
import {connect} from "react-redux";
import * as selectionProgressActions from "../../../../actions/selectionProgressActions";
import {bindActionCreators} from "redux";
import "./style.scss"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {AppRoute, RouterService} from "../../../../services/RouterService";
import UtilService from "../../../../services/UtilService";
import PropTypes from "prop-types";
import BreadcrumbNavigation, {BreadcrumbNavigationItem} from "../../../Utils/HMBreadcrumbNavigation";
import {parse} from "query-string";
import {NavigationItemType, SelectionProgressService} from "../../../../services/SelectionProgressService";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import NavigationSelector from "./NavigationSelector";
import {Progress} from 'reactstrap';
import CostDropdown from "../TopBar/CostDropdown";
import {withBus} from 'react-bus';
import {HM_TOPBAR_OPEN_MOBILE_NAVIGATION} from "../../../../actions/eventTypes";

class MobileNavigation extends Component {

    _openRoomPackageCategorySelectorOpen;
    constructor(props) {
        super(props);

        this.state = {
            navigationReady: false,
            subNavigationReady: false,
            roomPackageCategorySelectorOpen: false,
        };

        this.toggleRoomPackageCategorySelectorOpen = this.toggleRoomPackageCategorySelectorOpen.bind(this);
        this._openRoomPackageCategorySelectorOpen = () => this.toggleRoomPackageCategorySelectorOpen(true);
    }

    componentDidMount() {
        this.props.bus.on(HM_TOPBAR_OPEN_MOBILE_NAVIGATION, this._openRoomPackageCategorySelectorOpen);
    }

    componentWillUnmount() {
        this.props.bus.off(HM_TOPBAR_OPEN_MOBILE_NAVIGATION, this._openRoomPackageCategorySelectorOpen);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const navigationReady = this.isRequiredPropsNavigationReady();

        if (this.state.navigationReady !== navigationReady) {
            this.setState({...this.state, navigationReady: navigationReady});
        }

        const subNavigationReady = this.isRequiredPropsSubNavigationReady();

        if (this.state.subNavigationReady !== subNavigationReady) {
            this.setState({...this.state, subNavigationReady: subNavigationReady});
        }

        if (this.props.resizeCallback)
            this.props.resizeCallback();

        if (subNavigationReady) {
            const navItems = this.getNavigationItems();

            if (navItems && navItems.length > 0) {
                const activeItem = SelectionProgressService.getActiveNavigationItem(navItems);

                if (!activeItem || this.props.location.search !== activeItem.linkUrl.search) {
                    this.props.history.replace(navItems[0].linkUrl.search);
                } else {
                    this.props.selectionProgressActions.selectionProgressVisited(activeItem);
                    if (activeItem.argument.type === NavigationItemType.ExtraCategory)
                        this.props.selectionProgressActions.selectionProgressSelected(activeItem);
                }
            }
        }

        this.synchronizeHashAndModals();
    }

    isRequiredPropsNavigationReady () {
        return UtilService.includesAnyOf(RouterService.getCurrentRoutes(this.props.location), [AppRoute.Room, AppRoute.Summary, AppRoute.Checkout, AppRoute.Confirmation])
            && this.props.apartment && this.props.currentRoomId !== undefined;
    }

    isRequiredPropsSubNavigationReady () {
        return this.isRequiredPropsNavigationReady() && UtilService.includesAnyOf(RouterService.getCurrentRoutes(this.props.location), [AppRoute.Room])
            && this.props.currentRoom !== undefined && this.props.currentRoom !== null
            && this.props.location.pathname.toLowerCase() === RouterService.roomPath(this.props.currentRoom.id).toLowerCase();
    }

    getNavigationItems() {
        return SelectionProgressService.getNavigationItems(this.props.apartment, this.props.currentRoom, this.props.selectionProgress, parse(this.props.location.search));
    }

    synchronizeHashAndModals() {
        if (this.props.history.location.hash === "#navigation") {
            if (!this.state.roomPackageCategorySelectorOpen) {
                setTimeout(() => this.toggleRoomPackageCategorySelectorOpen(true, false));
            }
        } else if (!this.props.history.location.hash || this.props.history.location.hash !== '#navigation') {
            if (this.state.roomPackageCategorySelectorOpen) {
                setTimeout(() => this.toggleRoomPackageCategorySelectorOpen(false, false));
            }
        }
    }

    toggleRoomPackageCategorySelectorOpen(roomPackageCategorySelectorOpen, navigate = true) {
        if (this.state.roomPackageCategorySelectorOpen !== roomPackageCategorySelectorOpen) {
            this.setState({...this.state, roomPackageCategorySelectorOpen: roomPackageCategorySelectorOpen});

            if (navigate) {
                if (roomPackageCategorySelectorOpen) {
                    this.props.history.push(this.props.history.location.pathname + this.props.history.location.search + '#navigation');
                } else {
                    if (!!this.props.history.location.hash && this.props.history.location.hash === "#navigation") {
                        this.props.history.goBack();
                    }
                }
            }
        }
    }

    render() {
        if (!this.state.navigationReady) {
            return null;
        }

        const { t } = this.props;

        const currentRoutes = RouterService.getCurrentRoutes(this.props.location);
        const isEnd = UtilService.includesAnyOf(currentRoutes, [AppRoute.Confirmation]);

        const roomNavigationItems = SelectionProgressService.getRoomNavigationItems(this.props.apartment, this.props.roomsOrder.map(roomId => this.props.rooms.get(roomId)), this.props.currentRoomId, currentRoutes);
        SelectionProgressService.fillRoomSelectionProgress(roomNavigationItems, parse(this.props.location.search));
        const roomCount = roomNavigationItems.length;
        SelectionProgressService.addRoomNavigationItemsForRoutes(roomNavigationItems, currentRoutes, this.props.openNavigation, this.props.readOnlyMode, this.props.project.settings.doLeadsForSalesStart);
        const activeRoomItemIndex = roomNavigationItems.findIndex(rni => rni.active);
        const activeRoomItem = Object.assign(new BreadcrumbNavigationItem(), roomNavigationItems[activeRoomItemIndex]);

        const setCloseRoomPackageCategorySelectorOpen = (item) => {
            if (!item) return;

            item.onClick = () => this.toggleRoomPackageCategorySelectorOpen(false, false);

            if (!!item.children) {
                item.children.forEach(child => setCloseRoomPackageCategorySelectorOpen(child));
            }
        }

        // if (activeRoomItemIndex < roomCount) {
        //     activeRoomItem.title = `[${activeRoomItemIndex + 1}/${roomCount}]` + activeRoomItem.title;
        // }

        activeRoomItem.linkUrl = undefined;
        activeRoomItem.isNavLink = false;
        activeRoomItem.onClick = () => { this.toggleRoomPackageCategorySelectorOpen(true); };

        const subNavigationItems = this.state.subNavigationReady ? this.getNavigationItems() : undefined;
        if (!!subNavigationItems && subNavigationItems.length > 0) {
            let activeSubItem = subNavigationItems.find(sni => sni.active);
            let activeSubSubItem = undefined;

            if (!activeSubItem) {
                activeSubItem = subNavigationItems.find(sni => sni.children && sni.children.some(c => c.active));
                if (activeSubItem) {
                    activeSubSubItem = Object.assign(new BreadcrumbNavigationItem(), activeSubItem.children.find(c => c.active));

                    if (activeSubSubItem) {
                        activeSubSubItem.linkUrl = undefined;
                        activeSubSubItem.childrenExpanded = false;
                        activeSubSubItem.children = undefined;
                        activeSubSubItem.active = false;
                        activeSubSubItem.onClick = () => { this.toggleRoomPackageCategorySelectorOpen(true); };
                    }
                }
            }

            if (activeSubItem) {
                activeSubItem = Object.assign(new BreadcrumbNavigationItem(), activeSubItem);

                activeSubItem.linkUrl = undefined;
                activeSubItem.childrenExpanded = !!activeSubSubItem;
                activeSubItem.children = !!activeSubSubItem ? [activeSubSubItem] : undefined;
                activeSubItem.active = false;
                activeSubItem.onClick = () => { this.toggleRoomPackageCategorySelectorOpen(true); };

                activeRoomItem.children = [activeSubItem];
                activeSubItem.parent = activeRoomItem;
                activeRoomItem.childrenExpanded = true;
            }

            roomNavigationItems[activeRoomItemIndex].children = subNavigationItems;
            roomNavigationItems[activeRoomItemIndex].childrenExpanded = true;
            subNavigationItems.forEach(sni => sni.parent = roomNavigationItems[activeRoomItemIndex]);
        }

        roomNavigationItems.forEach(rni => setCloseRoomPackageCategorySelectorOpen(rni));

        // calculate progress
        const progressMax = 10000;
        let progressBig = 0;
        if (UtilService.includesAnyOf(currentRoutes, [AppRoute.Confirmation])
            || (!this.props.readOnlyMode && this.props.apartment.isLocked)
        ) {
            progressBig = progressMax;
        } else if (UtilService.includesAnyOf(currentRoutes, [AppRoute.Checkout])) {
            progressBig = 9500;
        }
        else if (UtilService.includesAnyOf(currentRoutes, [AppRoute.Summary])) {
            progressBig = 9100;
        }
        else {
            let completedRooms = 0;
            roomNavigationItems.forEach((rni, i) => {
                if (rni.finished || rni.isLocked) {
                    completedRooms += 1;
                }
            });
            progressBig = completedRooms * ((progressMax - 1000) / roomCount);
        }


        if (this.state.navigationReady)
        return (
            <React.Fragment>
                <div className={`hm-mobile-navigation`}>
                    <BreadcrumbNavigation className="hm-sub-navigation-menu" items={[activeRoomItem]} openStart={false} openEnd={!isEnd}/>
                    <Progress value={progressBig} max={progressMax} barClassName="progress-big"/>
                    <clr-icon shape="bars" class="navigation-icon" size="32" onClick={() => this.toggleRoomPackageCategorySelectorOpen(true)}></clr-icon>
                </div>

                <Modal isOpen={this.state.roomPackageCategorySelectorOpen}
                       toggle={() => this.toggleRoomPackageCategorySelectorOpen(false)}
                       centered={true}
                       className={"hm-full-screen-modal-modal hm-navigation-modal"}
                       modalClassName={"hm-full-screen-modal"}
                       modalTransition={{ timeout: 100 }}
                       // contentClassName={"hm-package-picker-modal-content"}
                       // backdropClassName={"hm-package-picker-modal-backdrop"}
                >
                    <ModalHeader>
                        <div className="d-flex align-items-center">
                            <clr-icon shape="map" size="28"></clr-icon>
                            <h2 className="pl-2 mb-0">{t('navigateTo')}</h2>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <CostDropdown currentRoutes={currentRoutes}/>
                        <NavigationSelector items={roomNavigationItems} showUnfinishedIcon={true}/>
                    </ModalBody>
                    <button className="hm-button btn hm-button-default hm-thin-button p-0 hm-floating-action-button" type="button" onClick={() => this.toggleRoomPackageCategorySelectorOpen(false)}>
                        <clr-icon shape="times" size="30"></clr-icon>
                    </button>
                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        openNavigation: state.app.openNavigation,
        readOnlyMode: state.app.readOnlyMode,
        project: state.app.project.project,
        apartment: state.app.apartment2.apartment,
        currentRoomId: state.app.currentRoomId,
        currentRoom: state.app.currentRoom,
        roomsOrder: state.app.roomsOrder,
        rooms: state.app.rooms,
        visited: state.app.visited,
        selectionProgress: state.app.selectionProgress,
        linkedProducts: state.app.linkedProducts // Watch linkedProducts so that component is always up to date with downloaded data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectionProgressActions: bindActionCreators(selectionProgressActions, dispatch)
    }
}

MobileNavigation.propTypes = {
    resizeCallback: PropTypes.func.isRequired
};

export default withBus()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MobileNavigation))));
