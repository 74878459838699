import React, {Component} from 'react';
import "./style.scss"
import ProductService from "../../../../services/ProductService";
import ProductStatus from "../ProductStatus";
import PropTypes from "prop-types";
import ProductSelection from "../../../../models/ProductSelection";
import {withTranslation} from "react-i18next";
import HMImage from "../../../Utils/HMImage";
import {Link} from "react-router-dom";
import {Fade} from "reactstrap";
import ProductProperty from "../ProductProperty";
import ProductImageNotFound from "../../../../assets/images/productImageNotFound.png";
import GrandTotal from "../../../Utils/GrandTotal";
import UtilService from "../../../../services/UtilService";
import {withRouter} from "react-router-dom";

class ProductSelectionBox extends Component {

    constructor(props) {
        super(props);
        this.state = {statusBorderColorClass: "border-color-standard"};
    }

    componentDidMount() {
        if (this.props.productSelection.projectProduct) {
            this.setState({
                ...this.state,
                statusBorderColorClass: ProductService.getStatusStyle(this.props.productSelection.projectProduct).borderColorClass
            });
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        let statusBorderColorClass = "border-color-standard";
        if (this.props.productSelection.projectProduct) {
            statusBorderColorClass = ProductService.getStatusStyle(this.props.productSelection.projectProduct).borderColorClass;
        }

        if (statusBorderColorClass !== this.state.statusBorderColorClass) {
            this.setState({
                ...this.state,
                statusBorderColorClass: statusBorderColorClass
            });
        }
    }

    getIcon(item) {

        // Show user an error message for this nav item should the selected product be disabled and illegal from Product relationships
        if (item && item.hasError)
            return <div className="clr-white-background"><div></div><clr-icon shape="error-standard" class="is-solid text-danger"></clr-icon></div>;

        if (!item || item.finished)
            return <div className="clr-white-background"><div></div><clr-icon shape="success-standard" class="is-solid"></clr-icon></div>;

        if (item.partlyFinished)
            return <clr-icon shape="circle" class="is-solid"></clr-icon>;

        return <clr-icon shape="circle"></clr-icon>;
    }

    renderCard(t) {
        return <div className={`card h-100 hm-product-selection-box ${this.props.navigationItem && this.props.navigationItem.active ? 'selected': ''}`}
             onClick={() => this.props.onChange && this.props.onChange()}
        >
            <div className={`card-header ${this.state.statusBorderColorClass}`}>
                <ProductStatus projectProduct={this.props.productSelection.projectProduct} hidePrices={this.props.hidePrices}/>
                <div className="image-outer-wrapper">
                    <HMImage imageVisibilityType={'contain'} imageSrc={this.props.productSelection.projectProduct ? ProductService.getImageUrl(this.props.productSelection.projectProduct.product) : ProductImageNotFound}/>
                </div>
                <Fade in={!!this.props.navigationItem && !!this.props.navigationItem.continuationType} tag="div" className="overlay" mountOnEnter={true} unmountOnExit={true}>
                    <div className="hm-button btn hm-button-active green">{this.props.navigationItem  && this.props.navigationItem.continuationType ? t('selectionProgress.' + this.props.navigationItem.continuationType) : ' '}</div>
                </Fade>
            </div>

            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 category-and-status">
                        <div className="row no-gutters">
                            <div className="col-auto p-0 m-0">
                                {this.getIcon(this.props.navigationItem)}
                            </div>
                            <div className="col p-0 m-0">
                                <h4 className="uppercase">
                                    {this.props.navigationItem ? this.props.navigationItem.title : UtilService.capitalize(this.props.productSelection.subCategory.name)}
                                </h4>
                            </div>
                        </div>
                    </div>

                    {this.props.productSelection.projectProduct &&
                    <div className="col-12">
                        <h6 className="bold">{this.props.productSelection.projectProduct.product.vendor} - {this.props.productSelection.projectProduct.product.name}</h6>
                    </div>
                    }

                    {this.props.productSelection.projectProduct &&
                    <div className="col-12 product-properties">
                        <ProductProperty hideKey={false} propertyKey="common:product.property.color"
                                         property={this.props.productSelection.projectProduct.product.color}/>
                        <ProductProperty hideKey={false} propertyKey="common:product.property.material"
                                         property={this.props.productSelection.projectProduct.product.material}/>
                        <ProductProperty hideKey={false} propertyKey="common:product.property.assembly"
                                         property={this.props.productSelection.projectProduct.product.assembly}/>
                        <ProductProperty hideKey={false} propertyKey="common:product.property.dimension"
                                         property={this.props.productSelection.projectProduct.product.dimension}/>
                    </div>
                    }

                    {this.props.productSelection.projectProduct && this.props.productSelection.projectProduct.product.note &&
                    <div className="col-12 mt-2">
                        <ProductProperty hideKey={false} propertyKey="common:product.property.note"
                                         property={this.props.productSelection.projectProduct.product.note}/>
                    </div>
                    }
                </div>
            </div>

            {this.props.productSelection.projectProduct &&
            <div className="card-footer">
                {!this.props.hidePrices &&
                <div className="row no-gutters property-price">
                    <div className="col-4 bold italic pl-3">
                        {t('product.property.price')}
                    </div>
                    <div className="col-8 text-right pr-3">
                        <GrandTotal grandTotal={{
                            price: this.props.productSelection.price,
                            hasDelayedPrice: this.props.productSelection.getDelayedPrice()
                        }} showDelayedPrice={false}/>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    }

    onLinkClick() {
        if (this.props.onSelectAction) {
            this.props.onSelectAction();
        }

        if (this.props.navigationItem && this.props.navigationItem.linkUrl) {
            setTimeout(() => {
                this.props.history.push(this.props.navigationItem.linkUrl);
            });
        }
    }

    render() {
        const t = this.props.t;

        if (!this.props.productSelection)
            return (null);

        if (!this.props.productSelection.projectProduct && this.props.navigationItem && !this.props.navigationItem.continuationType)
            this.props.navigationItem.continuationType = 'extraProductNotSelected';

        if (this.props.navigationItem) {
            return (
                <a id={this.props.navigationItem.key} className="hm-product-selection-box-link" onClick={() => this.onLinkClick()}>
                    {this.renderCard(t)}
                </a>
            );
        } else {
            return (
                <div className="hm-product-selection-box-link">
                    {this.renderCard(t)}
                </div>
            );
        }
    }
}

ProductSelectionBox.propTypes = {
    hidePrices: PropTypes.bool,
    productSelection: PropTypes.instanceOf(ProductSelection).isRequired,
    onChange: PropTypes.func,
    locked: PropTypes.bool.isRequired,
    navigationItem: PropTypes.object,
    onSelectAction: PropTypes.func,
};

export default withRouter(withTranslation('common')(ProductSelectionBox));
