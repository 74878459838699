import React, {Component} from 'react';
import Product from "../../Product/Product";
import "./style.scss"
import ProductAlternativesService from "../../../../services/ProductAlternativesService";
import HMButton from "../../../Utils/HMButton";
import {bindActionCreators} from "redux";
import * as actions from "../../../../actions/productActions";
import ProductSelection from "../../../../models/ProductSelection";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import HeaderLabel from "../../../Utils/HeaderLabel";
import HMNextAction from "../../../Utils/HMNextAction";
import HMCostInfo from "../../../Utils/HMCostInfo";
import {AppRoute} from "../../../../services/RouterService";
import {withRouter} from "react-router-dom";
import ProductPickerInfoModal from "../../Product/ProductPickerInfoModal";
import {withBus} from 'react-bus';
import {HM_TOPBAR_OPEN_MOBILE_NAVIGATION} from "../../../../actions/eventTypes";

class CategoryPicker extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isShowModal: false,
            productSelection: undefined,
            projectProduct: undefined
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.synchronizeHashAndModals();
    }

    getMergedAlternatives () {
        const {productSelection} = this.props;
        const nonDisabledOptions = productSelection.options.filter((projectProduct) => {return !projectProduct.disabled});

        return ProductAlternativesService.buildProjectProductAlternativesMap(nonDisabledOptions, productSelection.projectProduct);
    }

    synchronizeHashAndModals() {
        if (!this.props.history.location.hash || this.props.history.location.hash.indexOf("productInfo") < 0) {
            if (this.state.isShowModal) {
                setTimeout(() => this.toggleShowProductInfo(false, undefined, undefined, false));
            }
        }
    }

    toggleShowProductInfo(isShowModal, productSelection = undefined, projectProduct = undefined, navigate = true) {
        if (this.state.isShowModal !== isShowModal) {
            this.setState({...this.state, isShowModal: isShowModal, productSelection: isShowModal ? productSelection : this.state.productSelection, projectProduct: isShowModal ? projectProduct : this.state.projectProduct});

            if (!isShowModal) {
                setTimeout(() => this.setState({...this.state, productSelection: undefined, projectProduct: undefined}), 300);
            }

            if (navigate) {
                if (isShowModal) {
                    if (this.props.history.location.hash.indexOf("productInfo") >= 0) {
                        this.props.history.replace(
                            this.props.history.location.pathname
                            + this.props.history.location.search
                            + this.props.history.location.hash.replace('productInfo', '')
                        );
                    }

                    this.props.history.push(
                        this.props.history.location.pathname
                        + this.props.history.location.search
                        + (this.props.history.location.hash.startsWith('#') ? this.props.history.location.hash : '#')
                        + 'productInfo');
                } else {
                    if (!!this.props.history.location.hash && this.props.history.location.hash.indexOf("productInfo") >= 0) {
                        this.props.history.goBack();
                    }
                }
            }
        }
    }

    render() {
        const { t, readOnly, productSelection, isFromPackageWithPrice } = this.props;

        // This product selection is fully disabled by relationships meaning it should not be visible at this moment
        if (!productSelection || productSelection.hasNoEnabledChoisesFromProductRelationships())
            return (null);

        return (
            <React.Fragment>
                <div className={`hm-category-picker`}>
                    {this.props.productChanging && <div className="blocking-overlay"></div>}
                    <div className="scrollable">
                        <HeaderLabel text={this.props.selectedNavigationItem.title}/>
                        <div className="product-list">
                            {this.getMergedAlternatives().map((mergeMap, i) => {
                                return <Product
                                    // TODO Before the key looked like this ${this.constructor.name}-${i}-${mergeMap.projectProduct.id}. But we don't want to trigger a component unmount just because the selected project product has changed (mergeMap.projectProduct)
                                    readOnly={readOnly}
                                    key={`${this.constructor.name}-${i}-${productSelection.id}`}
                                    selected={(productSelection.projectProduct && mergeMap.projectProduct.id === productSelection.projectProduct.id) ? true : false} //TODO not sure about this
                                    projectProduct={mergeMap.projectProduct} //TODO not sure about this
                                    alternatives={mergeMap.alternatives}
                                    productSelection={productSelection}
                                    showPrices={!isFromPackageWithPrice && !this.props.hidePrices}
                                    isFromPackageWithPrice={this.props.isFromPackageWithPrice}
                                    selectedNavigationItem={this.props.selectedNavigationItem}
                                    showInfo={() => { this.toggleShowProductInfo(true, productSelection, mergeMap.projectProduct); }}
                                />
                            })}
                            {this.props.resettable && !readOnly &&
                            <div className="row no-gutters">
                                <div className="col">
                                    <HMButton text={t('reset')}
                                              clickFn={() => this.props.actions.resetProductSelection(productSelection)}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {!this.props.hidePrices && <HMCostInfo currentRoutes={this.props.currentRoutes} onClick={this.props.isMobile ? () => {this.props.bus.emit(HM_TOPBAR_OPEN_MOBILE_NAVIGATION)} : null}/>}
                    <HMNextAction
                        selectedNavigationItem={this.props.selectedNavigationItem}
                        nextUrlLink={this.props.nextUrlLink}
                        appSizes={this.props.appSizes}
                    />
                </div>

                <ProductPickerInfoModal
                    hidePrices={this.props.hidePrices}
                    isOpen={this.state.isShowModal}
                    toggle={() => this.toggleShowProductInfo(false)}
                    productSelection={this.state.productSelection} projectProduct={this.state.projectProduct}
                    isMobile={this.props.isMobile}
                />
            </React.Fragment>
    )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        hidePrices: state.app.hidePrices,
        currentRoom: state.app.currentRoom, // Required to re render on product change
        roomRelationships: state.app.roomRelationships,
        appSizes: state.app.appSizes,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

CategoryPicker.propTypes = {
    readOnly: PropTypes.bool,
    resettable: PropTypes.bool,
    productSelection: PropTypes.instanceOf(ProductSelection), // The product selection for reference in replacement actions
    showPrices: PropTypes.bool,
    isFromPackageWithPrice: PropTypes.bool,
    selectedNavigationItem: PropTypes.object,
    nextUrlLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    productChanging: PropTypes.bool,
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};

export default withBus()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CategoryPicker))));
