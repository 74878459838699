import React, {Component} from 'react';
import * as imageActions from "../../../../actions/imageActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './style.scss'
import HMImage from "../../../Utils/HMImage";
import PackageService from "../../../../services/PackageService";
import PropTypes from "prop-types";

// This class represent a ProjectProduct but aware of all other project products thru it's metaProduct
class PackageCard extends Component {

    onShowMore(e = undefined) {
        if (this.props.showMore) {
            if (e) {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }

            this.props.showMore();
        }
    }

    render() {
        const {style} = this.props;

        return (
            <div className="row no-gutters hm-package-card">
                <div className="col-auto" onClick={e => this.onShowMore(e)}>
                    <HMImage imageSrc={PackageService.getStyleImageUrlFromStyle(style)}
                             className={`package-image`}
                             title={style.name}
                             imageVisibilityType={'contain'}
                    />
                </div>
                <div className="col package-description">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="text-uppercase">
                                <h5>{style.name}</h5>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row no-gutters">*/}
                        {/*<div className="col-12">*/}
                            {/*<div className="text-truncate">*/}
                                {/*<p>{project && project.project ? project.project.account.name : ""}</p>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        project: state.app.project
    }
}

function mapDispatchToProps(dispatch) {
    return {
        imageActions: bindActionCreators(imageActions, dispatch)
    }
}

PackageCard.propTypes = {
    style: PropTypes.object,
    showMore: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageCard);
