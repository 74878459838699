import React, {Component} from 'react';
import "./style.scss"

export default class HMInformationIcon extends Component {

    render() {
        return (
            <span className="hm-information-icon">
                i
            </span>
        )
    }
}
