import React, {Component} from 'react';
import "./style.css"
import PropTypes from "prop-types";
import GrandTotal from "../../Utils/GrandTotal";
import {withTranslation} from "react-i18next";
import HMImage from "../../Utils/HMImage";
import PackageService from "../../../services/PackageService";

class StyleSummary extends Component {

    render() {
        const { t } = this.props;

        if (!this.props.style || !this.props.style.hasStylePrice)
            return null;

        return (
            <div className={`hm-product row align-items-center px-0 border-color-style`}>
                <div className="col-10 px-0">
                    <div className="image-outer-wrapper">
                        <HMImage className="pointer"
                                 imageSrc={PackageService.getStyleImageUrlFromStyle(this.props.style)}
                                 imageVisibilityType={'contain'}
                                 onClick={() => this.props.onImageClicked && this.props.onImageClicked()}/>
                    </div>
                    <div className="justify-content-center">
                        <h5 className="my-0 px-0 uppercase">{this.props.style.name}</h5>
                    </div>
                </div>

                {!this.props.hidePrices &&
                <div className="col-1 px-0">
                    <p className="italic">{t('common:cost')}</p>
                    <p className="bold">
                        <GrandTotal grandTotal={{price: this.props.style.stylePrice, hasDelayedPrice: false}} showDelayedPrice={false}/>
                    </p>
                </div>
                }

                <div className={`${this.props.hidePrices ? 'col-2' : 'col-1'} px-0 h-100 align-self-start text-right`}>
                    <div className="hm-product-status">
                        <span className="color-style">{t('summary.style')}</span>
                    </div>
                </div>
            </div>
        )
    }
}

StyleSummary.propTypes = {
    style: PropTypes.object.isRequired,
    onImageClicked: PropTypes.func
};

export default withTranslation('common')(StyleSummary);
