import React, {Component} from 'react';
import "./style.scss"
import {withTranslation} from "react-i18next";
import GrandTotal from "../GrandTotal";
import {connect} from "react-redux";
import {AppRoute, RouterService} from "../../../services/RouterService";
import CostDropdown from "../../App/Header/TopBar/CostDropdown";
import PropTypes from "prop-types";

class HMCostInfo extends Component {

    render() {
        const { t } = this.props;

        return (
            <div className={`hm-cost-info ${this.props.onClick ? 'clickable' : ''}`} onClick={() => {this.props.onClick && this.props.onClick();}}>
                <span className="cost-label">{t('cost_options')}:</span>
                <h3 className="cost-value normalcase">
                    <GrandTotal grandTotal={this.props.grandTotal}/>
                </h3>
                {this.props.onClick &&
                <clr-icon class="cost-chevron" shape="angle up"></clr-icon>
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        grandTotal: state.app.grandTotal,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

HMCostInfo.propTypes = {
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired,
    onClick: PropTypes.func,
};

export default connect(mapStateToProps)(withTranslation('common')(HMCostInfo));
