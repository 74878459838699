import React, {Component} from 'react';
import {connect} from "react-redux";

class ErrorMessageBar extends Component {

    render() {
        return this.props.errors && this.props.errors.length > 0 ? (
            <div>
                {this.props.errors.map ((error) => {
                    return <h4 key={error.key}>{error.msg}</h4>
                })}
            </div>
        ) : null
    }
}

function mapStateToProps(state, ownProps) {
    return {
        errors: state.app.errors
    }
}
export default connect(mapStateToProps)(ErrorMessageBar);
