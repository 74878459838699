import ProductSelection from "../models/ProductSelection";
import Axios from "axios"
import RoomService from "../services/RoomService";
import {Config} from "../config";
import AuthService from "../services/AuthService";
import store from "../reducers/store"
import LogService from "../services/LogService";
import {userBecameUnauthorized} from "../actions/authActions";
import AuthorizedUserService from "../services/AuthorizedUserService";
import ProjectService from "../services/ProjectService";

export default class BackendApi {

    static fetchMe() {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/me`).then(me => {
            return me;
        });
    }

    static fetchProjectById(projectId) {
        return this.doFetch(Config.API_BASE_URL + '/customerShowroom/project/' + projectId).then(project => {
            if (!project) return project;

            return ProjectService.processProjectImages(project);
        });
    }

    static fetchProjectByPublicKey(projectKey, keyType) {
        return this.doFetch(`${Config.API_BASE_URL}/publicShowroom/getProjectLandingPage?${keyType}=${projectKey}`).then(response => {
            if (response && response.project) {
                response.project.apartments = response.templates || [];

                ProjectService.processProjectImages(response.project);
            }

            return response.project;
        });
    }

    static fetchApartmentById(apartmentId) {
        return this.doFetch(Config.API_BASE_URL + '/customerShowroom/apartment/' + apartmentId).then(apt => {
            return apt;
        });
    }

    static fetchApartmentByPublicKey(readOnlyKey) {
        return this.doFetch(`${Config.API_BASE_URL}/publicShowroom/findDemoTemplateByKey?key=${readOnlyKey}`).then(templates => {
            return templates;
        });
    }

    static fetchRoom(id) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/apartmentRoom/${id}`).then(room => {
            return this._parseRoom(room);
        });
    }

    // Read only version of above
    static fetchReadOnlyRoom(id, readOnlyKey) {
        return this.doFetch(`${Config.API_BASE_URL}/publicShowroom/getApartmentRooms/${id}?key=${readOnlyKey}`).then(room => {
            return this._parseRoom(room);
        });
    }

    static fetchMetaProduct(id, productSelectionId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/metaProduct/${id}`).then(metaProduct => {
            return metaProduct;
        });
    }
    // Demo version of above
    static fetchReadOnlyMetaProduct(id, productSelectionId, key) {
        return this.doFetch(`${Config.API_BASE_URL}/publicShowroom/getMetaProduct/${id}?productSelectionId=${productSelectionId}&key=${key}`).then(metaProduct => {
            return metaProduct;
        });
    }

    static selectPackage(activeStyleId, newStyleId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/selectPackage`, {activeStyle: {id: activeStyleId}, newStyle: {id: newStyleId}}).then(activeStyle => {
            return activeStyle;
        });
    }

    static fetchReadOnlyBuildFakeProductSelectionsForStyleInRoom(styleId, roomId, key) {
        return this.doFetch(`${Config.API_BASE_URL}/publicShowroom/buildFakeProductSelectionsForStyleInRoom/${styleId}?roomId=${roomId}&key=${key}`).then(incompleteProductSelections => {
            return incompleteProductSelections ? incompleteProductSelections.map ((jsonPs) => {
                return new ProductSelection().parse(jsonPs);
            }) : [];
        });
    }

    static fetchBuildFakeProductSelectionsForStyleInRoom(styleId, roomId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/buildFakeProductSelectionsForStyleInRoom/${styleId}?roomId=${roomId}`).then(incompleteProductSelections => {
            return incompleteProductSelections ? incompleteProductSelections.map ((jsonPs) => {
                    return new ProductSelection().parse(jsonPs);
                }) : [];
        });
    }

    static selectProjectProduct(productSelectionId, projectProductId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/selectProduct/${productSelectionId}`, {projectProduct: {id: projectProductId}}).then(productSelection => {
            return new ProductSelection().parse(productSelection);
        });
    }

    static resetProductSelection(productSelectionId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/resetProductSelection/${productSelectionId}`).then(productSelection => {
            return new ProductSelection().parse(productSelection);
        });
    }

    static resetRoomSelection(apartmentId, roomId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/resetSelectionsInRoom?apartmentId=${apartmentId}&roomId=${roomId}`);
    }

    static fetchReadOnlyLinkedProductsMap(groupId, key) {
        return this.doFetch(`${Config.API_BASE_URL}/publicShowroom/getProductRelationshipGroup/${groupId}?key=${key}`).then(productRelationshipGroup => {
            return productRelationshipGroup.relationships;
        });
    }

    static fetchLinkedProductsMap(groupId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/productRelationshipGroup/${groupId}`).then(productRelationshipGroup => {
            return productRelationshipGroup.relationships;
        });
    }

    static downloadLeadForm(apartmentKey) {
        return this.doFetch(`${Config.API_BASE_URL}/lead/getFormSchema?key=${apartmentKey}`)
    }

    static registerLead(apartmentKey, lead, hidePrices) {
        return this.doFetch(`${Config.API_BASE_URL}/lead/create?key=${apartmentKey}&hidePrices=${hidePrices}`, lead);
    }

    static getPaymentStatus(apartmentId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/getPaymentStatus/${apartmentId}`, undefined, true)
    }

    static getApartmentConfirmationMessage(apartmentId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/getApartmentConfirmationMessage/${apartmentId}`)
    }

    static addonCheckout(apartmentId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/checkout/${apartmentId}`)
    }

    // Parser for the room endpoints
    static _parseRoom(room) {
        // Parse to instances of ProductSelections , ProjectProduct, Product and others
        RoomService.parse(room);
        return room;
    }

    static doFetch (url, jsonBody = undefined, includeFullResponse = false) {

        const headers = {};

        const token = AuthService.getAuthentication();
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        const selectedCustomer = AuthorizedUserService.getSelectedCustomer();
        if (selectedCustomer) {
            headers['X-Brightlabs-HMT-Selected-Customer'] = '' + selectedCustomer;
        }

        var options = {headers: headers};

        if (jsonBody)
            options = {...options,
                method: "POST",
                headers: {
                    ...headers,
                    "Content-Type": "application/json; charset=utf-8"
                },
                data: JSON.stringify(jsonBody)
            };

        return Axios(url, options).then(response => {
            return includeFullResponse ? response : response.data;
        }).catch((error) => {

            if (!includeFullResponse) {
                // log errors only if the caller is not handling full response
                LogService.error(error, "BackendApi");
                console.log(url, error.response, error.message);
            }

            if (error.message && error.message === "Network Error") {
                // TODO: handle - server not available
                console.error("Server not available...")
            }

            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        // Broadcast unauthorized event async
                        setTimeout(() => {
                            store.dispatch(userBecameUnauthorized());
                        });
                        break;

                    case 404:
                        break;

                    default:
                        throw error;
                }
            } else {
                // rethrow - app should handle it
                throw error;
            }
        });
    }

    static fetchDefaultMessageBoxGreeting(apartmentId) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/getDefaultMessageBoxGreeting/${apartmentId}`);
    }

    static markMessagesAsReadByCustomer(messageIds) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/markMessagesAsReadByCustomer`, messageIds);
    }

    static sendNewMessage(newMessage) {
        return this.doFetch(`${Config.API_BASE_URL}/customerShowroom/createMessage`, newMessage);
    }
}
