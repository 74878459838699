import React, {Component} from 'react';
import Slider from "react-slick/lib";
import PropTypes from "prop-types";
import './style.scss';
import PrettyBytes from 'pretty-bytes';
import Mime from 'mime-types';

class FileSlider extends Component {

    extensionToImage(extension) {
        switch (extension) {
            case "doc":
            case "docx":
                return "fa-file-word";

            case "zip":
            case "rar":
                return "fa-file-archive";

            case "wav":
            case "mp3":
                return "fa-file-audio";

            case "jpg":
            case "jpeg":
            case "png":
            case "bmp":
            case "svg":
                return "fa-file-image";

            case "avi":
                return "fa-file-video";

            case "xls":
            case "xlsx":
                return "fa-file-excel";

            case "pdf":
                return "fa-file-pdf";

            case "ppt":
            case "pptx":
                return "fa-file-powerpoint";


            case "bin":
                return "fa-file-alt";

            default:
                return "fa-file";
        }
    }

    render() {

        const sliderSettings = {
            arrows: true,
            autoplay: false,
            draggable: true,
            fade: false,
            initialSlide: 0,
            infinite: false,
            slidesToShow: 6,
            swipe: true,
            touchMove: true,
            adaptiveHeight: true,
            responsive: [...Array(8).keys()].map(i => { return {
                breakpoint: 250 * (i+1),
                settings: {
                    slidesToShow: (i+1)
                }
            }})
        };

        if (!this.props.attachments || this.props.attachments.length === 0)
            return null;

        return (
        <div className={`hm-file-slider hm-landing-page-section-fullWidthAttachments row justify-content-center ${this.props.className || ''}`}>
            <Slider className="col-12 file-slider align-items-center"
                    {...sliderSettings}
            >
                {this.props.attachments.sort((a,b) => { return a.id - b.id}).map((attachment, i) => {
                    return (
                        <a key={`attachment-${attachment.id}`} href={attachment.url} title={attachment.friendlyName} target="_blank">
                            <div  className="card text-center">
                                <div className="card-header">
                                    <i className={`far fa-3x ${this.extensionToImage(Mime.extension(attachment.contentType) || 'bin')}`}></i>
                                </div>
                                <div className="card-body">
                                    <div>
                                        {attachment.friendlyName}
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <span className="uppercase">{Mime.extension(attachment.contentType) || 'bin'}</span>, {PrettyBytes(attachment.size)}
                                </div>
                            </div>
                        </a>
                    );
                })}
            </Slider>
        </div>
        )
    }
}

FileSlider.propTypes = {
    className: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default FileSlider;
