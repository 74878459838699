import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

class ChatDropdownMessage extends Component {

    render() {

        if (!this.props.message)
            return null;

        const { t, message } = this.props;

        return (
            <div className={`row chat-message`}>
                <div className={`col-12 message-balloon ${!message.isPlacedByUser ? 'message-balloon-other' : ''} ${!message.isReadByCustomer ? 'unread-message-balloon' : ''}`}>
                    <p dangerouslySetInnerHTML={{__html: message.text.replace(/(\r\n|\r|\n)/g, '<br/>')}}></p>
                </div>
                <div className={`col-12 message-balloon-details text-truncate ${!message.isPlacedByUser ? 'message-balloon-details-other' : ''}`}>
                    <div>
                        <p className="text-truncate">{message.dateCreated ? t('dateWithTime', {date: message.dateCreated}) : ''}{message.dateCreated && message.owner ? ' ' + String.fromCharCode(8212) + ' ' : ''}{message.owner ? message.owner.name : ''}</p>
                    </div>
                </div>
            </div>
        )
    }
}

ChatDropdownMessage.propTypes = {
    message: PropTypes.object.isRequired
};


export default withTranslation('common')(ChatDropdownMessage);
