// fix for mobile devices (https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser)
// setting `--app-height` variable available in CSS via var(--app-height) - use it together with 100vh as vars are not available in IE11

const appHeight = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
};

let timeoutSubscription;

window.addEventListener('resize', () => {
  clearTimeout(timeoutSubscription);
  timeoutSubscription = setTimeout(appHeight, 100);
});

appHeight();
