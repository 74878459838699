import React, {Component} from 'react';

class NotFound extends Component {

    render() {
        return <h1>Wops! Where're you think you're going??</h1>
    }
}

export default NotFound;
