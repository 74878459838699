import ProductService from "./ProductService";
import UtilService from "./UtilService";
import LogService from "./LogService";
import {AppRoute} from "./RouterService";

export class RoomService {
    /**
     * The purpose of this method is to gather all SceneImages that are choosen in room.
     * Convenient for rendering all selected products on page load
     * @param room
     * @returns {[SceneProduct]}
     */
    static filterSelectedByRoomScene(room) {
        const sceneProducts = [];

        // add selected scene products
        ProductService.getAllProductSelectionsInRoom(room).forEach((selection) => {
            // find matching scene product
            const match = room.scene.products.find((sceneProduct) => {
                return selection.projectProduct
                    && sceneProduct.product
                    && (sceneProduct.product.id === selection.projectProduct.product.id);
            });

            if (match)
                sceneProducts.push(match);
        });

        // add always applied scene products
        room.scene.products.forEach(sceneProduct => {
            if (sceneProduct.isAlwaysApplied) {
                sceneProducts.push(sceneProduct);
            }
        });

        return sceneProducts.sort((a,b) => {

            // New implementation of isAlwaysApplied is missing product
            if (a.product && b.product)
                return a.product.id - b.product.id;
            else
                return !a.product && !b.product ? 0 : (!a.product ? -1 : 1);
        });
    }

    /**
     *
     * @param currentRoomId this is the origin room id
     * @param roomsOrder should be an array of room ids
     * @param steps up or down in index, e.g. 1 or -2
     * @returns the id of the neighbouring room
     */
    static getNeighbouringRoomId(currentRoomId, roomsOrder, steps) {
        const currentIndex = roomsOrder.indexOf(currentRoomId);

        if (currentIndex === -1)
            return null;

        const newIndex = currentIndex + steps;

        if (newIndex in roomsOrder)
            return roomsOrder[newIndex];
        else
            return null;
    }

    /**
     *
     * @param currentRoomId this is the origin room id
     * @param roomsOrder should be an array of room ids
     * @param steps up or down in index, e.g. 1 or -2
     * @param rooms table with rooms
     * @returns the id of the neighbouring room that is not locked
     */
    static getNeighbouringNotLockedRoomId(currentRoomId, roomsOrder, steps, rooms) {
        const currentIndex = roomsOrder.indexOf(currentRoomId);

        if (currentIndex === -1)
            return null;

        let offset = 0;
        while (true) {
            const newIndex = currentIndex + steps + offset;

            if (newIndex in roomsOrder) {
                const newRoom = rooms.find(r => r.id === roomsOrder[newIndex]);
                if (!newRoom || !newRoom.isLocked) {
                    return roomsOrder[newIndex];
                } else {
                    offset += steps > 0 ? 1 : -1;
                }
            }
            else
                return null;
        }
    }

    static getNextRoomId(roomsOrder, currentRoomId) {
        if (!roomsOrder || !currentRoomId || roomsOrder.length === 0) return undefined;

        switch (currentRoomId) {
            case AppRoute.Summary:
            case AppRoute.Checkout:
            case AppRoute.Confirmation:
                return undefined;
            default:
                const currIndex = roomsOrder.indexOf(currentRoomId);
                if (currIndex < 0)
                    return undefined;

                // last room - next one is Summary
                if (currIndex === roomsOrder.length - 1) {
                    return AppRoute.Summary;
                }

                return roomsOrder[currIndex + 1];
        }
    }

    static getPrevRoomId(roomsOrder, currentRoomId) {
        if (!roomsOrder || !currentRoomId || roomsOrder.length === 0) return undefined;

        switch (currentRoomId) {
            case AppRoute.Summary:
                // previous room is last from rooms order
                return roomsOrder[roomsOrder.length - 1];
            case AppRoute.Checkout:
            case AppRoute.Confirmation:
                return undefined;
            default:
                const currIndex = roomsOrder.indexOf(currentRoomId);
                if (currIndex <= 0)
                    return undefined;

                return roomsOrder[currIndex - 1];
        }
    }

    static parse (room) {
        if (!room) return;

        ProductService.parseAllSceneProductsInRoom(room);
        ProductService.parseAllProductSelectionsInRoom(room)
    }

    // This way wer can resume progress if user reloads or come back
    static persistLastRoomToLocalStorage(apartmentId, roomId) {
        UtilService.persistToLocalStorage(`apartment_${apartmentId}.last_room_id`, roomId);
        LogService.log(`Last visited room ${roomId} in apartment ${apartmentId} stored in localStorage`, "RoomService");
    }

    // This way wer can resume progress if user reloads or come back
    static getLastRoomFromLocalStorage(apartmentId) {
        return UtilService.getFromLocalStorage(`apartment_${apartmentId}.last_room_id`);
    }
}

export default RoomService;
