import React, {Component} from 'react';
import "./style.css"
import {connect} from 'react-redux';

class ProjectBackgroundImage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            productBackgroundImage: "https://s3-eu-west-1.amazonaws.com/hm-tillval-assets/brands/tillval/bg.jpg"
        }
    }


    componentDidMount() {
        this.updateBackgroundImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateBackgroundImage();
    }

    updateBackgroundImage() {
        if (this.props.project && this.props.project.backgroundImage && this.props.project.backgroundImage.url) {
            if (this.state.productBackgroundImage !== this.props.project.backgroundImage.url) {
                this.setState({...this.state, productBackgroundImage: this.props.project.backgroundImage.url})
            }
        } else {
            if (this.state.productBackgroundImage !== "https://s3-eu-west-1.amazonaws.com/hm-tillval-assets/brands/tillval/bg.jpg")
            this.setState({...this.state, productBackgroundImage: "https://s3-eu-west-1.amazonaws.com/hm-tillval-assets/brands/tillval/bg.jpg"})
        }
    }

    render() {
        return (
            <div className="hm-project-background" style={{backgroundImage: `url('${this.state.productBackgroundImage}')`}}/>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        project: state.app.project.project
    }
}

export default connect(mapStateToProps)(ProjectBackgroundImage)

