import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import HMButton from "../../../Utils/HMButton";
import RouterService from "../../../../services/RouterService";

class ProcessFinishedCallToAction extends Component {

    render() {
        const { t } = this.props;

        return(
            <div className="row m-0">
                <div className="col p-0">
                    <p>{t('apartmentLandingPage.addonProcessFinished')}</p>
                    <div className="call-to-action-button">
                        <HMButton text={t('apartmentLandingPage.callToActions.gotoSummary')}
                                  active={true}
                                  activeText={t('apartmentLandingPage.callToActions.gotoSummary')}
                                  href={RouterService.summaryPath()}
                                  clickFn={() => {}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('common')(ProcessFinishedCallToAction);
