import {Config} from "../config";

export default class LogService {

    static log(message, source = null) {
        if (Config.VERBOSE_LOGGING)
            console.log(this._buildMessage(source, message));
    }

    static error(message, source = null) {
        console.error(this._buildMessage(source, message));
    }

    static _buildMessage(source, message) {
        return `${Date.now()} - ${source ? source + ": " : ""}${message}`
    }
}