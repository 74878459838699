import React, {Component} from 'react';
import "./style.scss"
import TransparentBackground from "../../../assets/transparentBackground.png";
import PropTypes from "prop-types";
import {CSSTransition, TransitionGroup} from "react-transition-group";

class HMImage extends Component {

    _unmounted = false;
    _loading = false;

    constructor(props) {
        super(props);

        this.state = {
            imageSrc: TransparentBackground
        }
    }

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.imageSrc !== this.props.imageSrc)
            this.loadImage();
    }

    componentWillUnmount() {
        this._unmounted = true;

        if (this._loadedTimeout) {
            clearTimeout(this._loadedTimeout);
            this._loadedTimeout = undefined;
        }
    }

    loadImage() {
        if (this.props.imageSrc !== this.state.imageSrc) {
            if (!this.props.imageSrc) {
                this.imageLoadingError();
                return;
            }

            this._loading = this.props.imageSrc;
            let image = new Image();
            image.addEventListener('load', () => this.imageLoaded(image), false);
            image.addEventListener('error', () => this.imageLoadingError(), false);
            image.src = this.props.imageSrc;
            this.props.onLoading && this.props.onLoading();
        }
    }

    _loadedTimeout = undefined;
    imageLoaded(image) {
        if (this._unmounted || this._loading !== this.props.imageSrc)
            return;


        if (this._loadedTimeout) {
            clearTimeout(this._loadedTimeout);
        }

        this._loadedTimeout = setTimeout(() => {
            this._loadedTimeout = undefined;
            this.props.onLoaded && this.props.onLoaded(image.naturalHeight, image.naturalWidth);

            this.setState({...this.state, imageSrc: this._loading});
            this._loading = false;

        }, 50);
    }

    imageLoadingError() {
        if (this._unmounted || this._loading !== this.props.imageSrc) return;

        this.setState({...this.state, sceneSrc: TransparentBackground});
        this._loading = false;
        this.props.onError && this.props.onError();
    }

    render() {
        const backgroundImageUrl = `url('${this.state.imageSrc}')`;

        return (
            <div
                className={`hm-image ${this.props.className || ''}`}
                onClick={() => this.props.onClick && (this.props.onClick() || true)}
                title={this.props.title}>

                <TransitionGroup
                    className={"h-100"}
                >
                    <CSSTransition
                        key={this.state.imageSrc}
                        timeout={250}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        classNames={{
                            enter: 'animated animated-short',
                            enterActive: 'fadeIn',
                            exit: '',
                            exitActive: 'behind'
                        }}
                    >
                        <div className="hm-image-background" style={{backgroundImage: backgroundImageUrl, backgroundSize: this.props.imageVisibilityType}}/>
                    </CSSTransition>
                </TransitionGroup>

                {(this.props.overlayText || this.props.children) &&
                <div className="hm-image-overlay"
                     onClick={() => this.props.onClick && (this.props.onClick() || true)}>
                    {this.props.overlayText && <h4>{this.props.overlayText}</h4>}
                    {!this.props.overlayText && this.props.children}
                </div>
                }

            </div>
        )
    }
}

HMImage.propTypes = {
    className: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    imageVisibilityType: PropTypes.oneOf(['cover', 'contain']),
    onClick: PropTypes.func,
    onLoading: PropTypes.func,
    onLoaded: PropTypes.func,
    onError: PropTypes.func,
    overlayText: PropTypes.string,
    title: PropTypes.string
};

HMImage.defaultProps = {
    imageVisibilityType: 'cover',
};

export default HMImage;
