import * as actionTypes from '../actions/actionTypes'
import SceneService from '../services/SceneService'
import RoomService from '../services/RoomService'
import ProductService from "../services/ProductService";
import {Config} from "../config";
import LogService from "../services/LogService";
import ProductRelationshipService from "../services/ProductRelationshipService";
import AuthService from "../services/AuthService";
import {AppRoute} from "../services/RouterService";
import AuthorizedUserService from "../services/AuthorizedUserService";
import PackageService from "../services/PackageService";
import {SelectionProgressService} from "../services/SelectionProgressService";
import {TutorialService} from "../services/TutorialService";
import CookiesService from "../services/CookiesService";
import UtilService from "../services/UtilService";
import {ADDON} from "../enums/appModes";

export const bootstrapBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1500,
    order: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    currentBreakpoint: (width) => {
        return bootstrapBreakpoints.order
            .find((b, i) => width >= bootstrapBreakpoints[b] && (i === bootstrapBreakpoints.order.length - 1 || width < bootstrapBreakpoints[bootstrapBreakpoints.order[i + 1]] - 1));
    }
};
const mobileWidth = bootstrapBreakpoints.lg;

// This is the state machine. All states that will and can occur should be mapped and explained here in the initial state
const initialState = {
    cookies: CookiesService.loadCookies() || {
        accepted: false,
    },

    appMode: null, // Is this a showroom (offline mode for demo apartments) or addon (Api mode for authorized customers)
    singleApartment: false, // flag for single apartment mode

    readOnlyMode: false, // Is the mode readonly
    hidePrices: false, // Prices can be hidden (Preferable for sales start, but probably all readonly modes)
    pricePerMonth: false,
    openNavigation: false, // Navigation should by default be locked and not allow going to summary before finishing all rooms

    loading: 0, // global loading flag, nice to have a application wide variable for showing loaders
    loadingMessages: [], // optional messages to display on loading screen

    // current state of the app UI - sizes, sizes of the top bar etc.
    appSizes: {
        mobileWidth: mobileWidth,
        app: {
            width: 0,
            height: 0,
            isLandscape: true,
            isPortrait: false,
            isMobile: false,
            bootstrapBreakpoint: 'xs'
        },
        topBar: {
            width: 0,
            height: 0
        }
    },

    // Authentication
    auth: {
        username: undefined, // In a reset / activate password scenario this variable enables us to auto login user
        resetLinkSent: false,
        authorized: false,
        invalidCredentials: false,
    },
    enviseId: undefined,

    me: undefined, // TODO: Should be unified with ME_RECEIVE event and EnviseId
    meFetchingError: false,

    project: {
        projectId: undefined, // project id: id for ADDON mode, key for other modes
        project: undefined, // project json
        notFound: false // project not found flag,
    },

    // TODO: remove apartment and rename
    apartment2: {
        apartmentId: undefined, // apartment id: id for ADDON mode, key for other modes
        apartment: undefined, // apartment json
        notFound: false // apartment not found flag
    },

    currentRoom: undefined, // Current room (json contains the productSelections and styles)
    currentRoomId: undefined, // id of the current room (if real room), AppRoute for other states (Summary, Checkout, Confirmation)

    rooms: new Map(), // This is a offline collection of the changes made to other rooms. key(roomId): value (room object)
    roomsOrder: [], // To keep track of the order of rooms
    visited: [], // To keep track of the visited rooms
    grandTotal: undefined, // This is an object, For all rooms,
    productChanging: false, // product changing operation in progress
    openProductCategory: null, // Vital for the floating product picker of 2D rooms, but also used as an indicator in 3D
    openPackage: null,
    seenRooms: {}, // To keep track of seen rooms by the customer in the current session - it should not be stored in the local storage

    selectionProgress: SelectionProgressService.loadSelectionProgress(),
    selectionProgressRoomCompletion: SelectionProgressService.loadSelectionProgressRoomCompletion(),

    // Basically all these are for the relationships
    productStorage: new Map(), // Calculated once, should we keep this?
    roomRelationships: new Map(), // Calculated once Map<roomId><Map<prodId><RelationShipObject>>
    linkedProducts: new Map(), //TODO delete me? This map contains the adding and disabling product relationships / Linked products, key(roomId): value (maps)
    errors:[], // Array of error objects for direct user feedback

    // Contact form
    leadSending: false,
    leadSent: false,
    leadFormSchema: undefined,

    // Lightbox
    lightbox: undefined,

    currency: {
        currency: 'SEK',
        pattern: '#,##0 !',
        patternDelayedPrice: '#,##0 !*',
    },

    tutorialProgress: TutorialService.loadProgressFromLocalStorage()
};

const inBrowserScenes = ['ikanosverige_1_grundbild.pfs', 'SOLURET_1_GRUNDBILD.pfs', 'STRAND_1_GRUNDBILD.pfs'];
let lastInBrowserPromise = null;

// Below are the different events that will fire and change the app states
const rootReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.COOKIES_ACCEPTED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                cookies: {accepted: true},
            };
        }

        // Is this a showroom (offline mode for demo apartments) or addon (Api mode for authorized customers)
        case actionTypes.MODE_SET: {
            console.log(`Showroom by Envise: V.${Config.VERSION}\nBooting: ${action.payload.mode} MODE`);

            LogService.log(action.type, "Reducer");

            const readOnlyMode = Config.READ_ONLY_MODES.includes(action.payload.mode);
            const hidePrices = Config.HIDE_PRICES_MODES.includes(action.payload.mode);
            const openNavigation = Config.OPEN_NAVIGATION_MODES.includes(action.payload.mode);

            return {
                ...state,
                appMode: action.payload.mode,
                singleApartment: action.payload.singleApartment,
                readOnlyMode: readOnlyMode,
                hidePrices: hidePrices,
                openNavigation: openNavigation
            };
        }

        case actionTypes.APP_LOADING: {
            LogService.log(action.type, "Reducer");

            const message = action.payload.message;

            return {
                ...state,
                loading: state.loading + 1,
                loadingMessages: message && !state.loadingMessages.some(m => m.key === message.key)
                    ? [...state.loadingMessages, message]
                    : state.loadingMessages
            };
        }

        // The app is now ready (finished starting), tell subscribers
        case actionTypes.APP_READY: {
            LogService.log(action.type, "Reducer");

            const message = action.payload.message;

            return {
                ...state,
                loading: Math.max(state.loading - 1, 0),
                loadingMessages: message ? state.loadingMessages.filter(m => m.key !== message.key) : state.loadingMessages
            };
        }

        case actionTypes.ME_RECEIVE: {
            LogService.log(action.type, "Reducer");

            AuthorizedUserService.removeSelectedCustomer();

            const payload = action.payload;
            const enviseId = `HMT.${payload.userId}.${payload.identityId}`;

            // Track this
            UtilService.sDTrack(enviseId, `Login Showroom [${state.appMode}]`);

            return {
                ...state,
                enviseId: enviseId,
                me: payload.me
            };
        }

        case actionTypes.ME_RECEIVE_ERROR: {
            LogService.log(action.type, "Reducer");

            AuthorizedUserService.removeSelectedCustomer();

            return {
                ...state,
                meFetchingError: true
            };
        }

        case actionTypes.APP_SIZES_CHANGED: {
            LogService.log(action.type, "Reducer");

            const appSizes = {
                mobileWidth: mobileWidth,
                app: {
                    width: action.payload.app.width,
                    height: action.payload.app.height,
                    isLandscape: action.payload.app.width > action.payload.app.height,
                    isPortrait: action.payload.app.width <= action.payload.app.height,
                    isMobile: action.payload.app.width < mobileWidth,
                    bootstrapBreakpoint: bootstrapBreakpoints.currentBreakpoint(action.payload.app.width)
                },
                topBar: {
                    width: action.payload.topBar.width,
                    height: action.payload.topBar.height
                }
            };

            document.documentElement.style.setProperty('--app-screen-height', `${appSizes.app.height}px`);
            document.documentElement.style.setProperty('--app-screen-width', `${appSizes.app.width}px`);
            document.documentElement.style.setProperty('--app-topBar-height', `${appSizes.topBar.height}px`);
            document.documentElement.style.setProperty('--app-subNav-height', `${appSizes.app.isMobile ? 32 : 48}px`);

            return {
                ...state,
                appSizes: appSizes
            };
        }

        case actionTypes.PROJECT_LOADING: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                project: {
                    projectId: action.payload.projectId,
                    project: undefined,
                    notFound: false,
                }
            }
        }


        case actionTypes.PROJECT_LOADED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                pricePerMonth: action.payload.project.settings.hasOwnProperty("pricePerMonth") ? action.payload.project.settings["pricePerMonth"] : false,
                project: {
                    projectId: action.payload.projectId,
                    project: action.payload.project,
                    notFound: false,
                }
            }
        }

        case actionTypes.PROJECT_NOT_FOUND: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                project: {
                    projectId: action.payload.projectId,
                    project: undefined,
                    notFound: true,
                }
            }
        }

        case actionTypes.APARTMENT_LOADING: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                apartment2: {
                    apartmentId: action.payload.apartmentId,
                    apartment: undefined,
                    notFound: false,
                },

                // reset other state:
                currentRoom: undefined,
                currentRoomId: undefined,

                rooms: new Map(),
                roomsOrder: [],
                visited: [],
                grandTotal: undefined,
                openProductCategory: null,

                productStorage: new Map(),
                roomRelationships: new Map(),
                linkedProducts: new Map()
            }
        }


        case actionTypes.APARTMENT_LOADED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                apartment2: {
                    apartmentId: action.payload.apartmentId,
                    apartment: action.payload.apartment,
                    notFound: false,
                }
            }
        }

        case actionTypes.APARTMENT_NOT_FOUND: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                apartment2: {
                    apartmentId: action.payload.apartmentId,
                    apartment: undefined,
                    notFound: true,
                }
            }
        }

        case actionTypes.LOCK_APARTMENT: {
            LogService.log(action.type, "Reducer");

            // as apartment is locked - clear selection progress for all app modes except ADDON
            const newSelectionProgress = state.appMode !== ADDON ? undefined : state.selectionProgress;

            return {
                ...state,
                apartment2: {
                    ...state.apartment2,
                    apartment: {
                        ...state.apartment2.apartment,
                        isLocked: true
                    }
                },
                selectionProgress: newSelectionProgress
            }
        }

        case actionTypes.LOCK_ALL_ROOMS: {
            LogService.log(action.type, "Reducer");

            const rooms = state.rooms;

            rooms.forEach((room) => {
                room.isLocked = true;
            });

            // as all rooms are locked - clear selection progress for all app modes except ADDON
            const newSelectionProgress = state.appMode !== ADDON ? undefined : state.selectionProgress;

            return {
                ...state,
                rooms: rooms,
                selectionProgress: newSelectionProgress
            }
        }


        case actionTypes.MESSAGES_UPDATED: {
            LogService.log(action.type, "Reducer");

            if (!state.apartment2.apartment || !action.payload)
                return state;


            let messages = [...(state.apartment2.apartment.messages || [])];

            action.payload.forEach(msg => {
                let existingIndex = messages.findIndex(m => m.id === msg.id);

                if (existingIndex >= 0) {
                    messages[existingIndex] = msg;
                } else {
                    messages.push(msg);
                }
            });

            let newState = {...state};

            newState.apartment2.apartment.messages = messages;
            return newState;
        }


        // The scene has started loading, tell subscribers
        case actionTypes.SCENE_LOADING: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                loading: state.loading + 1,
            };
        }

        // The scene has finish loading, tell subscribers
        case actionTypes.SCENE_LOADED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                loading: Math.max(0, state.loading - 1),
            };
        }


        // A new room was received from API, store it in state
        case actionTypes.ROOM_RECEIVE: {
            LogService.log(action.type, "Reducer");

            const room = action.payload;

            // Build a product storage of all products in this room, including stuff from style groups and empty extra options
            ProductService.getAllProductSelectionsInRoom(room, true, false, true).forEach((ps) => {
                state.productStorage = new Map([...state.productStorage, ...ps.getAllProducts()]);
            });

            if (state.readOnlyMode) {
                // While the room was received from API we need to reselect all product if user is return with a saved session
                ProductService.restoreProductSelectionsFromLocalStorageInRoom(state.apartment2.apartmentId, room);
            }

            // Just build screens for showroom enabled rooms
            if (room.showroomScene) {
                // Compute what is selected and viewable and build scene
                const selectedImages = RoomService.filterSelectedByRoomScene(room);
                if (inBrowserScenes.findIndex(sRId => sRId === room.scene.referenceId) >= 0) {
                    const inBrowserPromise = SceneService.inBrowserSceneGetUrl(room.scene.referenceId, selectedImages);
                    room.sceneImg = inBrowserPromise;
                    lastInBrowserPromise = inBrowserPromise;
                    inBrowserPromise.then(src => {
                        if (lastInBrowserPromise === inBrowserPromise) {
                            room.sceneImg = src;
                        }
                    });
                } else {
                    room.sceneImg = SceneService.constructScene(room.scene.referenceId, selectedImages).getUrl();
                }
            }

            return {
                ...state,
                rooms: state.rooms.set(room.id, room) || state.rooms,
                roomsOrder: state.roomsOrder.includes(room.id) ? state.roomsOrder : [...state.roomsOrder, room.id],
                grandTotal: ProductService.calculateGrandTotal(state.rooms),
            };
        }

        case actionTypes.STYLE_FAKE_PRODUCT_SELECTIONS_RECEIVE: {
            LogService.log(action.type, "Reducer");
            const room = state.rooms.get(action.payload.forRoom.id);
            const styleId = action.payload.style.id;
            const fakeProductSelections = action.payload.fakeProductSelections;

            room.activeStyleGroups.forEach((asg) => {
                const style = PackageService.findStyleInStyleGroup(asg.styleGroup, styleId);
                if (style)
                    style.productSelections = fakeProductSelections;
            });

            return {
                ...state,
                rooms: state.rooms.set(room.id, room) || state.rooms,
            };
        }

        // Remember, this is the linked products received for a specific room
        case actionTypes.LINKED_PRODUCTS_MAP_RECEIVE: {
            LogService.log(action.type, "Reducer");

            const room = state.rooms.get(action.payload.forRoom.id);
            const mapsForRoom = action.payload.maps;

            // We need to iterate through a collection of product selections and their options trying to find and disable products mentioned in the relationship mapping.
            // This is done by using the ProductRelationshipService. It will back reference all products already stored in room instance.
            ProductRelationshipService.updateAllProductSelectionsForRelationshipsInRoom(room, mapsForRoom);

            //TODO declare why we update the current room again? This should have been the same object by reference updated above
            if (state.currentRoom && state.currentRoom.id === action.payload.forRoom.id) {
                ProductRelationshipService.updateAllProductSelectionsForRelationshipsInRoom(state.currentRoom, mapsForRoom);
            }

            // --------------------------------------------------------Map for ProductRelationshipInfo component ---------------------------------------------------------------
            // Lastly we want to get a complete relationShipsForRoom map for this room. The roomRelationships is a redux stored property used by ProductRelationshipInfo component to describe the relationship status in a user friendly way.
            // FIXME The ProductRelationshipInfo is not used anymore, or it has been muted so probably this is not necessary to compute anymore
            // Even though this looks like a repeating block its actually for performance reason we don't want to iterate
            // through all entries of product storage one by one but just to go through this room
            var productsInThisRoom = new Map();
            ProductService.getAllProductSelectionsInRoom(room, true, false, true).forEach((ps) => {
                productsInThisRoom = new Map([...productsInThisRoom, ...ps.getAllProducts()]);
            });

            const relationShipsForRoom = new Map();
            productsInThisRoom.forEach((product, prodId) => {
                relationShipsForRoom.set(prodId, ProductRelationshipService.getProductRelationships(product, mapsForRoom, state.productStorage));
            });
            // -----------------------------------------------------------------------------------------------------------------------------------------

            /* TODO
            Since the relationships tied to room and downloaded on room receive there will be cases where Package changes (simulated or api queried) cannot find a
            relationship configuration for the new products added. The quick fix would be to query room for relationships again since the new products where added.
            But we need to find a better approach here and solve this crippling Package / Style group mess once and for all.
            */

            return {
                ...state,
                forcedRelationshipUpdate: (state.forcedRelationshipUpdate || 0) + 1,
                roomRelationships: new Map([...state.roomRelationships.set(room.id, relationShipsForRoom), ...new Map()]), // Hack to let Redux broadcast the change
                linkedProducts: new Map([...state.linkedProducts.set(room.id, mapsForRoom), ...new Map()]), // Hack to let Redux broadcast the change
                grandTotal: ProductService.calculateGrandTotal(state.rooms) // The grand total might have changed due to disabled products because of relationships
            };
        }

        //The room was changed to another one, this means reference to current room needs to be update and scene re rendered
        case actionTypes.ROOM_CHANGE: {
            LogService.log(action.type, "Reducer");

            const oldRoom = state.currentRoom;
            const newRoomId = action.payload.roomId;

            // Room id 0 is the "summary room"
            if (newRoomId === AppRoute.Summary) {

                // Track this
                UtilService.sDTrack(state.enviseId, `View summary`);

                return {
                    ...state,
                    currentRoom: undefined,
                    currentRoomId: newRoomId,
                    visited: !oldRoom || state.visited.includes(oldRoom) ? state.visited : [...state.visited, oldRoom.id],
                };
            } else if (newRoomId === AppRoute.Checkout) {

                UtilService.sDTrack(state.enviseId, `View checkout`);

                return {
                    ...state,
                    currentRoomId: newRoomId,
                    currentRoom: undefined
                };

            } else if (newRoomId === AppRoute.Confirmation) {

                UtilService.sDTrack(state.enviseId, `Completed checkout`);

                return {
                    ...state,
                    currentRoomId: newRoomId,
                    currentRoom: undefined
                };
            } else {
                // Real room change work flow

                const currentRoom = state.rooms.get(newRoomId);

                if (!currentRoom) {
                    console.log("Invalid room id - TODO handling");
                    return state;
                }

                // Track this
                UtilService.sDTrack(state.enviseId, `View next room`);

                // We preselect one open category with this assignment
                // Not anymore, the UI is more intuitive now
                var openProductCategory = null;
                //TODO remove me
                if (currentRoom.productSelections.length > 0)
                    openProductCategory = currentRoom.productSelections[0];


                return {
                    ...state,
                    currentRoom: currentRoom,
                    currentRoomId: currentRoom.id,
                    openProductCategory: openProductCategory,
                    visited: !oldRoom || oldRoom.id === newRoomId || state.visited.includes(oldRoom) ? state.visited : [...state.visited, oldRoom.id],
                };
            }
        }

        case actionTypes.ROOM_SEEN_BY_USER: {
            LogService.log(action.type, "Reducer");

            const seenRooms = {...state.seenRooms};
            seenRooms[action.payload.roomId] = true;

            return {
                ...state,
                seenRooms: seenRooms
            }
        }


        case actionTypes.SELECTION_PROGRESS_VISITED:
        case actionTypes.SELECTION_PROGRESS_SELECTED:
        case actionTypes.SELECTION_PROGRESS_SELECT_ALL: {
            LogService.log(action.type, "Reducer");

            const updateSelectionProgress = (payload, selected, selectionProgress) => {
                let updated = false;

                if (!selectionProgress) selectionProgress = {};
                if (!selectionProgress[payload.apartmentId]) selectionProgress[payload.apartmentId] = {};
                if (!selectionProgress[payload.apartmentId][payload.roomId]) selectionProgress[payload.apartmentId][payload.roomId] = {};
                if (!selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId])
                    selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId] = {
                        v: false,
                        s: false,
                    };

                if (!payload.categoryId) {
                    if (!selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId].v) {
                        selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId].v = true;
                        updated = true;
                    }

                    if (!selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId].s && selected)
                    {
                        selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId].s
                            = selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId].s
                            || selected;
                        updated = true;
                    }
                }
                else {
                    if (!selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId])
                        selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId] = {
                            v: false,
                            s: false,
                        };

                    if (!selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId].v) {
                        selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId].v = true;
                        updated = true;
                    }

                    if (!selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId].s && selected) {
                        selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId].s =
                            selectionProgress[payload.apartmentId][payload.roomId][payload.styleGroupId][payload.categoryId].s
                            || selected;
                        updated = true;
                    }
                }

                return {
                    updated,
                    selectionProgress
                }
            };

            let payload = action.payload;
            if (action.type === actionTypes.SELECTION_PROGRESS_SELECT_ALL) {
                payload = SelectionProgressService.getSelectionProgressPayloadForRoom(state.apartment2.apartment, state.currentRoom);
            }

            if (!Array.isArray(payload)) {
                payload = [payload];
            }

            const selected =
                action.type === actionTypes.SELECTION_PROGRESS_SELECTED
                || action.type === actionTypes.SELECTION_PROGRESS_SELECT_ALL;

            let updated = false;
            let selectionProgress = Object.assign({}, state.selectionProgress);

            payload.forEach(item => {
                const result = updateSelectionProgress(item, selected, selectionProgress);
                updated = updated || result.updated;
                selectionProgress = result.selectionProgress;
            });

            if (updated && state.tutorialProgress.isLoaded) {
                /** we might need to show some parts of the tutorial that were not previously shown */
                state = {
                    ...state,
                    tutorialProgress: {
                        ...state.tutorialProgress,
                        steps: TutorialService.buildSteps(state.tutorialProgress),
                        isTourOpen: true
                    }
                }
            }

            return updated ? {...state, selectionProgress: selectionProgress} : {...state}
        }

        case actionTypes.SELECTION_PROGRESS_ROOM_COMPLETED: {
            LogService.log(action.type, "Reducer");


            let newSelectionProgressRoomCompletion = (state.selectionProgressRoomCompletion || []);
            if (action.payload.completed) {
                if (newSelectionProgressRoomCompletion.every(rId => rId !== action.payload.roomId)) {
                    newSelectionProgressRoomCompletion = newSelectionProgressRoomCompletion.concat([action.payload.roomId]);
                }
            } else {
                newSelectionProgressRoomCompletion = newSelectionProgressRoomCompletion.filter(rId => rId !== action.payload.roomId);
            }

            return {
                ...state,
                selectionProgressRoomCompletion: newSelectionProgressRoomCompletion
            }
        }

        case actionTypes.PRODUCT_CHANGING: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                productChanging: action.payload
            }
        }

        // A product was selected, update the scene and tell subscribers
        case actionTypes.PRODUCT_CHANGE: {

            LogService.log(`${action.type} - New project product: ${action.payload.projectProduct ? action.payload.projectProduct.id : null}`, "Reducer");

            // Track this event
            UtilService.sDTrack(state.enviseId, `Changed product`);

            // Re iterate on the relationships, yes this is probably a bit resource intensive
            const linkedProducts = state.linkedProducts.get(state.currentRoom.id);
            if (linkedProducts)
                ProductRelationshipService.updateAllProductSelectionsForRelationshipsInRoom(state.currentRoom, linkedProducts);

            const currentRoom = {
                ...state.currentRoom,
                productSelections: state.currentRoom.productSelections // https://github.com/reduxjs/redux/issues/432 this is to tell subscribers an object property has changed
            };

            // (Re)Compute what is selected and viewable and build scene if this room is 3D
            if (state.currentRoom.showroomScene) {
                const selectedImages = RoomService.filterSelectedByRoomScene(state.currentRoom);
                if (inBrowserScenes.findIndex(sRId => sRId === currentRoom.scene.referenceId) >= 0) {
                    const inBrowserPromise = SceneService.inBrowserSceneGetUrl(currentRoom.scene.referenceId, selectedImages);
                    currentRoom.sceneImg = inBrowserPromise;
                    lastInBrowserPromise = inBrowserPromise;
                    inBrowserPromise.then(src => {
                        if (lastInBrowserPromise === inBrowserPromise) {
                            currentRoom.sceneImg = src;
                        }
                    });
                } else {
                    currentRoom.sceneImg = SceneService.constructScene(state.currentRoom.scene.referenceId, selectedImages).getUrl();
                }
            }

            return {
                ...state,
                currentRoom: currentRoom,
                rooms: state.rooms.set(state.currentRoomId, currentRoom) || state.rooms,
                grandTotal: ProductService.calculateGrandTotal(state.rooms)
            };
        }

        case actionTypes.CLICKED_PRODUCT_CATEGORY_CHANGE: {

            LogService.log(action.type, "Reducer");

            return {
                ...state,
                openProductCategory: action.payload.productSelection
            };
        }

        case actionTypes.CLICKED_PACKAGE_CHANGE: {

            LogService.log(action.type, "Reducer");

            return {
                ...state,
                openPackage: action.payload.style
            };
        }

        case actionTypes.LEAD_SENDING: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                leadSending: true,
                leadSent: false,
                errors: [...state.errors].filter(e => e.key !== "ERRORS.LEAD_SEND_ERROR")
            };
        }

        case actionTypes.LEAD_SEND_ERROR: {
            LogService.log(action.type, "Reducer");

            var errors = state.errors;
            if (!state.errors.find(e => e.key === "ERRORS.LEAD_SEND_ERROR")) {
                errors = [...state.errors, {key: "ERRORS.LEAD_SEND_ERROR", msg: "Error while sending your message"}];
            }

            return {
                ...state,
                leadSending: false,
                leadSent: false,
                errors: errors,
            };
        }

        case actionTypes.LEAD_SENT: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                leadSending: false,
                leadSent: true,
                errors: [...state.errors].filter(e => e.key !== "ERRORS.LEAD_SEND_ERROR")
            };
        }

        case actionTypes.LEAD_RESET: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                leadSending: false,
                leadSent: false,
                errors: [...state.errors].filter(e => e.key !== "ERRORS.LEAD_SEND_ERROR")
            };
        }

        case actionTypes.LEAD_FORM_DOWNLOADING: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                leadFormSchema: undefined
            };
        }

        case actionTypes.LEAD_FORM_DOWNLOADED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                leadFormSchema: action.payload
            };
        }

        case actionTypes.LIGHT_BOX_SHOW: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                lightbox: action.payload.lightbox
            };
        }

        case actionTypes.LIGHT_BOX_HIDE: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                lightbox: undefined
            };
        }

        case actionTypes.AUTHORIZING_STARTED: {
            LogService.log(action.type, "Reducer");
            AuthService.clearAuthentication();

            return {
                ...state,
                loading: state.loading + 1,
                auth: {
                    ...state.auth,
                    invalidCredentials: false
                },
            }
        }

        case actionTypes.AUTHORIZING_SUCCEEDED: {
            LogService.log(action.type, "Reducer");

            const {reauthorize, username} = action.payload;

            if (!reauthorize) {
                AuthorizedUserService.removeSelectedCustomer();
            }

            return {
                ...state,
                loading: reauthorize ? state.loading : Math.max(0, state.loading - 1),
                auth: {
                    ...state.auth,
                    authorized: true,
                    username: username ? username : state.auth.username,
                    invalidCredentials: false
                },
                me: undefined,
                meFetchingError: false
            }
        }

        case actionTypes.AUTHORIZING_FAILED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                loading: Math.max(0, state.loading - 1),
                auth: {
                    ...state.auth,
                    authorized: false,
                    invalidCredentials: true
                }
            }
        }

        case actionTypes.USER_BECAME_UNAUTHORIZED: {
            LogService.log(action.type, "Reducer");
            AuthService.clearAuthentication();
            AuthorizedUserService.removeSelectedCustomer();

            return {
                ...state,
                auth: {
                    ...state.auth,
                    authorized: false
                },
                me: undefined,
                meFetchingError: false
            }
        }

        case actionTypes.RESET_AUTH: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                auth: {
                    ...state.auth,
                    resetLinkSent: false,
                    invalidCredentials: false
                }
            }
        }

        case actionTypes.SEND_FORGOT_PASSWORD_RESET_LINK_STARTED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                auth: {
                    ...state.auth,
                    resetLinkSent: false,
                    invalidCredentials: false
                },
                loading: state.loading + 1
            }
        }

        case actionTypes.SEND_FORGOT_PASSWORD_RESET_LINK_SUCCEEDED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                auth: {
                    ...state.auth,
                    resetLinkSent: true,
                    invalidCredentials: false
                },
                loading: Math.max(0, state.loading - 1)
            }
        }

        case actionTypes.SEND_FORGOT_PASSWORD_RESET_LINK_FAILED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                auth: {
                    ...state.auth,
                    resetLinkSent: false,
                    invalidCredentials: true
                },
                loading: Math.max(0, state.loading - 1)
            }
        }

        case actionTypes.RESET_PASSWORD_STARTED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                loading: state.loading + 1
            }
        }

        case actionTypes.RESET_PASSWORD_SUCCEEDED: {
            LogService.log(action.type, "Reducer");

            const {username} = action.payload;

            return {
                ...state,
                auth: {
                    ...state.auth,
                    username: username,
                    invalidCredentials: false
                },
                loading: Math.max(0, state.loading - 1)
            }
        }

        case actionTypes.RESET_PASSWORD_FAILED: {
            LogService.log(action.type, "Reducer");

            return {
                ...state,
                auth: {
                    ...state.auth,
                    invalidCredentials: true
                },
                loading: Math.max(0, state.loading - 1)
            }
        }

        case actionTypes.CLOSE_TUTORIAL: {
            LogService.log(action.type, "Reducer");
            return {
                ...state,
                tutorialProgress: {
                    ...state.tutorialProgress,
                    isTourOpen: false
                }
            }
        }

        case actionTypes.UPDATE_TUTORIAL_STEPS: {
            LogService.log(action.type, "Reducer");
            return state.tutorialProgress.isLoaded ? {
                ...state,
                tutorialProgress: {
                    ...state.tutorialProgress,
                    steps: TutorialService.buildSteps(state.tutorialProgress),
                }
            } : state
        }

        case actionTypes.TUTORIAL_IS_LOADED: {
            LogService.log(action.type, "Reducer");
            return {
                ...state,
                tutorialProgress: {
                    ...state.tutorialProgress,
                    isLoaded: true,
                    isTourOpen: true
                }
            }
        }

        case actionTypes.COMPLETE_TUTORIAL_STEPS: {
            LogService.log(action.type, "Reducer");
            const visitedSteps = state.tutorialProgress.visitedSteps;
            action.stepNames.forEach(stepName => visitedSteps.push(stepName));
            return {
                ...state,
                tutorialProgress: {
                    ...state.tutorialProgress,
                   visitedSteps: visitedSteps
                }
            }
        }

        case actionTypes.RESET_TUTORIAL: {
            LogService.log(action.type, "Reducer");
            return {
                ...state,
                tutorialProgress: {
                    ...state.tutorialProgress,
                    visitedSteps: [],
                    isTourOpen: true
                }
            }
        }



        default: {
            return state;
        }
    }
};

export default rootReducer;
