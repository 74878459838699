import React, {Component} from 'react';
import "./style.scss"
import {bindActionCreators} from "redux";
import * as actions from "../../../../actions/productActions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import HMNextAction from "../../../Utils/HMNextAction";
import HMCostInfo from "../../../Utils/HMCostInfo";
import {AppRoute} from "../../../../services/RouterService";
import {withRouter} from "react-router-dom";
import {withBus} from 'react-bus';
import {HM_TOPBAR_OPEN_MOBILE_NAVIGATION} from "../../../../actions/eventTypes";
import HeaderLabel from "../../../Utils/HeaderLabel";

class EmptyPicker extends Component {

    render() {
        const { t } = this.props;

        return (
            <div className={`hm-empty-picker`}>
                <div className="scrollable">
                    {!this.props.children &&
                    <div className={"hm-package-picker-header"}>
                        <HeaderLabel
                            text={this.props.currentRoom.roomType}
                            subText={this.props.messageKey ? t(this.props.messageKey) : ' '}
                        />
                    </div>
                    }
                    {this.props.children}
                </div>
                {!this.props.hidePrices && <HMCostInfo currentRoutes={this.props.currentRoutes} onClick={this.props.isMobile ? () => {this.props.bus.emit(HM_TOPBAR_OPEN_MOBILE_NAVIGATION)} : null}/>}
                <HMNextAction
                    selectedNavigationItem={{selectionProgress: {s: true}}}
                    nextUrlLink={this.props.nextUrlLink}
                    appSizes={this.props.appSizes}
                />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        hidePrices: state.app.hidePrices,
        currentRoom: state.app.currentRoom, // Required to re render on product change
        appSizes: state.app.appSizes,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

EmptyPicker.propTypes = {
    nextUrlLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired,
    messageKey: PropTypes.string.isRequired,
};

export default withBus()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EmptyPicker))));
