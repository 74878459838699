import ModelValidationError from "./ModelValidationError";
import Product from "./Product";

class ProjectProduct {
    constructor() {
        this.id = null;
        this.delayedPrice = false; // Price on Offer
        this.expired = false;
        this.price = 0;
        this.product = null;
        this.status = "NOT_USED";

        this.disabled = false; // This will be the property that tells if this project product is disabled by another one in the room (ProductRelationship / Linked Product)
    }

    isFree() {
        return this.price === 0;
    }

    isFreeOptional() {
        return this.isFree() && this.status === "OPTIONAL";
    }

    parse(obj) {
        Object.assign(this, obj);

        if (obj.product) this.product = new Product().parse(obj.product);

        this._validate();

        return this;
    }

    resetMarkingFromProductRelationships() {
        this.disabled = false;
    }

    _validate() {
        if (!this.id) {
            throw new ModelValidationError("Instance is missing id");
        }

        if (!this.product) {
            throw new ModelValidationError("Instance is missing product");
        }
    }
}

export default ProjectProduct;
