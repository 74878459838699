import store from "../reducers/store";
import ProductService from '../services/ProductService'
import BackendApi from "../api/BackendApi";
import * as types from "./actionTypes";
import PackageService from "../services/PackageService";

export function downloadFormSchema() {
    return function (dispatch) {
        const state = store.getState();

        dispatch({type: types.LEAD_FORM_DOWNLOADING, payload: {}});

        const cleanDefaultFormData = (data) => {
            if (data && typeof data === 'object') {
                for (let property in data) {
                    if (typeof data[property] === 'object') {
                        cleanDefaultFormData(data[property]);
                    } else if (Array.isArray(data[property])) {
                        for (let i = 0; i < data[property].length; i++) {
                            if (data[property][i] === 'undefined' || data[property][i] === 'null') {
                                data[property][i] = null;
                            }
                        }
                    } else if (data[property] === 'undefined' || data[property] === 'null') {
                        data[property] = null;
                    }
                }
            }
        };

        BackendApi.downloadLeadForm(state.app.apartment2.apartmentId).then(response => {
            if (!response || response.error) {
                dispatch({type: types.LEAD_FORM_DOWNLOAD_ERROR, payload: {}});
            } else {
                cleanDefaultFormData(response);
                dispatch({type: types.LEAD_FORM_DOWNLOADED, payload: response});
            }
        });
    }
}

export function resetLead() {
    return function (dispatch) {
        dispatch({type: types.LEAD_RESET, payload: {}});
    }
}

export function sendLead(contactFormData) {
    return function (dispatch) {
        const state = store.getState();

        const rooms = state.app.roomsOrder
            .map(roomId => state.app.rooms.get(roomId))
            .filter( room => !!room);

        if (rooms.length === 0) {
            dispatch({type: types.LEAD_SEND_ERROR, payload: {}});
            return;
        }

        const roomsWithSelections = rooms
            .map(room => {
                const roomData = {
                    id: room.id,
                    roomType: room.roomType,
                    showroomScene: room.showroomScene,
                    sceneImg: room.sceneImg || PackageService.getFirstImageUrlFrom(room.activeStyleGroups),
                    grandTotal: ProductService.calculateGrandTotal([room]),
                    hierarchicalProductSelections: PackageService.getAllProductSelectionsInRoomWithStylesHierarchy(room)
                };

                roomData.hierarchicalProductSelections.room.map(productSelection => {
                   productSelection.delayedPrice = productSelection.getDelayedPrice();
                   productSelection.statusStyle = ProductService.getStatusStyle(productSelection.projectProduct);
                   delete productSelection.room;
                   return productSelection;
                });

                roomData.hierarchicalProductSelections.activeStyles.forEach(styleProductSelections => {
                    styleProductSelections.productSelections.map(productSelection => {
                        productSelection.delayedPrice = productSelection.getDelayedPrice();
                        productSelection.statusStyle = ProductService.getStatusStyle(productSelection.projectProduct);
                        delete productSelection.room;
                        return productSelection;
                    });
                });

                return roomData;
            });

        const selection = {
            rooms: roomsWithSelections,
            grandTotal: ProductService.calculateGrandTotal(rooms)
        };

        const lead = {
            version: "1",
            contactDetails : contactFormData,
            selection: selection
        };

        dispatch({type: types.LEAD_SENDING, payload: {}});

        BackendApi.registerLead(state.app.apartment2.apartmentId, lead, state.app.hidePrices).then((response) => {
            if (!response || response.error) {
                dispatch({type: types.LEAD_SEND_ERROR, payload: response});
            } else {
                dispatch({type: types.LEAD_SENT, payload: response});
            }
        });
    }
}
