import React, {Component} from 'react';
import './style.scss';
import MapMarker from "../../../../assets/map-marker.png";

export default class HmMapMarker extends Component {

    render() {
        return (
            <div className="hm-map-marker">
                <img src={MapMarker} alt=""/>
                <span>{this.props.text}</span>
            </div>
        );
    }
}
