import React, {Component} from 'react';
import PropTypes from "prop-types";
import ProjectProduct from "../../../../models/ProjectProduct";
import UtilService from "../../../../services/UtilService";
import BackendApi from "../../../../api/BackendApi";
import ProductAlternative from "../ProductAlternative";
import ProductAlternativesService from "../../../../services/ProductAlternativesService";
import * as actions from "../../../../actions/productActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import "./style.scss"

/**
 * TODO this class can seriously be improved, a few things was repeated and duck taped before last demo
 */
class ProductAlternatives extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);

        this.state = {
            metaProduct: null,
            groups: null,
            propertyGroupsToRender: {}, /** after calling calculateState() this contains a {name -> {group}...} of the groups, where each group has been prepared for rendering */
            selectedProperties: [],
            forProjectProduct: null // Just to keep track of when component should recalculate and render
        };
    }

    componentDidMount() {
        var metaProductPromise = undefined;

        if (this.props.readOnlyMode) {
            metaProductPromise = BackendApi.fetchReadOnlyMetaProduct(this.props.projectProduct.product.metaProduct.id, this.props.productSelection.id, this.props.readOnlyKey);

        } else {
            metaProductPromise = BackendApi.fetchMetaProduct(this.props.projectProduct.product.metaProduct.id, this.props.productSelection.id);
        }

        metaProductPromise.then((metaProduct) => {
            if (this._unmounted) return;

            // First, what groups and properties occur in the choosable project products (Alternatives)
            const groups = ProductAlternativesService.mapProjectProductsToPropertyGroups(this.props.alternatives);
            this.setState({...this.state, groups: groups, metaProduct: metaProduct});
        });

    }

    componentDidUpdate() {

        // Have we already calculated for this chosen project product?
        if (this.props.projectProduct === this.state.forProjectProduct)
            return;

        // Only precede if we have required data for calculation
        if (!this.state.metaProduct || !this.state.groups)
            return;

        const newState = this.calculateState();

        // Yes this respects current state variables
        this.setState(newState);
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    calculateState() {
        // OR render? We need to make sure we're not displaying a impossible property

        const selectedProperties = [];
        const metaProduct = this.state.metaProduct;
        const groups = this.state.groups;

        /** build a {propertyGroupName: string -> [{property}...] ...} that stores all the property groups by the group names,
         * the properties themselves are enriched with the alternatives image */
        const allPropertyGroups = Object.keys(groups).reduce((acc, groupName) => {
            const group = groups[groupName];
            acc[groupName] = UtilService.iterableToArray(group).map((val) => {
                /** mark the property as selected */
                if(ProductAlternativesService.isPropertySelected(this.props.projectProduct, val)) selectedProperties.push(val);
                /** enrich the property with the corresponding image */
                val.imageUrl = this.findAlternativeImageUrl(metaProduct, val.id);
                return val;
            });
            return acc;
        }, {});

        return {...this.state,
            propertyGroupsToRender: allPropertyGroups,
            selectedProperties: selectedProperties,
            forProjectProduct: this.props.projectProduct
        };
    }

    // TODO merge this "pre work" with ProductAlternativesService.getAlternativeThumbnail()
    findAlternativeImageUrl(metaProduct, metaPropertyId) {
        let match;
        // JS for loops are breakable
        for (let i = 0; i < metaProduct.metaPropertyGroups.length; i++) {
            let group = metaProduct.metaPropertyGroups[i];
            match = group.metaProperties.find((property) => {
                return property.id ===  metaPropertyId;
            });

            if(match)
                break;
        }

        return match && match.image ? match.image.thumbnailUrl : undefined
    }

    isSelected(property) {
        return ProductAlternativesService.isPropertySelected(this.props.projectProduct, property);
    }

    // Using the same flow as we would select the property.
    // Usually this is if the property would pick an illegal ProjectProduct, like a project product not in use. When this happens, no candidate is found when seeking
    isSelectable(property) {
        try {
            return ProductAlternativesService.findNextProjectProduct(this.state.selectedProperties, property, this.props.alternatives) !== undefined;
        } catch (e) {
            //TODO why doesn't this work?
            // if (e instanceof ProjectProductNotFoundError)
            //     return false;
            // else
            //     return true;

            return false;
        }
    }

    // This is when we click one of the properties like White. Then we need to find the right project product corresponding to this
    selectProperty(property) {
        if (this.props.readOnly) {
            return;
        }

        if (this.props.beforeSelect)
            this.props.beforeSelect();

        const newProjectProduct = ProductAlternativesService.findNextProjectProduct(this.state.selectedProperties, property, this.props.alternatives);
        this.props.actions.changeProjectProduct(newProjectProduct, this.props.productSelection)
        // Should find the right group, change it at match the seeked product
    }

    /** No properties -> no property title */
    shouldShowPropertyTitle() {
        return Object.keys(this.state.propertyGroupsToRender).length > 0;
    }

    renderPropertyHeader(property) {
        if (this.shouldShowPropertyTitle()) {
            return <div className="row">
                <div className="col-12">
                    <span>{property}</span>
                </div>
            </div>
        } else {
            return (null)
        }
    }

    renderPropertyBody(alternatives) {
        return <div className="row no-gutters alternatives">
                    {alternatives.map((alternative, i) => {
                        return (
                            <div key={`alternative-property-body-${i}`} className="mr-1">
                                <ProductAlternative readOnly={this.props.readOnly} clickFn={() => this.selectProperty(alternative)} alternative={alternative} parentSelected={this.props.selected} selected={this.isSelected(alternative)} selectable={!this.props.readOnly && this.isSelectable(alternative)}/>
                            </div>
                        )
                    })}
                </div>
    }

    /** Render a single property group with its name and possible properties */
    renderPropertyGroup(properties, groupName) {
        if(properties.length > 1) {
            /** the group name text localization requires to be passed the property type object with `type` and `name` fields, that is why we simply take the meta property of the first one */
            const groupNameText = ProductAlternativesService.tryLocalizingTheName(this.props.t, properties[0].metaPropertyGroup);
            return <div className="col-12" key={`alternative-property-group-${groupName}`}>
                {this.renderPropertyHeader(groupNameText)}
                {this.renderPropertyBody(properties)}
            </div>
        } else {
            return null;
        }
    }

    /** Render each of the property groups available for the current product */
    renderPropertyGroups(groups){
        const sortedGroupNames = ProductAlternativesService.getOrderedPropertyTypesList(Object.keys(groups));
        return sortedGroupNames.map((groupName) => {
            return this.renderPropertyGroup(groups[groupName], groupName);
        })
    }

    /** Render the column of property groups for the product */
    render() {
        return (
            <div className="hm-product-alternatives row">
                {this.renderPropertyGroups(this.state.propertyGroupsToRender)}
            </div>
        )
    }
}

ProductAlternatives.propTypes = {
    readOnly: PropTypes.bool,
    alternatives: PropTypes.array.isRequired,
    projectProduct: PropTypes.instanceOf(ProjectProduct).isRequired, // THis is the current selected projectProduct
    beforeSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        readOnlyMode: state.app.readOnlyMode,
        readOnlyKey: state.app.apartment2.apartmentId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ProductAlternatives));
