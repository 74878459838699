// This class maps directly to the API model of SceneProduct
class SceneProduct {
    constructor() {
        this.class = null;
        this.product = null;
        this.referenceId = null;
        this.color = null;
        this.objectIndex = null;
    }

    parse(obj) {
        Object.assign(this, obj);

        // Add validations
        // if (this.class == "home.maker.room.visualization.SceneDesign" && (!referenceId  !))
        //     throw "Instance is missing id";

        return this;
    }
}

export default SceneProduct;