import React, {Component} from 'react';
import PropTypes from "prop-types";
import './style.scss'
import ProductAlternativesService from "../../../../services/ProductAlternativesService";
import HMImage from "../../../Utils/HMImage";

export default class ProductAlternative extends Component {

    select($event) {
        if (!this.props.selected && this.props.selectable) {
            this.props.clickFn();
        }

        $event.stopPropagation();
    }

    render() {
        return (
            <div onClick={($event) => this.select($event)} className={`hm-product-alternative ${this.props.readOnly ? '' : 'pointer'}`}>
                <HMImage
                    className={`rounded-circle img-thumbnail ${this.props.selected ? (this.props.parentSelected ? "selected" : "selected-no-parent") : ""} ${this.props.selectable ? "" : "disabled"}`}
                    imageSrc={ProductAlternativesService.getAlternativeThumbnail(this.props.alternative)}
                    title={this.props.alternative.name}
                    imageVisibilityType="contain"
                />
                {/*<img*/}
                {/*    className={`rounded-circle img-thumbnail ${this.props.selected ? "selected": ""} ${this.props.selectable ? "": "disabled"}`}*/}
                {/*    title={this.props.alternative.name}*/}
                {/*    alt={this.props.alternative.name}*/}
                {/*    src={ProductAlternativesService.getAlternativeThumbnail(this.props.alternative)}/>*/}
            </div>
        )
    }
}

ProductAlternative.propTypes = {
    readOnly: PropTypes.bool,
    alternative: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    parentSelected: PropTypes.bool.isRequired,
    selectable: PropTypes.bool.isRequired,
    clickFn: PropTypes.func.isRequired
};
