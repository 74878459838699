import React, {Component} from 'react';
import PropTypes from "prop-types";
import "./style.scss"
import {Link} from "react-router-dom";

class HMButton extends Component {

    constructor(props) {
        super(props);

        this.state = {mouseHover: false};
    }

    setMouseHover(value) {
        if (this.state.mouseHover !== value)
            this.setState({mouseHover: value})
    }

    getBtnClasses() {
        let classes = "hm-button-default";

        if (this.props.active && !this.props.disabled)
            classes = "hm-button-active " + (this.props.activeType || 'orange');

        if (this.props.thinStyle)
            classes += " hm-thin-button";

        if (this.props.animate)
            classes += " wiggle-animate";

        return classes
    }

    render() {
        return this.props.href ? (
            this.props.disabled ? (
                <div
                    id={this.props.id}
                    onMouseEnter={() => this.setMouseHover(true)}
                    onMouseLeave={() => this.setMouseHover(false)}
                    className={`hm-button hm-button-disabled btn btn-block ${this.getBtnClasses()}`}
                >
                    <span dangerouslySetInnerHTML={{__html: this.props.disabledText}}/>
                </div>
            ) : (
                <Link
                    id={this.props.id}
                    to={this.props.href}
                    onMouseEnter={() => this.setMouseHover(true)}
                    onMouseLeave={() => this.setMouseHover(false)}
                    className={`hm-button btn btn-block ${this.getBtnClasses()}`}
                >
                    <span dangerouslySetInnerHTML={{__html: this.props.active ? this.props.activeText : this.props.text}}/>
                </Link>
            )
        ) : (
            <button
                id={this.props.id}
                disabled={this.props.disabled}
                onMouseEnter={() => this.setMouseHover(true)}
                onMouseLeave={() => this.setMouseHover(false)}
                onClick={($event) => {this.props.clickFn($event); $event.stopPropagation(); return false;}}
                className={`hm-button btn btn-block ${this.getBtnClasses()}`} type={`${this.props.type || 'button'}`}
            >
                {this.props.disabled ? (
                    <span dangerouslySetInnerHTML={{__html: this.props.disabledText}}/>
                ) : (
                    <span dangerouslySetInnerHTML={{__html: this.props.active ? this.props.activeText : this.props.text}}/>
                )}
                {this.props.children}
            </button>
        )
    }
}

HMButton.propTypes = {
    id: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit']),
    text: PropTypes.string.isRequired,
    clickFn: PropTypes.func.isRequired,
    active: PropTypes.bool,
    activeText: PropTypes.string,
    activeType: PropTypes.oneOf(['orange', 'green']),
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    href: PropTypes.string,
    thinStyle: PropTypes.bool,
    animate: PropTypes.bool
};

HMButton.defaultProps = {
    active: true,
    activeType: 'orange',
    disabled: false,
    type: "button",
    thinStyle: false,
    animate: false
};

export default HMButton;
