// Offline version of the backend code for demo apartments
import ProductSelection from "../models/ProductSelection";
import UtilService from "./UtilService";
import BackendApi from "../api/BackendApi";
import ProductImageNotFound from "../assets/images/productImageNotFound.png";

export default class PackageService {

    // Central method for changing a style in a style group
    static selectStyle(activeStyle, newStyle, newProductSelections) {

        if (activeStyle.style.id === newStyle.id)
            return false;

        // Clear invalid product selections from old style
        activeStyle.productSelections = [];
        activeStyle.style = newStyle;

        newProductSelections.forEach((ps) => {
            activeStyle.productSelections.push(new ProductSelection().parse(ps))
        });
    }

    static getAllProductSelectionsFromActiveStyle(activeStyle, includeDisabledByRelationships = false, includeEmptyExtraOptions = false) {

        let productSelections = activeStyle.productSelections;

        productSelections = includeDisabledByRelationships ? productSelections : productSelections.filter((ps) => {return !ps.isDisabledFromProductRelationships()});

        productSelections = includeEmptyExtraOptions ? productSelections : productSelections.filter( ps => !ps.extraOption || (ps.extraOption && ps.projectProduct));

        return productSelections;
    }

    static getAllProductSelectionsInRoomWithStylesHierarchy(room) {
        let productSelections = {};
        productSelections.room = [...room.productSelections, ...room.extraProductSelections.filter(ps => ps.projectProduct)].filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships());
        productSelections.activeStyles = [];

        room.activeStyleGroups.forEach((activeStyle) => {
            if (activeStyle.productSelections.length > 0) {
                productSelections.activeStyles.push({
                    style: this.getFullStyle(activeStyle),
                    productSelections: [...activeStyle.productSelections.filter( ps => !ps.extraOption || (ps.extraOption && ps.projectProduct)).filter(ps => !ps.hasNoEnabledChoisesFromProductRelationships())]
                });
            }
        });

        return productSelections;
    }

    // Should only be called within a read only context
    static async restorePackageSelectionsFromLocalStorageInRoom(apartmentId, room) {
        const packageSelections = this.getPackageSelectionsToLocalStorage(apartmentId, room);

        if (packageSelections.size !== 0) {
            for (let i = 0; i < room.activeStyleGroups.length; i ++) {
                const activeStyle = room.activeStyleGroups[i];

                if (packageSelections.has(activeStyle.id)) {
                    const newStyle = this.findStyleInStyleGroup(activeStyle.styleGroup, packageSelections.get(activeStyle.id));

                    const fakedProductSelections = await this.getFakedProductSelectionsFromUnselectedPackageFromApi(newStyle, room, apartmentId);
                    this.selectStyle(activeStyle, newStyle, fakedProductSelections);
                }
            }
        }

        return room;
    }

    static async getFakedProductSelectionsFromUnselectedPackageFromApi(newStyle, room, apartmentId) {

        // Is within Read only context?
        if (!apartmentId) {
            return await BackendApi.fetchBuildFakeProductSelectionsForStyleInRoom(newStyle.id, room.id);
        } else {
            return await BackendApi.fetchReadOnlyBuildFakeProductSelectionsForStyleInRoom(newStyle.id, room.id, apartmentId);
        }
    }

    //TODO remove me, since I'm not used
    static buildFakeProductSelections(incompleteProductSelections, newStyle, room) {
        // The id here is not a valid ID from the API. That's because this product selection actually not exists. This brings problem to the MetaProduct query in Alternatives Component
        return incompleteProductSelections ? incompleteProductSelections.map((psMap) => {
            return new ProductSelection().parse({...psMap, id : `fid-${newStyle.id}-${psMap.projectProduct.product.id}`, room: room});
        }) : [];
    }

    static getPersistentPackageSelectionKey(apartmentId, roomId) {
        return `apartment_${apartmentId}.room_${roomId}.selected_packages`;
    }

    static persistPackageSelectionsToLocalStorage(apartmentId, room, activeStyle, style) {
        UtilService.appendToLocalStorageMap(
            this.getPersistentPackageSelectionKey(apartmentId, room.id),
            activeStyle.id,
            style.id
        )
    }

    static getPackageSelectionsToLocalStorage(apartmentId, room) {
        return UtilService.getMapFromLocalStorage(this.getPersistentPackageSelectionKey(apartmentId, room.id));
    }

    static getFullStyle(activeStyle) {
        return this.findStyleInStyleGroup(activeStyle.styleGroup, activeStyle.style.id);
    }

    static findStyleInStyleGroup(styleGroup, styleId) {
        return styleGroup.styles.find((style) => {return style.id === styleId});
    }

    static getStyleImageUrl(activeStyle) {
        if (!activeStyle) return null;

        const style = this.getFullStyle(activeStyle);
        return style && style.image ? style.image.url : "";
    }

    static getStyleImageUrlFromStyle(style, returnEmptyIfNotPreset = true) {
        return style && style.image ? style.image.url : (returnEmptyIfNotPreset ? ProductImageNotFound : null);
    }

    static getStyleGroupImage(activeStyle) {
        if (!activeStyle) return null;

        const activeStyleImage = this.getFirstImageUrlFrom([activeStyle]);

        if (activeStyleImage) return activeStyleImage;

        for (let i = 0; i < activeStyle.styleGroup.styles.length; i++) {
            const style = activeStyle.styleGroup.styles[i];
            const styleImage = this.getStyleImageUrlFromStyle(style, false);
            if (styleImage) return styleImage;
        }

        return null;
    }

    static getFirstImageUrlFrom(activeStyleGroups) {
        if (!activeStyleGroups || activeStyleGroups.length === 0) return null;

        for (let i = 0; i < activeStyleGroups.length; i++) {
            const asg = activeStyleGroups[i];

            const activeStyleImage = this.getStyleImageUrl(asg);
            if (activeStyleImage && activeStyleImage !== "") return activeStyleImage;
        }

        return null;
    }
}
