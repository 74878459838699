import React, {Component} from 'react';
import "./style.scss"
import {Trans, withTranslation} from "react-i18next";
import * as actions from "../../../actions/appActions";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from 'redux';
import HMButton from "../../Utils/HMButton";
import {Fade} from "reactstrap";

class CookiesAlert extends Component {
    onAgree() {
        this.props.appActions.cookiesAccepted();
    }

    render() {
        const { t } = this.props;

        return (
            <Fade in={!this.props.cookiesAccepted} tag="div" className="row no-gutters hm-cookies-alert justify-content-center" mountOnEnter={true} unmountOnExit={true}>
                <div className="shield-icon">
                    <clr-icon shape="shield-check" className="is-solid" size="70"></clr-icon>
                </div>
                <div className="content">
                    <h2>{t('cookies.title')}</h2>
                    <p>
                        <Trans i18nKey="cookies.content">
                            More information on processing of your personal data through cookies and more information about your rights may be found in the <a href="https://envise.io/privacy-policy/" target="_blank">Information</a> about processing of personal data through cookies and other web technologies.
                            On the right you may grant your consent to processing of your personal data also for the stated purposes.
                        </Trans>

                    </p>
                </div>
                <HMButton
                    text={""}
                    activeText={t('cookies.agree')}
                    active={true}
                    clickFn={() => this.onAgree()}
                />
            </Fade>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        cookiesAccepted: state.app.cookies && state.app.cookies.accepted
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appActions: bindActionCreators(actions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CookiesAlert));
