import React, {Component} from 'react';
import "./style.scss"
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {NavigationItemType} from "../../../services/SelectionProgressService";
import {withTranslation} from "react-i18next";
import {Tooltip} from 'reactstrap';

class HMNextAction extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };
    }

    showTooltip() {
        this.setState({...this.state, tooltipOpen: true});
    }

    hideTooltip() {
        this.setState({...this.state, tooltipOpen: false});
    }

    isActive() {
        return this.props.active || this.props.selectedNavigationItem.selectionProgress.s;
    }

    getTitle(t) {
        if (!this.isActive())
            return t("selectionProgress.makeAChoice");

        if (this.props.nextUrlLink.backToUnfinished) {
            return t("selectionProgress.backToFirstUnfinished")
        }

        switch (this.props.nextUrlLink.type) {
            case NavigationItemType.Summary:
                return t("selectionProgress.nextSummary");
            case NavigationItemType.Room:
                return t("selectionProgress.nextStep");
            default:
                return t("selectionProgress.nextStep");
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className={`hm-next-action uppercase ${this.isActive() ? 'active' : 'inactive'}`}>
                {this.isActive() &&
                <Link to={this.props.nextUrlLink.to}>
                    <h4 className="m-0">{this.getTitle(this.props.t)} <clr-icon shape="angle right" className="with-text"/></h4>
                </Link>
                }

                {!this.isActive() &&
                <React.Fragment>
                    <div href="#" id="DisabledNextTooltip" onMouseLeave={() => this.hideTooltip()} onClick={() => this.showTooltip()}>
                        <h4 className="m-0">{this.getTitle(this.props.t)} <clr-icon shape="angle right" className="with-text"/></h4>
                    </div>
                    <Tooltip placement={this.props.appSizes.app.isMobile && this.props.appSizes.app.isLandscape ? "left" : "top"} trigger="" autohide={true} isOpen={this.state.tooltipOpen} target="DisabledNextTooltip">
                        {t('selectionProgress.makeAChoiceClicked')}
                    </Tooltip>
                </React.Fragment>
                }
            </div>
        )
    }
}

HMNextAction.propTypes = {
    active: PropTypes.bool,
    selectedNavigationItem: PropTypes.object,
    nextUrlLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    appSizes: PropTypes.object.isRequired,
};


export default withTranslation('common')(HMNextAction);
