import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router";
import {bindActionCreators} from "redux";
import * as apartmentActions from "../../actions/apartmentActions";
import * as projectActions from "../../actions/projectActions";
import connect from "react-redux/es/connect/connect";
import ApartmentLanding from './ApartmentLanding';
import HMError from '../Utils/HMError';
import {Trans, withTranslation} from "react-i18next";
import Summary from "../Summary";
import Room from "../Room";
import Checkout from "../Checkout";
import Confirmation from "../Confirmation";
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";

class Apartment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reloaded: false
        };
    }

    _locationPathname = undefined;
    _hidden = true;
    _reloadTimeout = undefined;

    componentDidMount() {
        this.initApartment();
        this._locationPathname = this.props.location.pathname;

        this.setDelayedReloaded();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this._locationPathname !== nextProps.location.pathname) {
            this._locationPathname = nextProps.location.pathname;
            this._hidden = true;
            this.setState({...this.state, reloaded: false});
            this.clearReloadTimeout();
            this.setDelayedReloaded();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initApartment();
    }

    componentWillUnmount() {
        this.clearReloadTimeout();
    }

    clearReloadTimeout() {
        if (this._reloadTimeout) {
            clearTimeout(this._reloadTimeout);
            this._reloadTimeout = undefined;
        }
    }

    setDelayedReloaded() {
        const setReloadTimeout = () => {
            this._reloadTimeout = setTimeout(() => {
                if (this.props.loading) {
                    setReloadTimeout();
                } else {
                    this.setReloaded();
                }
            }, 10);
        };

        setReloadTimeout();
    }

    setReloaded() {
        this._hidden = false;
        this.setState({...this.state, reloaded: true});
    }

    initApartment() {
        if (this.props.match.params.apartmentId) {
            if (this.props.singleApartment) {
                this.props.projectActions.getProject(this.props.appMode, true, this.props.match.params.apartmentId);
            }

            this.props.apartmentActions.getApartment(this.props.appMode, !!this.props.singleApartment, this.props.match.params.apartmentId);
        }
    }

    render() {

        if (this.props.singleApartment) {
            if (this.props.match.params.apartmentId !== '' + this.props.project.projectId)
                return null;

            if (this.props.project.notFound) {
                const projectId = this.props.match.params.apartmentId;
                return (
                    <HMError>
                        <Trans i18nKey="common:errors.projectNotFound">
                            Project with id: '<strong>{{projectId}}</strong>' not found.
                        </Trans>
                    </HMError>
                );
            }

            if (!this.props.project.project)
                return null;
        }

        if (this.props.match.params.apartmentId !== '' + this.props.apartment.apartmentId)
            return null;

        if (this.props.apartment.notFound) {
            const apartmentId = this.props.match.params.apartmentId;
            return (
                <HMError>
                    <Trans i18nKey="common:errors.apartmentNotFound">
                        Apartment with id: '<strong>{{apartmentId}}</strong>' not found.
                    </Trans>
                </HMError>
            );
        }

        if (!this.props.apartment.apartment)
            return null;

        // It is fine with having apartments with empty rooms. Clients might want to use apartment landing page for file uploads and general information without the product configurator
        // if (!this.props.roomsOrder || this.props.roomsOrder.length === 0) {
        //     return (
        //         <HMError>
        //             <Trans i18nKey="common:errors.roomsNotConfigured">
        //                 No rooms configured.
        //             </Trans>
        //         </HMError>
        //     );
        // }

        return (
            <CSSTransition
                in={this.state.reloaded}
                timeout={this.state.reloaded ? 300 : 10}
                classNames={{
                    enter: "animated",
                    enterActive: "fadeIn"
                }}
                >
            <div className={`min-height-100 ${this._hidden ? "hidden" : ""} ${"hm-app-mode-" + this.props.currentAppMode}`}>
            <Switch>
                <Route exact path={`${this.props.match.path}/`} component={ApartmentLanding}/>

                <Route path={`${this.props.match.path}/summary`} component={Summary} />,
                <Route path={`${this.props.match.path}/checkout`} component={Checkout} />,
                <Route path={`${this.props.match.path}/confirmation`} component={Confirmation} />
                <Route path={`${this.props.match.path}/:roomId`} component={Room} />
            </Switch>
            </div>
            </CSSTransition>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentAppMode: state.app.appMode,
        project: state.app.project,
        apartment: state.app.apartment2,
        roomsOrder: state.app.roomsOrder,
        loading: state.app.loading > 0
    }
}

function mapDispatchToProps(dispatch) {
    return {
        apartmentActions: bindActionCreators(apartmentActions, dispatch),
        projectActions: bindActionCreators(projectActions, dispatch)
    }
}

Apartment.propTypes = {
    appMode: PropTypes.string,
    singleApartment: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Apartment)));
