import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as roomActions from '../../actions/roomActions';
import * as selectionProgressActions from "../../actions/selectionProgressActions";
import Scene from './Scene';
import EmptyPicker from './Picker/EmptyPicker';
import CategoryPicker from './Picker/CategoryPicker';
import PackagePicker from './Picker/PackagePicker';
import RoomSelectedPackageProductList from './Product/RoomSelectedPackageProductList';
import PropTypes from "prop-types";
import "./style.scss";
import {withRouter} from "react-router-dom";
import GuideOverlay from "../../assets/guideOverlay.png";
import SummaryPicker from "./Picker/SummaryPicker";
import {parse} from "query-string";
import {Trans, withTranslation} from "react-i18next";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {NavigationItemType, SelectionProgressService} from "../../services/SelectionProgressService";
import {RouterService} from "../../services/RouterService";
import RoomUnselectedPackage from "./Product/RoomUnselectedPackage";
import HMTutorial from "../Utils/HMTutorial";
import moment from "moment";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import HMButton from "../Utils/HMButton";


class Room extends Component {

    constructor (props) {
        super(props);

        this.state = {
            doGuide: false,
            showCompletion: false,
            showOverview: false,
        };
        this.setCurrentRoom(props);
    }

    _synchronizeTimeout = undefined;
    componentWillUpdate(nextProps, nextState, nextContext) {
        this.setCurrentRoom(nextProps);
        this.synchronizeHashAndModals();
    }

    setCurrentRoom(props) {
        if (props.match.params['roomId'] && (!props.currentRoom || props.currentRoom.id !== +props.match.params['roomId'])) {
            this.props.roomActions.changeRoom(+props.match.params['roomId']);
            setTimeout(() => this.setState({...this.state, showCompletion: false}));

            if (window.hj) {
                window.hj('vpv', `/room`);
            }
        }
    }

    toggleGuide() {
       this.setState({...this.state, doGuide: !this.state.doGuide});
    }

    getRootNavigationItemType(navigationItem) {
        switch (navigationItem.argument.type) {
            case NavigationItemType.Package:
                return "Package" + navigationItem.argument.item.id;

            case NavigationItemType.PackageCategory:
                return "Package" + navigationItem.parent.argument.item.id;

            case NavigationItemType.Other:
            case NavigationItemType.OtherCategory:
                return "Other";

            case NavigationItemType.Extra:
            case NavigationItemType.ExtraCategory:
                return "Extra";

            default:
                return "Unknown";
        }
    }

    checkRoomCompletion(apartment, currentRoom, navItems) {
        const today = moment().startOf('day');

        if (
            !apartment || apartment.isLocked
            || !currentRoom || currentRoom.isLocked
            || !currentRoom.lockDate
            || moment(currentRoom.lockDate).isBefore(today)
            || !navItems || navItems.some(c => !SelectionProgressService.isNavItemFinished(c))
        ) {
            if (this.state.showCompletion) {
                this.setState({...this.state, showCompletion: false});
            }

            if ((this.props.selectionProgressRoomCompletion || []).some(rId => rId === currentRoom.id)) {
                this.props.selectionProgressActions.selectionProgressRoomCompleted(currentRoom.id, false);
            }

            return;
        }

        if (!this.state.showCompletion && (this.props.selectionProgressRoomCompletion || []).every(rId => rId !== currentRoom.id)) {
            // should display completion message
            this.setState({...this.state, showCompletion: true});
            this.props.selectionProgressActions.selectionProgressRoomCompleted(currentRoom.id, true);
        }
    }

    onCompletionHide(currentRoom) {
        if (this.state.showCompletion) {
            this.setState({...this.state, showCompletion: false});
        }
    }

    synchronizeHashAndModals() {
        clearTimeout(this._synchronizeTimeout);

        this._synchronizeTimeout = setTimeout(() => {
            if (this.props.history.location.hash === "#overview") {
                if (!this.state.showOverview) {
                    setTimeout(() => this.toggleShowOverview(true, false));
                }
            } else if (!this.props.history.location.hash || this.props.history.location.hash !== '#overview') {
                if (this.state.showOverview) {
                    setTimeout(() => this.toggleShowOverview(false, false));
                }
            }
        }, 0);
    }

    toggleShowOverview(showOverview, navigate = true) {
        if (this.state.showOverview !== showOverview) {
            if (!showOverview && !!this.props.currentRoom.id) {
                this.props.roomActions.userHasSeenRoom(this.props.currentRoom.id)
            }

            this.setState({...this.state, showOverview: showOverview});

            if (navigate) {
                if (showOverview) {
                    this.props.history.push(this.props.history.location.pathname + this.props.history.location.search + '#overview');
                } else {
                    if (!!this.props.history.location.hash && this.props.history.location.hash === '#overview') {
                        this.props.history.goBack();
                    }
                }
            }
        }
    }

    _firstSceneChange = true;
    _sceneChangedTimeout = undefined;
    onSceneChanged() {
        clearTimeout(this._sceneChangedTimeout);

        setTimeout(() => {
            if (this.props.isMobile && !this.props.currentRoom.isLocked && !this.state.showOverview && !this._firstSceneChange) {
                this.toggleShowOverview(true);
            }
        }, 600);
        this._firstSceneChange = false;
    }

    timeout = undefined;

    render() {

        const {t, apartment, currentRoom, currentRoomId, productPickerOffset, isMobile} = this.props;

        if (!currentRoom || this.props.match.params.roomId !== '' + currentRoomId)
            return null;

        const navItems = SelectionProgressService.getNavigationItems(apartment, currentRoom, this.props.selectionProgress, parse(this.props.location.search));
        const selectedItem = SelectionProgressService.getActiveNavigationItem(navItems);

        if (!selectedItem) {
            // empty room
            const currentRoutes = RouterService.getCurrentRoutes(this.props.location);
            const nextUrlLink = SelectionProgressService.getNavigationItemNextUrlLinkFromItems(
                this.props.roomsOrder,
                currentRoom,
                navItems,
                apartment,
                this.props.roomsOrder.map(roomId => this.props.rooms.get(roomId)),
                currentRoutes,
                parse(this.props.location.search),
                this.props.selectionProgress
            );

            return (
                <div id='tutorial-id-left-area' className={`hm-product-picker-col ${isMobile ? 'hm-product-picker-col-mobile' : ''}`}>
                    <EmptyPicker
                        currentRoutes={currentRoutes}
                        nextUrlLink={nextUrlLink}
                        messageKey={'emptyRoomMessage'}
                    />
                </div>
            );
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => this.checkRoomCompletion(apartment, currentRoom, navItems), 500);

        if (!!currentRoom.id && !this.props.seenRooms[currentRoom.id]) {
            if (!this.state.showOverview && currentRoom.showroomScene && !currentRoom.isLocked && this.props.isMobile) {
                setTimeout(() => this.toggleShowOverview(true), 0);
            }
        }

        const currentRoutes = RouterService.getCurrentRoutes(this.props.location);
        const nextUrlLink = SelectionProgressService.getNavigationItemNextUrlLinkFromItems(
            this.props.roomsOrder,
            currentRoom,
            navItems,
            apartment,
            this.props.roomsOrder.map(roomId => this.props.rooms.get(roomId)),
            currentRoutes,
            parse(this.props.location.search),
            this.props.selectionProgress);

        const animationTime = 300;

        return (
            <React.Fragment>
                {(!isMobile || isMobile && false && this.props.currentRoom.isLocked) &&
                    <div id='tutorial-id-right-area' className={`container-fluid m-0 p-0 hm-product-view ${isMobile ? "" : "with-product-picker"}`} style={true || !currentRoom.isLocked ? {top: productPickerOffset} : {}}>
                        <div className="row m-0 h-100">
                            <div className="col px-0 h-100">
                                {currentRoom.showroomScene &&
                                    <Scene isModal={false}/>
                                }

                                {!currentRoom.showroomScene &&
                                <div className="hm-floating-room-selected-products">
                                    <TransitionGroup>
                                        {selectedItem.argument.type === NavigationItemType.Package && !selectedItem.selectionProgress.s &&
                                        <CSSTransition
                                            key={`${this.props.currentRoomId}-${this.getRootNavigationItemType(selectedItem)}-unselected`}
                                            in={true}
                                            timeout={{enter: animationTime, exit: 0}}
                                            mountOnEnter={true}
                                            unmountOnExit={true}
                                            exit={false}
                                            classNames={{
                                                enter: 'animated',
                                                enterActive: 'fadeIn',
                                                exit: '',
                                                exitActive: 'behind'
                                            }}
                                        >
                                            <RoomUnselectedPackage activeStyle={selectedItem.argument.type === NavigationItemType.Package && !selectedItem.selectionProgress.s ? selectedItem.argument.item : undefined}/>
                                        </CSSTransition>
                                        }

                                        {(selectedItem.argument.type !== NavigationItemType.Package || (selectedItem.argument.type === NavigationItemType.Package && selectedItem.selectionProgress.s)) &&
                                        <CSSTransition
                                            key={`${this.props.currentRoomId}-${this.getRootNavigationItemType(selectedItem)}-list`}
                                            in={true}
                                            timeout={{enter: animationTime, exit: 0}}
                                            mountOnEnter={true}
                                            unmountOnExit={true}
                                            exit={false}
                                            classNames={{
                                                enter: 'animated',
                                                enterActive: 'fadeIn',
                                                exit: '',
                                                exitActive: 'behind'
                                            }}
                                        >
                                            <RoomSelectedPackageProductList
                                                topBarHeight={this.props.topBarHeight}
                                                hidePrices={this.props.hidePrices}
                                                room={currentRoom}
                                                selectedNavigationItem={selectedItem}
                                            />
                                        </CSSTransition>
                                        }
                                    </TransitionGroup>
                                </div>
                                }
                            </div>
                            <img className={`guide ${this.state.doGuide ? "" : "d-none"}`} src={GuideOverlay} alt="guide" onClick={() => {this.toggleGuide()}}/>
                        </div>
                    </div>
                }

                {(!this.props.currentRoom.isLocked || true) &&
                    <React.Fragment>

                        {!this.props.isMobile && <HMTutorial/>}

                        <div id='tutorial-id-left-area' className={`hm-product-picker-col ${isMobile ? 'hm-product-picker-col-mobile' : ''}`}>
                            <TransitionGroup>
                                <CSSTransition
                                    key={`room-${this.props.currentRoomId}-package-${selectedItem.argument.item.id}`}
                                    in={selectedItem.argument.type === NavigationItemType.Package}
                                    timeout={{enter: animationTime, exit: 0}}
                                    mountOnEnter={true}
                                    unmountOnExit={true}
                                    exit={false}
                                    classNames={{
                                        enter: 'animated',
                                        enterActive: 'fadeIn',
                                        exit: '',
                                        exitActive: 'behind'
                                    }}
                                >
                                    <PackagePicker
                                        readOnly={this.props.currentRoom.isLocked}
                                        currentRoutes={currentRoutes}
                                        description={this.props.currentRoom.roomGreeting || ""}
                                        activeStyle={selectedItem.argument.type === NavigationItemType.Package ? selectedItem.argument.item : undefined}
                                        selectedNavigationItem={selectedItem}
                                        nextUrlLink={nextUrlLink}/>
                                </CSSTransition>

                                <CSSTransition
                                    key={`room-${this.props.currentRoomId}-other-${selectedItem.titleKey}`}
                                    in={selectedItem.argument.type === NavigationItemType.Other}
                                    timeout={{enter: animationTime, exit: 0}}
                                    mountOnEnter={true}
                                    unmountOnExit={true}
                                    exit={false}
                                    classNames={{
                                        enter: 'animated',
                                        enterActive: 'fadeIn',
                                        exit: '',
                                        exitActive: 'behind'
                                    }}
                                >
                                    <SummaryPicker
                                        readOnly={this.props.currentRoom.isLocked}
                                        currentRoutes={currentRoutes}
                                        hidePrices={this.props.hidePrices}
                                        showStatus={false}
                                        title={selectedItem.getTitle(t, {room: this.props.currentRoom})}
                                        description={this.props.currentRoom.roomGreeting || ""}
                                        productSelections={selectedItem.argument.type === NavigationItemType.Other ? [] : undefined}
                                        selectedNavigationItem={selectedItem}
                                        nextUrlLink={nextUrlLink}
                                        appSizes={this.props.appSizes}
                                    />
                                </CSSTransition>

                                <CSSTransition
                                    key={`room-${this.props.currentRoomId}-extra-${selectedItem.titleKey}`}
                                    in={selectedItem.argument.type === NavigationItemType.Extra}
                                    timeout={{enter: animationTime, exit: 0}}
                                    mountOnEnter={true}
                                    unmountOnExit={true}
                                    exit={false}
                                    classNames={{
                                        enter: 'animated',
                                        enterActive: 'fadeIn',
                                        exit: '',
                                        exitActive: 'behind'
                                    }}
                                >
                                    <SummaryPicker
                                        readOnly={this.props.currentRoom.isLocked}
                                        currentRoutes={currentRoutes}
                                        hidePrices={this.props.hidePrices}
                                        showStatus={false}
                                        title={selectedItem.getTitle(t, {room: this.props.currentRoom})}
                                        description={this.props.currentRoom.roomGreeting || ""}
                                        productSelections={selectedItem.argument.type === NavigationItemType.Extra ? [] : undefined}
                                        selectedNavigationItem={selectedItem}
                                        nextUrlLink={nextUrlLink}
                                        appSizes={this.props.appSizes}
                                    />
                                </CSSTransition>

                                <CSSTransition
                                    key={`room-${this.props.currentRoomId}-${selectedItem.argument.type === NavigationItemType.PackageCategory ? 'package-' + selectedItem.argument.item.id : selectedItem.argument.type === NavigationItemType.OtherCategory ? 'other' : selectedItem.argument.type === NavigationItemType.ExtraCategory ? 'extra' : 'unknown'}-category-${selectedItem.argument.item.id}`}
                                    in={selectedItem.argument.type === NavigationItemType.PackageCategory || selectedItem.argument.type === NavigationItemType.OtherCategory || selectedItem.argument.type === NavigationItemType.ExtraCategory}
                                    timeout={{enter: animationTime, exit: 0}}
                                    mountOnEnter={true}
                                    unmountOnExit={true}
                                    exit={false}
                                    classNames={{
                                        enter: 'animated',
                                        enterActive: 'fadeIn',
                                        exit: '',
                                        exitActive: 'behind'
                                    }}
                                >
                                    <CategoryPicker
                                        readOnly={this.props.currentRoom.isLocked}
                                        currentRoutes={currentRoutes}
                                        productSelection={selectedItem.argument.type === NavigationItemType.PackageCategory || selectedItem.argument.type === NavigationItemType.OtherCategory || selectedItem.argument.type === NavigationItemType.ExtraCategory ? selectedItem.argument.item : undefined}
                                        isFromPackageWithPrice={selectedItem.argument.type === NavigationItemType.PackageCategory && selectedItem.parent.argument.item.style.hasStylePrice}
                                        selectedNavigationItem={selectedItem}
                                        nextUrlLink={nextUrlLink}
                                        productChanging={this.props.productChanging}
                                    />
                                </CSSTransition>
                            </TransitionGroup>


                            {this.props.isMobile &&
                                <button className="hm-button btn hm-button-active green with-shadow hm-thin-button p-0 hm-floating-action-button" type="button" onClick={() => this.toggleShowOverview(true)}>
                                    <clr-icon shape="eye" size="30"></clr-icon>
                                </button>
                            }
                        </div>

                    </React.Fragment>
                }

                {(this.props.currentRoom.isLocked && false) &&
                <div id='tutorial-id-left-area' className={`hm-product-picker-col ${isMobile ? 'hm-product-picker-col-mobile' : ''}`}>
                    <TransitionGroup>
                        <CSSTransition
                            key={`room-${this.props.currentRoomId}-locked-room`}
                            in={true}
                            timeout={{enter: animationTime, exit: 0}}
                            mountOnEnter={true}
                            unmountOnExit={true}
                            exit={false}
                            classNames={{
                                enter: 'animated',
                                enterActive: 'fadeIn',
                                exit: '',
                                exitActive: 'behind'
                            }}
                        >
                            <EmptyPicker
                                currentRoutes={currentRoutes}
                                nextUrlLink={nextUrlLink}
                                messageKey={'lockedRoomMessage'}
                            >
                                {isMobile &&
                                <div id='tutorial-id-right-area' className={`container-fluid m-0 p-0 hm-product-view`}>
                                    <div className="row m-0 h-100">
                                        <div className="col px-0 h-100">
                                            {currentRoom.showroomScene &&
                                            <Scene isModal={false}
                                                   bottomOffset={this.props.appSizes.app.isPortrait ? ((this.props.hidePrices ? 0 : 40) + 50) : 0}/>
                                            }

                                            {!currentRoom.showroomScene &&
                                            <div className="hm-floating-room-selected-products">
                                                <RoomSelectedPackageProductList
                                                    topBarHeight={this.props.topBarHeight}
                                                    hidePrices={this.props.hidePrices}
                                                    room={currentRoom}
                                                    selectedNavigationItem={selectedItem}
                                                />
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                }

                            </EmptyPicker>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                }


                <Modal isOpen={this.state.showCompletion}
                       centered={true}
                       modalTransition={{ timeout: 100 }}
                       backdrop={true}
                >
                    <ModalHeader>{t('selectionProgress.roomWithLockDateCompletion.title')}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Trans
                                i18nKey="selectionProgress.roomWithLockDateCompletion.body"
                                values={{
                                    roomType: currentRoom.roomType,
                                    lockDate: t('longDate', {date: currentRoom.lockDate})
                                }}
                                defaults="You have completed selecting options for room <b>{{roomType}}</b> which will be automatically locked at <b>{{lockDate}}</b>. Until then the room will stay unlocked and you are free to change your selections."
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <HMButton text={t('selectionProgress.roomWithLockDateCompletion.action')}
                                  active={true}
                                  activeText={t('selectionProgress.roomWithLockDateCompletion.action')}
                                  clickFn={() => this.onCompletionHide(currentRoom)}/>
                    </ModalFooter>
                </Modal>

                {isMobile &&
                <Modal isOpen={this.state.showOverview}
                       toggle={() => this.toggleShowOverview(false)}
                       centered={true}
                       modalTransition={{ timeout: 100 }}
                       className={"hm-full-screen-modal-modal without-padding h-100"}
                       modalClassName={"hm-full-screen-modal"}
                >
                    <ModalBody>
                        <div className="hm-mobile-overview">
                            <div className="h-100">
                                {currentRoom.showroomScene &&
                                <Scene isModal={true}/>
                                }

                                {!currentRoom.showroomScene &&
                                <div className="hm-floating-room-selected-products">
                                    <TransitionGroup>
                                        {selectedItem.argument.type === NavigationItemType.Package && !selectedItem.selectionProgress.s &&
                                        <CSSTransition
                                            key={`${this.props.currentRoomId}-${this.getRootNavigationItemType(selectedItem)}-unselected`}
                                            in={true}
                                            timeout={{enter: animationTime, exit: 0}}
                                            mountOnEnter={true}
                                            unmountOnExit={true}
                                            exit={false}
                                            classNames={{
                                                enter: 'animated',
                                                enterActive: 'fadeIn',
                                                exit: '',
                                                exitActive: 'behind'
                                            }}
                                        >
                                            <RoomUnselectedPackage activeStyle={selectedItem.argument.type === NavigationItemType.Package && !selectedItem.selectionProgress.s ? selectedItem.argument.item : undefined}/>
                                        </CSSTransition>
                                        }

                                        {(selectedItem.argument.type !== NavigationItemType.Package || (selectedItem.argument.type === NavigationItemType.Package && selectedItem.selectionProgress.s)) &&
                                        <CSSTransition
                                            key={`${this.props.currentRoomId}-${this.getRootNavigationItemType(selectedItem)}-list`}
                                            in={true}
                                            timeout={{enter: animationTime, exit: 0}}
                                            mountOnEnter={true}
                                            unmountOnExit={true}
                                            exit={false}
                                            classNames={{
                                                enter: 'animated',
                                                enterActive: 'fadeIn',
                                                exit: '',
                                                exitActive: 'behind'
                                            }}
                                        >
                                            <RoomSelectedPackageProductList
                                                topBarHeight={this.props.topBarHeight}
                                                hidePrices={this.props.hidePrices}
                                                room={currentRoom}
                                                selectedNavigationItem={selectedItem}
                                                onSelectAction={() => this.toggleShowOverview(false)}
                                            />
                                        </CSSTransition>
                                        }
                                    </TransitionGroup>
                                </div>
                                }
                            </div>
                        </div>
                    </ModalBody>
                    {!(!!currentRoom.id && !this.props.seenRooms[currentRoom.id] && currentRoom.showroomScene && !currentRoom.isLocked) &&
                        <button className="hm-button btn hm-button-default hm-thin-button p-0 hm-floating-action-button" type="button" onClick={() => this.toggleShowOverview(false)}>
                            <clr-icon shape="times" size="30"></clr-icon>
                        </button>
                    }
                    {!!currentRoom.id && !this.props.seenRooms[currentRoom.id] && currentRoom.showroomScene && !currentRoom.isLocked &&
                        <React.Fragment>
                            <div className="hm-mobile-overview-welcome">
                                <h2 className="w-100 text-center">
                                    <Trans
                                        i18nKey="showroomWelcome.title"
                                        values={{
                                            roomName: currentRoom.roomType,
                                        }}
                                    />
                                </h2>

                                <p>
                                    <Trans
                                        i18nKey="showroomWelcome.body"
                                        values={{
                                            roomName: currentRoom.roomType,
                                        }}
                                    />
                                </p>

                                <div className="pt-1 d-flex flex-nowrap align-items-center align-self-start">
                                    <button className="hm-button btn hm-button-default hm-thin-button p-0 mr-3" type="button" onClick={() => this.toggleShowOverview(false)}>
                                        <clr-icon shape="eye" size="30"></clr-icon>
                                    </button>
                                    <span className="flex-grow-1">{t('showroomWelcome.overviewButtonExplanation')}</span>
                                </div>
                            </div>

                            <div className="hm-mobile-overview-get-started">
                                <HMButton
                                    thinStyle={false}
                                    text={""}
                                    active={true}
                                    activeText={t('showroomWelcome.getStarted')}
                                    clickFn={() => this.toggleShowOverview(false)}
                                />
                            </div>
                        </React.Fragment>
                    }
                </Modal>
                }
                {isMobile && currentRoom.showroomScene &&
                    <div className="hm-shadow-scene">
                        {<Scene isModal={true} isShadow={true} onSceneChanged={() => this.onSceneChanged()}/>}
                    </div>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        hidePrices: state.app.hidePrices,
        apartment: state.app.apartment2.apartment,
        currentRoomId: state.app.currentRoomId,
        currentRoom: state.app.currentRoom,
        roomsOrder: state.app.roomsOrder,
        rooms: state.app.rooms,
        selectionProgress: state.app.selectionProgress,
        selectionProgressRoomCompletion: state.app.selectionProgressRoomCompletion,
        openProductCategory: state.app.openProductCategory,
        topBarHeight: state.app.appSizes.topBar.height,
        productPickerOffset: state.app.appSizes.topBar.height + "px",
        appSizes: state.app.appSizes,
        isMobile: state.app.appSizes.app.isMobile,
        productChanging: state.app.productChanging,
        seenRooms: state.app.seenRooms,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        roomActions: bindActionCreators(roomActions, dispatch),
        selectionProgressActions: bindActionCreators(selectionProgressActions, dispatch),
    }
}

Room.propTypes = {
    appMode: PropTypes.string
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Room)));
