import * as types from "./actionTypes";

function showLightBoxSuccess(lightbox) {
    return {type: types.LIGHT_BOX_SHOW, payload: {lightbox: lightbox}}
}

function hideLightBoxSuccess() {
    return {type: types.LIGHT_BOX_HIDE}
}

export function getLightboxForOneImage(src, title = undefined, caption = undefined) {
    return {
        images: [
            {
                src: src,
                title: title,
                caption: caption
            }
        ],
        selectedIndex: 0
    }
}

export function showLightBox(lightbox) {
    return function (dispatch) {
        dispatch(showLightBoxSuccess(lightbox));
    }
}

export function hideLightBox() {
    return function (dispatch) {
        dispatch(hideLightBoxSuccess());
    }
}
