import UtilService from "./UtilService";

export default class AuthorizedUserService {
    static fixLastInviteEmailSent(user) {
        if (!user || !user.customers) return user;

        user.customers = user.customers.filter(customer => !!customer && !!customer.lastInviteEmailSent);
        user.hasMultipleCustomers = user.customers.length > 1;

        if (user.customers.length === 1) {
            user.customer = user.customers[0];
        }

        return user;
    }

    static _selectedCustomerKey = "selectedCustomer";

    static selectCustomer(customerId) {
        UtilService.persistToLocalStorage(this._selectedCustomerKey, customerId);
    }

    static getSelectedCustomer() {
        return UtilService.getFromLocalStorage(this._selectedCustomerKey);
    }

    static removeSelectedCustomer() {
        return UtilService.removeFromLocalStorage(this._selectedCustomerKey);
    }
}
