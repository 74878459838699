// auth
export const AUTHORIZING_STARTED = "AUTHORIZING_STARTED";
export const AUTHORIZING_SUCCEEDED = "AUTHORIZING_SUCCEEDED";
export const AUTHORIZING_FAILED = "AUTHORIZING_FAILED";
export const USER_BECAME_UNAUTHORIZED = "USER_BECAME_UNAUTHORIZED";

export const RESET_AUTH = "RESET_AUTH";
export const SEND_FORGOT_PASSWORD_RESET_LINK_STARTED = "SEND_FORGOT_PASSWORD_RESET_LINK_STARTED";
export const SEND_FORGOT_PASSWORD_RESET_LINK_SUCCEEDED = "SEND_FORGOT_PASSWORD_RESET_LINK_SUCCEEDED";
export const SEND_FORGOT_PASSWORD_RESET_LINK_FAILED = "SEND_FORGOT_PASSWORD_RESET_LINK_FAILED";

export const RESET_PASSWORD_STARTED = "RESET_PASSWORD_STARTED";
export const RESET_PASSWORD_SUCCEEDED = "RESET_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";


// app
export const COOKIES_ACCEPTED = 'COOKIES_ACCEPTED';
export const MODE_SET = 'MODE_SET';
export const APP_LOADING = 'APP_LOADING';
export const APP_READY = 'APP_READY';

export const ME_RECEIVE = 'ME_RECEIVE';
export const ME_RECEIVE_ERROR = 'ME_RECEIVE_ERROR';

export const APP_SIZES_CHANGED = "APP_SIZES_CHANGED";

// project
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const PROJECT_LOADED = 'PROJECT_LOADED';
export const PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND';

// apartment & rooms
export const APARTMENT_LOADING = 'APARTMENT_LOADING';
export const APARTMENT_LOADED = 'APARTMENT_LOADED';
export const APARTMENT_NOT_FOUND = 'APARTMENT_NOT_FOUND';

export const LOCK_APARTMENT = 'LOCK_APARTMENT';
export const LOCK_ALL_ROOMS = 'LOCK_ALL_ROOMS';

export const MESSAGES_UPDATED = "MESSAGES_UPDATED";

export const ROOM_REQUEST = 'ROOM_REQUEST';
export const ROOM_RECEIVE = 'ROOM_RECEIVE';

export const LINKED_PRODUCTS_MAP_RECEIVE = 'LINKED_PRODUCTS_MAP_RECEIVE';

export const STYLE_FAKE_PRODUCT_SELECTIONS_RECEIVE = 'STYLE_FAKE_PRODUCT_SELECTIONS_RECEIVE';

export const ROOM_CHANGE = 'ROOM_CHANGE';

export const ROOM_SEEN_BY_USER = 'ROOM_SEEN_BY_USER';

export const SELECTION_PROGRESS_VISITED = 'SELECTION_PROGRESS_VISITED';
export const SELECTION_PROGRESS_SELECTED = 'SELECTION_PROGRESS_SELECTED';
export const SELECTION_PROGRESS_SELECT_ALL = 'SELECTION_PROGRESS_SELECT_ALL';
export const SELECTION_PROGRESS_ROOM_COMPLETED = 'SELECTION_PROGRESS_ROOM_COMPLETED';

export const PRODUCT_CHANGING = 'PRODUCT_CHANGING';
export const PRODUCT_CHANGE = 'PRODUCT_CHANGE';
export const CLICKED_PRODUCT_CATEGORY_CHANGE = 'CLICKED_PRODUCT_CATEGORY_CHANGE';
export const CLICKED_PACKAGE_CHANGE = 'CLICKED_PACKAGE_CHANGE';

export const SCENE_LOADING = 'SCENE_LOADING';
export const SCENE_LOADED = 'SCENE_LOADED';

export const LIGHT_BOX_SHOW = 'LIGHT_BOX_SHOW';
export const LIGHT_BOX_HIDE = 'LIGHT_BOX_HIDE';

export const LEAD_SENDING = 'LEAD_SENDING';
export const LEAD_SEND_ERROR = 'LEAD_SEND_ERROR';
export const LEAD_SENT = 'LEAD_SENT';
export const LEAD_RESET = 'LEAD_RESET';

export const LEAD_FORM_DOWNLOAD_ERROR = 'LEAD_FORM_DOWNLOAD_ERROR';
export const LEAD_FORM_DOWNLOADED = 'LEAD_FORM_DOWNLOADED';
export const LEAD_FORM_DOWNLOADING = 'LEAD_FORM_DOWNLOADING';

export const CLOSE_TUTORIAL = 'CLOSE_TUTORIAL';
export const UPDATE_TUTORIAL_STEPS = 'UPDATE_TUTORIAL_STEPS';
export const COMPLETE_TUTORIAL_STEPS = 'COMPLETE_TUTORIAL_STEPS';
export const TUTORIAL_IS_LOADED = 'TUTORIAL_IS_LOADED';
export const RESET_TUTORIAL = 'RESET_TUTORIAL';
