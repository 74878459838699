import React, {Component} from 'react';
import GrandTotal from "../../Utils/GrandTotal";
import HMButton from "../../Utils/HMButton";
import RouterService from "../../../services/RouterService";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

class SummaryCallToActionBottom extends Component {

    getCheckoutPath() {
        return RouterService.checkoutPath();
    }

    getCheckoutText(t) {
        return this.props.addonMode && this.props.apartmentLocked
            ? t("summary.seeNextSteps")
            : this.props.hidePrices ? t("summary.completeOptionsReadOnly") : t("summary.completeOptions");
    }

    render() {
        const { t } = this.props;

        return(
            <div className="row hm-showroom-summary-header bg-frosted-heavy">
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-12 pl-0 pr-0">
                            <h2 className="normalcase">{t('summary.title')}</h2>
                        </div>
                        <div className="col-lg-9 col-12 px-0 pl-lg-3 call-to-action-row-container">
                            <div className="call-to-action-row">
                                <div className="call-to-action-cell call-to-action-cell-bottom">
                                    {!this.props.hidePrices &&
                                    <h5><i className="grandTotal">{t('summary.totalCost')}:</i> <GrandTotal grandTotal={this.props.grandTotal}/></h5>
                                    }
                                </div>
                                <div className="call-to-action-cell">
                                    {(this.props.addonMode || this.props.doLeadsForSalesStart) &&
                                    <HMButton
                                        text={""}
                                        active={true}
                                        activeText={this.getCheckoutText(t)}
                                        href={this.getCheckoutPath()}
                                        clickFn={() => {}}
                                    />
                                    }
                                </div>
                                {!this.props.hidePrices &&
                                <h6 className="italic normal are-you-sure">{t('summary.areYouSureToCompleteOptions')}</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SummaryCallToActionBottom.propTypes = {
    hidePrices: PropTypes.bool,
    grandTotal: PropTypes.object.isRequired,
    addonMode: PropTypes.bool.isRequired,
    apartmentLocked: PropTypes.bool.isRequired,
    doLeadsForSalesStart: PropTypes.bool.isRequired,
};


export default withTranslation('common')(SummaryCallToActionBottom);
