import React, {Component} from "react";
import Tour from 'reactour';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/tutorialActions";
import {withTranslation} from "react-i18next";
import "./style.scss"

class HMTutorial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            steps: [],
            lastId: 0,
            lastSelectors: ""
        };
    }

    closeTour() {
        this.props.actions.completeSteps(this.props.tutorialProgress.steps.map(step => step.name));
        this.props.actions.close();
        this.setState({...this.state, lastSelectors: ""});
    }

    populateSteps() {
        this.props.actions.updateSteps();
    }

    componentDidMount() {
        /** launching the steps population into the future we achieve that the DOM selectors with work */
        setTimeout(() => {
            this.props.actions.tutorialIsLoaded();
            this.populateSteps();
        }, 1000);
    }

    renderTip(translationKey, videoKey) {
        const videoUrl = !!videoKey ? this.props.t(videoKey) : videoKey;
        const hasVideo = !!videoUrl && videoUrl !== videoKey && videoUrl.startsWith('http');

        return <div className={`container ${hasVideo ? 'has-video' : ''}`}>
            <div className="row no-gutters">
                <div className="col-1 info-icon">
                    <clr-icon shape="help-info" class="is-solid" size="26"></clr-icon>
                </div>
                <div className="col-11 pl-3 info-text">
                    <p>{this.props.t(translationKey)}</p>
                </div>
                {hasVideo &&
                <div className="col-11 pl-3">
                    <iframe width="560" height="315" src={videoUrl} frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                }
            </div>
        </div>
    }

    render() {
        const { t } = this.props;

        if(this.props.tutorialProgress.steps.length === 0) {
            return null;
        }

        this.props.tutorialProgress.steps.forEach(step => {
            step.content = this.renderTip(step.translationKey, step.videoKey);
        });

        const tour = (
            <Tour
                steps={this.props.tutorialProgress.steps}
                isOpen={!this.props.isMobile && this.props.tutorialProgress.isTourOpen} // disabled for the moment on mobiles
                onRequestClose={() => this.closeTour()}
                className="hm-tutorial"
                closeWithMask={false}
                disableDotsNavigation={false}
                showNavigation={true}
                showCloseButton={true}
                startAt={0}
                goToStep={0}
                maskSpace={0}
                disableInteraction={true}
                badgeContent={(curr, tot) => `${curr}/${tot}`}
                prevButton={<div></div>}
                nextButton={<div className="hm-tutorial-next-btn hm-button btn btn-block hm-button-active hm-thin-button">{t('tutorial.nextButton')}</div>}
                lastStepNextButton={<div className="hm-tutorial-next-btn hm-button btn btn-block hm-button-active hm-thin-button">{t('tutorial.lastButton')}</div>}
            />
        );

        return tour;
    }

}

function mapStateProps(state, ownProps) {
    return {
        tutorialProgress: state.app.tutorialProgress,
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateProps, mapDispatchToProps)(withTranslation('common')(HMTutorial))
