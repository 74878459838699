import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

class Signatory extends Component {

    render() {
        const {signatory, t} = this.props;

        return (
            <div className="col-auto signing-party">
                <a href={signatory.hasSigned ? undefined : signatory.url}>
                    <div className={`card ${signatory.hasSigned ? "signed" : ""}`}>
                        <div className="row">
                            <div className="col-12">
                                <h4>{signatory.fullName}</h4>
                            </div>
                            {signatory.hasSigned ?
                                (
                                    <div className="col-12 signing-status">
                                        <clr-icon size="30" shape="success-standard"></clr-icon>
                                        <h5>{t('signingCheckout.party.done')}</h5>
                                    </div>
                                )
                                :
                                (
                                    <div className="col-12 signing-status">
                                        <clr-icon size="30" shape="plus-circle"></clr-icon>
                                        <h5>{t('signingCheckout.party.clickToSign')}</h5>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

Signatory.propTypes = {
    signatory: PropTypes.object.isRequired
};

export default withTranslation('common')(Signatory);
