import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import HMButton from "../../../Utils/HMButton";
import RouterService from "../../../../services/RouterService";

class ProcessOngoingCallToAction extends Component {

    render() {
        const { t } = this.props;
        return(
            <div className="row">
                <div className="col">
                    <p>{t('apartmentLandingPage.addonProcessOngoing')}</p>
                    <div className="call-to-action-button">
                        <HMButton text={t('apartmentLandingPage.callToActions.seeMyOptions')}
                                  active={true}
                                  activeText={t('apartmentLandingPage.callToActions.seeMyOptions')}
                                  href={RouterService.roomPath(this.props.roomsOrder[0])}
                                  clickFn={() => {}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('common')(ProcessOngoingCallToAction);
