import React, {Component} from 'react';
import {connect} from "react-redux";
import * as selectionProgressActions from "../../../../actions/selectionProgressActions";
import {bindActionCreators} from "redux";
import "./style.scss"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {AppRoute, RouterService} from "../../../../services/RouterService";
import UtilService from "../../../../services/UtilService";
import PropTypes from "prop-types";
import BreadcrumbNavigation from "../../../Utils/HMBreadcrumbNavigation";
import {parse} from "query-string";
import {NavigationItemType, SelectionProgressService} from "../../../../services/SelectionProgressService";

class SubNavigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ready: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const ready = this.isRequiredPropsReady();

        if (this.state.ready !== ready) {
            this.setState({...this.state, ready: ready});

            if (this.props.resizeCallback)
                this.props.resizeCallback();
        }

        if (ready) {
            const navItems = this.getNavigationItems();

            if (navItems && navItems.length > 0) {
                const activeItem = SelectionProgressService.getActiveNavigationItem(navItems);

                if (!activeItem || this.props.location.search !== activeItem.linkUrl.search) {
                    this.props.history.replace(navItems[0].linkUrl.search);
                } else {
                    this.props.selectionProgressActions.selectionProgressVisited(activeItem);
                    if (activeItem.argument.type === NavigationItemType.ExtraCategory)
                        this.props.selectionProgressActions.selectionProgressSelected(activeItem);
                }
            }
        }
    }

    isRequiredPropsReady () {
        return UtilService.includesAnyOf(RouterService.getCurrentRoutes(this.props.location), [AppRoute.Room])
            && this.props.currentRoom !== undefined && this.props.currentRoom !== null
            && this.props.location.pathname.toLowerCase() === RouterService.roomPath(this.props.currentRoom.id).toLowerCase();
    }

    getNavigationItems() {
        return SelectionProgressService.getNavigationItems(this.props.apartment, this.props.currentRoom, this.props.selectionProgress, parse(this.props.location.search));
    }

    render() {
        let navigationItems = [];

        if (this.state.ready) {
            navigationItems = this.getNavigationItems();
        }

        if (this.state.ready && navigationItems && navigationItems.length > 0)
        return (
            <div className="hm-sub-navigation hm-hide-on-small-screens">
                <BreadcrumbNavigation className="hm-sub-navigation-menu" items={navigationItems} openStart={true} openEnd={true}/>
            </div>
        );
        else
            return (null);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        apartment: state.app.apartment2.apartment,
        currentRoom: state.app.currentRoom,
        selectionProgress: state.app.selectionProgress,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectionProgressActions: bindActionCreators(selectionProgressActions, dispatch)
    }
}

SubNavigation.propTypes = {
    resizeCallback: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SubNavigation)));
