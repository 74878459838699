import React, {Component} from 'react';
import NotFound from '../NotFound'
import './App.scss'
import * as appModes from "../../enums/appModes";
import ErrorMessageBar from '../Utils/ErrorMessageBar'
import HMLightBox from '../Utils/HMLightBox'
import {Provider} from 'react-redux'
import store, {history} from "../../reducers/store";
import '@webcomponents/custom-elements'
import '@clr/icons/clr-icons.min.css'
import '@clr/icons/clr-icons.min'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css"
import LoadingSpinner from "./LoadingSpinner";
import ProjectBackgroundImage from "./ProjectBackgroundImage";
import AuthorizedRoute from "../Auth/AuthorizedRoute";
import ChooseApartment from "../ChooseApartment";
import {ConnectedRouter} from 'connected-react-router'
import Auth from "../Auth";
import {Redirect, Route, Switch} from "react-router";
import Project from '../Project';
import Apartment from '../Apartment';
import TitleSetter from './TitleSetter';
import StickyHeader from "./Header/StickyHeader";
import {Provider as EventBusProvider} from 'react-bus';
import CookiesAlert from "./CookiesAlert";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contentMargin: 0
        }
    }

    updateContentMargin(margin) {
        if (margin > 0 && this.state.contentMargin !== margin) {
            this.setState({...this.state, contentMargin: margin});
        }
    }

    render() {

        return (
            <EventBusProvider>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <React.Fragment>
                        <TitleSetter/>
                        <CookiesAlert/>
                        <Switch>
                            <Route path="/not-found" component={NotFound}/>
                            <Route path="/auth" component={Auth}/>
                            <Route render={props => (
                                <div className="app-container">
                                    <HMLightBox/>
                                    <ErrorMessageBar/>
                                    <LoadingSpinner/>
                                    <ProjectBackgroundImage/>
                                    {/*<MinimalWidthMessage styleSettings={{top: this.state.contentMargin + 'px', 'paddingBottom': this.state.contentMargin + 'px'}}/>*/}

                                    <StickyHeader resizeCallback={(margin) => this.updateContentMargin(margin)}/>

                                    <div className="hm-hide-on-small-screens app-container container-fluid" style={{paddingTop: this.state.contentMargin + 'px'}}>
                                    <Switch>
                                        <Route path="/demo/:projectId" render={(props) => <Project {...props} appMode={appModes.DEMO} />}/>
                                        <Route path="/demo-apartment/:apartmentId" render={(props) => <Apartment {...props} appMode={appModes.DEMO} singleApartment={true} />}/>
                                        <Route path="/sales-start/:projectId" render={(props) => <Project {...props} appMode={appModes.SALESSTART} />}/>
                                        <Route path="/sales-start-apartment/:apartmentId" render={(props) => <Apartment {...props} appMode={appModes.SALESSTART} singleApartment={true} />}/>
                                        <Route path="/rentals/:projectId" render={(props) => <Project {...props} appMode={appModes.RENTALS} />}/>
                                        <Route path="/rentals-apartment/:apartmentId" render={(props) => <Apartment {...props} appMode={appModes.RENTALS} singleApartment={true} />}/>

                                        <AuthorizedRoute exact path="/" component={ChooseApartment}/>
                                        <AuthorizedRoute path="/:projectId" render={(props) => <Project {...props} appMode={appModes.ADDON} />}/>

                                        <Redirect to="/"/>
                                    </Switch>
                                    </div>
                                </div>
                            )}/>
                        </Switch>
                        </React.Fragment>
                    </ConnectedRouter>
                </Provider>
            </EventBusProvider>
        );
    }
}

export default App;
