import store from "../reducers/store";
import {ADDON} from "../enums/appModes";
import BackendApi from "../api/BackendApi";

export function getPayment() {
    return function (dispatch) {
        const state = store.getState();

        if (state.app.appMode === ADDON && state.app.apartment2.apartment) {

            return BackendApi.getPaymentStatus(state.app.apartment2.apartment.id).then(response => {
                if (response && response.status === 200 && response.data.data.publicToken) {
                    // return existing payment
                    return response.data;
                }

                return undefined;
            }).catch(() => {
                return undefined;
            });
        }
    }
}
