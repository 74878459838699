import React, {Component} from 'react';
import "./style.scss"
import {connect} from 'react-redux';
import {CSSTransition} from 'react-transition-group';

class LoadingSpinner extends Component {

    render() {
        return (
        <CSSTransition
            in={this.props.loading > 0}
            timeout={250}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={{
                enter: 'animated animated-short',
                enterActive: 'fadeIn',
                exit: 'animated animated-short',
                exitActive: 'fadeOut'
            }}
        >
            <div className="hm-loading-wrapper">
                <div className="messageWrapper">
                    <div className="spinner">
                        <div className="double-bounce1"/>
                        <div className="double-bounce2"/>
                    </div>
                    {this.props.loadingMessages && this.props.loadingMessages.length > 0 &&
                    <div className="message">
                        {this.props.loadingMessages.map(m =>
                            <h4 key={m.key}>{m.message}</h4>
                        )}
                    </div>
                    }
                </div>
            </div>
        </CSSTransition>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        loading: state.app.loading,
        loadingMessages: state.app.loadingMessages
    }
}

export default connect(mapStateToProps)(LoadingSpinner)

