import React, {Component} from 'react';
import "./style.scss"
import ProductCard from "../../Product/ProductCard"
import ProductSelection from "../../../../models/ProductSelection";
import PropTypes from "prop-types";
import HeaderLabel from "../../../Utils/HeaderLabel";
import HMNextAction from "../../../Utils/HMNextAction";
import HMCostInfo from "../../../Utils/HMCostInfo";
import HMChooseStandardsForRoomAction from "../../../Utils/HMChooseStandardsForRoomAction";
import {AppRoute} from "../../../../services/RouterService";
import {withRouter} from "react-router-dom";
import ProductPickerInfoModal from "../../Product/ProductPickerInfoModal";
import {connect} from "react-redux";
import {withBus} from 'react-bus';
import {HM_TOPBAR_OPEN_MOBILE_NAVIGATION} from "../../../../actions/eventTypes";

class SummaryPicker extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isShowModal: false,
            productSelection: undefined,
            projectProduct: undefined
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.synchronizeHashAndModals();
    }

    synchronizeHashAndModals() {
        if (!this.props.history.location.hash || this.props.history.location.hash.indexOf("productInfo") < 0) {
            if (this.state.isShowModal) {
                setTimeout(() => this.toggleShowProductInfo(false, undefined, undefined, false));
            }
        }
    }

    toggleShowProductInfo(isShowModal, productSelection = undefined, projectProduct = undefined, navigate = true) {
        if (this.state.isShowModal !== isShowModal) {
            this.setState({...this.state, isShowModal: isShowModal, productSelection: isShowModal ? productSelection : this.state.productSelection, projectProduct: isShowModal ? projectProduct : this.state.projectProduct});

            if (!isShowModal) {
                setTimeout(() => this.setState({...this.state, productSelection: undefined, projectProduct: undefined}), 300);
            }

            if (navigate) {
                if (isShowModal) {
                    this.props.history.push(
                        this.props.history.location.pathname
                        + this.props.history.location.search
                        + (this.props.history.location.hash.startsWith('#') ? this.props.history.location.hash : '#')
                        + 'productInfo');
                } else {
                    if (!!this.props.history.location.hash && this.props.history.location.hash.indexOf("productInfo") >= 0) {
                        this.props.history.goBack();
                    }
                }
            }
        }
    }

    render() {
        const {title, description, productSelections, showStatus, hasNextStep, onCloseButtonClick} = this.props;

        if (!productSelections) return (null);

        return (
            <React.Fragment>
                <div className={`hm-summary-picker`}>
                    <div className={`scrollable ${this.props.hasNextStep ? '' : 'no-next-step'}`}>
                        <HeaderLabel
                            text={title}
                            subText={description}
                            fallbackSubTextKey={this.props.showStatus ? undefined : "pickerFallbackText"}
                        />
                        <div className="product-list">

                            {productSelections && productSelections.map((productSelection, i) => {
                                return <React.Fragment key={`ProductCard-${i}`}>
                                        <div className="row no-gutters category-name">
                                            <div className="col-12">
                                                <h4>{productSelection.subCategory.name}</h4>
                                            </div>
                                        </div>
                                        <div className="top-border hm-summary-picker-product-card">
                                            <ProductCard
                                                hidePrices={this.props.hidePrices}
                                                showStatus={showStatus}
                                                isShowInfoButton={true}
                                                productSelection={productSelection}
                                                projectProduct={productSelection.projectProduct}
                                                showInfo={() => this.toggleShowProductInfo(true, productSelection, productSelection.projectProduct)}
                                            />
                                        </div>
                                </React.Fragment>
                            })}

                        </div>
                        {this.props.selectedNavigationItem && this.props.selectedNavigationItem.isStart && !this.props.isMobile &&
                        <HMChooseStandardsForRoomAction/>
                        }

                    </div>
                    {hasNextStep &&
                        <React.Fragment>
                            {!this.props.hidePrices && <HMCostInfo currentRoutes={this.props.currentRoutes} onClick={this.props.isMobile ? () => {this.props.bus.emit(HM_TOPBAR_OPEN_MOBILE_NAVIGATION)} : null}/>}
                            <HMNextAction
                                active={true}
                                selectedNavigationItem={this.props.selectedNavigationItem}
                                nextUrlLink={this.props.nextUrlLink}
                                appSizes={this.props.appSizes}
                            />
                        </React.Fragment>
                    }
                    {!!onCloseButtonClick &&
                    <button className="hm-button btn hm-button-default hm-thin-button p-0 hm-floating-action-button" type="button" onClick={() => onCloseButtonClick()}>
                        <clr-icon shape="times" size="30"></clr-icon>
                    </button>
                    }
                </div>

                <ProductPickerInfoModal
                    hidePrices={this.props.hidePrices}
                    isOpen={this.state.isShowModal}
                    toggle={() => this.toggleShowProductInfo(false)}
                    productSelection={this.state.productSelection} projectProduct={this.state.projectProduct}
                    isMobile={this.props.isMobile}
                />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        isMobile: state.app.appSizes.app.isMobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

SummaryPicker.propTypes = {
    readOnly: PropTypes.bool,
    hidePrices: PropTypes.bool.isRequired,
    productSelections: PropTypes.arrayOf(PropTypes.instanceOf(ProductSelection)), // The product selections this component will summaries
    title: PropTypes.string,
    description: PropTypes.string,
    showStatus: PropTypes.bool,
    hasNextStep: PropTypes.bool,
    selectedNavigationItem: PropTypes.object,
    nextUrlLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    appSizes: PropTypes.object.isRequired,
    onCloseButtonClick: PropTypes.func,
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};

SummaryPicker.defaultProps = {
    showStatus: true,
    hasNextStep: true,
};

export default withBus()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryPicker)));
