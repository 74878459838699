import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as imageActions from "../../../../actions/imageActions";
import ProductService from "../../../../services/ProductService";
import HMImage from "../../../Utils/HMImage";
import ProductAlternativesService from "../../../../services/ProductAlternativesService";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import ProductInfoTable from "../ProductInfoTable";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import './style.scss'

class ProductPickerInfoModal extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            projectProduct: props.projectProduct
        };
    }

    _timeout = undefined;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.projectProduct || !this.props.productSelection) {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(() => this.setState({...this.state, projectProduct: undefined}));
        } else if (!this.state.projectProduct) {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(() => this.setState({...this.state, projectProduct: this.props.projectProduct}));
        }
    }

    componentWillUnmount() {
        clearTimeout(this._timeout);
    }

    setAlternative(alternativeProjectProduct) {
        this.setState({projectProduct: alternativeProjectProduct});
    }

    getMergedAlternatives() {
        const {productSelection} = this.props;
        const nonDisabledOptions = productSelection.options.filter((projectProduct) => {
            return !projectProduct.disabled
        });

        return ProductAlternativesService.buildProjectProductAlternativesMap(nonDisabledOptions, productSelection.projectProduct);
    }

    render() {
        if (!this.props.projectProduct || !this.props.productSelection) {
            return null;
        }

        let alternatives = undefined;

        if (this.props.productSelection) {
            alternatives = this.getMergedAlternatives();
            alternatives = alternatives.find(({projectProduct}) => {
                return projectProduct.id === this.props.projectProduct.id
            }).alternatives;
        }

        const projectProduct = this.state.projectProduct;
        if (!projectProduct) {
            return (null);
        }

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                size="lg"
                centered={true}
                backdrop={true}
                contentClassName="hm-productinfo-modal"
                className={this.props.isMobile ? "hm-full-screen-modal-modal" : ""}
                modalClassName={this.props.isMobile ? "hm-full-screen-modal" : ""}
            >
                <ModalHeader
                    close={!this.props.isMobile ? (
                        <button className="hm-button btn hm-button-default hm-thin-button p-0" type="button" onClick={this.props.toggle}>
                            <clr-icon shape="times" size="30"></clr-icon>
                        </button>
                        ) : (null)
                    }
                >
                    {projectProduct.product.vendor} {projectProduct.product.name}
                </ModalHeader>
                <ModalBody className="bg-frosted pt-1">
                    <div className="container">
                        <div className="row show-grid no-gutters">
                            <div className="col-md-8 col-sm-12 pl-0 pr-0">
                                <div className="container">
                                    <div className="row image-container-white image-container-h400">
                                        <HMImage imageSrc={ProductService.getImageUrl(projectProduct.product)}
                                                 className={`hm-product-image`}
                                                 title={projectProduct.product.productNum}
                                                 imageVisibilityType={'contain'}/>
                                    </div>
                                    {
                                        alternatives && alternatives.length > 0 &&

                                        <div className="row show-grid">
                                            {alternatives.map(alternative =>
                                                <div key={`${this.constructor.name}-${alternative.id}`}
                                                     className={`alternative-thumbnail image-container-white ${projectProduct.id === alternative.id ? 'alternative-selected' : ''}`}>
                                                    <HMImage
                                                        imageVisibilityType={'contain'}
                                                        imageSrc={ProductService.getImageUrl(alternative.product)}
                                                        onClick={() => this.setAlternative(alternative)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>

                            </div>

                            <ProductInfoTable hidePrices={this.props.hidePrices} projectProduct={projectProduct} productSelection={this.props.productSelection}/>

                        </div>

                    </div>
                </ModalBody>
                {this.props.isMobile &&
                <button className="hm-button btn hm-button-default hm-thin-button p-0 hm-floating-action-button" type="button" onClick={this.props.toggle}>
                    <clr-icon shape="times" size="30"></clr-icon>
                </button>
                }
            </Modal>
        )
    };
}

ProductPickerInfoModal.propTypes = {
    hidePrices: PropTypes.bool,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    projectProduct: PropTypes.object,
    productSelection: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
}

ProductPickerInfoModal.defaultProps = {
    isOpen: false,
    toggle: null,
    projectProduct: null,
    productSelection: null,
    isMobile: false,
}

function mapDispatchToProps(dispatch) {
    return {
        imageActions: bindActionCreators(imageActions, dispatch)
    }
}

export default connect(mapDispatchToProps)(withTranslation('common')(ProductPickerInfoModal));
