import {DEMO, RENTALS, SALESSTART} from "../enums/appModes";

// TODO: make this config environment aware
export const Config = {
    DEV: false,
    API_BASE_URL: "https://tillval.envise.io",
    // API_BASE_URL: "https://tillval-stage.envise.io",
    // API_BASE_URL: "http://localhost:8080",
    IN_BROWSER_URL: "https://hm-3d-scenes.s3.eu-west-1.amazonaws.com/showroom",
    VERBOSE_LOGGING: false,
    VERSION: "24.1.1",
    READ_ONLY_MODES: [DEMO, RENTALS, SALESSTART],
    HIDE_PRICES_MODES: [SALESSTART],
    OPEN_NAVIGATION_MODES: [SALESSTART],
    // HOTJAR: { // https://showroom-stage.envise.io/
    //     hjid:1229496,
    //     hjsv:6
    // }
    HOTJAR: { // https://showroom.envise.io/
        hjid:1232020,
        hjsv:6
    }
};
