import React, {Component} from 'react';
import {connect} from "react-redux";
import * as contactFormActions from "../../../actions/contactFormActions";
import {bindActionCreators} from "redux";
import "./style.scss"
import {withRouter} from 'react-router-dom';
import Form from '@react-schema-form/bootstrap';
import PropTypes from "prop-types";
import HMButton from "../../Utils/HMButton";
import {withTranslation} from "react-i18next";
import RouterService from "../../../services/RouterService";
import {Redirect} from "react-router";
import i18n from "../../../i18n";

class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: props.leadFormSchema && props.leadFormSchema.defaultFormData ? props.leadFormSchema.defaultFormData : undefined
        };

        if (!props.leadFormSchema) {
            this.props.contactFormActions.downloadFormSchema();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.apartment2.apartmentId !== prevProps.apartment2.apartmentId) {
            this.props.contactFormActions.downloadFormSchema();
        }

        if ((this.props.leadSent && !prevProps.leadSent) || (this.props.leadFormSchema !== prevProps.leadFormSchema)) {
            this.setState({...this.state, formData: {...this.props.leadFormSchema.defaultFormData}});
        }

        if (this.props.leadSent && !prevProps.leadSent) {
            this.props.contactFormActions.resetLead();
        }
    }

    handleChange = ({ formData }) => {
        this.setState({ ...this.state, formData });
    };

    onSubmit = ({ formData }) => {
        this.props.contactFormActions.sendLead(formData);
    };

    transformValidationErrors = (errors) => {
        return errors.map(error => {
            switch (error.name) {
                case "required":
                    error.message = i18n.t('contactForm.errors.required');
                    break;

                case "type":
                case "pattern":
                    if (error.property.includes("email")) {
                        error.message = i18n.t('contactForm.errors.email');
                    } else if (error.property.includes("phone")) {
                        error.message = i18n.t('contactForm.errors.phone');
                    }
                    break;

                case "format":
                    if (error.params.format === "email") {
                        error.message = i18n.t('contactForm.errors.email');
                    }
                    break;

                default:
                    break;
            }

            return error;
        })
    };

    render() {
        const { t } = this.props;

        if (!this.props.leadFormSchema || !this.state.formData)
            return(null);
        else if (this.props.leadSent) {
            return (<Redirect to={RouterService.confirmationPath()}/>);
        }
        else
            return(
                <div className="container-fluid hm-contact-form bg-frosted">
                    <div className="row hm-contact-form-header">
                        <div className="col">
                            <div className="row align-items-end bottom-border">
                                <div className="col-12 px-0">
                                    <h1>{t('contactForm.title')}</h1>
                                </div>
                            </div>


                            <div className="row justify-content-center">
                                <div className="col px-0 contact-form">
                                    <Form schema={this.props.leadFormSchema.schema}
                                          formData={this.state.formData}
                                          uiSchema={this.props.leadFormSchema.uiSchema}
                                          onChange={this.handleChange}
                                          onSubmit={this.onSubmit}
                                          noHtml5Validate={true}
                                          showErrorList={false}
                                          transformErrors={this.transformValidationErrors}
                                          liveValidate={false}
                                    >
                                        <div className="submit-button-wrapper">
                                            <HMButton text={t('common:submit')}
                                                      active={true}
                                                      activeText={t('common:submit')}
                                                      clickFn={() => {}}
                                                      type="submit"
                                                      disabled={this.props.leadSending}
                                                      disabledText={t('common:sending')}/>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        leadSending: state.app.leadSending,
        leadSent: state.app.leadSent,
        leadFormSchema: state.app.leadFormSchema,
        apartment2: state.app.apartment2
    }
}

function mapDispatchToProps(dispatch) {
    return {
        contactFormActions: bindActionCreators(contactFormActions, dispatch)
    }
}

ContactForm.propTypes = {
    formData: PropTypes.object,
};

ContactForm.defaultProps = {
    formData: {}
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ContactForm)));
