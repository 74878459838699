import React, {Component} from 'react';
import ProductService from "../../../../services/ProductService";
import PropTypes from "prop-types";
import ProjectProduct from "../../../../models/ProjectProduct";
import {withTranslation} from "react-i18next";
import "./style.scss"

class ProductStatus extends Component {

    constructor(props) {
        super(props);

        if (props.projectProduct)
            this.state = this.createStateForProps();
        else
            this.state = {
                statusTranslationKey: null,
                className: "",
                forProjectProduct: null
            }
    }

    componentDidUpdate(prev) {

        // Update the state only if the new property is another Project product than before
        if (this.props.projectProduct === this.state.forProjectProduct)
            return;

        this.setState(this.createStateForProps())
    }

    createStateForProps () {
        if (this.props.projectProduct) {
            const style = ProductService.getStatusStyle(this.props.projectProduct, this.props.hidePrices);
            return {statusTranslationKey: style.translationKey, className: style.textColorClass, forProjectProduct: this.props.projectProduct};
        }

        return {
            statusTranslationKey: null,
            className: "",
            forProjectProduct: null
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="hm-product-status">
                <span className={this.state.className}>
                    {this.state.statusTranslationKey ? t(this.state.statusTranslationKey) : ' '}
                </span>
            </div>
        )
    }
}

ProductStatus.propTypes = {
    projectProduct: PropTypes.instanceOf(ProjectProduct),
    hidePrices: PropTypes.bool.isRequired
};

export default withTranslation('common')(ProductStatus);
