import {Tutorial} from "../config/tutorial";
import UtilService from "./UtilService";

export class TutorialService {

    static buildSteps(tutorialProgress) {
        const style = {
            backgroundColor: '#7fbcec',
            width: '428px'
        };

        const name = "threeAreasTour";
        const tutorial = Tutorial.tutorials[name];
        const fullSteps = tutorial.steps;
        const steps = [];
        fullSteps.forEach(stepName => {
            if(tutorialProgress.visitedSteps.indexOf(stepName) === -1) {
                let step = Tutorial.items[stepName];
                const isSelectorSatisfied = step.selector ? document.querySelector(step.selector) : false;
                if(isSelectorSatisfied) {
                    step = {
                        ...step,
                        name: stepName,
                        style: style
                    };
                    steps.push(step);
                }
            }
        });
        return steps;
    }

    static _tutorialProgressLocalStorageKey = 'hmTutorialProgress';

    static loadProgressFromLocalStorage() {
        try {
            const serializedState = UtilService.getFromLocalStorage(this._tutorialProgressLocalStorageKey);

            if (serializedState === null) {
                return {
                    isTourOpen: true,
                    steps: [],
                    visitedSteps: [],
                    isLoaded: false
                }
            }
            return JSON.parse(serializedState);
        } catch (err) {
            console.error(err);
            return {
                isTourOpen: true,
                steps: [],
                visitedSteps: [],
                isLoaded: false
            };
        }
    }

    static saveTutorialProgress(tutorialProgressState) {
        const progressWithoutSteps = {
            ...tutorialProgressState,
            steps: []
        };

        try {
            const serializedState = JSON.stringify(progressWithoutSteps);
            UtilService.persistToLocalStorage(this._tutorialProgressLocalStorageKey, serializedState);
        } catch(err) {
            console.error('Tutorial progress write error:');
            console.error(err);
        }
    }
}
