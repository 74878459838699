import React, {Component} from 'react';
import './style.scss'
import HMButton from "../../Utils/HMButton";
import HMImageCarousel, {HMImageCarouselMode} from "../../Utils/HMImageCarousel";
import PropTypes from "prop-types";

class HMImageCarouselWithContentAndCallToAction extends Component {

    render() {
        return(
            <div className={`hm-image-carousel-with-content-and-call-to-action row ${this.props.className || ''}`}>
                <div className="col m-0 p-0">
                    <HMImageCarousel images={this.props.images}
                                     containerHeight={this.props.containerHeight}
                                     mode={HMImageCarouselMode.OneFadingImage}/>

                    <div className="row justify-content-center content-wrapper">
                        <div className="content-wrapper-col-auto col-auto">
                            <div className="row">
                                {this.props.contentHtml &&
                                <div className="col-12 bg-frosted-heavy content"
                                     dangerouslySetInnerHTML={{__html: this.props.contentHtml}}/>
                                }
                                {this.props.children &&
                                <div className="col-12 bg-frosted-heavy content-children">
                                    {this.props.children}
                                </div>
                                }
                                <div className="call-to-action col-12 text-right">
                                    <div className="float-right">
                                        {this.props.callToActionText &&
                                        <HMButton text={this.props.callToActionText}
                                                  active={true}
                                                  activeText={this.props.callToActionText}
                                                  href={this.props.callToActionHref}
                                                  clickFn={this.props.callToActionClick || (() => {})}
                                        />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

HMImageCarouselWithContentAndCallToAction.propTypes = {
    className: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    containerHeight: PropTypes.string.isRequired,
    contentHtml: PropTypes.string,
    callToActionText: PropTypes.string,
    callToActionHref: PropTypes.string,
    callToActionClick: PropTypes.func
};

export default HMImageCarouselWithContentAndCallToAction;
