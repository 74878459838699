import React, {Component} from 'react';
import "./style.scss"
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Link, NavLink} from "react-router-dom";
import {debounce} from "../../../services/UtilService";
import {SelectionProgressService} from "../../../services/SelectionProgressService";

export class BreadcrumbNavigationItem {
    key;
    parent;
    selectionProgress;
    argument;
    title;
    titleKey;
    children;
    childrenExpanded;
    forceActive;
    active;
    finished;
    partlyFinished;
    onClick;
    isNavLink;
    linkUrl;

    getTitle(t, params) {
        if (this.fullTitleKey)
            return t(this.fullTitleKey, params);

        if (this.titleKey)
            return t(this.titleKey);

        return this.title || "";
    }
}

class BreadcrumbNavigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            overflow: false
        };
    }

    _lastActiveItem = undefined;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.items) {
                const currentActiveItem = SelectionProgressService.getActiveNavigationItem(this.props.items);

                const shouldNotCalcOverflow = !!currentActiveItem && !!this._lastActiveItem && (
                    (currentActiveItem.key === this._lastActiveItem.key)
                    || ((!currentActiveItem.children || currentActiveItem.children.length === 0 || !currentActiveItem.childrenExpanded) && (!this._lastActiveItem.children || this._lastActiveItem.children.length === 0 || !this._lastActiveItem.childrenExpanded) && ((!currentActiveItem.parent && !this._lastActiveItem.parent) || (!!currentActiveItem.parent && !!this._lastActiveItem.parent && currentActiveItem.parent.key === this._lastActiveItem.parent.key)))
                    || (!!currentActiveItem.parent && currentActiveItem.parent.key === this._lastActiveItem.key)
                    || (!!currentActiveItem.parent && !!this._lastActiveItem.parent && currentActiveItem.parent.key === this._lastActiveItem.parent.key)
                    || (!!this._lastActiveItem.parent && currentActiveItem.key === this._lastActiveItem.parent.key)
                    || (currentActiveItem.key === this._lastActiveItem.key));

                if (!shouldNotCalcOverflow) {
                    this.calcOverflow();
                }

                this._lastActiveItem = currentActiveItem
            }
        }
    }

    _onResize;
    componentDidMount(prevProps, prevState, snapshot) {
        this._onResize = debounce(() => {
            this.calcOverflow();

        }, 1000);
        window.addEventListener('resize', this._onResize);
        this.calcOverflow();
    }

    componentWillUnmount() {
        if (this._onResize) {
            window.removeEventListener('resize', this._onResize);
            this._onResize = null;
        }

        if (this._overflowTimeout) {
            clearTimeout(this._overflowTimeout);
            this._overflowTimeout = undefined;
        }
    }

    getItemClasses(item, level) {
        let classes = `bn-level-${level}`;

        if (this.state.overflow && this._beforeActive)
            classes += " overflow";

        if (item.finished)
            classes += " bn-finished";

        if (item.partlyFinished)
            classes += " bn-partly-finished";

        if (!item.finished && !item.partlyFinished)
            classes += " bn-unfinished";

        if (!item.isNavLink && item.active || item.forceActive)
            classes += " bn-active";

        if (!item.linkUrl && !item.onClick)
            classes += " bn-inactive-link";

        if (item.active) {
            this._beforeActive = false;
        }

        return classes;
    }

    getIcon(item) {

        // Show user an error message for this nav item should the selected product be disabled and illegal from Product relationships
        if (item.hasError)
            return <div className="clr-white-background"><div></div><clr-icon shape="error-standard" class="is-solid text-danger"></clr-icon></div>;

        if (item.isLocked)
            return <clr-icon shape="lock" class="is-solid is-locked"></clr-icon>;

        if (item.finished)
            return <div className="clr-white-background"><div></div><clr-icon shape="success-standard" class="is-solid"></clr-icon></div>;

        if (item.partlyFinished)
            return <clr-icon shape="circle" class="is-solid"></clr-icon>;

        return this.props.showUnfinishedIcon ? <clr-icon shape="circle"></clr-icon> : null;
    }

    generateItem(item, level, hidden, index) {
        const { t } = this.props;

        const parent =
            <li key={item.key} className={`${hidden ? 'hidden' : ''}`}>
                {item.linkUrl && item.isNavLink &&
                <NavLink
                    className={this.getItemClasses(item, level)}
                    activeClassName="bn-active"
                    to={item.linkUrl}
                    onClick={() => item.onClick && item.onClick(item)}
                >
                    {this.getIcon(item)}
                    {item.title && <span>{item.title}</span>}
                    {!item.title && item.titleKey && <span>{t(item.titleKey)}</span>}
                </NavLink>}

                {item.linkUrl && !item.isNavLink &&
                <Link
                    className={this.getItemClasses(item, level)}
                    to={item.linkUrl}
                    onClick={() => item.onClick && item.onClick(item)}
                >
                    {this.getIcon(item)}
                    {item.title && <span>{item.title}</span>}
                    {!item.title && item.titleKey && <span>{t(item.titleKey)}</span>}
                </Link>}

                {!item.linkUrl &&
                <a
                    className={this.getItemClasses(item, level)}
                    href="javascript:void(0);"
                    onClick={() => item.onClick && item.onClick(item)}
                >
                    {this.getIcon(item)}
                    {item.title && <span>{item.title}</span>}
                    {!item.title && item.titleKey && <span>{t(item.titleKey)}</span>}
                </a>
                }
            </li>;

        if (!item.children || item.children.length === 0)
            return parent;

        return [parent]
            .concat(item.children.map((child, i) => this.generateItem(child, level + 1, !item.childrenExpanded, i)));
    }

    _menuRef = undefined;
    setMenuElement(el) {
        this._menuRef = el;
    }

    _overflowTimeout = undefined;
    calcOverflow() {
        if (this.state.overflow) {
            this.setState({...this.state, overflow: false});
        }

        if (this._overflowTimeout) {
            clearTimeout(this._overflowTimeout);
            this._overflowTimeout = undefined;
        }

        this._overflowTimeout = setTimeout(() => {
            if (this._menuRef) {
                const overflow = this._menuRef.scrollWidth > this._menuRef.clientWidth;

                if (overflow !== this.state.overflow) {
                    this.setState({...this.state, overflow: overflow});
                }
            }
        }, 500);
    }

    _beforeActive;

    render() {
        if (!this.props.items || this.props.items.length === 0)
            return (null);

        this._beforeActive = true;

        return <ul key={this.props.items} ref={el => this.setMenuElement(el)} className={`hm-breadcrumbs-navigation ${this.props.className || ''} ${this.props.openStart ? 'bn-open-start' : 'bn-closed-start'} ${this.props.openEnd ? 'bn-open-end' : 'bn-closed-end'}`}>
            {this.props.items.map((item, i) => {
                return this.generateItem(item, 0, false, i);
            })}
        </ul>
    }
}

BreadcrumbNavigation.propTypes = {
    className: PropTypes.string,
    openStart: PropTypes.bool,
    openEnd: PropTypes.bool,
    showUnfinishedIcon: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.instanceOf(BreadcrumbNavigationItem)).isRequired,
};

export default withTranslation('common')(BreadcrumbNavigation);
