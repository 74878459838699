import React, {Component} from 'react';
import "./style.css"
import ProductSelection from "../../../models/ProductSelection";
import PropTypes from "prop-types";
import ProductService from "../../../services/ProductService";
import ProductStatus from "../../Room/Product/ProductStatus";
import GrandTotal from "../../Utils/GrandTotal";
import {Trans, withTranslation} from "react-i18next";
import HMImage from "../../Utils/HMImage";

class ProductCategorySummary extends Component {

    constructor(props) {
        super(props);
        this.state = {statusBorderColorClass: ""};
    }

    componentDidMount() {
        if (this.props.productSelection.projectProduct) {
            this.setState({
                ...this.state,
                statusBorderColorClass: ProductService.getStatusStyle(this.props.productSelection.projectProduct).borderColorClass
            });
        }
    }

    render() {
        const { t, styleName } = this.props;

        if (!this.props.productSelection.projectProduct)
            return null;

        // It is safe to say this.props.productSelection.projectProduct will never be null here as "extra options" should be filtered out in the summary

        return (
            <div className={`hm-product row align-items-center px-0 ${this.state.statusBorderColorClass}`}>
                <div className="d-block d-lg-none col-lg-1 col-12 px-0 h-100 align-self-start text-right" style={{marginBottom: '-22px'}}>
                    <ProductStatus projectProduct={this.props.productSelection.projectProduct} hidePrices={this.props.hidePrices}/>
                </div>

                <div className="col-lg-4 col-md-6 col-12 px-0 pt-md-0 pt-2">
                    <div className="image-outer-wrapper">
                        <HMImage className="pointer"
                                 imageSrc={ProductService.getImageUrl(this.props.productSelection.projectProduct.product)}
                                 imageVisibilityType={'contain'}
                                 onClick={() => this.props.onImageClicked && this.props.onImageClicked()}/>
                    </div>
                    <div className="justify-content-center">
                        {this.props.isInStyleWithFixedPrice &&
                        <span className="px-0 uppercase">{this.props.styleName}:</span>
                        }
                        <h5 className="my-0 px-0 uppercase">{this.props.productSelection.subCategory.name}</h5>
                    </div>
                </div>

                <div className="col-lg-2 col-md-6 col-12 px-0 pl-md-3 pt-md-0 pt-2">
                    <p className="product-vendor text-truncate">{this.props.productSelection.projectProduct.product.vendor}</p>
                    <p className="product-name italic">{this.props.productSelection.projectProduct.product.name}</p>
                </div>

                <div className={this.props.hidePrices ? 'col-12 col-lg-5 col-md-6 px-lg-3 px-0 pt-md-0 pt-2' : 'col-12 col-lg-4 col-md-6 px-lg-3 px-0 pt-md-0 pt-2'}>
                    {[{prop: 'productNum'}, {prop: 'color'}, {prop: 'material'}, {prop: 'assembly'}, {prop: 'dimension'}, {prop: 'description', hideName: true}].map(prop => { return this.props.productSelection.projectProduct.product[prop.prop] != null ? (
                        <p key={prop.prop} className="product-property text-truncate">{!prop.hideName && <span className="italic">{t(`common:product.property.${prop.prop}`)}: </span>}<span>{this.props.productSelection.projectProduct.product[prop.prop]}</span></p>
                    ) : null})}
                </div>

                {!this.props.hidePrices &&
                <div className="col-lg-1 col-md-6 col-12 px-lg-0 pl-md-3 px-0 pt-md-0 pt-2">
                    {!this.props.isInStyleWithFixedPrice &&
                        <p className="italic">{t('common:cost')}</p>
                            &&
                        <p className="bold">
                            <GrandTotal grandTotal={{price: this.props.productSelection.price, hasDelayedPrice: this.props.productSelection.getDelayedPrice()}} showDelayedPrice={false}/>
                        </p>
                    }

                    {this.props.isInStyleWithFixedPrice &&
                    <p>
                        <Trans i18nKey="common:summary.partOfTheStyle">
                            Included in <strong>{{styleName}}</strong> package.
                        </Trans>
                    </p>
                    }
                </div>
                }

                <div className="d-none d-lg-block col-lg-1 col-12 px-0 h-100 align-self-start text-right">
                    <ProductStatus projectProduct={this.props.productSelection.projectProduct} hidePrices={this.props.hidePrices}/>
                </div>
            </div>
        )
    }
}

ProductCategorySummary.propTypes = {
    hidePrices: PropTypes.bool,
    productSelection: PropTypes.instanceOf(ProductSelection).isRequired,
    isInStyleWithFixedPrice: PropTypes.bool.isRequired,
    styleName: PropTypes.string,
    onImageClicked: PropTypes.func
};

export default withTranslation('common')(ProductCategorySummary);
