import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../../../actions/roomActions";
import * as tutorialActions from "../../../../actions/tutorialActions";
import {bindActionCreators} from "redux";
import "./style.scss"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {AppRoute, RouterService} from "../../../../services/RouterService";
import UtilService from "../../../../services/UtilService";
import {CSSTransition} from 'react-transition-group';
import PropTypes from "prop-types";
import BreadcrumbNavigation, {BreadcrumbNavigationItem} from "../../../Utils/HMBreadcrumbNavigation";
import {parse} from "query-string";
import {SelectionProgressService} from "../../../../services/SelectionProgressService";


class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ready: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const ready = this.isRequiredPropsReady();

        if (this.state.ready !== ready) {
            this.setState({...this.state, ready: ready});

            if (this.props.resizeCallback)
                this.props.resizeCallback();
        }
    }

    isRequiredPropsReady () {
        return UtilService.includesAnyOf(RouterService.getCurrentRoutes(this.props.location), [AppRoute.Room, AppRoute.Summary, AppRoute.Checkout, AppRoute.Confirmation])
            && this.props.apartment && this.props.currentRoomId !== undefined;
    }

    render() {
        const currentRoutes = RouterService.getCurrentRoutes(this.props.location);

        let navigationItems;
        if (this.state.ready) {
            navigationItems = SelectionProgressService.getRoomNavigationItems(this.props.apartment, this.props.roomsOrder.map(roomId => this.props.rooms.get(roomId)), this.props.currentRoomId, currentRoutes);
            SelectionProgressService.fillRoomSelectionProgress(navigationItems, parse(this.props.location.search));
            SelectionProgressService.addRoomNavigationItemsForRoutes(navigationItems, currentRoutes, this.props.openNavigation, this.props.readOnlyMode, this.props.project.settings.doLeadsForSalesStart);
        }

        // if (this.isRequiredPropsReady())
        return (
            <CSSTransition
                in={this.state.ready}
                timeout={this.state.ready ? 100 : 10}
                classNames={{
                    enter: "animated",
                    enterActive: "fadeIn",
                    exit: "animated",
                    exitActive: "fadeOut"
                }}
                mountOnEnter={true}
                unmountOnExit={true}
                onEntered={() => this.props.resizeCallback()}
                onExited={() => this.props.resizeCallback()}>
            <div className="hm-navigation hm-hide-on-small-screens">
                {this.state.ready && navigationItems &&
                    <BreadcrumbNavigation
                        className="hm-navigation-menu"
                        items={navigationItems}
                        openStart={true}
                        openEnd={!UtilService.includesAnyOf(currentRoutes, [AppRoute.Confirmation])}
                        showUnfinishedIcon={true}
                    />
                }

                {this.props.currentRoom && !this.props.currentRoom.isLocked &&
                <div id='tutorial-id-tutorial-area'  className="restart-tutorial-button float-right" onClick={() => {
                    this.props.tutorialActions.resetTutorial();
                    this.props.tutorialActions.updateSteps();}}>
                    <clr-icon shape="unknown-status" size="30"></clr-icon>
                </div>
                }
            </div>
            </CSSTransition>
        );
        // else
        //     return (null);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        openNavigation: state.app.openNavigation,
        readOnlyMode: state.app.readOnlyMode,
        project: state.app.project.project,
        apartment: state.app.apartment2.apartment,
        currentRoomId: state.app.currentRoomId,
        currentRoom: state.app.currentRoom,
        roomsOrder: state.app.roomsOrder,
        rooms: state.app.rooms,
        visited: state.app.visited,
        selectionProgress: state.app.selectionProgress,
        linkedProducts: state.app.linkedProducts // Watch linkedProducts so that component is always up to date with downloaded data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        tutorialActions: bindActionCreators(tutorialActions, dispatch)
    }
}

Navigation.propTypes = {
    resizeCallback: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Navigation)));
