import React, {Component} from 'react';
import Slider from "react-slick/lib";
import UtilService from "../../../services/UtilService";
import PropTypes from "prop-types";
import './style.scss';
import {Enum} from "enumify";

export class HMImageCarouselMode extends Enum {}

HMImageCarouselMode.initEnum({
    OneFadingImage: {
        get slickSettings() {
            return {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 7000,
                dots: true,
                fade: true,
                pauseOnHover: false,
                swipe: false,
                touchMove: false
            }
        }
    },

    TwoSlidingImages: {
        get slickSettings() {
            return {
                arrows: true,
                autoplay: true,
                autoplaySpeed: 12000,
                dots: true,
                draggable: true,
                fade: false,
                infinite: true,
                pauseOnHover: false,
                slidesToShow: 2,
                swipe: true,
                touchMove: true
            }
        }
    },
});


class HMImageCarousel extends Component {

    componentDidMount() {
        UtilService.preloadImages(this.props.images);
    }

    render() {

        if (!this.props.images || this.props.images.length === 0)
            return null;

        const sliderSettings = {...this.props.mode.slickSettings, ...this.props.slickSettingsOverride};

        return (
        <div className={`hm-image-carousel row ${this.props.className || ''}`}>
            <Slider className="col-12 image-carousel"
                    {...sliderSettings}
            >
                {this.props.images.map((image, i) => {
                    return (
                        <div key={`mood-image-${i}`} className="image-wrapper">
                            <div className="image-wrapper" style={{'backgroundImage': `url(${image})`, height: this.props.containerHeight}}/>
                        </div>
                    );
                })}
            </Slider>
        </div>
        )
    }
}

HMImageCarousel.propTypes = {
    className: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    containerHeight: PropTypes.string.isRequired,
    mode: PropTypes.instanceOf(HMImageCarouselMode).isRequired,
    slickSettingsOverride: PropTypes.object
};

export default HMImageCarousel;
