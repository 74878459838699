import React, {Component} from 'react';
import i18n from "../../../../../../i18n";
import BlankImage from "../../../../../../assets/flags/blank.gif";
import "../../../../../../assets/flags/flags.min.css";
import "./style.scss";

class LanguageSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    render() {

        return (
            <div className="hm-language-selector d-flex flex-row justify-content-start flex-wrap">
                <div>
                    <img src={BlankImage} className={`flag flag-se ${i18n.language === 'sv' ? "selected" : "not-selected pointer"}`} alt="svenska" title="svenska" onClick={() => i18n.changeLanguage('sv')}/>
                </div>
                <div>
                    <img src={BlankImage} className={`flag flag-dk ${i18n.language === 'da' ? "selected" : "not-selected pointer"}`} alt="dansk" title="dansk" onClick={() => i18n.changeLanguage('da')}/>
                </div>
                <div>
                    <img src={BlankImage} className={`flag flag-gb ${i18n.language === 'en' ? "selected" : "not-selected pointer"}`} alt="english" title="english" onClick={() => i18n.changeLanguage('en')}/>
                </div>
                <div>
                    <img src={BlankImage} className={`flag flag-no ${i18n.language === 'nb' ? "selected" : "not-selected pointer"}`} alt="norsk" title="norsk" onClick={() => i18n.changeLanguage('nb')}/>
                </div>
            </div>
        )
    }
}

export default LanguageSelector;
