import * as types from "./actionTypes";
import BackendAuthApi from "../api/BackendAuthApi";
import AuthService from "../services/AuthService";

export function authorizingStarted() {
    return {type: types.AUTHORIZING_STARTED, payload: {}};
}

export function authorizingSucceeded(reauthorize = false, username) {
    return {type: types.AUTHORIZING_SUCCEEDED, payload: {reauthorize: reauthorize, username: username}};
}

export function authorizingFailed() {
    return {type: types.AUTHORIZING_FAILED, payload: {}};
}

export function userBecameUnauthorized() {
    return {type: types.USER_BECAME_UNAUTHORIZED, payload: {}};
}

export function authorize(username, password) {
    return function authorize(dispatch) {

        dispatch(authorizingStarted());

        BackendAuthApi.authorize(username, password).then((response) => {
            if (response) {
                AuthService.storeAuthentication(response.access_token);
                dispatch(authorizingSucceeded(false, response.username));
            } else {
                dispatch(authorizingFailed());
            }
        });
    }
}

export function reauthorize() {
    return function authorize(dispatch) {
        dispatch(authorizingSucceeded(true));
    }
}

export function resetAuth() {
    return {type: types.RESET_AUTH, payload: {}};
}

export function sendForgotPasswordResetLinkStarted() {
    return {type: types.SEND_FORGOT_PASSWORD_RESET_LINK_STARTED, payload: {}};
}

export function sendForgotPasswordResetLinkSucceeded() {
    return {type: types.SEND_FORGOT_PASSWORD_RESET_LINK_SUCCEEDED, payload: {}};
}

export function sendForgotPasswordResetLinkFailed() {
    return {type: types.SEND_FORGOT_PASSWORD_RESET_LINK_FAILED, payload: {}};
}

export function sendForgotPasswordResetLink(username) {
    return function authorize(dispatch) {

        dispatch(sendForgotPasswordResetLinkStarted());

        BackendAuthApi.forgotPassword(username).then((response) => {
            if (response) {
                dispatch(sendForgotPasswordResetLinkSucceeded());
            } else {
                dispatch(sendForgotPasswordResetLinkFailed());
            }
        });
    }
}

export function resetPasswordStarted() {
    return {type: types.RESET_PASSWORD_STARTED, payload: {}};
}

export function resetPasswordSucceeded(username) {
    return {type: types.RESET_PASSWORD_SUCCEEDED, payload: {username: username}};
}

export function resetPasswordFailed() {
    return {type: types.RESET_PASSWORD_FAILED, payload: {}};
}

export function resetPassword(resetToken, newPassword) {
    return function authorize(dispatch) {

        dispatch(resetPasswordStarted());

        BackendAuthApi.resetPassword(resetToken, newPassword).then((user) => {
            if (user) {
                dispatch(resetPasswordSucceeded(user.username));
            } else {
                dispatch(resetPasswordFailed());
            }
        });
    }
}
