import ImageNotFound from "../assets/imageNotFound.png";

export default class ProjectService {
    static processProjectImages(project) {
        if (!project) return project;

        if (!project.moodImages)
            project.moodImages = [];

        if (project.moodImages.length === 0) {
            project.moodImages = [{
                url: ImageNotFound,
                thumbnailUrl: ImageNotFound
            }];
        }

        project.moodImagesSorted = {};

        const metaData = [{name: 'header', imageNotFoundIfEmpty: true, landingSectionType: "Header"}, {name: 'statics', imageNotFoundIfEmpty: false, landingSectionType: "FullWidthImages"}];
        metaData.forEach(md => {
            // try find mood images in the new landing sections
            ['apartmentLandingSections', 'landingSections'].forEach(landingSectionsName => {
                if (!!project[landingSectionsName] && !project.moodImagesSorted[md.name] || project.moodImagesSorted[md.name].length === 0) {
                    project.moodImagesSorted[md.name] = project[landingSectionsName]
                        .filter(ls => ls.sectionType === md.landingSectionType && !!ls.images && ls.images.length > 0)
                        .map(ls => ls.images)
                        .reduce((allImages, sectionImages) => { return allImages.concat(sectionImages); }, []);
                }
            })

            if (!project.moodImagesSorted[md.name] || project.moodImagesSorted[md.name].length === 0) {
                // if not found - try find them in old mood images
                project.moodImagesSorted[md.name] = project.moodImages.filter(attachment => attachment.metaData && attachment.metaData[md.name]);
            }

            if ((!project.moodImagesSorted[md.name] || project.moodImagesSorted[md.name].length === 0) && md.imageNotFoundIfEmpty)
                project.moodImagesSorted[md.name] = [{
                    url: ImageNotFound,
                    thumbnailUrl: ImageNotFound
                }];
        });

        return project;
    }
}
