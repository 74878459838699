import React, {Component} from 'react';
import PropTypes from "prop-types";
import './style.scss';
import UtilService from "../../../../services/UtilService";
import RouterService from "../../../../services/RouterService";
import {ADDON} from "../../../../enums/appModes";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class HeaderActions extends Component {

    render() {

        if (!this.props.project || !this.props.project.project)
            return null;

        const { t } = this.props;

        const apartments = (this.props.project.project.apartments || [])
            .filter(a => this.props.appMode === ADDON || a.publicKeyEnabled);

        return (
            <div className="row justify-content-center hm-landing-page-section-header-actions">
                <div className="col-auto hm-landing-page-section-header-actions-col-auto">
                    <div className="row hm-landing-page-section-header-actions-wrapper">

                        {/*Buttons don't necessarily describes apartments*/}
                {/*<div className="col-auto hm-header-actions-container">*/}
                {/*    <div className="row justify-content-center">*/}
                {/*        <div className="col-auto">*/}
                {/*            <h3>{t('projectLandingPage.chooseApartments')}</h3>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {apartments && apartments.length > 0 && UtilService.arrayToChunks(apartments, 3).map((chunk, i) => {
                    return <div key={`apartment-chunk-${i}`} className="col-auto hm-header-actions-container">
                        <div className="row justify-content-center apartment-links">
                            {chunk.map(apartment => {
                                return (
                                    <div key={`apartment-${apartment.id}`} className="apartment-links-col-auto col-auto">
                                        <Link to={RouterService.chooseApartmentApartmentPath(this.props.project.projectId, this.props.appMode === ADDON ? apartment.id : apartment.publicKey)}>
                                            <div className="card">
                                                <span>{apartment.name}</span>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                })}
                    </div>
                </div>
            </div>
        )
    }
}

HeaderActions.propTypes = {
    project: PropTypes.object,
    appMode: PropTypes.string,
};

export default withTranslation('common')(HeaderActions);
