import React, {Component} from 'react';
import PropTypes from "prop-types";
import {AppRoute} from "../../../../../services/RouterService";
import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import {ADDON} from "../../../../../enums/appModes";
import UtilService from "../../../../../services/UtilService";
import {bindActionCreators} from "redux";
import * as apartmentActions from "../../../../../actions/apartmentActions";
import './style.scss';
import HMButton from "../../../../Utils/HMButton";
import ChatDropdownMessage from "./ChatDropdownMessage";
import {withBus} from 'react-bus';
import {HM_TOPBAR_CHAT_TOGGLE} from "../../../../../actions/eventTypes";

class ChatDropdown extends Component {

    _isMounted = false;
    _firstUnread = undefined;
    _messagesEnd = undefined;
    _markAsReadTimeout = undefined;
    _dropdownMenu = undefined;
    _onToggle = undefined;

    constructor(props) {
        super(props);

        this.state = {
            defaultMessageBoxGreeting: undefined,
            expanded: false,
            newMessageText: "",
            sendingMessage: false
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this._onToggle = () => {
            this.toggle();
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchDefaultMessageBoxGreeting();
        this.scrollToFirstUnreadMessage();
        this.props.bus.on(HM_TOPBAR_CHAT_TOGGLE, this._onToggle);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.apartmentId !== prevProps.apartmentId) {
            this.setState({...this.state, defaultMessageBoxGreeting: undefined});
        }

        if (!this.state.defaultMessageBoxGreeting) {
            this.fetchDefaultMessageBoxGreeting();
        }

        this.scrollToFirstUnreadMessage();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.bus.off(HM_TOPBAR_CHAT_TOGGLE, this._onToggle);

        if (this._markAsReadTimeout)
            clearTimeout(this._markAsReadTimeout);

        this.removeListeners();
    }

    fetchDefaultMessageBoxGreeting() {
        if (this.props.appMode === ADDON
            && UtilService.includesAnyOf(this.props.currentRoutes, [AppRoute.Apartment])
            && this.props.auth.authorized
            && this.props.apartment
            && !this.state.defaultMessageBoxGreeting
            && (!this.props.messages || this.props.messages.length === 0)
        ) {
            this.props.apartmentActions.getDefaultMessageBoxGreeting()
                .then(greetings => {
                    if (this._isMounted)
                        this.setState({...this.state, defaultMessageBoxGreeting: greetings});
                })
                .catch(error => {
                    if (this._isMounted)
                        this.setState({...this.state, defaultMessageBoxGreeting: []});
                });
        }
    }

    messages() {
        return (this.props.messages && this.props.messages.length > 0
            ? this.props.messages
            : this.state.defaultMessageBoxGreeting || [])
                .sort((m1, m2) => m1.id - m2.id);
    }


    toggle() {
        if (this.messages().length === 0) {
            return;
        }

        if (!this.state.expanded && this.anyUnreadByCustomer()) {
            this._markAsReadTimeout = setTimeout(() => this.markMessagesAsRead(), 5000);
        }

        if (this.state.expanded && this._markAsReadTimeout) {
            clearTimeout(this._markAsReadTimeout);
            this._markAsReadTimeout = undefined;
        }

        const expanded = !this.state.expanded;

        this.setState({...this.state, expanded: expanded});

        if (expanded) {
            this.addListeners();
        } else {
            this.removeListeners();
        }
    }

    addListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    }

    removeListeners() {
        ['click', 'touchstart'].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }

    handleDocumentClick(e) {
        if (!this.state.expanded || (this._dropdownMenu && (this._dropdownMenu.contains(e.target) || this._dropdownMenu === e.target))) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.toggle();
    }

    hoverOn() {
        this.setState({...this.state, hover: true});
    }

    hoverOff() {
        this.setState({...this.state, hover: false});
    }

    markMessagesAsRead() {
        if (!this.anyUnreadByCustomer()) return;

        this.props.apartmentActions.markMessagesAsRead();
    }

    anyUnreadByCustomer() {
        return this.messages().some(message => !message.isReadByCustomer);
    }

    firstUnreadMessageId() {
        const firstUnreadMessage = this.messages().find(message => !message.isReadByCustomer);
        return firstUnreadMessage ? firstUnreadMessage.id : undefined;
    }

    scrollToFirstUnreadMessage() {
        if (this.messages().length > 0 && this._firstUnread && this.state.expanded) {
            this._firstUnread.scrollIntoView({ behavior: 'smooth' });
        }
    }

    sendMessage() {
        this.setState({...this.state, sendingMessage: true});

        this.props.apartmentActions.sendNewMessage(this.state.newMessageText)
            .then(() => {
                if (!this._isMounted) return;
                this.setState({...this.state, sendingMessage: false, newMessageText: ''});
                setTimeout(() => {
                    if (!this._isMounted || !this._messagesEnd) return;
                    this._messagesEnd.scrollIntoView({ behavior: 'smooth' });
                },10);
            })
            .catch(() => {
                if (!this._isMounted) return;
                this.setState({...this.state, sendingMessage: false});
            });
    }

    render() {

        if (this.props.appMode !== ADDON
            || !UtilService.includesAnyOf(this.props.currentRoutes, [AppRoute.Apartment])
            || !this.props.auth.authorized
            || !this.props.apartmentId)
            return null;

        const { t } = this.props;

        this._firstUnread = undefined;

        return (
            <li className="hm-chat-dropdown hm-hide-on-small-screens nav-item dropdown" ref={(el) => { this._dropdownMenu = el; }}>
                <div className="action-link pointer"
                     title={t('topBar.chat')}
                     onClick={() => this.toggle()}
                     onMouseEnter={() => this.hoverOn()}
                     onMouseLeave={() => this.hoverOff()}>
                    <div id="tutorial-id-message-area" className="action-link">
                        <clr-icon shape="chat-bubble" class={`${this.state.hover || this.state.expanded ? "is-solid" : ""} ${this.anyUnreadByCustomer() ? "has-badge" : ""}`}/>
                    </div>
                </div>

                <div className={`hm-chat dropdown-menu dropdown-menu-right ${this.state.expanded ? "show" : ""}`}>
                    <div className="row scrollable-messages-wrapper">
                        {this.messages().map(message => {
                            return (
                                <div key={`message-${message.id}`} className="col-12 p-0 m-0">
                                    <ChatDropdownMessage message={message}/>
                                    {message.id === this.firstUnreadMessageId() &&
                                        <div style={{ float:"left", clear: "both" }}
                                             ref={(el) => { this._firstUnread = el; }}>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        <div style={{ float:"left", clear: "both" }}
                             ref={(el) => { this._messagesEnd = el; }}>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <textarea
                                className="form-control new-message-text"
                                placeholder={t('topBar.writeMessage')}
                                value={this.state.newMessageText}
                                disabled={this.state.sendingMessage}
                                onChange={(event) => this.setState({...this.state, newMessageText: event.target.value})}
                            />
                        </div>
                        <div className="col-12">
                            <div className="send-action-wrapper float-right">
                                <HMButton text={t('send')}
                                          active={this.state.newMessageText !== ''}
                                          activeText={t('send')}
                                          clickFn={() => {this.sendMessage()}}
                                          disabled={this.state.sendingMessage || this.state.newMessageText === ''}
                                          disabledText={this.state.sendingMessage ? "<i class='fas fa-spinner fa-spin'></i> " + t('sending') : t('send')}
                                          thinStyle={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appMode: state.app.appMode,
        auth: state.app.auth,
        apartment: state.app.apartment2.apartment,
        apartmentId: state.app.apartment2.apartmentId,
        messages: state.app.apartment2.apartment ? state.app.apartment2.apartment.messages : undefined
    }
}

function mapDispatchToProps(dispatch) {
    return {
        apartmentActions: bindActionCreators(apartmentActions, dispatch)
    }
}

ChatDropdown.propTypes = {
    currentRoutes: PropTypes.arrayOf(PropTypes.instanceOf(AppRoute)).isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(withBus()(withTranslation('common')(ChatDropdown)));
